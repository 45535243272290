import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  ListGroup,
  ListGroupItem,
  ButtonGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  InputGroup,
  InputGroupText
} from 'reactstrap';
import Heading from '../../common/Typo/heading';
import {
  fetchMedia,
  deleteMedia,
  fetchBanners
} from '../../services/redux/reducers/media/actionCreators';
import MediaForm from './editForm';
import UploadForm from './uploadForm';
import MediaItem from '../../common/Media/item';
// import API from '../../services/api';
import { calculateMaxPages } from '../../utilities/calculates';

const MediaPagination = (count, currentPage, changePage) => {
  let pages = calculateMaxPages(count, 10);
  let pagination = new Set();
  for (let i = 0; i < pages; i++) {
    if (
      i === 0 ||
      (currentPage - 2 < i && currentPage + 2 > i) ||
      i === pages - 1
    ) {
      pagination.add(
        <PaginationItem key={i}>
          <PaginationLink
            href="#"
            onClick={(e) => {
              e.preventDefault();
              changePage(i);
            }}
          >
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      );
    } else if (i === currentPage + 2 || i === currentPage - 2) {
      pagination.add(
        <PaginationItem>
          <PaginationLink>...</PaginationLink>
        </PaginationItem>
      );
    }
  }
  return (
    <>
      <Pagination>
        {pagination}
        <PaginationItem>
          <InputGroup>
            <InputGroupText className="bg-white border-left-0">
              Przejdź do:
            </InputGroupText>
            <Input
              type="number"
              max={pages}
              min={1}
              style={{ maxWidth: '65px' }}
              onBlur={(e) => {
                const newPage = parseInt(e.target.value);
                if (newPage > 0 && newPage <= pages) {
                  changePage(newPage);
                }
              }}
            />
          </InputGroup>
        </PaginationItem>
      </Pagination>
    </>
  );
};

class MediaLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFiles: [],
      editMode: -1,
      data: [],
      page: 0,
      pages: null,
      loading: true,
      filters: ['image', 'audio', 'document', 'video'],
      filterValue: this.props.defaultFiter || '',
      initialValues: {
        files: []
      }
    };
    // this.selectFile = this.selectFile.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    this.props.fetchBanners();
  }

  handleFilter = (e) => {
    this.setState({ filterValue: e.target.value }, () => {
      this.fetchData();
    });
  };

  handlePage = (page) => {
    this.setState({ page: page }, () => {
      this.fetchData();
    });
  };

  fetchData() {
    const { filterValue, page } = this.state;
    this.props.fetchMedia(page, filterValue);
  }

  selectFile = (e, file) => {
    e.preventDefault();
    this.props.selectedFile(file);
  };

  addFile = (acceptedFiles, setFieldValue, values) => {
    if (values.files && Array.isArray(acceptedFiles)) {
      let newFiles = [];
      acceptedFiles.forEach((element) => {
        newFiles.push({ file: element, description: '' });
      });
      setFieldValue('files', [...values.files, ...acceptedFiles]);
    } else {
      setFieldValue('files', [
        ...values.files,
        { file: acceptedFiles, description: '', name: acceptedFiles.name }
      ]);
    }
  };

  editFile = (e, file) => {
    this.setState({
      editMode: file.id
    });
  };

  checkIfMediumActive = (id) => {
    const { filesList } = this.props;
    if (filesList) {
      return filesList.findIndex((el) => el.id === id) >= 0;
    }
    return false;
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.mediaRequest.uploading && this.props.mediaRequest.uploaded) {
      setTimeout(() => {
        this.setState({
          initialValues: {
            files: []
          }
        });
      }, 1000);
    }
  }

  render() {
    const { selectMedia, media, defaultFiter, deleteMedia } = this.props;
    const { data: mediaList, count } = media;
    const { filters, filterValue, page } = this.state;

    return (
      <>
        <UploadForm />
        <Heading title="Lista mediów" />
        {!defaultFiter ? (
          <Form>
            <FormGroup>
              <Label for="filters">Filtruj pliki</Label>
              <Input
                type="select"
                name="select"
                id="filters"
                onChange={this.handleFilter}
                value={filterValue}
              >
                <option value="">Wybierz</option>
                {filters.map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Form>
        ) : null}
        <ListGroup>
          {mediaList.length ? (
            mediaList.map((medium) => (
              <ListGroupItem
                key={medium.id}
                className="listItem--with-button listItem--with-button-top"
                action
                active={this.checkIfMediumActive(medium.id)}
              >
                <div className="files-item">
                  <MediaItem medium={medium} />
                  <ButtonGroup size="sm">
                    {selectMedia !== undefined ? (
                      <Button
                        color="primary"
                        onClick={(e) => this.selectFile(e, medium)}
                        size="sm"
                        disabled={this.checkIfMediumActive(medium.id)}
                      >
                        Wybierz
                      </Button>
                    ) : null}
                    <Button onClick={(e) => this.editFile(e, medium)}>
                      Edytuj
                    </Button>
                    {selectMedia === undefined ? (
                      <Button
                        color="danger"
                        onClick={() => {
                          deleteMedia(medium.id);
                          this.fetchData();
                        }}
                      >
                        Usuń
                      </Button>
                    ) : null}
                  </ButtonGroup>
                  <MediaForm
                    show={this.state.editMode === medium.id}
                    hide={() => this.setState({ editMode: -1 })}
                    file={medium}
                  />
                </div>
              </ListGroupItem>
            ))
          ) : (
            <ListGroupItem>Brak plików</ListGroupItem>
          )}
          <div className="pt-2">
            {MediaPagination(count, page, (page) => this.handlePage(page))}
          </div>
        </ListGroup>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { media, articles, mediaRequest } = state;
  return {
    media,
    articleMedia: articles.media,
    mediaRequest
  };
}

export default connect(mapStateToProps, {
  fetchMedia,
  deleteMedia,
  fetchBanners
})(MediaLayout);
