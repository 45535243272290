import React from "react";
import Layout from "../../components/Layout/index.jsx";
import { getQueryStringValue } from "../../utilities/history";
import { MultimediaForm } from '../../components/Multimedia/MultimediaForm.jsx';
import { useParams } from 'react-router';

export const MultimediaEdit = () => {
  const { id } = useParams()
  return (
    <Layout>
      <h1 className="h2 pb-md-4 page-header">
        {getQueryStringValue("group") ? (
          <>
            <strong>Dodajesz tłumaczenie</strong> multimedium
          </>
        ) : (
          "Edycja multimedium"
        )}
      </h1>{" "}
      <MultimediaForm multimediaId={id} />
    </Layout>
  );
}
