import slugify from 'slugify';

export const PRODUCTS_FETCH = 'products/fetch';
export const PRODUCTS_SET = 'products/set';
export const PRODUCTS_SET_RESOURCE_STATE = 'products/setResourceState';
export const PRODUCTS_SET_ACTIONS_RESOURCE_STATE =
  'products/setActionsResourceState';
export const PRODUCTS_ADD = 'products/add';
export const PRODUCTS_REMOVE = 'products/remove';
export const PRODUCTS_UPDATE = 'products/update';

export const fetchProducts = () => ({
  type: PRODUCTS_FETCH
});

export const addNewProduct = (names, titles, creator, slug) => ({
  type: PRODUCTS_ADD,
  names,
  titles,
  creator: creator || undefined,
  slug: !!slug.length ? slugify(slug) : undefined
});

export const deleteProduct = (id) => ({
  type: PRODUCTS_REMOVE,
  id
});

export const updateProduct = (product) => ({
  type: PRODUCTS_UPDATE,
  product
});

export const setProducts = (data) => ({
  type: PRODUCTS_SET,
  data
});

export const setProductsResourceState = (data) => ({
  type: PRODUCTS_SET_RESOURCE_STATE,
  data
});

export const setProductsActionsResourceState = (data) => ({
  type: PRODUCTS_SET_ACTIONS_RESOURCE_STATE,
  data
});
