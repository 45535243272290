import * as actions from './actionTypes';
import { SUCCESS } from '../../utils/actionSuffix';

const InitialState = {
  id: -1,
  token: {
    expired_date: '',
    key: ''
  },
  email: '',
  username: '',
  first_name: '',
  last_name: '',
  user_type: '',
  edit: '',
  logs: null
};

export function user(state = InitialState, action) {
  switch (action.type) {
    case actions.SET_USER_TOKEN:
      return {
        ...state,
        token: {
          ...state.token,
          key: action.token
        }
      };

    case actions.SET_USER_API_DATA:
      return {
        ...state,
        token: {
          ...state.token,
          key: action.token
        },
        id: action.id
      };

    case actions.SET_USER_DATA:
      return {
        ...state,
        ...action.data
      };

    case actions.FETCH_USER_DATA:
      return {
        ...state,
        ...action.data
      };

    case actions.FETCH_USER_LOGS + SUCCESS:
      return {
        ...state,
        logs: action.data
      };

    case actions.LOGOUT_USER:
      return {
        ...state,
        InitialState
      };

    case actions.SET_USER_DATA_EDIT:
      return {
        ...state,
        edit: action.data.data
      };

    case actions.RESET_REQ:
      return {
        ...state,
        edit: ''
      };

    default:
      return state;
  }
}
