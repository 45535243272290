import React, { useState } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner
} from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

import { selectCMSLang } from '../../services/redux/reducers/selectors';
import Layout from '../../components/Layout/index.jsx';
import QuizzesList from '../../components/Quizzes/components/QuizzesList/QuizzesList';
import { useQuizzesApi } from '../../components/Quizzes/Quizzes.api';
import { QuizzesProvider } from '../../components/Quizzes/Quizzes.context';

import './Quizzes.scss';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Pole wymagane'),
  description: Yup.string().required('Pole wymagane'),
  highlight_image: Yup.mixed().required('Pole wymagane')
});

function QuizzesPage() {
  const [isModalVisible, setModalVisible] = useState(false);
  const { postQuizzes } = useQuizzesApi();
  const lang = useSelector(selectCMSLang);
  const history = useHistory();

  const handleSubmit = ({ title, description, highlight_image }, formik) => {
    const form = new FormData();
    form.append('title', title);
    form.append('description', description);
    form.append('highlight_image', highlight_image);
    form.append('lang_code', lang);

    formik.setSubmitting(true);

    postQuizzes(form).then(({ data }) => {
      formik.setSubmitting(false);
      history.push(
        generatePath('/quizzes/:slug', {
          slug: data.slug
        })
      );
    });
  };

  return (
    <Layout>
      <div className="Quizzes">
        <h1 className="h2 pb-md-4 page-header">
          Quizy
          <Button
            onClick={() => setModalVisible(true)}
            color="primary"
            type="button"
          >
            Dodaj Nowy
          </Button>
        </h1>
        <Row>
          <Col xs={12}>
            <QuizzesProvider>
              <QuizzesList />
            </QuizzesProvider>
          </Col>
          <Col xs={12} className="Quizzes__actions">
            <Button
              onClick={() => setModalVisible(true)}
              color="primary"
              type="button"
            >
              Dodaj Nowy
            </Button>
          </Col>
        </Row>
      </div>
      <Modal isOpen={isModalVisible}>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={{ title: '', description: '', highlight_image: null }}
          render={({
            values,
            handleSubmit,
            handleChange,
            errors,
            touched,
            setFieldValue,
            isSubmitting
          }) => (
            <Form onSubmit={handleSubmit}>
              <ModalHeader toggle={() => setModalVisible(!isModalVisible)}>
                Dodaj nowy Quiz
              </ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="title">Tytuł</Label>
                  <Input
                    type="text"
                    name="title"
                    id="title"
                    invalid={!!errors.title && touched.title}
                    value={values.title}
                    onChange={handleChange}
                  />
                  {!!errors.title && touched.title && (
                    <FormFeedback>{errors.title}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="description">Opis</Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="description"
                    value={values.description}
                    onChange={handleChange}
                    invalid={!!errors.description && touched.description}
                  />
                  {!!errors.description && touched.description && (
                    <FormFeedback>{errors.description}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="highlight_image">Okładka</Label>
                  <Input
                    type="file"
                    name="highlight_image"
                    id="highlight_image"
                    onChange={(event) => {
                      setFieldValue('highlight_image', event.target.files[0]);
                    }}
                    invalid={!!errors.highlight_image}
                  />
                  {!!errors.highlight_image && (
                    <FormFeedback>{errors.highlight_image}</FormFeedback>
                  )}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                {isSubmitting ? (
                  <Spinner color="primary" />
                ) : (
                  <>
                    <Button type="submit" color="primary">
                      Utwórz
                    </Button>
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => setModalVisible(!isModalVisible)}
                    >
                      Anuluj
                    </Button>
                  </>
                )}
              </ModalFooter>
            </Form>
          )}
        />
      </Modal>
    </Layout>
  );
}

export default QuizzesPage;
