import React from 'react';
import { Button, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import SelectMedia from '../../components/Media/selectMedia';
import MediaItem from '../Media/item';

export const SelectAttachments = ({ value, setFieldValue, errors }) => {
  return (
    <FormGroup>
      <Label>
        Załączniki
      </Label>
      <Input
        type="hidden"
        name="medias_list"
        required
        value={value}
        invalid={!!errors.medias_list}
      />
      {value
        ? value.map((item, index) => (
            <div
              className="d-flex align-items-start justify-content-between"
              key={`article_media_${item.id}`}
            >
              <MediaItem medium={item} />
              <Button
                className="btn-with-icon"
                onClick={() => {
                  const mediaList = [...value]
                  mediaList.splice(index, 1)
                  setFieldValue(mediaList)
                }}
              >
                <i className="bi-x-lg"></i>
              </Button>
            </div>
          ))
        : null}
      <SelectMedia
        selectType="files"
        filesList={value}
        selectedFile={file => {
          setFieldValue([
            ...value,
            file
          ]);
        }}
      />
      <FormFeedback>{errors.medias_list}</FormFeedback>
    </FormGroup>
  )
}
