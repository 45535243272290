import React from 'react';
import { Icon } from 'agronomist-ui-components';
import { FormGroup, Label, Input } from 'reactstrap';

import { icons, spaces } from '../ElementsForms/elementsForms.utils';
import MediaButton from '../MediaButton'

const IconsInput = ({ labelProps, inputProps }) => {
  return (
    <FormGroup>
      <Label for={labelProps.for}>
        Ikonka
        <div style={{ width: '3rem', display: 'inline-block' }}>
          <Icon name={inputProps.value} />
        </div>
      </Label>
      <Input
        id={inputProps.id}
        name={inputProps.name}
        value={inputProps.value}
        type="select"
        onChange={inputProps.onChange}
      >
        {icons.map((icon) => (
          <option value={icon} key={icon}>
            {icon}
          </option>
        ))}
      </Input>
    </FormGroup>
  );
};

const SpacesInput = (props) => {
  return (
    <FormGroup>
      <Label for={props.name}>Odstęp</Label>
      <Input type="select" {...props}>
        {spaces.map((space) => (
          <option value={space.value} key={space.label}>
            {space.label}
          </option>
        ))}
      </Input>
    </FormGroup>
  );
};

export default {
  IconsInput,
  SpacesInput,
  MediaButton
};
