import * as actionTypes from './actionTypes';
import { SUCCESS, FAILURE } from '../../utils/actionSuffix';
// add types
const InitialState = {
  isLoginUser: false,
  fetchUserDataError: null,
  createdUser: false,
  newUser: {
    errorBody: {}
  }
};

export function userRequest(state = InitialState, action) {
  switch (action.type) {
    case actionTypes.LOGIN_USER:
      return { ...state, isLoginUser: true, fetchUserDataError: false };

    case actionTypes.FETCH_USER_DATA:
      return { ...state, isLoginUser: true, fetchUserDataError: false };

    case actionTypes.SET_USER_DATA + SUCCESS:
    case actionTypes.FETCH_USER_DATA + SUCCESS:
      return { ...state, isLoginUser: false, fetchUserDataError: false };

    case actionTypes.FETCH_USER_DATA + FAILURE:
    case actionTypes.DELETE_USER + FAILURE:
      return { ...state, fetchUserDataError: action.error };

    case actionTypes.CREATE_USER + FAILURE:
      return {
        ...state,
        newUser: {
          errorBody: action.error.body
        }
      };

    case actionTypes.CREATE_USER + SUCCESS:
    case actionTypes.SET_USER_DATA_EDIT + SUCCESS:
      return {
        ...state,
        createdUser: true
      };

    case actionTypes.RESET_REQ:
      return {
        ...state,
        createdUser: false,
        newUser: {
          errorBody: {}
        }
      };

    default:
      return state;
  }
}
