import * as userSelectors from './user/selectors';
import * as articleTypesSelectors from './articlesTypes/selectors';
import * as articlesCategoriesSelectors from './articlesCategories/selectors';
import * as settingsSelectors from './settings/selectors';

export const selectToken = (state) => userSelectors.selectToken2(state.user);

export const selectUser = (state) => userSelectors.selectId2(state.user);
export const selectUserEmail = (state) =>
  userSelectors.selectUserEmail(state.user);

export const selectArticleCategory = (state) =>
  articlesCategoriesSelectors.selectSelectedCat(state.articleCategories);

export const selectNewArticleCategories = (state) =>
  articlesCategoriesSelectors.selectNewCategories(state.articleCategories);

export const selectArticleTypeId = (state) =>
  articleTypesSelectors.selectType(state.articleTypes);

export const selectCMSLang = (state) =>
  settingsSelectors.selectLang(state.settings);
