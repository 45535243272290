import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import QuizAnswers from '../QuizAnswers/QuizAnswers';
import { QuizContext } from '../../Quiz.context';

import './QuizQuestions.scss';

function QuizQuestions() {
  const {
    quiz,
    questions,
    createNewQuestion,
    removeQuestion,
    updateQuestion,
    createNewAnswer,
    updateAnswer,
    removeAnswer
  } = useContext(QuizContext);
  const handleAddQuestion = () =>
    createNewQuestion().then(() => {
      toast.success(`Nowe pytanie zostało dodane do "${quiz.title}"`);
    });

  const updateQuestions = ({ question_text, id }) =>
    updateQuestion({ question_text, question_id: id }).then(() => {
      toast.success('Pytanie zostało zaktualizowane');
    });

  const handleAddAnswer = (question) =>
    createNewAnswer(question).then(() => {
      toast.success(
        `Nowa odpowiedź została dodana do pytania "${question.question_text}"`
      );
    });

  const handleUpdateAnswer = (answer) =>
    updateAnswer(answer).then(() => {
      toast.success('Odpowiedź została zaktualizowana');
    });

  const handleRemoveQuestion = (question) =>
    removeQuestion(question.id).then(() => {
      toast.success(`Pytanie "${question.question_text}" zostało usunięte`);
    });

  const handleRemoveAnswer = (answer) =>
    removeAnswer(answer.id).then(() => {
      toast.success(`Odpowiedź "${answer.answer_text}" została usunięta`);
    });

  const updateField = (field, question) => {
    if (field.value !== '' && field.value !== question[field.name]) {
      return updateQuestions({
        ...question,
        [field.name]: field.value
      });
    }

    return (field.value = question[field.name]);
  };

  if (!quiz || !questions) {
    return null;
  }

  const QUIZ_IS_PUBLISHED = Number(quiz.publish_status) === 3;

  return (
    <div className="QuizQuestions">
      {questions.map((question) => (
        <div className="QuizQuestions__question">
          <div className="QuizQuestions__question__title">
            <input
              className="QuizQuestions__question_text"
              defaultValue={question.question_text}
              name="question_text"
              onBlur={(e) => updateField(e.target, question)}
              disabled={QUIZ_IS_PUBLISHED}
            />
            {!QUIZ_IS_PUBLISHED && (
              <FontAwesomeIcon
                icon={faTrashAlt}
                onClick={() => handleRemoveQuestion(question)}
              />
            )}
          </div>
          <QuizAnswers
            answers={question.answers}
            onAddAnswer={() => handleAddAnswer(question)}
            onUpdateAnswer={(answer) => handleUpdateAnswer(answer)}
            onRemoveAnswer={(answer) => handleRemoveAnswer(answer)}
            readOnly={QUIZ_IS_PUBLISHED}
          />
        </div>
      ))}
      {!questions.length && (
        <Alert color="info">
          Twój Quiz nie zawiera żadnych pytań. Utwórz swoje pierwsze pytanie.
        </Alert>
      )}

      {!QUIZ_IS_PUBLISHED && (
        <div className="QuizQuestions__actions">
          <Button color="success" size="lg" block onClick={handleAddQuestion}>
            Dodaj nowe pytanie
          </Button>
        </div>
      )}
    </div>
  );
}

QuizQuestions.propTypes = {
  questionss: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      question_text: PropTypes.string.isRequired,
      multi_choice: PropTypes.bool.isRequired,
      quiz: PropTypes.string.isRequired,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          question: PropTypes.string.isRequired,
          answer_text: PropTypes.string.isRequired,
          answer_image: PropTypes.string,
          correct: PropTypes.bool.isRequired
        })
      ).isRequired
    })
  ).isRequired
};
QuizQuestions.defaultProps = {
  questionss: []
};

export default QuizQuestions;
