export const translations = {
  "1": "Informacja prasowa",
  "2": "Raporty",
  "3": "Food&Agro News",
  "4": "Food&Agro Trends",
  "5": "Raporty Przekrojowe",
  "6": "Agro hub markets",
  "7": "Sparks dziennie",
  "8": "Sparks miesięcznie",
  "9": "Sparks kwartalnie",
  "10": "Pozostałe",
  "11": "Partnerstwo",
  "12": "Shopper experience",
  "13": "Branding",
  "14": "Sprzedaż eksportowa",
  "15": "Nowe kanały sprzedaży",
  "16": "Category vision",
  "17": "Innowacje"
};

export const getTranslatedCategoryName = id => translations[id] || null;
