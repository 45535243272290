import { combineReducers } from 'redux';

import { alert } from './alerts/reducer';
import { articles } from './articles/reducer';
import { articlesRequest } from './articles/ArticlesRequestState';
import { articleTypes } from './articlesTypes/reducer';
import { articlesTypesRequest } from './articlesTypes/TypesRequestState';
import { articleCategories } from './articlesCategories/reducer';
import { events } from './events/reducer';
import { eventsRequest } from './events/EventsRequestState';
import { media } from './media/reducer';
import { mediaRequest } from './media/MediaRequestState';
import { user } from './user/reducer';
import { userRequest } from './user/UserRequestState';
import { settings } from './settings/reducer';
import { polls } from './polls/reducer';
import { products } from '../../../features/prf/state/prf.reducer';

const rootReducer = combineReducers({
  alert,
  articles,
  articlesRequest,
  articleTypes,
  articlesTypesRequest,
  articleCategories,
  events,
  eventsRequest,
  media,
  mediaRequest,
  user,
  userRequest,
  settings,
  polls,
  products
});

export default rootReducer;
