import React from 'react';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { selectUserEmail } from '../../services/redux/reducers/selectors';

const sidebarLinks = [
  {
    to: '/articles/',
    name: 'Artykuł'
  },
  {
    to: '/articles-categories',
    name: 'Kategorie artykułów'
  },
  {
    to: '/articles-tags',
    name: 'Tagi artykułów'
  },
  {
    to: '/agroraport/',
    name: 'Agroraport'
  },
  {
    to: '/agroraport-categories',
    name: 'Agroraport Kategorie'
  },
  {
    to: '/events',
    name: 'Wydarzenia'
  },
  {
    to: '/users',
    name: 'Użytkownicy'
  },
  {
    to: '/media',
    name: 'Media'
  },
  {
    to: '/multimedia',
    name: 'Multimedia'
  },
  {
    to: '/polls',
    name: 'Sondy'
  },

  {
    to: '/experts/conversations',
    name: 'Konwersacje ekspertów'
  },
  {
    to: '/show-off-section',
    name: 'Twoje pole do popisu'
  },
  {
    to: '/components',
    name: 'Komponenty'
  },
  {
    to: '/real-estate',
    name: 'Nieruchomości'
  },
  {
    to: '/videos',
    name: 'Wideo'
  },
  {
    to: '/quizzes/',
    name: 'Quizy'
  },
  {
    to: '/products',
    name: 'Produkty'
  }
];

const checkIfActive = (link) => window.location.pathname.indexOf(link) === 0;

const Sidebar = () => {
  const emails = ['dsn2@bnpparibas.pl', 'joka_32@interia.eu'];
  const userEmail = useSelector(selectUserEmail);

  return (
    <nav className="col-md-2 d-none d-md-block position-relative sidebar">
      <div className="sidebar--sticky">
        <ul className="nav flex-column">
          {sidebarLinks
            .filter(({ to }) => {
              if (emails.indexOf(userEmail) !== -1) {
                const is = ['/real-estate', '/media'].find((url) =>
                  url.includes(to)
                );
                if (!!is) {
                  return true;
                }
                return false;
              }
              return true;
            })
            .map((item, index) => (
              <li className="nav-item" key={`nav-${index}`}>
                <Link
                  to={item.to}
                  className={`nav-link ${
                    checkIfActive(item.to) ? 'nav-link--active' : ''
                  }`}
                >
                  {item.name}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
