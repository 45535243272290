export default {
  PROPERTIES: '/api/v1/properties/',
  COUNTIES: '/api/v1/counties/',
  PROVINCES: '/api/v1/provinces/',
  BOROUGHS: '/api/v1/boroughs/',
  TOWNS: '/api/v1/towns/',
  TRANSACTION_TYPES: '/api/v1/transaction-types/',
  PROPERTY_TYPES: '/api/v1/property-types/',
  PROPERTY: '/api/v1/properties/%s/',
  MEDIAS: '/api/v1/properties/%s/medias/',
  MEDIA: '/api/v1/properties/%s/medias/%s/',
  PROPERTY_QUERY: '/api/v1/properties/%s/query/',
  SUBSCRIBE: '/api/v1/newsletter/subscribe/',
  UNSUBSCRIBE: '/api/v1/newsletter/unsubscribe/',
  KEEPER: '/api/v1/keepers/',
  IMPORT_FILE: '/api/v1/properties/upload/'
};
