import React, { Component } from 'react';
import Layout from '../../components/Layout/index.jsx';
import UserForm from '../../components/Forms/UserForm';

class NewUser extends Component {
  render() {
    return (
      <Layout>
        <h1 className="h2 pb-md-4 page-header">Edytuj użytkownik</h1>
        {/* Content */}
        <UserForm userid={this.props.match.params.id} />
      </Layout>
    );
  }
}

export default NewUser;
