import React from 'react';
import { Button, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import SelectMedia from '../../components/Media/selectMedia';
import MediaItem from '../Media/item';

export const SelectPhotoForArticle = ({ value, setFieldValue, errors = {}, label }) => {
  return (
    <FormGroup>
      <Label>
        {label || 'Zdjęcie w kafelku'}
      </Label>
      <Input
        type="hidden"
        name="thumb_id"
        required
        value={value}
        invalid={!!errors.thumb_id}
      />
      {value ? (
        <div className="d-flex align-items-start justify-content-between">
          <MediaItem medium={value} hideSetBanner />
          <Button
            className="btn-with-icon"
            onClick={() => setFieldValue('')}
          >
            <i className="bi-x-lg"></i>
          </Button>
        </div>
      ) : null}
      <SelectMedia
        selectType="thumb"
        defaultFiter="image"
        filesList={[value]}
        selectedFile={file => {
          setFieldValue(file);
        }}
      />
      <FormFeedback>{errors.thumb_id}</FormFeedback>
    </FormGroup>
  )
}
