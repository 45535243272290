import React from 'react';
import { useFormik } from 'formik';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button
} from 'reactstrap';
import debounce from 'lodash.debounce';

import Inputs from '../../Inputs';

const BenefitsForm = ({
  title,
  subtitle,
  benefits,
  withArrow,
  py,
  onChange,
  anchor
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: title,
      subtitle: subtitle,
      benefits: benefits,
      withArrow: withArrow,
      py: py,
      anchor
    },
    onSubmit: (values) => {
      onChange(values);
    }
  });

  const onBlur = () => {
    formik.submitForm();
  };

  const debounceSave = debounce(() => {
    onBlur();
  }, 800);

  return (
    <Form>
      <Row>
        <Col xs={9}>
          <FormGroup>
            <Label for="title">Tytuł</Label>
            <Input
              id="title"
              name="title"
              type="textarea"
              value={formik.values.title}
              invalid={!!formik.errors.title}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('title', target.value);
                debounceSave();
              }}
            />
            {!!formik.errors.title && (
              <FormFeedback>{formik.errors.title}</FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="subtitle">Podtytuł</Label>
            <Input
              onBlur={onBlur}
              id="subtitle"
              name="subtitle"
              type="textarea"
              value={formik.values.subtitle}
              invalid={!!formik.errors.subtitle}
              onChange={({ target }) => {
                formik.setFieldValue('subtitle', target.value);
                debounceSave();
              }}
            />
            {!!formik.errors.subtitle && (
              <FormFeedback>{formik.errors.subtitle}</FormFeedback>
            )}
          </FormGroup>
          <Row>
            {formik.values.benefits.map((b, k) => (
              <Col>
                <FormGroup>
                  <Label for={`benefits[${k}].title`}>Tytuł</Label>
                  <Input
                    onBlur={onBlur}
                    id={`benefits[${k}].title`}
                    name={`benefits[${k}].title`}
                    type="textarea"
                    value={formik.values.benefits[k].title}
                    onChange={({ target }) => {
                      formik.setFieldValue(
                        `benefits[${k}].title`,
                        target.value
                      );
                      debounceSave();
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for={`benefits[${k}].description`}>Opis</Label>
                  <Input
                    onBlur={onBlur}
                    id={`benefits[${k}].description`}
                    name={`benefits[${k}].description`}
                    type="textarea"
                    value={formik.values.benefits[k].description}
                    onChange={({ target }) => {
                      formik.setFieldValue(
                        `benefits[${k}].description`,
                        target.value
                      );
                      debounceSave();
                    }}
                  />
                </FormGroup>
                <Inputs.IconsInput
                  labelProps={{ for: `benefits[${k}].icon` }}
                  inputProps={{
                    name: `benefits[${k}].icon`,
                    value: formik.values.benefits[k].icon,
                    onChange: ({ target }) => {
                      formik.setFieldValue(`benefits[${k}].icon`, target.value);
                      onBlur();
                    }
                  }}
                />
                {formik.values.benefits.length > 2 &&
                  formik.values.benefits.length <= 5 && (
                    <Button
                      fullWidth
                      onClick={() => {
                        formik.setFieldValue(
                          `benefits`,
                          [...formik.values.benefits].filter(
                            (_, key) => k !== key
                          )
                        );
                        onBlur();
                      }}
                      type="button"
                      style={{ marginBottom: '1rem' }}
                      block
                      color="danger"
                    >
                      Usuń
                    </Button>
                  )}
              </Col>
            ))}
          </Row>
        </Col>
        <Col xs={3}>
          <Inputs.SpacesInput
            id="py"
            name="py"
            value={formik.values.pt}
            invalid={!!formik.errors.pt}
            onBlur={onBlur}
            onChange={({ target }) => {
              formik.setFieldValue('py', target.value);
              debounceSave();
            }}
          />
          <FormGroup check>
            <Input
              type="radio"
              name="withArrow"
              checked={formik.values.withArrow}
              onChange={() => {
                formik.setFieldValue('withArrow', true);
                onBlur();
              }}
            />
            Z strzałką
          </FormGroup>

          <FormGroup check>
            <Input
              type="radio"
              name="withArrow"
              checked={!formik.values.withArrow}
              onChange={() => {
                formik.setFieldValue('withArrow', false);
                onBlur();
              }}
            />
            Bez strzałki
          </FormGroup>
          {formik.values.benefits.length <= 5 && (
            <Button
              fullWidth
              onClick={() => {
                formik.setFieldValue(`benefits`, [
                  ...formik.values.benefits,
                  {
                    title: 'Tytuł',
                    description: 'Opis',
                    icon: 'PaperShield'
                  }
                ]);
                onBlur();
              }}
              type="button"
              style={{ marginTop: '1rem' }}
              block
              color="success"
            >
              Dodaj benefit
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <FormGroup>
            <Label for="anchor">Zakotwiczenie</Label>
            <Input
              id="anchor"
              name="anchor"
              type="text"
              value={formik.values.anchor}
              invalid={!!formik.errors.anchor}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('anchor', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default BenefitsForm;
