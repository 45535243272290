import React from 'react';
import { Row, Col } from 'reactstrap';

import QuizQuestions from '../../components/Quizzes/components/QuizQuestions/QuizQuestions';
import Layout from '../../components/Layout/index.jsx';
import { QuizProvider } from '../../components/Quizzes/Quiz.context';
import QuizDetails from '../../components/Quizzes/components/QuizDetails/QuizDetails';

import './Quiz.scss';

function QuizPage() {
  return (
    <QuizProvider>
      <Layout>
        <Row className="Quiz">
          <Col xs={12} md={8} lg={9}>
            <QuizQuestions />
          </Col>
          <Col xs={12} md={4} lg={3}>
            <QuizDetails />
          </Col>
        </Row>
      </Layout>
    </QuizProvider>
  );
}

export default QuizPage;
