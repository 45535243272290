import React from 'react';
import { useFormik } from 'formik';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import debounce from 'lodash.debounce';

const HeadlineForm = ({
  onChange,
  py,
  title,
  subtitle,
  btnText,
  link,
  backgroundColor,
  color,
  list,
  backgroundColorItem,
  anchor
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      py,
      title,
      subtitle,
      btnText,
      link,
      backgroundColor,
      color,
      list: list || [],
      backgroundColorItem,
      anchor
    },
    onSubmit: (values) => {
      onChange(values);
    }
  });

  const onBlur = () => {
    formik.submitForm();
  };

  const debounceSave = debounce(() => {
    onBlur();
  }, 800);

  return (
    <Form>
      <Row>
        <Col xs={6}>
          <FormGroup>
            <Label for="title">Tytuł</Label>
            <Input
              id="title"
              name="title"
              type="textarea"
              value={formik.values.title}
              invalid={!!formik.errors.title}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('title', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="subtitle">Tekst</Label>
            <Input
              id="subtitle"
              name="subtitle"
              type="textarea"
              value={formik.values.subtitle}
              invalid={!!formik.errors.subtitle}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('subtitle', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="list">Lista (oddzielona ;)</Label>
            <Input
              id="list"
              name="list"
              type="textarea"
              value={formik.values.list.join(';')}
              invalid={!!formik.errors.list}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('list', target.value.split(';'));
                debounceSave();
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="btnText">Przycisk</Label>
            <Input
              id="btnText"
              name="btnText"
              type="textarea"
              value={formik.values.btnText}
              invalid={!!formik.errors.btnText}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('btnText', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="link">Link</Label>
            <Input
              id="link"
              name="link"
              type="text"
              value={formik.values.link}
              invalid={!!formik.errors.link}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('link', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="backgroundColor">Kolor tła</Label>
            <Input
              type="select"
              value={formik.values.backgroundColor}
              onChange={({ target }) => {
                formik.setFieldValue('backgroundColor', target.value);
                switch (target.value) {
                  case '#00965e':
                    formik.setFieldValue('backgroundColorItem', 'green');
                    formik.setFieldValue('color', 'white');
                    break;
                  case '#ececec':
                  case '#fff':
                    formik.setFieldValue('backgroundColorItem', 'white');
                    formik.setFieldValue('color', '#ececec');
                    break;
                  default:
                    formik.setFieldValue('backgroundColorItem', 'green');
                    formik.setFieldValue('color', 'white');
                    break;
                }
                debounceSave();
              }}
            >
              <option value="#00965e">Zielony</option>
              <option value="#ececec">Szary</option>
              <option value="#fff">Biały</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <FormGroup>
            <Label for="anchor">Zakotwiczenie</Label>
            <Input
              id="anchor"
              name="anchor"
              type="text"
              value={formik.values.anchor}
              invalid={!!formik.errors.anchor}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('anchor', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default HeadlineForm;
