import React, { Component } from 'react';

import Banner from '../../components/Banners/Banner';

const Img = ({ url }) => {
  return <img
    className="files-item__preview files-item__preview--img"
    src={url}
    alt=""
    title=""
  />
}

class MediaItem extends Component {
  render() {
    const { medium, hideSetBanner } = this.props;
    return (
      <div className="files-item__preview-row">
        {medium.mime_type ? (
          medium.mime_type.includes('image') ? (
            <Img url={medium.file}/>
          ) : (
            <div className="files-item__preview files-item__preview--ext">
              {medium.mime_type}
            </div>
          )
        ) : null}
        {!medium.mime_type && medium.photo_id ? (
          <Img url={medium.file}/>
        ) : null}
        <div className="files-item__data">
          Nazwa pliku (tag title): <strong>{medium.name}</strong> <br />
          Alt: <strong>{medium.description}</strong> <br />
          URL:{' '}
          <a href={medium.file} target="_blank" rel="noopener noreferrer">
            link
          </a>
          <p style={{fontSize:'0.8rem'}}>
            {!!medium.style ? '*Rozciągnij do szerokości kontenera' : '*Zachowaj orginalną szerokość obrazka' }
          </p>
          {!hideSetBanner && <Banner mediaId={medium.id} />}
        </div>
      </div>
    );
  }
}

export default MediaItem;
