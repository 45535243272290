import API from '../../../api';

export const fetchEvents = token =>
  API.get('/api/v1/events/', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const createEvent = (token, data, user) => {
  return API.post(
    '/api/v1/events/',
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

export const editEvent = (token, data, user, id) => {
  return API.put(
    `/api/v1/events/${id}/`,
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

export const removeEvent = (token, id) =>
  API.delete(`/api/v1/events/${id}/`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const getEvent = (token, event_id) => {
  return API.get(`/api/v1/events/${event_id}/`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const setEventStatus = (token, id, status) =>
  API.patch(
    `/api/v1/events/${id}/`,
    {
      publish_status: status
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );

export const getEventPrelegents = (token, eventid) =>
  API.get(`/api/v1//lecturers/`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const setEventPrelegents = (token, eventid, prelegent) => {
  let fileData = new FormData();
  Object.keys(prelegent).forEach(item => {
    fileData.append(item, prelegent[item]);
  });
  fileData.append('event_id', eventid);
  return API.post(`/api/v1/lecturers/`, fileData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const updatePrelengt = (token, eventid, prelegentid, prelegent) => {
  let fileData = new FormData();
  delete prelegent.id;
  Object.keys(prelegent).forEach(item => {
    switch (item) {
      case 'thumbnail':
        console.log(prelegent[item]);
        if (prelegent[item].name) {
          fileData.append(item, prelegent[item]);
        }
        break;

      default:
        fileData.append(item, prelegent[item]);
        break;
    }
  });
  fileData.append('event_id', eventid);
  return API.patch(`/api/v1/lecturers/${prelegentid}/`, fileData, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const removePrelengt = (token, lectureId) =>
  API.delete(`/api/v1/lecturers/${lectureId}/`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const getEventTranslation = (token, id) =>
  API.get(`/api/v1/events/?event=${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const inviteUsers = (token, id, emails) =>
  API.post(`/api/v1/events/${id}/invite/`, emails, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
