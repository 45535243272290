export const FETCH_ARTICLE_CATEGORIES = 'FETCH_ARTICLE_CATEGORIES';
export const SET_ARTICLE_CATEGORIES = 'SET_ARTICLE_CATEGORIES';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const SET_SELECTED = 'SET_SELECTED';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';

export const FETCH_ARTICLE_CATEGORIES_NEW = 'FETCH_ARTICLE_CATEGORIES_NEW';
export const SET_ARTICLE_CATEGORIES_NEW = 'SET_ARTICLE_CATEGORIES_NEW';
export const FETCH_PROMOTED_ARTICLE_CATEGORIES_NEW = 'FETCH_PROMOTED_ARTICLE_CATEGORIES_NEW';
export const SET_PROMOTED_ARTICLE_CATEGORIES_NEW = 'SET_PROMOTED_ARTICLE_CATEGORIES_NEW';

