import { useSelector } from 'react-redux';

import API from '../../services/api';

export function useVideosApi() {
  const token = useSelector((state) => state.user.token.key);

  const getListOfAvailableVideos = (params) =>
    API.get('/api/v1/videos/', {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        ...params,
        ordering: '-created_at'
      }
    });

  const createNewVideo = ({
    title,
    description,
    lang_code,
    video_url,
    publish_status
  }) =>
    API.post(
      '/api/v1/videos/',
      {
        title,
        description,
        lang_code,
        video_url,
        publish_status
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

  const deleteVideo = ({ slug }) =>
    API.delete(`/api/v1/videos/${slug}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

  const getSpecificVideo = ({ slug }) =>
    API.get(`/api/v1/videos/${slug}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

  const updateVideo = ({
    title,
    description,
    lang_code,
    slug,
    publish_status
  }) =>
    API.patch(
      `/api/v1/videos/${slug}/`,
      {
        title,
        description,
        lang_code,
        publish_status
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

  return {
    getListOfAvailableVideos,
    getSpecificVideo,
    createNewVideo,
    updateVideo,
    deleteVideo
  };
}
