import * as actionTypes from './actionTypes';
import cookies from '../../../../utilities/cookies';
import { avblLangs } from '../../../../utilities/locale/index';

const InitialState = {
  lang: cookies.getLang() || 'PL',
  switcher: {
    gb: '',
    pl: ''
  }
};

export function settings(state = InitialState, action) {
  switch (action.type) {
    case actionTypes.LANG_SWITCH:
      cookies.setLang(action.lang);
      return {
        lang: action.lang
      };

    default:
      return state;
  }
}
