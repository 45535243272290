import React from 'react';
import { useFormik } from 'formik';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import debounce from 'lodash.debounce';

import Inputs from '../../Inputs';

const LogosArvalForm = ({ onChange, py, link, anchor }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      link,
      anchor,
      py: py
    },
    onSubmit: (values) => {
      onChange(values);
    }
  });

  const onBlur = () => {
    formik.submitForm();
  };

  const debounceSave = debounce(() => {
    onBlur();
  }, 800);

  return (
    <Form>
      <Row>
        <Col xs={6}>
          <FormGroup>
            <Label for="link">Link</Label>
            <Input
              id="link"
              name="link"
              type="textarea"
              value={formik.values.link}
              invalid={!!formik.errors.link}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('link', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="anchor">Zakotwieczenie</Label>
            <Input
              id="anchor"
              name="anchor"
              type="textarea"
              value={formik.values.anchor}
              invalid={!!formik.errors.anchor}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('anchor', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Inputs.SpacesInput
            id="py"
            name="py"
            value={formik.values.pt}
            invalid={!!formik.errors.pt}
            onBlur={onBlur}
            onChange={({ target }) => {
              formik.setFieldValue('py', target.value);
            }}
          />
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="anchor">Zakotwiczenie</Label>
            <Input
              id="anchor"
              name="anchor"
              type="text"
              value={formik.values.anchor}
              invalid={!!formik.errors.anchor}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('anchor', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default LogosArvalForm;
