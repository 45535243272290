import React from "react";
import Layout from "../../components/Layout/index.jsx";
import { connect } from "react-redux";
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Row,
  Col
} from "reactstrap";
import api from "../../services/api/index.js";

class SingleConvertsations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      conver: [],
      id: this.props.match.params.id
    };
  }

  componentDidMount() {
    this.fetchConv();
  }

  fetchConv = () => {
    const { token } = this.props;
    const { id } = this.state;
    let url = `/api/v1/conversations/${id}/messages/`;
    api
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(({ data }) => {
        this.setState({
          conver: data
        });
      });
  };

  render() {
    const { conver } = this.state;
    return (
      <Layout>
        <h1 className="h2 pb-md-4 page-header">Konwersacja</h1>
        <Row className="space-between">
          <Col>
            {/* <h4 className="mb-5">Konwersacja</h4> */}
            {conver.length ? (
              <ListGroup>
                {conver.map((item, index) => (
                  <ListGroupItem>
                    <ListGroupItemHeading>
                      <strong>Od: {item.from_email}</strong>
                    </ListGroupItemHeading>
                    <ListGroupItemText>{item.content}</ListGroupItemText>
                  </ListGroupItem>
                ))}
              </ListGroup>
            ) : null}
          </Col>
        </Row>
      </Layout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { user, settings } = state;
  return {
    token: user.token.key,
    lang: settings.lang
  };
}

export default connect(mapStateToProps)(SingleConvertsations);
