import React from "react";
import { FormGroup, FormFeedback, Label, Input } from "reactstrap";

export const InputField = props => {
  const { label, validation, ...rest } = props;
  return (
    <FormGroup>
      <Label for={props.name}>{label}</Label>
      <Input {...rest} invalid={validation} />
      <FormFeedback>{validation}</FormFeedback>
    </FormGroup>
  );
};

// export default InputField;
