import React, { useState, createContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectCMSLang } from '../../services/redux/reducers/selectors';
import { useVideosApi } from './Videos.api';

const defaultContext = {
  list: {
    count: -1,
    next: null,
    previous: null,
    results: []
  },
  pages: null,
  page: 0,
  defaultPageSize: 10,
  pageSize: 10,
  isProcessing: false,
  video: {
    slug: '',
    title: '',
    video_url: '',
    description: '',
    lang_code: 'PL',
    publish_status: 1
  },
  filters: {}
};

export const VideosContext = createContext();

export const VideosProvider = ({ children }) => {
  const [filters, setFilters] = useState(defaultContext.filters);
  const [video, setVideo] = useState(defaultContext.video);
  const [pages, setPages] = useState(defaultContext.pages);
  const [page, setPage] = useState(defaultContext.page);
  const [list, setList] = useState(defaultContext.list);
  const [pageSize, setPageSize] = useState(defaultContext.pageSize);
  const [isProcessing, setIsProcessing] = useState(defaultContext.isProcessing);
  const lang = useSelector(selectCMSLang);

  const { getListOfAvailableVideos } = useVideosApi();

  const getVideos = (params = { offset: undefined, limit: undefined }) => {
    setIsProcessing(true);
    getListOfAvailableVideos({ lang_code: lang, ...params, ...filters })
      .then(({ data }) => {
        setList(data);
        setPages(Math.ceil(data.count / pageSize));
        if (!!params.limit) {
          setPageSize(params.limit);
          setPages(Math.ceil(data.count / params.limit));
        }
        if (!!params.offset) {
          setPage(params.offset / pageSize);
        } else {
          setPage(0);
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  useEffect(() => {
    getVideos();

    // eslint-disable-next-line
  }, [filters]);

  return (
    <VideosContext.Provider
      value={{
        list,
        setList,
        pageSize,
        setPageSize,
        isProcessing,
        setIsProcessing,
        getVideos,
        page,
        setPage,
        pages,
        video,
        setVideo,
        filters,
        setFilters,
        defaultPageSize: defaultContext.defaultPageSize
      }}
    >
      {children}
    </VideosContext.Provider>
  );
};
