import * as actions from "./actionTypes";
import { getTranslatedCategoryName } from "../../../../utilities/categories";

const InitialState = {
  data: [],
  agroData: [],
  selectedId: -1
};

const articleMembership = (item, id) => item.membership === id;

const convertCategories = (data, type) =>
  data
    .filter(item => articleMembership(item, type))
    .map(item => ({
      ...item,
      type_name: getTranslatedCategoryName(item.id)
    }));

export function articleTypes(state = InitialState, action) {
  switch (action.type) {
    case actions.SET_ARTICLE_TYPES:
      return {
        ...state,
        data: convertCategories(action.data, 1),
        agroData: convertCategories(action.data, 2)
      };

    case actions.SET_ARTICLE_TYPES_ID:
      return {
        ...state,
        selectedId: action.id
      };

    default:
      return state;
  }
}
