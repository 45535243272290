import React, { useState } from 'react';
import { Input, Button } from 'reactstrap';
import debounce from 'lodash.debounce';

import ListElement from '../../ListElement';

const DisclaimerForm = ({ notes, onChange }) => {
  const [_notes, setNotes] = useState(notes);

  const onBlur = () => {
    onChange({ notes: _notes });
  };

  const addNote = () => setNotes((state) => [...state, '']);

  const debounceSave = debounce(() => {
    onBlur();
  }, 800);

  return (
    <>
      {_notes.map((note, idx) => (
        <ListElement
          currentIndex={idx}
          onRemove={() => {
            setNotes((state) => state.filter((_, i) => i !== idx));
          }}
        >
          <Input
            style={{ marginBottom: '8px' }}
            value={note}
            onChange={({ target }) => {
              setNotes((state) =>
                state.map((value, i) => {
                  if (idx === i) {
                    return target.value;
                  }
                  return value;
                })
              );
              debounceSave();
            }}
            onBlur={onBlur}
            type="textarea"
          />
        </ListElement>
      ))}
      <Button block color="info" style={{ marginTop: '8px' }} onClick={addNote}>
        Dodaj
      </Button>
    </>
  );
};

export default DisclaimerForm;
