import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Transforms } from 'slate';
import { NODE_TYPES } from '../../consts';
import { toggleBlock } from '../../utils/SlateUtilityFunctions';
import { createParagraph } from '../../utils/paragraph';
import ArticlesList from '../../../../components/Articles/List';
import { frontLink } from '../../../../services/api';
import { useSelector } from 'react-redux';

const AddArticleModal = ({ editor, modalOpened, toggle }) => {
  const lang = useSelector(state => state.settings.lang)
  const domain = `${frontLink()}${lang === 'PL' ? 'artykul/' : 'articles/'}`

  const selectArtcle = (article) => {
    const link = {
      type: NODE_TYPES.ARTICLE_LINK,
      children: [{ text: '' }],
      data: {
        title: article.short_name,
        href: domain + article.slug,
        text: lang === 'PL' ? `Przeczytaj również:` : `Learn more`
      }
    }

    Transforms.setNodes(editor, link, {});
    Transforms.insertNodes(editor, createParagraph(""), { mode: 'highest' })
    toggle()
  }

  return (
    <Modal
      isOpen={modalOpened}
      toggle={toggle}
    >
      <ModalHeader
        toggle={toggle}
      >
        Wybierz artykuł
      </ModalHeader>
      <ModalBody>
        <div style={{ margin: '1rem 0' }}>
          <ArticlesList
            selectMode
            selectArticle={selectArtcle}
          />
        </div>
      </ModalBody>
    </Modal>
  )
}

const TextAdditionalButton = ({ editor }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => {
    setDropdownOpen(!dropdownOpen)
  };

  const addDisclaimer = (e) => {
    e.preventDefault();
    toggleBlock(editor, NODE_TYPES.DISCLAIMER, true)
  }

  const addSeparator = () => {
    // toggleBlock(editor, NODE_TYPES.SEPARATOR)

    Transforms.setNodes(editor, { type: NODE_TYPES.SEPARATOR }, {});
    Transforms.insertNodes(editor, createParagraph(""), { mode: 'highest' })
  }


  const [modalOpened, setModalOpen] = useState(false)

  const toggleModal = () => {
    setModalOpen(!modalOpened)
  }

  const addLinkToArticle = () => {
    setModalOpen(true)
  }


  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} size="sm">
        <DropdownToggle
          className="toolbar-dropdown"
          caret
          outline
        >
          Dodatkowe
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={addDisclaimer}
          >
            Disclaimer
          </DropdownItem>
          <DropdownItem
            onClick={addSeparator}
          >
            Separator
          </DropdownItem>
          <DropdownItem
            onClick={addLinkToArticle}
          >
            Link do artykułu
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <AddArticleModal modalOpened={modalOpened} toggle={toggleModal} editor={editor}></AddArticleModal>
    </>
  );
};

export default TextAdditionalButton
