import React from 'react';
import { Row, Col } from 'reactstrap';

import Layout from '../../components/Layout/index.jsx';

import { VideosProvider } from './Videos.context';
import VideosList from './components/VideosList';
import VideosPreview from './components/VideosPreview';

function VideosPage() {
  return (
    <VideosProvider>
      <Layout>
        <h1 className="h2 pb-md-4 page-header">Materiały wideo </h1>
        <Row>
          <Col xs={12} md={6}>
            <VideosList />
          </Col>
          <Col xs={12} md={6}>
            <VideosPreview />
          </Col>
        </Row>
      </Layout>
    </VideosProvider>
  );
}

export default VideosPage;
