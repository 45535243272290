import React from "react";
import Layout from "../../components/Layout";
import ArticleTypesList from "../../common/Articles/types";
import ArticleTypesForm from "../../components/Forms/ArticleTypes";
import { Row, Col } from "reactstrap";

class ArticleTypes extends Layout {
  constructor(props) {
    super(props);
    this.state = {
      report: this.props.match.path.includes("/agroraport")
    };
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;

    if (prevProps.match.path !== match.path) {
      const report = match.path.includes("/agroraport");
      this.setState({
        report
      });
    }
  }

  render() {
    const { report } = this.state;
    return (
      <Layout>
        <h1 className="h2 pb-md-4 page-header">
          Kategorie artykułów {report && "Agroraportu"}
        </h1>
        <Row>
          <Col sm="6">
            <ArticleTypesList isList={true} report={report} />
          </Col>
          <Col sm="6">
            {/* form for article types edit */}
            <ArticleTypesForm report={report} />
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default ArticleTypes;
