import React, { useContext, useEffect } from 'react';
import { sprintf } from 'sprintf-js';
import { useTimeoutFn } from 'react-use';

import { VideosContext } from './Videos.context';
import { useVideosApi } from './Videos.api'

export function useVideos() {
  const {
    list,
    setList,
    pageSize,
    setPageSize,
    defaultPageSize,
    isProcessing,
    setIsProcessing,
    getVideos,
    page,
    setPage,
    pages,
    video,
    setVideo,
    filters,
    setFilters
  } = useContext(VideosContext);

  const {createNewVideo, updateVideo, deleteVideo } = useVideosApi()

  const addNewVideo = (video) => createNewVideo(video).then(() => {
    getVideos()
  })

  const updateVideoDetails = (video) => updateVideo(video).then(() => {
    getVideos()
  })

  const removeVideo = (slug) => deleteVideo({ slug }).then(() => {
    getVideos()
  })

  return {
    list,
    setList,
    searchParams: {
      pageSize,
      setPageSize,
      defaultPageSize,
      page,
      setPage,
      pages,
      filters,
      setFilters
    },
    isProcessing,
    setIsProcessing,
    getVideos,
    video,
    setVideo,
    addNewVideo,
    updateVideoDetails,
    removeVideo
  };
}
