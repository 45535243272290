import React, { Component } from 'react';
import { connect } from 'react-redux';
import Heading from '../../common/Typo/heading';
import { Formik } from 'formik';
import { Form, Button, FormGroup, Input, Label } from 'reactstrap';

import {
  createCategory,
  editCategory
} from '../../services/redux/reducers/articlesCategories/actionCreators';
import { avblLangs } from '../../utilities/locale';

class ArticleCategoriesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'new'
    };
  }

  newCat = React.createRef();

  render() {
    const { getCategory, selectedID } = this.props;
    // const name = getCategory(selectedID) ? getCategory(selectedID).name : '';
    let translations = {};
    let name = '';
    avblLangs.map(item => {
      translations[item.lang_code] = '';
    });
    const cat = getCategory(selectedID);
    if (cat) {
      name = cat.name;
      cat.translations.forEach(element => {
        translations[element.lang_code] = element.name;
      });
    }
    return (
      <>
        <Heading title={selectedID === -1 ? 'Dodaj tag' : 'Edytuj tag'} />
        <Formik
          initialValues={{
            nameCategory: name,
            translations
          }}
          enableReinitialize={true}
          ref={this.newCat}
          onSubmit={(values, { setSubmitting }) => {
            // setSubmitting(false);
            let translations = [];
            Object.keys(values.translations).map(item => {
              if (values.translations[item]) {
                translations.push({
                  lang_code: item,
                  name: values.translations[item]
                });
              }
            });
            const categoryData = {
              name: values.nameCategory,
              translations: JSON.stringify(translations)
            };
            if (selectedID === -1) {
              this.props.createCategory(categoryData);
            } else {
              this.props.editCategory(categoryData);
            }
          }}
          render={({ values, handleSubmit, handleChange }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="category_title">Nazwa zbiorcza taga</Label>
                <Input
                  type="text"
                  name="nameCategory"
                  id="category_title"
                  required
                  value={values.nameCategory}
                  onChange={handleChange}
                />
              </FormGroup>
              {avblLangs.map((item, index) => (
                <FormGroup>
                  <Label for={`category_translations_${item.lang_code}`}>
                    Tłumaczenie: {item.name}
                  </Label>
                  <Input
                    type="text"
                    name={`translations[${item.lang_code}]`}
                    id={`category_translations_${item.lang_code}`}
                    value={values.translations[`${item.lang_code}`]}
                    onChange={handleChange}
                  />
                </FormGroup>
              ))}
              <Button type="submit" color="primary">
                Zapisz
              </Button>
            </Form>
          )}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  const { selected, data } = state.articleCategories;
  return {
    selectedID: selected,
    getCategory: id => data.find(el => el.id === id)
    // articleCategories
  };
}

const mapDispatchToProps = dispatch => {
  return {
    createCategory: data => dispatch(createCategory(data)),
    editCategory: data => dispatch(editCategory(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleCategoriesForm);
