import React from 'react';
import { useFormik } from 'formik';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import debounce from 'lodash.debounce';

import Inputs from '../../Inputs';

const ColorsName = {
  MOUNTAIN_MEADOW: 'mountainMeadow',
  SHAMROCK_GREEN: 'shamrockGreen',
  DARK_SPRING_GREEN: 'darkSpringGreen',
  MSU_GREEN: 'msuGreen',
  BRIGHT_GRAY: 'brightGray'
};

const PackageTableForms = ({
  onChange,
  py,
  filterTitle,
  disclaimer,
  packages,
  filters,
  features,
  anchor
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      py,
      filterTitle,
      disclaimer,
      packages,
      filters,
      features,
      anchor
    },
    onSubmit: (values) => {
      onChange(values);
    }
  });

  const onBlur = () => {
    formik.submitForm();
  };

  const debounceSave = debounce(() => {
    onBlur();
  }, 800);

  return (
    <Form>
      <Row>
        <Col xs={12}>
          <h5>Pakiety</h5>
        </Col>
        {formik.values.packages.map((_, key) => (
          <Col xs={3} key={key}>
            <Button
              onClick={() => {
                formik.setFieldValue(
                  'packages',
                  formik.values.packages.filter((_, k) => k !== key)
                );
                onBlur();
              }}
              type="button"
              style={{ marginBottom: '1rem' }}
              block
              color="danger"
            >
              Usuń pakiet
            </Button>
            <FormGroup>
              <Label for={`packages[${key}].label`}>Nazwa</Label>
              <Input
                id={`packages[${key}].label`}
                name={`packages[${key}].label`}
                type="textarea"
                value={formik.values.packages[key].label}
                onBlur={onBlur}
                onChange={({ target }) => {
                  formik.setFieldValue(`packages[${key}].label`, target.value);
                  debounceSave();
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for={`packages[${key}].slug`}>Slug</Label>
              <Input
                id={`packages[${key}].slug`}
                name={`packages[${key}].slug`}
                type="textarea"
                value={formik.values.packages[key].slug}
                onBlur={onBlur}
                onChange={({ target }) => {
                  formik.setFieldValue(`filters[${key}].slug`, target.value);
                  debounceSave();
                }}
              />
            </FormGroup>
          </Col>
        ))}

        <Col xs={12}>
          <Button
            onClick={() => {
              formik.setFieldValue('packages', [
                ...formik.values.packages,
                {
                  label: 'Nazwa pakietu',
                  slug: `A${formik.values.packages.length + 1}`
                }
              ]);
              onBlur();
            }}
            type="button"
            style={{ marginBottom: '1rem' }}
            block
            color="success"
          >
            Dodaj pakiet
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h5>Funkcje</h5>
        </Col>
        {Object.keys(formik.values.features).map((key) => (
          <Col xs={3}>
            <Label for={`features[${key}]`}>Funkcja (slug: {key})</Label>
            {formik.values.features[key].map((_, k) => (
              <div
                style={{
                  padding: '0.5rem',
                  border: '1px solid black',
                  marginBottom: '1rem'
                }}
              >
                <FormGroup>
                  <Input
                    id={`features[${key}][k].label`}
                    name={`features[${key}][k].label`}
                    type="textarea"
                    value={formik.values.features[key][k].label}
                    onBlur={onBlur}
                    onChange={({ target }) => {
                      formik.setFieldValue(
                        `features[${key}][${k}].label`,
                        target.value
                      );
                      debounceSave();
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="select"
                    id={`features[${key}][k].color`}
                    name={`features[${key}][k].color`}
                    value={formik.values.features[key][k].color}
                    onChange={({ target }) => {
                      formik.setFieldValue(
                        `features[${key}][${k}].color`,
                        target.value
                      );
                      onBlur();
                    }}
                  >
                    {Object.keys(ColorsName).map((color) => (
                      <option value={ColorsName[color]} key={color}>
                        {ColorsName[color]}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </div>
            ))}
          </Col>
        ))}
      </Row>
      <Row>
        <Col xs={12}>
          <h5>Filtry</h5>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <Label for="filterTitle">Nazwa filtrów</Label>
            <Input
              id="filterTitle"
              name="filterTitle"
              type="textarea"
              value={formik.values.filterTitle}
              invalid={!!formik.errors.filterTitle}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('filterTitle', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
        {formik.values.filters.map((_, key) => (
          <Col xs={3} key={key}>
            <Button
              onClick={() => {
                formik.setFieldValue(
                  'filters',
                  formik.values.filters.filter((_, k) => k !== key)
                );
                onBlur();
              }}
              type="button"
              style={{ marginBottom: '1rem' }}
              block
              color="danger"
            >
              Usuń filtr
            </Button>
            <FormGroup>
              <Label for={`filters[${key}].label`}>Nazwa</Label>
              <Input
                id={`filters[${key}].label`}
                name={`filters[${key}].label`}
                type="textarea"
                value={formik.values.filters[key].label}
                onBlur={onBlur}
                onChange={({ target }) => {
                  formik.setFieldValue(`filters[${key}].label`, target.value);
                  debounceSave();
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for={`filters[${key}].includes`}>
                Dotyczy (slug, slug, slug)
              </Label>
              <Input
                id={`filters[${key}].includes`}
                name={`filters[${key}].includes`}
                type="textarea"
                value={formik.values.filters[key].includes}
                onBlur={onBlur}
                onChange={({ target }) => {
                  formik.setFieldValue(
                    `filters[${key}].includes`,
                    target.value
                  );
                  debounceSave();
                }}
              />
            </FormGroup>
          </Col>
        ))}

        <Col xs={12}>
          <Button
            onClick={() => {
              formik.setFieldValue('filters', [
                ...formik.values.filters,
                {
                  label: 'Nowy filtr',
                  includes: ''
                }
              ]);
              onBlur();
            }}
            type="button"
            style={{ marginBottom: '1rem' }}
            block
            color="success"
          >
            Dodaj filtr
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="disclaimer">Disclaimer</Label>
            <Input
              id="disclaimer"
              name="disclaimer"
              type="textarea"
              value={formik.values.disclaimer}
              invalid={!!formik.errors.disclaimer}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('disclaimer', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Inputs.SpacesInput
            id="py"
            name="py"
            value={formik.values.py}
            invalid={!!formik.errors.py}
            onBlur={onBlur}
            onChange={({ target }) => {
              formik.setFieldValue('py', target.value);
            }}
          />
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="anchor">Zakotwiczenie</Label>
            <Input
              id="anchor"
              name="anchor"
              type="text"
              value={formik.values.anchor}
              invalid={!!formik.errors.anchor}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('anchor', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default PackageTableForms;
