import * as actions from './actionTypes';
import { SUCCESS } from '../../utils/actionSuffix';

const InitialState = {
  count: -1,
  data: [],
  selected: -1,
  banners: []
};

export function media(state = InitialState, action) {
  switch (action.type) {
    case actions.SET_MEDIA:
      return {
        ...state,
        count: action.count,
        data: action.data
      };

    case actions.FETCH_MEDIA + SUCCESS:
      return {
        ...state,
        count: action.count,
        data: [...action.data]
      };

    case actions.EDIT_MEDIA + SUCCESS:
      let index = state.data.findIndex(el => el.id === state.selected);
      state.data[index] = action.data;
      return {
        ...state,
        selected: -1
      };

      case actions.FETCH_BANNERS + SUCCESS:
      return {
        ...state,
        banners: [...action.data]
      };

    default:
      return state;
  }
}
