import * as actionTypes from './actionTypes';

export const loginUser = (email, password) => ({
  type: actionTypes.LOGIN_USER,
  email,
  password
});

export const setUserToken = token => ({
  type: actionTypes.SET_USER_TOKEN,
  token
});

export const setUserApiData = (token, id) => ({
  type: actionTypes.SET_USER_API_DATA,
  token,
  id
});

export const setUserData = data => ({
  type: actionTypes.SET_USER_DATA,
  data
});

export const setUserDataEdit = data => ({
  type: actionTypes.SET_USER_DATA_EDIT,
  data
});

export const setTokenCookie = (token, expireDate, id) => ({
  type: actionTypes.SET_TOKEN_COOKIE,
  token,
  expireDate,
  id
});

export const fetchUserData = () => ({
  type: actionTypes.FETCH_USER_DATA
});

export const fetchUserLogs = id => ({
  type: actionTypes.FETCH_USER_LOGS,
  id
});

export const logoutUser = () => ({
  type: actionTypes.LOGOUT_USER
});

export const fetchUsers = () => ({
  type: actionTypes.FETCH_USERS
});

export const createUser = userData => ({
  type: actionTypes.CREATE_USER,
  userData
});

export const deleteUser = id => ({
  type: actionTypes.DELETE_USER,
  id
});

export const editUser = id => ({
  type: actionTypes.FETCH_USER_EDIT,
  id
});

export const editUserData = (data, id) => ({
  type: actionTypes.EDIT_USER_DATA,
  data,
  id
});

export const resetReq = () => ({ type: actionTypes.RESET_REQ });
