import { NODE_TYPES } from '../consts';

const withEmbeds = (editor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) =>
    [NODE_TYPES.VIDEO, NODE_TYPES.IMAGE, NODE_TYPES.GRID_IMAGES, NODE_TYPES.SEPARATOR, NODE_TYPES.BLOCKQUOTE, NODE_TYPES.ARTICLE_LINK].includes(element.type) ? true : isVoid(element);
  return editor;
}

export default withEmbeds
