import React from 'react';
import Layout from '../../components/Layout';
import ArticleCategories from './ArticleCategories';
import ArticleTypesForm from './ArticleTypesForm';
import { Row, Col } from 'reactstrap';
import {
  fetchArticleCategoriesNew,
  fetchPromotedArticleCategoriesNew
} from '../../services/redux/reducers/articlesCategories/actionCreators';
import { selectNewArticleCategories } from '../../services/redux/reducers/selectors';
import { useSelector, useDispatch } from 'react-redux';

const ArticleTypes = () => {
  const newArticleCategories = useSelector(selectNewArticleCategories);
  const dispatch = useDispatch();

  const fetchArticleCategories = React.useCallback(
    () => dispatch(fetchArticleCategoriesNew()),
    [dispatch]
  );

  const fetchPromotedArticleCategories = React.useCallback(
    (id) => dispatch(fetchPromotedArticleCategoriesNew(id)),
    [dispatch]
  );

  React.useEffect(() => {
    if (!newArticleCategories.length) {
      fetchArticleCategories();
    }
  }, [newArticleCategories, fetchArticleCategories]);

  return (
    <Layout>
      <h1 className="h2 pb-md-4 page-header">Kategorie artykułów</h1>
      <Row>
        <Col sm="6">
          <ArticleCategories />
        </Col>
        <Col sm="6">
          <ArticleTypesForm
            fetchPromotedArticleCategories={fetchPromotedArticleCategories}
            fetchArticleCategories={fetchArticleCategories}
          />
        </Col>
      </Row>
    </Layout>
  );
};

export default ArticleTypes;
