import React from 'react';
import { useFormik } from 'formik';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import debounce from 'lodash.debounce';

import Inputs from '../../Inputs';

const ArticlesSectionForm = ({
  onChange,
  py,
  title,
  subtitle,
  linkToAll,
  toAllText,
  articles,
  anchor
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      anchor,
      title,
      subtitle,
      linkToAll,
      toAllText,
      py: py
    },
    onSubmit: (values) => {
      onChange({ ...values, articles });
    }
  });

  const onBlur = () => {
    formik.submitForm();
  };

  const debounceSave = debounce(() => {
    onBlur();
  }, 800);

  return (
    <Form>
      <Row>
        <Col xs={6}>
          <FormGroup>
            <Label for="title">Tytuł</Label>
            <Input
              id="title"
              name="title"
              type="textarea"
              value={formik.values.title}
              invalid={!!formik.errors.title}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('title', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="subtitle">Podtytuł</Label>
            <Input
              id="subtitle"
              name="subtitle"
              type="textarea"
              value={formik.values.subtitle}
              invalid={!!formik.errors.subtitle}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('subtitle', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <FormGroup>
            <Label for="linkToAll">
              Link do wszystkich - Adres ('/przyklad-link')
            </Label>
            <Input
              id="linkToAll"
              name="linkToAll"
              type="textarea"
              value={formik.values.linkToAll}
              invalid={!!formik.errors.linkToAll}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('linkToAll', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="toAllText">Link do wszystkich - Tekst</Label>
            <Input
              id="toAllText"
              name="toAllText"
              type="textarea"
              value={formik.values.toAllText}
              invalid={!!formik.errors.toAllText}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('toAllText', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Inputs.SpacesInput
            id="py"
            name="py"
            value={formik.values.pt}
            invalid={!!formik.errors.pt}
            onBlur={onBlur}
            onChange={({ target }) => {
              formik.setFieldValue('py', target.value);
            }}
          />
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="anchor">Zakotwiczenie</Label>
            <Input
              id="anchor"
              name="anchor"
              type="text"
              value={formik.values.anchor}
              invalid={!!formik.errors.anchor}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('anchor', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default ArticlesSectionForm;
