import API from '../../../services/api';

export const ResourceState = {
  IDLE: 'idle',
  FETCHING: 'fatching',
  ERROR: 'error'
};

const prfApi = {
  getProducts: ({ token }) =>
    API.get('/api/v1/products/', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }),
  postProduct: ({ token, names, titles, creator, slug }) =>
    API.post(
      '/api/v1/products/',
      {
        name: names,
        title: titles,
        body: {
          pl: [],
          gb: []
        },
        creator_id: creator,
        meta: {
          pl: '',
          gb: ''
        },
        slug
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    ),
  deleteProduct: ({ token, id }) =>
    API.delete(`/api/v1/products/${id}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }),
  patchProduct: ({ token, product }) =>
    API.patch(
      `/api/v1/products/${product.id}/`,
      {
        ...product
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
};

export default prfApi;
