import * as api from "./api";
import {
  takeLatest,
  call,
  put,
  select,
  takeEvery
} from "@redux-saga/core/effects";
import * as actionTypes from "./actionTypes";
import * as actionCreators from "./actionCreators";
import { SUCCESS } from "../../utils/actionSuffix";
import * as selectors from "../selectors";
import {
  mapFailure,
  alertFailure,
  alertSuccess
} from "../../utils/mapResponse";

export function* fetchArticles() {
  const token = yield select(selectors.selectToken);
  try {
    const response = yield call(api.fetchArticles, token);
    const { data } = response;
    const { results } = data;
    yield put(actionCreators.setArticles(results));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchArticle({ id }) {
  const token = yield select(selectors.selectToken);
  try {
    const response = yield call(api.getArticle, token, id);
    const { data } = response;
    yield put(actionCreators.setArticleId(data.id));
    yield put(actionCreators.setArticles([data]));
    yield put({ type: actionTypes.FETCH_ARTICLE + SUCCESS, id: data.id });
    yield put(actionCreators.articleTranslation(data.grouping_id));
  } catch (e) {
    console.log(e);
  }
}

export function* setArticleMedia({ data }) {
  try {
    yield put(actionCreators.setArticleMediaFilesState(data));
  } catch (e) {
    console.log(e);
  }
}

export function* createArticle({ articleFormData, id }) {
  const token = yield select(selectors.selectToken);
  const user = yield select(selectors.selectUser);
  const lang = yield select(selectors.selectCMSLang);
  const editId = id;
  let response = null;
  try {
    let message = null;
    if (!editId) {
      response = yield call(
        api.createArticle,
        token,
        { ...articleFormData, lang_code: lang },
        user
      );
      message = "Dodano pomyślnie artykuł.";
    } else {
      response = yield call(
        api.editArticle,
        token,
        articleFormData,
        user,
        editId
      );
      message = "Edytowano pomyślnie artykuł.";
    }
    const {
      data: { id }
    } = response;
    // if (id) {
    yield put({ type: actionTypes.CREATE_ARTICLE + SUCCESS });
    // }
    yield put(alertSuccess(message));
    // console.log(id);
  } catch (e) {
    yield put(mapFailure(e, actionTypes.CREATE_ARTICLE));
    yield put(alertFailure("Wystąpił błąd podczas zapisywania artykułu"));
  }
}

export function* removeArticle({ id }) {
  const token = yield select(selectors.selectToken);
  try {
    yield call(api.removeArticle, token, id);
    yield put(actionCreators.fetchArticles());
  } catch (e) {
    // yield put(mapFailure(e, actionTypes.CREATE_ARTICLE));
    yield put(alertFailure("Wystąpił błąd podczas usuwania artykułu"));
  }
}

export function* fetchArticleTranslation({ groupID }) {
  const token = yield select(selectors.selectToken);
  try {
    const {
      data: { results: translations }
    } = yield call(api.getArticleTranslation, token, groupID);
    // map langs
    yield put({
      type: actionTypes.FETCH_ARTICLE_TRANSLATION + SUCCESS,
      translations
    });
  } catch (e) {
    yield put(alertFailure("Nie udało się pobrać tłumaczeń artykułów"));
  }
}

export function* changeArticleStatus({ id, status }) {
  const token = yield select(selectors.selectToken);
  let response = null;
  let editId = id;
  try {
    let message = null;
    response = yield call(api.setArticleStatus, token, editId, status);
    message = "Edytowano pomyślnie artykuł.";
    const {
      data: { id }
    } = response;
    if (id) {
      yield put(actionCreators.setArticleId(id));
    }
    yield put(alertSuccess(message));
    // console.log(id);
  } catch (e) {
    yield put(mapFailure(e, actionTypes.CREATE_ARTICLE));
    yield put(
      alertFailure(
        "Wystąpił błąd podczas publikowania artykułu. Sprawdź, czy na pewno masz odpowiednie uprawnienia"
      )
    );
  }
}

export default [
  takeLatest(actionTypes.FETCH_ARTICLES, fetchArticles),
  takeEvery(actionTypes.SET_ARTICLE_MEDIA, setArticleMedia),
  takeEvery(actionTypes.CREATE_ARTICLE, createArticle),
  takeEvery(actionTypes.FETCH_ARTICLE, fetchArticle),
  takeLatest(actionTypes.REMOVE_ARTICLE, removeArticle),
  takeEvery(actionTypes.FETCH_ARTICLE_TRANSLATION, fetchArticleTranslation),
  takeEvery(actionTypes.SET_ARTICLE_STATUS, changeArticleStatus)
  //
];
