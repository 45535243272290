import { ALERT, ALERT_CLEAR } from '../reducers/alerts/actionTypes';
import { FAILURE, SUCCESS } from './actionSuffix';

export const mapFailure = (error, actionType) => ({
  type: actionType + FAILURE,
  error: {
    body: error.response.data,
    status: error.response.status
  }
});

export const alertFailure = (message) => ({
  type: ALERT + FAILURE,
  message
});

export const alertSuccess = (message) => ({
  type: ALERT + SUCCESS,
  message
});

export const clearAlert = () => ({
  type: ALERT_CLEAR
});
