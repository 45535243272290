import * as actionTypes from './actionTypes';
import { SUCCESS, FAILURE } from '../../utils/actionSuffix';
// add types
const InitialState = {
  uploading: false,
  uploaded: false,
  error: null,
  errorBody: {}
};

export function eventsRequest(state = InitialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_EVENT:
      return {
        ...state,
        uploaded: false,
        uploading: true,
        error: false,
        errorBody: {}
      };

    case actionTypes.CREATE_EVENT + SUCCESS:
      return {
        ...state,
        uploading: false,
        uploaded: true,
        error: false,
        errorBody: {}
      };

    case actionTypes.CREATE_EVENT + FAILURE:
    case actionTypes.REMOVE_EVENT + FAILURE:
      return {
        ...state,
        uploading: false,
        error: true,
        errorBody: action.error.body
      };

    case actionTypes.RESET_REQ:
      return InitialState;

    default:
      return state;
  }
}
