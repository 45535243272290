import * as api from './api';
import {
  takeLatest,
  call,
  put,
  select,
  takeEvery
} from '@redux-saga/core/effects';
import * as actionTypes from './actionTypes';
import * as actionCreators from './actionCreators';
import * as selectors from '../selectors';
import { SUCCESS, FAILURE } from '../../utils/actionSuffix';
import {
  mapFailure,
  alertFailure,
  alertSuccess
} from '../../utils/mapResponse';

export function* fetchArticleCategories({ report }) {
  const token = yield select(selectors.selectToken);
  const lang = yield select(selectors.selectCMSLang);
  try {
    const response = yield call(
      api.fetchArticleCategories,
      token,
      lang,
      report
    );
    const { data } = response;
    let { results } = data;
    results.forEach((item, index) => {
      results[index].translations = JSON.parse(item.translations);
    });
    yield put(actionCreators.setArticleCategories(results));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchArticleCategoriesNew() {
  const token = yield select(selectors.selectToken);
  const lang = yield select(selectors.selectCMSLang);
  try {
    const response = yield call(api.fetchArticleCategoriesNew, token, lang);
    const { data } = response;
    let { results } = data;
    // results.forEach((item, index) => {
    //   results[index].translations = JSON.parse(item.translations);
    // });
    yield put(actionCreators.setArticleCategoriesNew(results));
  } catch (e) {
    console.log(e);
  }
}

export function* createCategory({ categoryFormData }) {
  const token = yield select(selectors.selectToken);
  const lang = yield select(selectors.selectCMSLang);
  try {
    const response = yield call(api.createCategory, token, {
      ...categoryFormData
      // lang_code: lang
    });
    let { data } = response;
    data.translations = JSON.parse(data.translations);
    yield put({
      type: actionTypes.EDIT_CATEGORY + SUCCESS,
      data
    });
    yield put(actionCreators.fetchArticleCategories());
  } catch (e) {
    console.log(e);
  }
}

export function* editCategory({ categoryFormData }) {
  const token = yield select(selectors.selectToken);
  const { id } = yield select(selectors.selectArticleCategory);
  try {
    const response = yield call(api.editCategory, token, categoryFormData, id);
    let { data } = response;
    data.translations = JSON.parse(data.translations);
    yield put({
      type: actionTypes.EDIT_CATEGORY + SUCCESS,
      data: data
    });
    yield put(alertSuccess('Edytowano pomyślnie Tag.'));
  } catch (e) {
    console.log(e);
  }
}

export function* deleteCategory({ id }) {
  const token = yield select(selectors.selectToken);
  try {
    const response = yield call(api.deleteCategory, token, id);
    const { data } = response;
    yield put({
      type: actionTypes.DELETE_CATEGORY + SUCCESS,
      data: data
    });
    yield put(actionCreators.fetchArticleCategories());
    yield put(alertSuccess('Usunięto pomyślnie Tag.'));
  } catch (e) {
    console.log(e);
    yield put(mapFailure(e, actionTypes.DELETE_CATEGORY + FAILURE));
    yield put(
      alertFailure(
        'Nie udało się usunąć taga. W celu usunięcia skontaktuj się z administratorem.'
      )
    );
  }
}

export function* fetchPromotedArticleCategories({ id }) {
  const token = yield select(selectors.selectToken);
  const lang_code = yield select(selectors.selectCMSLang);
  try {
    const response = yield call(
      api.fetchPromotedArticleCategories,
      token,
      id,
      lang_code
    );
    const { data } = response;
    let { results } = data;
    yield put(actionCreators.setPromotedArticleCategoriesNew(results));
  } catch (e) {
    console.log(e);
  }
}

export default [
  takeLatest(actionTypes.FETCH_ARTICLE_CATEGORIES, fetchArticleCategories),
  takeEvery(actionTypes.CREATE_CATEGORY, createCategory),
  takeEvery(actionTypes.EDIT_CATEGORY, editCategory),
  takeEvery(actionTypes.DELETE_CATEGORY, deleteCategory),
  takeEvery(
    actionTypes.FETCH_ARTICLE_CATEGORIES_NEW,
    fetchArticleCategoriesNew
  ),
  takeEvery(
    actionTypes.FETCH_PROMOTED_ARTICLE_CATEGORIES_NEW,
    fetchPromotedArticleCategories
  )
];
