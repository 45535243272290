import React, { Component } from 'react';
import { connect } from "react-redux";
import {
  Label,
  Button,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import api from '../../services/api';
import { formatDate } from '../../utilities/dates';


class VerificationHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      id: this.props.userid,
      opened: false,
      none: false,
      filter: ''
    }
  }

  // componentDidMount () {
  //   if (this.state.id) {
  //     this.fetch()
  //   }
  // }

  fetch = () => {
    const { token } = this.props;
    const { id } = this.state;
    let url = `/api/v1/users/${id}/authorizations/`;
    api
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(({ data }) => {
        this.setState({
          list: data,
          none: data.length === 0
        });
      });
  };

  toggle = () => {
    this.setState(
      (state) => ({ opened: !state.opened }),
      () => this.state.opened && this.fetch())
  }

  render() {
    const { list, opened, none } = this.state;
    return (
      <div>
        <Label className="">Historia autoryzacji</Label>
        <Modal isOpen={opened} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Historia autoryzacji</ModalHeader>
          <ModalBody>
            <div>
              {list.length ? (
                <ListGroup>
                  {list.map(({ created_at, passed }, index) => (
                    <ListGroupItem color={passed ? 'success' : 'warning'}>
                      <div>
                        Data próby autoryzacji: {formatDate(new Date(created_at))}
                      </div>
                      <div>Status: {passed ? `Pozytywna` : `Negatywna`}</div>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              ) : null}
              {none && 'brak prób autoryzacji'}
            </div>
          </ModalBody>
        </Modal>
        <div
          style={{ margin: '.5rem 0 1rem' }}>
          <Button
            color="primary"
            id="toggler"
            onClick={this.toggle}
          >
            {'Pokaż'}
          </Button>
        </div>
        {/* </UncontrolledCollapse> */}
      </div>
    );
  }
};

function mapStateToProps(state, ownProps) {
  const { user, settings } = state;
  return {
    token: user.token.key,
    lang: settings.lang
  };
}

export default connect(mapStateToProps)(VerificationHistory);
