import React, { useEffect } from 'react';
import { Field, Formik } from 'formik';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button, Col, FormGroup, Label, Row, Card, CardBody } from 'reactstrap';
import {
  AsyncFormikSelect,
  FormikCheckbox,
  FormikInput,
  FormikSelect,
  STATUS
} from './RealEstateInputs';
import {
  usePropertyTypes,
  useTransactionTypes,
  usePropertyLocations,
  useKeepers,
  getSearchLabel
} from './useRealEstate';
import RealEstateMedia from './RealEstateMedia';
import RealEstateThumbnail from './RealEstateThumbnail';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Pole wymagane'),
  province: Yup.string().nullable().required('Pole wymagane'),
  county: Yup.string().nullable().required('Pole wymagane'),
  borough: Yup.string().nullable().required('Pole wymagane'),
  property_type: Yup.string().nullable().required('Pole wymagane'),
  transaction_type: Yup.string().nullable().required('Pole wymagane'),
  price: Yup.string().nullable().required('Pole wymagane'),
  nr_kw: Yup.string().required('Pole wymagane'),
  keeper: Yup.string().nullable().required('Pole wymagane'),
  offer_number: Yup.string().required('Pole wymagane'),
  town: Yup.string().nullable().required('Pole wymagane')
});

const CardBodyStyled = styled(CardBody)`
  position: sticky;
  top: 0;
`;

const ErrorMsg = styled.div`
  color: red;
  font-size: 12px;
`;

function RealEstateForm({ initialValues, onSubmit, media, mediaThumbnail }) {
  const [medias, setMedias] = React.useState(media || []);
  const [thumbnail, setThumbnail] = React.useState(mediaThumbnail || null);
  const token = useSelector((state) => state.user.token.key);
  const { propertyTypes } = usePropertyTypes(token);
  const { transactionTypes } = useTransactionTypes(token);
  const { keepers } = useKeepers(token);
  const {
    boroughsOptions,
    countiesOptions,
    provincesOptions,
    townsOptions
  } = usePropertyLocations(token);
  const handleSubmit = async (values) => {
    onSubmit({
      name: values.name,
      province: values.province,
      county: values.county,
      borough: values.borough,
      property_type: values.property_type,
      transaction_type: values.transaction_type,
      keeper: values.keeper,
      price: values.price,
      nr_kw: values.nr_kw,
      promoted: values.promoted,
      description: values.description,
      details: '',
      land_area: values.land_area,
      local_area: values.local_area,
      offer_number: values.offer_number,
      town: values.town,
      publish_status: values.publish_status,
      medias: medias.map(({ id }) => id),
      thumbnail
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      render={({ handleSubmit, errors, touched, values }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col sm="8">
              <FormGroup>
                <Label for="content">Nazwa</Label>
                <Field
                  type="text"
                  name="name"
                  id="name"
                  component={FormikInput}
                />
                {errors.name && touched.name && (
                  <ErrorMsg>{errors.name}</ErrorMsg>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="content">Numer KW</Label>
                <Field
                  type="text"
                  name="nr_kw"
                  id="nr_kw"
                  component={FormikInput}
                />
                {errors.nr_kw && touched.nr_kw && (
                  <ErrorMsg>{errors.nr_kw}</ErrorMsg>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="content">Numer oferty</Label>
                <Field
                  type="text"
                  name="offer_number"
                  id="offer_number"
                  component={FormikInput}
                />
                {errors.offer_number && touched.offer_number && (
                  <ErrorMsg>{errors.offer_number}</ErrorMsg>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="content">Opis</Label>
                <Field
                  type="textarea"
                  name="description"
                  id="description"
                  component={FormikInput}
                />
              </FormGroup>
              <Row>
                <Col sm={6}>
                  <FormGroup>
                    <Label for="content">Typ zabezpieczenia</Label>
                    <Field
                      type="select"
                      name="property_type"
                      id="property_type"
                      options={[
                        { label: 'Wybierz typ zabezpieczenia', value: null },
                        ...propertyTypes.map(({ id, name }) => ({
                          label: name,
                          value: id
                        }))
                      ]}
                      component={FormikSelect}
                    />
                    {errors.property_type && touched.property_type && (
                      <ErrorMsg>{errors.property_type}</ErrorMsg>
                    )}
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup>
                    <Label for="content">Rodzaj transakcji</Label>
                    <Field
                      type="select"
                      name="transaction_type"
                      id="transaction_type"
                      options={[
                        { label: 'Wybierz typ transakcji', value: null },
                        ...transactionTypes.map(({ id, name }) => ({
                          label: name,
                          value: id
                        }))
                      ]}
                      component={FormikSelect}
                    />
                    {errors.transaction_type && touched.transaction_type && (
                      <ErrorMsg>{errors.transaction_type}</ErrorMsg>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <FormGroup>
                    <Label for="content">Województwo</Label>
                    <Field
                      id="province"
                      name="province"
                      defaultValue={{
                        id: initialValues.province,
                        label: initialValues.province_name
                      }}
                      defaultOptions={[
                        {
                          id: initialValues.province,
                          label: initialValues.province_name
                        }
                      ]}
                      component={AsyncFormikSelect}
                      loadOptions={provincesOptions}
                    />
                    {errors.province && touched.province && (
                      <ErrorMsg>{errors.province}</ErrorMsg>
                    )}
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup>
                    <Label for="content">Powiat</Label>
                    <Field
                      id="county"
                      name="county"
                      defaultValue={{
                        id: initialValues.county,
                        label: getSearchLabel([
                          initialValues.county_name,
                          initialValues.province_name
                        ])
                      }}
                      defaultOptions={[
                        {
                          id: initialValues.county,
                          label: getSearchLabel([
                            initialValues.county_name,
                            initialValues.province_name
                          ])
                        }
                      ]}
                      component={AsyncFormikSelect}
                      loadOptions={countiesOptions}
                    />
                    {errors.county && touched.county && (
                      <ErrorMsg>{errors.county}</ErrorMsg>
                    )}
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup>
                    <Label for="content">Gmina</Label>
                    <Field
                      id="borough"
                      name="borough"
                      defaultValue={{
                        id: initialValues.borough,
                        label: initialValues.borough_name
                      }}
                      defaultOptions={[
                        {
                          id: initialValues.borough,
                          label: initialValues.borough_name
                        }
                      ]}
                      component={AsyncFormikSelect}
                      loadOptions={boroughsOptions}
                    />
                    {errors.borough && touched.borough && (
                      <ErrorMsg>{errors.borough}</ErrorMsg>
                    )}
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup>
                    <Label for="content">Miasto</Label>
                    <Field
                      id="town"
                      name="town"
                      defaultValue={{
                        id: initialValues.town,
                        label: getSearchLabel([
                          initialValues.town_name,
                          initialValues.county_name,
                          initialValues.province_name
                        ])
                      }}
                      defaultOptions={[
                        {
                          id: initialValues.town,
                          label: getSearchLabel([
                            initialValues.town_name,
                            initialValues.county_name,
                            initialValues.province_name
                          ])
                        }
                      ]}
                      component={AsyncFormikSelect}
                      loadOptions={townsOptions}
                    />
                    {errors.town && touched.town && (
                      <ErrorMsg>{errors.town}</ErrorMsg>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <RealEstateMedia
                    medias={medias}
                    onRemove={(medias) => setMedias(medias)}
                    onAdd={(medias) => setMedias(medias)}
                  />
                </Col>
              </Row>
              <FormGroup>
                <Button type="submit" color="primary" className="mt-3">
                  Zapisz
                </Button>
              </FormGroup>
            </Col>
            <Col sm="4">
              <Card className="card--sticky">
                <CardBodyStyled>
                  <FormGroup check>
                    <Label check>
                      <Field name="promoted" component={FormikCheckbox} />
                      Oferta promowana
                    </Label>
                  </FormGroup>
                  <FormGroup>
                    <Label for="content">Status</Label>
                    <Field
                      type="select"
                      name="publish_status"
                      id="publish_status"
                      options={STATUS}
                      component={FormikSelect}
                      defaultValue="1"
                    />
                    {errors.publish_status && touched.publish_status && (
                      <ErrorMsg>{errors.publish_status}</ErrorMsg>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label for="content">Cena minimalna</Label>
                    <Field
                      type="text"
                      name="price"
                      id="price"
                      component={FormikInput}
                    />
                    {errors.price && touched.price && (
                      <ErrorMsg>{errors.price}</ErrorMsg>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label for="content">Powierzchnia działki</Label>
                    <Field
                      type="text"
                      name="land_area"
                      id="land_area"
                      component={FormikInput}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="content">Powierzchnia domu</Label>
                    <Field
                      type="text"
                      name="local_area"
                      id="local_area"
                      component={FormikInput}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="content">Opiekun</Label>
                    <Field
                      type="select"
                      name="keeper"
                      id="keeper"
                      placeholder="Wybierz opiekuna"
                      options={[
                        {
                          label: 'Wybierz opiekuna',
                          value: null
                        },
                        ...keepers.map(({ id, first_name, last_name }) => ({
                          label: `${first_name} ${last_name}`,
                          value: id
                        }))
                      ]}
                      isClearable
                      component={FormikSelect}
                    />
                    {errors.keeper && touched.keeper && (
                      <ErrorMsg>{errors.keeper}</ErrorMsg>
                    )}
                  </FormGroup>
                  <RealEstateThumbnail
                    medias={initialValues.thumbnail}
                    onRemove={() => setThumbnail(null)}
                    onAdd={(thumbnail) => setThumbnail(thumbnail)}
                  />
                </CardBodyStyled>
              </Card>
            </Col>
          </Row>
        </form>
      )}
    ></Formik>
  );
}

export default RealEstateForm;
