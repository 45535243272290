import { useSelector } from 'react-redux';
import { sprintf } from 'sprintf-js';

import API from '../../services/api';

const URLS = {
  QUIZZES: '/api/v1/quizzes/',
  QUIZ: '/api/v1/quizzes/%s/',
  QUIZ_QUESTIONS: '/api/v1/quizzes/%s/questions/',
  QUESTIONS: '/api/v1/questions/',
  QUESTION: '/api/v1/questions/%s/',
  ANSWERS: '/api/v1/answers/',
  ANSWER: '/api/v1/answers/%s/'
};

const DEFUALT_PARAMS = {
  ordering: '-created_at'
};

const quizData = ({ publish_status, highlight_image, title, description }) => {
  if (!!highlight_image) {
    const form = new FormData();
    form.append('highlight_image', highlight_image);
    form.append('publish_status', publish_status);
    form.append('title', title);
    form.append('description', description);

    return form;
  }
  return { publish_status, title, description };
};

const answerData = ({ answer_text, answer_image, question, correct }) => {
  if (typeof answer_image !== 'string' && answer_image !== null) {
    const form = new FormData();
    form.append('answer_text', answer_text);
    form.append('answer_image', answer_image);
    form.append('question', question);
    form.append('correct', correct);

    return form;
  }
  if (answer_image === null) {
    return {
      answer_text,
      question,
      correct,
      answer_image
    };
  }

  return { answer_text, question, correct };
};

export function useQuizzesApi() {
  const token = useSelector((state) => state.user.token.key);

  const getQuizzes = (params = DEFUALT_PARAMS) =>
    API.get(URLS.QUIZZES, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        ...params
      }
    });

  const postQuizzes = (formData) =>
    API.post(URLS.QUIZZES, formData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

  const deleteQuiz = ({ quiz_slug }) =>
    API.delete(sprintf(URLS.QUIZ, quiz_slug), {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

  const getQuiz = ({ slug }) =>
    API.get(sprintf(URLS.QUIZ, slug), {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

  const patchQuiz = ({
    publish_status,
    highlight_image,
    title,
    description,
    slug
  }) =>
    API.patch(
      sprintf(URLS.QUIZ, slug),
      quizData({ publish_status, highlight_image, title, description }),
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

  const getQuestions = ({ slug }) =>
    API.get(sprintf(URLS.QUIZ_QUESTIONS, slug), {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

  const postQuestion = ({ quiz, question_text }) =>
    API.post(
      URLS.QUESTIONS,
      { quiz, question_text },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

  const deleteQuestion = ({ question_id }) =>
    API.delete(sprintf(URLS.QUESTION, question_id), {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

  const patchQuestion = ({ question_text, question_id }) =>
    API.patch(
      sprintf(URLS.QUESTION, question_id),
      { question_text },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

  const postAnswer = ({ answer_text, question, answer_image }) =>
    API.post(
      URLS.ANSWERS,
      { answer_text, question, answer_image },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

  const deleteAnswer = ({ answer_id }) =>
    API.delete(sprintf(URLS.ANSWER, answer_id), {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

  const patchAnswer = ({
    answer_text,
    answer_image,
    correct,
    answer_id,
    question
  }) =>
    API.patch(
      sprintf(URLS.ANSWER, answer_id),
      answerData({ answer_text, answer_image, question, correct }),
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

  return {
    getQuizzes,
    postQuizzes,
    getQuiz,
    getQuestions,
    postQuestion,
    deleteQuestion,
    patchQuestion,
    postAnswer,
    deleteAnswer,
    patchAnswer,
    deleteQuiz,
    patchQuiz
  };
}
