import React, { Component } from "react";
import { Input } from "reactstrap";
import api from "../../services/api";
import cookies from "../../utilities/cookies";

class SpecializationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.initialValue,
      list: []
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { multiple } = this.props;
    const { value } = this.state;
    let newVal = parseInt(event.target.value);
    if (multiple) {
      let existig = value.findIndex(item => parseInt(item) === newVal);
      if (existig >= 0) {
        value.splice(existig, 1);
        this.setState({ value }, () => {
          this.props.selectType(this.state.value);
        });
      } else {
        this.setState({ value: [...value, newVal] }, () => {
          this.props.selectType(this.state.value);
        });
      }
    } else {
      this.setState({ value: newVal }, this.props.selectType(newVal));
    }
  }

  componentDidMount() {
    // if (!this.props.articleCategories.data.length) {
    this.fetchList();
    // }
  }

  fetchList = () => {
    const token = cookies.getToken();
    const headers = {
      Authorization: `Bearer ${token}`
    };
    api
      .get("/api/v1/specializations/?limit=20", {
        headers
      })
      .then(res => {
        this.setState({
          list: res.data.results
        });
      });
  };

  selectInput = React.createRef();

  render() {
    const { multiple } = this.props;
    const { list } = this.state;
    return (
      <>
        <Input
          type="select"
          name={this.props.name}
          id={`select${this.props.name}`}
          // onChange={this.handleChange}
          ref={this.selectInput}
          value={this.state.value}
          multiple={multiple}
          onClick={this.handleChange}
        >
          {!multiple ? <option value="">Wybierz</option> : null}
          {list.map(type => (
            <option value={type.id} key={type.id}>
              {type.name}
            </option>
          ))}
        </Input>
      </>
    );
  }
}

export default SpecializationsList;
