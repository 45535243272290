import React from "react";
import Layout from "../../components/Layout";
import { connect } from "react-redux";
import { UploadFile } from "../../common/Inputs";

class Home extends Layout {
  componentDidMount() {
    this.props.history.push("/articles/");
  }

  render() {
    const { first_name, last_name } = this.props;
    return (
      <Layout>
        <h1>
          Witaj{" "}
          <strong>
            {first_name} {last_name}
          </strong>
        </h1>
        <br />
        <h3>Dodawanie mediów</h3>
        <UploadFile />
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const {
    user: { username, first_name, last_name }
  } = state;
  return {
    username,
    first_name,
    last_name
  };
}

export default connect(mapStateToProps)(Home);
