import { cloneDeep } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { Button, ButtonGroup, FormGroup, FormText, Input } from 'reactstrap'

export const FormLabels = ({ value: _value, setFieldValue, access }) => {
  const [value, setValue] = useState(_value)
  const [newLabel, setNewLabel] = useState('')

  const addLabel = () => {
    const labels = cloneDeep(value)
    labels.push(newLabel)
    setValue(labels)
    setFieldValue(labels)
    setNewLabel('')
  }

  const deleteLabel = (index) => {
    const labels = cloneDeep(value)
    labels.splice(index, 1)
    setValue(labels)
    setFieldValue(labels)
  }

  const accessLabel = useMemo(() => {
    if (access === 1 || !access) {
      return
    }
    return access === 3
      ? 'Dla klientów'
      : 'Dla zalogowanych'
  }, [access])


  return (
    <div className="categories-input-wrapper">
      {_value.length || accessLabel ?
        <div className="categories-input">
          {accessLabel ?
            <Button
              disabled
              className="ms-2 my-1"
            >{accessLabel}</Button>
          : null}
          {(_value || []).map((l, index) => (
              <ButtonGroup
                key={index}
                className="ms-2 my-1"
                size="sm"
                color="dark"
              >
                <Button disabled>{l}</Button>
                <Button
                  className="btn-with-icon"
                  onClick={() => deleteLabel(index)}
                >
                  <i className="bi-x-lg"></i>
                </Button>
              </ButtonGroup>
          ))}
        </div>
        : ''
      }

      <FormGroup>
        <Input
          className="categories-input-search mb-3"
          placeholder="Wpisz etykietę"
          value={newLabel}
          onChange={e => setNewLabel(e.target.value)}
          maxLength={25}
        />
        <FormText className="field-length">{newLabel.length}/25 znaków</FormText>
      </FormGroup>

      <Button type="button" outline onClick={addLabel}>
        Dodaj etykietę
      </Button>

    </div>
  )
}
