import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from '../../components/Layout/index.jsx';
import { Row, Col } from 'reactstrap';
import { removeEvent } from '../../services/redux/reducers/events/actionCreators';
import EventUserList from '../../components/EventUsers/EventUserList';
import ExportButton from '../../common/Export/ExportButton';

class EventsUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: null,
      loading: true,
      tableState: ''
    };
  }

  render() {
    const { permission } = this.props;
    return (
      <Layout>
        <h1 className="h2 pb-md-4 page-header">Lista uczestników wydarzenia</h1>
        <Row className="space-between">
          <Col>{/* <h4>Lista użytkowników</h4> */}</Col>
          <Col sm="3" className="text-right">
            <ExportButton
              type="exportEventUsers"
              eventid={this.props.match.params.id}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <EventUserList eventid={this.props.match.params.id} />
          </Col>
          {/* <Col><UploadUser /></Col> */}
        </Row>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { events, user } = state;
  return {
    events,
    token: user.token.key
  };
}

export default connect(
  mapStateToProps,
  {
    removeEvent
  }
)(EventsUsers);
