export const userTypes = [
  'administrator',
  'editor',
  'partner',
  'user',
  'ekspert produktowy'
];

export const userType = (id) => {
  return userTypes[id - 1];
};

export const consumerTypes = [
  // { val: 1, label: /*'undefined'*/ '' },
  { val: 2, label: 'manufacturer' },
  { val: 3, label: 'farmer' }
];

export const consumerType = (id) => {
  return consumerTypes.find((element) => element.val === id);
};

export const consumerStatuses = [
  { val: 1, label: 'Klient oczekujący banku (Pending Premium)' },
  { val: 2, label: 'Klient zarejestrowany (Normal)' },
  { val: 3, label: 'Klient zaakceptowany banku (Premium)' },
  { val: 5, label: 'Klient promocyjny (Premium)' },
  { val: 4, label: 'Nie dotyczy' }
];

export const consumerStatus = (id) => {
  return consumerStatuses.find((element) => element.val === id);
};

export const userTools = [
  {
    name: 'Rolnikon',
    type: 'checkbox',
    val: 0,
    dependent: [1]
  },
  {
    name: 'Agrivi',
    type: 'checkbox',
    val: 1,
    dependent: [0]
  },
  {
    name: 'Satagro',
    type: 'checkbox',
    val: 2
    // dependent: [0]
  },
  {
    name: 'Findoor',
    type: 'checkbox',
    val: 3
  }
];

// consumer_status:
//    VERIFIED = 1
//    UNVERIFIED = 2
//    PREMIUM = 3
// consumer_type:
//    undefined = 1
//    manufacturer = 2
//    farmer = 3
// user_type:
//    ADMIN = 1
//    EDITOR = 2
//    PARTNER = 3
//    CONSUMER = 4
