import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  createUser,
  editUser,
  editUserData,
  resetReq
} from '../../services/redux/reducers/user/actionCreators';
import {
  Button,
  ButtonGroup,
  Form,
  FormGroup,
  FormFeedback,
  FormText,
  Label,
  Input,
  Col,
  Card,
  CardBody
} from 'reactstrap';
import { Formik } from 'formik';
import Loading from '../../common/Loading';
import {
  userTypes,
  consumerTypes,
  consumerStatuses,
  userTools
} from '../../utilities/user';
import { FormattedMessage } from 'react-intl';
import ArticleCategoriesList from '../../common/Articles/tags';
import { Redirect } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import RegionsList from '../../common/Inputs/Regions';
import SpecializationsList from '../../common/Inputs/Specializations';
import VerificationHistory from '../Users/VerificationHistory';

const userPermissions = [
  {
    key: 1,
    label: 'Może tworzyć artykuły',
    name: 'can_create_articles'
  },
  {
    key: 2,
    label: 'Może tworzyć wydarzenia',
    name: 'can_create_events'
  }
];

class UserForm extends Component {
  constructor(props) {
    super(props);
    const { userid } = this.props;
    let editMode = this.props.user.edit || false;
    let loaded = false;

    let initialValues = {
      email: '',
      first_name: '',
      last_name: '',
      user_type: '',
      consumer_type: '',
      consumer_status: '',
      telephone: '',
      favorite_cats_list: [],
      allowed_tools: [0, 0, 0, 0],
      can_create_articles: false,
      can_create_events: false,
      about_me: '',
      profession: '',
      regions_list: [],
      specializations_list: [],
      photo: null,
      is_email_confirmed: false
    };

    if (userid) {
      editMode = true;
    }

    this.state = {
      editMode,
      loaded,
      initialValues,
      blockedTools: []
    };
  }

  componentDidMount() {
    if (!this.state.loaded && this.state.editMode) {
      this.props.editUser(this.props.userid);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.loaded && this.state.editMode && this.props.user.edit) {
      let data = this.props.user.edit;
      delete data.password;
      let allowed_tools = [0, 0, 0, 0];
      data.allowed_tools = data.allowed_tools.toString(2).split('');
      data.allowed_tools.forEach((el, index) => {
        allowed_tools[index] = parseInt(el);
      });
      if (data.allowed_tools.length !== 4) {
        data.allowed_tools = allowed_tools.reverse();
      } else {
        data.allowed_tools = allowed_tools;
      }

      let block = [];

      allowed_tools.forEach((tool, index) => {
        if (tool && userTools[index].dependent)
          block.push(...userTools[index].dependent);
      });

      data.favorite_cats_list = data.categories.map((item) => item.id);
      delete data.categories;

      data.regions_list = data.regions.map((item) => item.id);
      data.specializations_list = data.specializations.map((item) => item.id);
      delete data.regions;
      delete data.specializations;

      this.setState({
        loaded: true,
        blockedTools: block,
        initialValues: {
          ...this.state.initialValues,
          ...data
        }
      });
    }
    if (prevProps.user.edit && this.props.user.edit === '') {
      this.setState({ editMode: false });
    }
    if (!prevProps.created && this.props.created) {
      this.setState({ redirect: true });
    }
    if (
      prevState.loaded &&
      !prevState.initialValues.is_expert &&
      this.props.user.edit.is_expert
    ) {
      window.location.reload();
    }
  }

  componentWillUnmount() {
    const { resetReq } = this.props;
    resetReq();
  }

  selectUserType = React.createRef();
  selectConsumerType = React.createRef();
  selectConsumerStatus = React.createRef();

  render() {
    const { userid, validation } = this.props;
    const { editMode, loaded, initialValues, redirect, blockedTools } =
      this.state;
    // if (redirect) {
    //   return <Redirect to={`/users`} />;
    // }
    return (
      <div>
        {!editMode || (editMode && loaded) ? (
          <Formik
            initialValues={initialValues}
            ref={this.newEvent}
            onSubmit={(values, { setValues }) => {
              let data = cloneDeep(values);
              delete data.deleted_at;
              if (parseInt(values.user_type) !== 4) {
                delete data.allowed_tools;
                delete data.consumer_type;
                delete data.consumer_status;
                data.is_email_confirmed = true;
              } else {
                data.allowed_tools = parseInt(data.allowed_tools.join(''), 2);
              }
              if (!initialValues.is_expert || !data.is_expert) {
                // about_me: "",
                // profession: "",
                // regions_list: [],
                // specializations_list: [],
                // photo: null
                delete data.regions_list;
                delete data.specializations_list;
                delete data.photo;
                delete data.profession;
                delete data.about_me;
              }
              const fileData = new FormData();
              if (
                typeof data.photo === 'string' ||
                data.photo instanceof String
              ) {
                delete data.photo;
              }
              Object.keys(data).forEach((item) => {
                if (
                  item === 'regions_list' ||
                  item === 'specializations_list' ||
                  item === 'favorite_cats_list'
                ) {
                  for (var i = 0; i < data[item].length; i++) {
                    fileData.append(`${item}`, parseInt(data[item][i]));
                  }
                } else {
                  fileData.append(item, data[item]);
                }
              });
              if (!editMode) {
                this.props.createUser(fileData);
              } else {
                this.props.editUserData(fileData, userid);
              }
            }}
            render={({ values, handleSubmit, handleChange, setFieldValue }) => (
              <Form className="row" onSubmit={handleSubmit} noValidate>
                <Col sm="8">
                  <FormGroup check className="mb-4">
                    <Label check>
                      <Input
                        type="checkbox"
                        name="is_email_confirmed"
                        onChange={handleChange}
                        checked={!!values.is_email_confirmed}
                      />{' '}
                      Użytkownik aktywny
                    </Label>
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">E-mail</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      required
                      value={values.email}
                      onChange={handleChange}
                      autoComplete="new-email"
                      invalid={!!validation.email}
                    />
                    <FormFeedback>{validation.email}</FormFeedback>
                  </FormGroup>
                  {!editMode ? (
                    <FormGroup>
                      <Label for="password">Hasło</Label>
                      <Input
                        type="password"
                        name="password"
                        id="password"
                        required
                        value={values.password}
                        onChange={handleChange}
                        autoComplete="new-password"
                        invalid={!!validation.password}
                      />
                      <FormFeedback>{validation.password}</FormFeedback>
                    </FormGroup>
                  ) : null}
                  <FormGroup>
                    <Label for="first_name">Imię</Label>
                    <Input
                      type="text"
                      name="first_name"
                      id="first_name"
                      required
                      value={values.first_name}
                      onChange={handleChange}
                      invalid={!!validation.first_name}
                    />
                    <FormFeedback>{validation.first_name}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="last_name">Nazwisko</Label>
                    <Input
                      type="text"
                      name="last_name"
                      id="last_name"
                      required
                      value={values.last_name}
                      onChange={handleChange}
                      invalid={!!validation.name}
                    />
                    <FormFeedback>{validation.name}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="telephone">Nr telefonu</Label>
                    <Input
                      type="number"
                      name="telephone"
                      id="telephone"
                      value={values.telephone}
                      onChange={handleChange}
                      invalid={!!validation.name}
                    />
                    <FormFeedback>{validation.name}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="favorite_cats_list">
                      <FormattedMessage
                        id="aricle.favorite_cats_list"
                        defaultMessage="Ulubione kategorie"
                      />
                    </Label>
                    <Input
                      type="hidden"
                      required
                      value={values.favorite_cats_list}
                      onChange={handleChange}
                      invalid={!!validation.favorite_cats_list}
                    />
                    <ArticleCategoriesList
                      isList={false}
                      selectType={(id) =>
                        setFieldValue('favorite_cats_list', id)
                      }
                      initialValue={values.favorite_cats_list}
                      multiple={true}
                    />
                    <FormFeedback>{validation.favorite_cats_list}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="user_type">Typ użytkownika</Label>
                    <Input
                      type="select"
                      name="user_type"
                      id="user_type"
                      onChange={handleChange}
                      ref={this.selectUserType}
                      value={values.user_type}
                      invalid={!!validation.user_type}
                    >
                      <option value="null">Wybierz</option>
                      {userTypes.map((type, id) => (
                        <option value={id + 1} key={id}>
                          {type}
                        </option>
                      ))}
                    </Input>
                    <FormFeedback>{validation.user_type}</FormFeedback>
                  </FormGroup>
                  {parseInt(values.user_type) === 3 && (
                    <>
                      <FormGroup tag="fieldset">
                        <Label for="allowed_tools">Uprawnienia:</Label>
                        {userPermissions.map((item, index) => (
                          <FormGroup check key={index}>
                            <Label check>
                              <Input
                                type="checkbox"
                                name={item.name}
                                onChange={handleChange}
                                checked={values[item.name]}
                                value={values[item.name]}
                              />{' '}
                              {item.label}
                            </Label>
                            <FormFeedback>
                              {validation.allowed_tools}
                            </FormFeedback>
                          </FormGroup>
                        ))}
                      </FormGroup>
                    </>
                  )}

                  {parseInt(values.user_type) === 4 ? (
                    <>
                      <FormGroup>
                        <Label for="consumer_type">Typ klienta</Label>
                        <Input
                          type="select"
                          name="consumer_type"
                          id="consumer_type"
                          onChange={handleChange}
                          ref={this.selectConsumerType}
                          value={values.consumer_type}
                          invalid={!!validation.consumer_type}
                        >
                          <option value="null">Wybierz</option>
                          {consumerTypes.map((type, id) => (
                            <option value={type.val} key={id}>
                              {type.label}
                            </option>
                          ))}
                        </Input>
                        <FormFeedback>{validation.consumer_type}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label for="consumer_status">Status klienta</Label>
                        <Input
                          type="select"
                          name="consumer_status"
                          id="consumer_status"
                          onChange={handleChange}
                          ref={this.selectConsumerStatus}
                          value={values.consumer_status}
                          invalid={!!validation.consumer_status}
                        >
                          <option value="null">Wybierz</option>
                          {consumerStatuses.map((type, index) => (
                            <option value={type.val} key={index}>
                              {type.label}
                            </option>
                          ))}
                        </Input>
                        <FormFeedback>
                          {validation.consumer_status}
                        </FormFeedback>
                      </FormGroup>
                      <VerificationHistory
                        userid={userid}
                      ></VerificationHistory>
                      <FormGroup tag="fieldset">
                        <Label for="allowed_tools">Dostępne narzędzia</Label>
                        {userTools.map((item, index) => (
                          <FormGroup check key={index}>
                            <Label check>
                              <Input
                                type={item.type}
                                name="allowed_tools"
                                onChange={(e) => {
                                  let allowed = cloneDeep(values.allowed_tools);
                                  allowed[item.val] =
                                    +!values.allowed_tools[item.val];
                                  setFieldValue(`allowed_tools`, allowed);
                                  let block = [];
                                  allowed.forEach((tool, index) => {
                                    if (tool && userTools[index].dependent)
                                      block.push(...userTools[index].dependent);
                                  });
                                  this.setState({
                                    blockedTools: block
                                  });
                                }}
                                checked={!!values.allowed_tools[item.val]}
                                value={item.val}
                                disabled={blockedTools.includes(index)}
                                // value={index}
                              />{' '}
                              {item.name}
                            </Label>
                          </FormGroup>
                        ))}
                        <FormFeedback>{validation.allowed_tools}</FormFeedback>
                      </FormGroup>
                    </>
                  ) : null}

                  <h2>Pola związane z ekspert</h2>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="is_expert"
                        onChange={handleChange}
                        checked={!!values.is_expert}
                        // value={item.val}
                        // value={index}
                      />{' '}
                      Oznacz jako ekspert
                    </Label>
                  </FormGroup>
                  <FormGroup>
                    <Label for="profession">Stanowisko</Label>
                    <Input
                      type="text"
                      name="profession"
                      id="profession"
                      required
                      value={values.profession}
                      onChange={handleChange}
                      invalid={!!validation.profession}
                    />
                    <FormFeedback>{validation.profession}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="about_me">O mnie</Label>
                    <Input
                      type="textarea"
                      name="about_me"
                      id="about_me"
                      required
                      value={values.about_me}
                      onChange={handleChange}
                      invalid={!!validation.about_me}
                    />
                    <FormFeedback>{validation.about_me}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="specializations_list">
                      <FormattedMessage
                        id="aricle.specializations_list"
                        defaultMessage="Specjalizacja"
                      />
                    </Label>
                    <Input
                      type="hidden"
                      required
                      value={values.specializations_list}
                      onChange={handleChange}
                      invalid={!!validation.specializations_list}
                    />
                    <SpecializationsList
                      isList={false}
                      selectType={(id) =>
                        setFieldValue('specializations_list', id)
                      }
                      initialValue={values.specializations_list}
                      multiple={true}
                    ></SpecializationsList>
                    <FormFeedback>
                      {validation.specializations_list}
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="regions_list">
                      <FormattedMessage
                        id="aricle.regions_list"
                        defaultMessage="Regiony"
                      />
                    </Label>
                    <Input
                      type="hidden"
                      required
                      value={values.regions_list}
                      onChange={handleChange}
                      invalid={!!validation.regions_list}
                    />
                    <RegionsList
                      isList={false}
                      selectType={(id) => setFieldValue('regions_list', id)}
                      initialValue={values.regions_list}
                      multiple={true}
                    ></RegionsList>
                    <FormFeedback>{validation.regions_list}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="photo">Zdjęcie</Label> <br />
                    {values.photo &&
                      typeof values.photo === 'string' &&
                      values.photo.includes('http') && (
                        <img
                          src={values.photo}
                          className="mb-4"
                          style={{
                            width: '250px',
                            height: '250px',
                            overflow: 'hidden',
                            borderRadius: '50%',
                            objectFit: 'contain'
                          }}
                        />
                      )}
                    <Input
                      type="file"
                      name="photo"
                      onChange={(e) => {
                        const { files } = e.target;
                        const file = files[0];
                        const fileSumSize = file.size;
                        let error = null;
                        const MAX_FILE_SIZE = 5 * 1e6;
                        if (fileSumSize >= MAX_FILE_SIZE) {
                          error = 'plik jest za duży';
                        }
                        const reader = new FileReader();
                        const _this = this;
                        reader.onloadend = () => {
                          const image = new Image();
                          image.src = reader.result;
                          image.onload = function () {
                            if (this.width < 250 || this.height < 250) {
                              error = 'szerokość i wysokość pliku jest za mała';
                            }
                            if (!error) {
                              _this.setState({
                                imagePreviewUrl: reader.result
                              });
                              setFieldValue('photo', file);
                            } else {
                              alert(error);
                            }
                          };
                        };
                        reader.readAsDataURL(file);
                      }}
                      id="photo"
                      value={
                        values.photo instanceof String
                          ? values.photo
                          : values.photo
                          ? values.photo.filename
                          : values.photo
                      }
                    />
                    <FormText color="muted">
                      (Format kwadratowy, minimum 250x250 px, nie większe niż
                      5MB)
                    </FormText>
                  </FormGroup>
                </Col>
                <Col sm="4">
                  <Card>
                    <CardBody>
                      <ButtonGroup>
                        {editMode ? (
                          <Button type="submit" color="primary">
                            Zapisz zmiany
                          </Button>
                        ) : (
                          <Button type="submit" color="primary">
                            Zapisz
                          </Button>
                        )}
                      </ButtonGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Form>
            )}
          />
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, userRequest } = state;
  return {
    user,
    validation: userRequest.newUser.errorBody,
    created: userRequest.createdUser
  };
}

export default connect(mapStateToProps, {
  createUser,
  editUser,
  editUserData,
  resetReq
})(UserForm);
