import * as actions from './actionTypes';
import { SUCCESS } from '../../utils/actionSuffix';

const InitialState = {
  data: [],
  selectedEvent: -1,
  translations: []
};

export function events(state = InitialState, action) {
  switch (action.type) {
    case actions.SET_EVENTS:
      return {
        ...state,
        data: action.data
      };

    case actions.SET_EVENT_ID:
      return {
        ...state,
        selectedEvent: parseInt(action.id)
      };

    case actions.SET_EVENT_STATUS + SUCCESS:
      let data = state.data;
      data[0].publish_status = action.status;
      return {
        ...state,
        data: data
      };

    case actions.FETCH_EVENT_TRANSLATION + SUCCESS:
      return {
        ...state,
        translations: action.translations
      };

    case actions.RESET_REQ:
      return {
        ...state,
        data: [],
        translations: []
      };

    default:
      return state;
  }
}
