import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup, ListGroup, ListGroupItem } from 'reactstrap';
import { setSelected } from '../../services/redux/reducers/articlesCategories/actionCreators';
import { selectNewArticleCategories } from '../../services/redux/reducers/selectors';
import { fetchPromotedArticleCategoriesNew } from '../../services/redux/reducers/articlesCategories/actionCreators';

const ArticleTypesList = () => {
  const newArticleCategories = useSelector(selectNewArticleCategories);
  const dispatch = useDispatch();

  const setSelectedCategory = React.useCallback(
    (id) => dispatch(setSelected(id)),
    [dispatch]
  );

  const fetchPromotedArticleCategories = React.useCallback(
    (id) => dispatch(fetchPromotedArticleCategoriesNew(id)),
    [dispatch]
  );

  React.useEffect(() => {
    return () => {
      setSelectedCategory(-1);
    };
  }, [setSelectedCategory]);

  return (
    <ListGroup>
      {newArticleCategories.length ? (
        newArticleCategories.map((type) => (
          <ListGroupItem key={type.id} className="listItem--with-button">
            <div>Nazwa: {type.type_name}</div>
            <ButtonGroup size="sm">
              <Button
                size="sm"
                onClick={(e) => {
                  setSelectedCategory(type.id);
                  fetchPromotedArticleCategories(type.id);
                }}
              >
                Edytuj
              </Button>
            </ButtonGroup>
          </ListGroupItem>
        ))
      ) : (
        <ListGroupItem>Brak typów</ListGroupItem>
      )}
    </ListGroup>
  );
};

export default ArticleTypesList;
