import React, { useRef, useState } from 'react'
import { Transforms } from 'slate';
import { useSelected, useFocused, useSlateStatic, ReactEditor } from "slate-react";
import { useAutosizeTextArea } from '../../utils/useAutosizeTextArea';

const Image = ({ attributes, element, children }) => {
  const editor = useSlateStatic()
  const { url, width, height, alt } = element;
  const selected = useSelected();
  const focused = useFocused();

  const [desc, setDesc] = useState(element.desc || '')
  const textAreaRef = useRef(null)
  useAutosizeTextArea(textAreaRef, desc)
  const handleChange = (event) => {
    setDesc(event.target.value)
  }
  const handleSave = () => {
    const path = ReactEditor.findPath(editor, element);
    // ReactEditor.setNode(path, { ...element, desc: desc });
    Transforms.setNodes(editor, { ...element, desc: desc }, { at: path })
  }

  const isFocused = (selected && focused) || (textAreaRef && textAreaRef.current === document.activeElement)

  return (
    <div
      {...attributes}
      className='element-image'
      style={{ display: 'flex', justifyContent: 'center', boxShadow: isFocused && '0 0 3px 3px lightgray' }}
    >
      <div contentEditable={false} style={{ width: width, height: height }}>
        <img alt={alt} src={url} />
        <textarea
          className="editor-textarea"
          rows="1"
          onChange={handleChange}
          onBlur={handleSave}
          placeholder="Dodaj opis do zdjęcia"
          ref={textAreaRef}
          value={desc}
        />
      </div>
      {children}
    </div>
  );
};
export default Image;
