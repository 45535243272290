import * as actionTypes from './actionTypes';
import { SUCCESS, FAILURE } from '../../utils/actionSuffix';

const InitialState = {
  type: '',
  message: ''
};

export function alert(state = InitialState, action) {
  switch (action.type) {
    case actionTypes.ALERT + SUCCESS:
      return {
        type: 'success',
        message: action.message
      };
    case actionTypes.ALERT + FAILURE:
      return {
        type: 'danger',
        message: action.message
      };
    case actionTypes.ALERT_CLEAR:
      return {};
    default:
      return state;
  }
}
