import React from 'react';
import { useFormik } from 'formik';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import debounce from 'lodash.debounce';

import Inputs from '../../Inputs';

const DocumentsForm = ({ onChange, py, title, files, anchor }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      py,
      title,
      files,
      anchor
    },
    onSubmit: (values) => {
      onChange(values);
    }
  });

  const onBlur = () => {
    formik.submitForm();
  };

  const debounceSave = debounce(() => {
    onBlur();
  }, 800);

  return (
    <Form>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="title">Tytuł</Label>
            <Input
              id="title"
              name="title"
              type="textarea"
              value={formik.values.title}
              invalid={!!formik.errors.title}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('title', target.value);
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        {formik.values.files.map((_, key) => (
          <Col xs={4}>
            <Button
              onClick={() => {
                formik.setFieldValue(
                  'files',
                  formik.values.files.filter((_, k) => k !== key)
                );
                onBlur();
              }}
              type="button"
              style={{ marginBottom: '1rem' }}
              block
              color="danger"
            >
              Usuń dokument
            </Button>
            <FormGroup>
              <Label for={`files[${key}].name`}>Nazwa</Label>
              <Input
                id={`files[${key}].name`}
                name={`files[${key}].name`}
                type="textarea"
                value={formik.values.files[key].name}
                onBlur={onBlur}
                onChange={({ target }) => {
                  formik.setFieldValue(`files[${key}].name`, target.value);
                  debounceSave();
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for={`files[${key}].details`}>Szczegóły</Label>
              <Input
                id={`files[${key}].details`}
                name={`files[${key}].details`}
                type="textarea"
                value={formik.values.files[key].details}
                onBlur={onBlur}
                onChange={({ target }) => {
                  formik.setFieldValue(`files[${key}].details`, target.value);
                  debounceSave();
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for={`files[${key}].url`}>URL</Label>
              <Input
                id={`files[${key}].url`}
                name={`files[${key}].url`}
                type="textarea"
                value={formik.values.files[key].url}
                onBlur={onBlur}
                onChange={({ target }) => {
                  formik.setFieldValue(`files[${key}].url`, target.value);
                  debounceSave();
                }}
              />
              <Inputs.MediaButton
                type="document"
                onChange={(url) => {
                  formik.setFieldValue(`files[${key}].url`, url);
                  debounceSave();
                }}
              />
            </FormGroup>
          </Col>
        ))}
        <Col xs={12}>
          <Button
            onClick={() => {
              formik.setFieldValue('files', [
                ...formik.values.files,
                {
                  name: 'Załącznik do oferty 1',
                  details: 'Produkt 2021, Plik .pdf 3.4mb',
                  url: '#'
                }
              ]);
              onBlur();
            }}
            type="button"
            style={{ marginBottom: '1rem' }}
            block
            color="success"
          >
            Dodaj dokument
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Inputs.SpacesInput
            id="py"
            name="py"
            value={formik.values.py}
            invalid={!!formik.errors.py}
            onBlur={onBlur}
            onChange={({ target }) => {
              formik.setFieldValue('py', target.value);
            }}
          />
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="anchor">Zakotwiczenie</Label>
            <Input
              id="anchor"
              name="anchor"
              type="text"
              value={formik.values.anchor}
              invalid={!!formik.errors.anchor}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('anchor', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default DocumentsForm;
