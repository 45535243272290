import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, ButtonGroup, Input, ListGroup, ListGroupItem } from 'reactstrap';
import { useMultimediaCategories } from './useMultimediaCategories';

export const MultimediaCategories = ({ value: _value, setFieldValue }) => {
  const [search, setSearch] = useState()
  const { categories } = useMultimediaCategories()
  const [value, setValue] = useState(_value)

  const selectedCategoriesIdsMap = value.map(c => c.id)
  const categoriesList = categories

  const handleCategorySelect = (cat) => {
    const cats = cloneDeep(value)
    cats.push(cat)
    setValue(cats)
    setFieldValue(cats.map(c => c.id))
  }

  const handleCategoryDelete = (index) => {
    const cats = cloneDeep(value)
    cats.splice(index, 1)
    setValue(cats)
    setFieldValue(cats.map(c => c.id))
  }

  useEffect(() => {
    if (_value.length && categoriesList.length) {
      const cats = categoriesList.filter(cat => _value.includes(cat.id))
      setValue(cats)
    }
  }, [_value, categoriesList.length])

  const filteredCategories = useMemo(() => {
    const searchUp = search && search.toUpperCase()
    return categoriesList
      .filter(cat => searchUp ? cat.name.toUpperCase().includes(searchUp) : true)
      .filter(cat => !selectedCategoriesIdsMap.includes(cat.id))
  }, [categoriesList, search, selectedCategoriesIdsMap])

  return (
    <div className="categories-input-wrapper">
      {value.length ? (
        <div className="categories-input">
          {value.map((c, index) => {
            return (
              <ButtonGroup
                key={c.id}
                className="ms-2 my-1"
                size="sm"
                color="dark"
              >
                <Button disabled>
                  {c.name}
                </Button>
                <Button
                  className="btn-with-icon"
                  onClick={() => handleCategoryDelete(index)}
                >
                  <i className="bi-x-lg"></i>
                </Button>
              </ButtonGroup>
            )
          })}
        </div>)
        : null}

      <Input
        className="categories-input-search"
        placeholder="Szukaj kategorii"
        value={search}
        onChange={e => setSearch(e.target.value)}
      />

      <ListGroup className="categories-list">
        {filteredCategories.length
          ? filteredCategories.map(cat => {
            return (
              <ListGroupItem
                key={cat.id}
                onClick={() => handleCategorySelect(cat)}
                className={`${cat.isSubCategory ? 'categories-list-el--sub' : ''}`}
              >{cat.name}</ListGroupItem>
            )
          })
          : (<ListGroupItem>Brak kategorii</ListGroupItem>)
        }
      </ListGroup>
    </div>
  )
}
