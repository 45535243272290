import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  FormGroup,
  Input
} from 'reactstrap';
import debounce from 'lodash.debounce';

import { selectToken } from '../../../../services/redux/reducers/user/selectors';
import API from '../../../../services/api';

import './MediaButton.scss';

const MEDIA_TYPE = {
  image: 'image',
  audio: 'audio',
  document: 'document',
  video: 'video'
};

const MediaFile = ({ type, name, id, file, onClick }) => {
  switch (type) {
    case MEDIA_TYPE.audio:
      return (
        <Row className="media-card" onClick={onClick}>
          <Col xs={8}>
            <audio src={file} controls>
              Your browser does not support the video element.
            </audio>
          </Col>
          <Col xs={4}>
            <h6>{name}</h6>
            <p>{id}</p>
          </Col>
        </Row>
      );
    case MEDIA_TYPE.image:
      return (
        <Row className="media-card" onClick={onClick}>
          <Col xs={8}>
            <img src={file} alt={name} />
          </Col>
          <Col xs={4}>
            <h6>{name}</h6>
            <p>{id}</p>
          </Col>
        </Row>
      );
    case MEDIA_TYPE.document:
      return (
        <Row className="media-card" onClick={onClick}>
          <Col xs={8}>Dokument</Col>
          <Col xs={4}>
            <h6>{name}</h6>
            <p>{id}</p>
          </Col>
        </Row>
      );
    case MEDIA_TYPE.video:
      return (
        <Row className="media-card" onClick={onClick}>
          <Col xs={8}>
            <video src={file} controls>
              Your browser does not support the video element.
            </video>
          </Col>
          <Col xs={4}>
            <h6>{name}</h6>
            <p>{id}</p>
          </Col>
        </Row>
      );
    default:
      return null;
  }
};

const fetchMedia = (token, type, search) =>
  API.get(`/api/v1/media-files/?limit=50&type=${type}&search=${search}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

const MediaButton = ({ onChange, type }) => {
  const [media, setMedia] = useState({
    count: -1,
    next: null,
    previous: null,
    results: []
  });
  const [isOpen, setOpen] = useState(false);
  const token = useSelector(selectToken);

  useEffect(() => {
    fetchMedia(token, type, '').then(({ data }) => {
      setMedia(data);
    });
    // eslint-disable-next-line
  }, []);

  const debounceSearch = debounce((search) => {
    fetchMedia(token, type, search).then(({ data }) => {
      setMedia(data);
    });
  }, 700);

  const addNewFile = (e) => {
    e.preventDefault();
    let fileData = new FormData(e.target);

    API.post('/api/v1/media-files/', fileData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    }).then(({ data }) => {
      onChange(data.file);
      setOpen(false);
    });
  };

  return (
    <>
      <Button
        color="primary"
        outline
        size="sm"
        type="button"
        style={{ marginBottom: '1rem' }}
        onClick={() => setOpen(true)}
      >
        Media
      </Button>
      <Modal isOpen={isOpen} toggle={() => setOpen(false)}>
        <ModalHeader>Media</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={6}>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Input
                      id="search"
                      name="search"
                      placeholder="Szukaj..."
                      type="text"
                      onChange={({ target }) => {
                        debounceSearch(target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {media.count === -1 && <p>Wyszukaj aby wyświetlić media...</p>}
              {media.count === 0 && <p>Brak wyników</p>}
              {media.results.map(({ file, id, type, name }) => (
                <MediaFile
                  key={id}
                  file={file}
                  id={id}
                  type={type}
                  name={name}
                  onClick={() => {
                    onChange(file);
                    setOpen(false);
                  }}
                />
              ))}
            </Col>
            <Col xs={6}>
              <form onSubmit={addNewFile}>
                <FormGroup>
                  <Input
                    id="file"
                    name="file"
                    placeholder="Dodaj nowy"
                    type="file"
                  />
                </FormGroup>
                <Button
                  color="primary"
                  size="sm"
                  type="submit"
                  style={{ marginBottom: '1rem' }}
                >
                  Dodaj
                </Button>
              </form>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default MediaButton;
