export const MULTIMEDIA_TYPES_VALUES = {
  Video: 1,
  Podcast: 2,
  PhotoGallery: 3
}

export const MUTLIMEDIA_TYPES = [
  { label: 'Video', value: MULTIMEDIA_TYPES_VALUES.Video },
  { label: 'Podcast', value: MULTIMEDIA_TYPES_VALUES.Podcast },
  { label: 'Photo gallery', value: MULTIMEDIA_TYPES_VALUES.PhotoGallery }
]
