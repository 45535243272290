import * as actionTypes from './actionTypes';

export const fetchArticleCategories = report => ({
  type: actionTypes.FETCH_ARTICLE_CATEGORIES,
  report
});

export const setArticleCategories = data => ({
  type: actionTypes.SET_ARTICLE_CATEGORIES,
  data
});

export const fetchArticleCategoriesNew = () => ({
  type: actionTypes.FETCH_ARTICLE_CATEGORIES_NEW
});

export const setArticleCategoriesNew = data => ({
  type: actionTypes.SET_ARTICLE_CATEGORIES_NEW,
  data
});

export const fetchPromotedArticleCategoriesNew = (id) => ({
  type: actionTypes.FETCH_PROMOTED_ARTICLE_CATEGORIES_NEW,
  id
});

export const setPromotedArticleCategoriesNew = data => ({
  type: actionTypes.SET_PROMOTED_ARTICLE_CATEGORIES_NEW,
  data
});

export const createCategory = categoryFormData => ({
  type: actionTypes.CREATE_CATEGORY,
  categoryFormData
});

export const editCategory = categoryFormData => ({
  type: actionTypes.EDIT_CATEGORY,
  categoryFormData
});

export const deleteCategory = id => ({
  type: actionTypes.DELETE_CATEGORY,
  id
});

export const setSelected = id => ({
  type: actionTypes.SET_SELECTED,
  id
});
