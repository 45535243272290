import * as actionTypes from './actionTypes';
import { SUCCESS, FAILURE } from '../../utils/actionSuffix';
// add types
const InitialState = {
  uploading: false,
  uploaded: false,
  error: null,
  errorBody: {}
};

export function articlesRequest(state = InitialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_ARTICLE:
      return { ...state, uploaded: false, uploading: true, error: false };

    case actionTypes.CREATE_ARTICLE + SUCCESS:
      return { ...state, uploading: false, uploaded: true, error: false };

    case actionTypes.CREATE_ARTICLE + FAILURE:
    case actionTypes.REMOVE_ARTICLE + FAILURE:
      return {
        ...state,
        uploading: false,
        error: true,
        errorBody: action.error.body
      };

    case actionTypes.RESET_REQ:
      return InitialState;

    default:
      return state;
  }
}
