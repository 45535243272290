import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Layout from '../../components/Layout';
import { removeArticle } from '../../services/redux/reducers/articles/actionCreators';
import { fetchArticleCategoriesNew } from '../../services/redux/reducers/articlesCategories/actionCreators';
import ArticlesList from '../../components/Articles/List';

class Articles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: 'articles'
    };
  }

  componentDidMount() {
    this.props.fetchArticleCategoriesNew();
  }

  removeArticle(id) {
    this.props.removeArticle(id);
    setTimeout(() => {
      this.fetchData(this.state.tableState);
    }, 1000);
  }

  render() {
    const { slug } = this.state;

    return (
      <Layout>
        <h1 className="h2 pb-md-4 page-header">
          Artykuły
          <Button
            className="ms-3"
            onClick={(e) => {
              e.preventDefault();
              this.props.history.push(`/${slug}/new`);
            }}
            color="primary"
          >
            Dodaj nowy
          </Button>
        </h1>
        <h4>Lista artykułów</h4>
        <ArticlesList />
      </Layout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { user, settings, articleCategories } = state;
  return {
    token: user.token.key,
    userType: user.user_type,
    lang: settings.lang,
    articleCategories: articleCategories.articleCategoriesNew.data
  };
}

export default connect(mapStateToProps, {
  removeArticle,
  fetchArticleCategoriesNew
})(Articles);
