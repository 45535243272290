// 1 - Draft
// 2 - Pending for approval
// 3 - Approved by admin

export const statuses = [
  {
    id: 1,
    label: 'Szkic'
  },
  {
    id: 2,
    label: 'Oczekujący na opublikowanie'
  },
  {
    id: 3,
    label: 'Opublikowany'
  }
];

export const displayStatus = (id) =>
  statuses.find((status) => status.id === id).label;
