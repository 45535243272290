import React from 'react';
import { useFormik } from 'formik';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import debounce from 'lodash.debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faAngleDown,
  faAngleUp
} from '@fortawesome/free-solid-svg-icons';

import Inputs from '../../Inputs';

const PackageDetailsForm = ({
  onChange,
  py,
  imageSrc,
  list,
  btnText,
  isReverse,
  isLight,
  background,
  anchor
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      py,
      imageSrc,
      list,
      btnText,
      isReverse,
      isLight,
      background,
      anchor
    },
    onSubmit: (values) => {
      onChange(values);
    }
  });

  const onBlur = () => {
    formik.submitForm();
  };

  const debounceSave = debounce(() => {
    onBlur();
  }, 800);

  return (
    <Form>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="imageSrc">Obrazek</Label>
            <Inputs.MediaButton
              type="image"
              onChange={(url) => {
                formik.setFieldValue(`imageSrc`, url);
                debounceSave();
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="background">Kolor tła</Label>
            <Input
              type="select"
              value={formik.values.background}
              onChange={({ target }) => {
                formik.setFieldValue('background', target.value);
                onBlur();
              }}
            >
              <option value="#00965e">Zielony</option>
              <option value="#ececec">Szary</option>
            </Input>
          </FormGroup>
          <FormGroup style={{ paddingLeft: '1rem' }}>
            <Input
              id="isReverse"
              name="isReverse"
              type="checkbox"
              checked={formik.values.isReverse}
              invalid={!!formik.errors.isReverse}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue(`isReverse`, target.checked);
                debounceSave();
              }}
            />
            <Label for="isReverse">Odwrotny schemat</Label>
          </FormGroup>
          <FormGroup style={{ paddingLeft: '1rem' }}>
            <Input
              id="isLight"
              name="isLight"
              type="checkbox"
              checked={formik.values.isLight}
              invalid={!!formik.errors.isLight}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue(`isLight`, target.checked);
                debounceSave();
              }}
            />
            <Label for="isLight">Jasny schemat</Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <FormGroup>
            <Label for={'list.title'}>Tytuł</Label>
            <Input
              id="list.title"
              name="list.title"
              type="textarea"
              value={formik.values.list.title}
              invalid={!!formik.errors.list}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue(`list.title`, target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for={'list.description'}>Opis</Label>
            <Input
              id="list.description"
              name="list.description"
              type="textarea"
              value={formik.values.list.description}
              invalid={!!formik.errors.list}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue(`list.description`, target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
        <Col xs="12">
          <FormGroup>
            <Label for={'list.items'}>Opis</Label>
            <Input
              id="list.items"
              name="list.items"
              type="textarea"
              style={{ minHeight: '10rem' }}
              value={formik.values.list.items.join('; ')}
              invalid={!!formik.errors.items}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue(`list.items`, target.value.split('; '));
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Inputs.SpacesInput
            id="py"
            name="py"
            value={formik.values.py}
            invalid={!!formik.errors.py}
            onBlur={onBlur}
            onChange={({ target }) => {
              formik.setFieldValue('py', target.value);
            }}
          />
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="anchor">Zakotwiczenie</Label>
            <Input
              id="anchor"
              name="anchor"
              type="text"
              value={formik.values.anchor}
              invalid={!!formik.errors.anchor}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('anchor', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default PackageDetailsForm;
