import { cloneDeep } from 'lodash'
import React from 'react'
import { Button, ButtonGroup, FormFeedback, FormGroup, Input, ListGroup, ListGroupItem, ListGroupItemHeading } from 'reactstrap'
import { SelectPhotoForArticle } from '../../common/Articles/select-photo'
import { InputField } from '../../common/Inputs'

export const PhotoGallery = ({ value, errors, handleChange, setFieldValue }) => {
  const addNewPhoto = (index) => {
    const gallery = cloneDeep(value)
    gallery.splice(index, 0, {
      file: '',
      description: ''
    })

    setFieldValue('gallery', gallery)
  }

  console.log('xxx', errors)

  const deletePhoto = (index) => {
    const gallery = cloneDeep(value)
    gallery.splice(index, 1)
    setFieldValue('gallery', gallery)

  }

  const movePhoto = (index, direction) => {
    const gallery = cloneDeep(value)
    if (direction === 'up') {
      const newIndex = index - 1
      const deletedElements = gallery.slice(newIndex, index + 1)
      gallery[newIndex] = deletedElements[1]
      gallery[index] = deletedElements[0]
      setFieldValue('gallery', gallery)
    }

    if (direction === 'down') {
      const newIndex = index + 1
      const deletedElements = gallery.slice(index, newIndex + 1)
      gallery[newIndex] = deletedElements[0]
      gallery[index] = deletedElements[1]
      setFieldValue('gallery', gallery)
    }

  }

  return (
    <FormGroup>
      <ListGroup>
        {value && value.map((photo, index) => {
          return <ListGroupItem key={index}>
            <ListGroupItemHeading>
              Zdjęcie #{index + 1}
            </ListGroupItemHeading>
            <div className="photogallery-form">
              <SelectPhotoForArticle
                label="Zdjęcie"
                value={photo.file}
                setFieldValue={(value) => setFieldValue(`gallery[${index}].file`, value)}
                errors={errors}
              />
              <FormGroup>
                <InputField
                  label="Opis zdjęcia"
                  type="textarea"
                  name={`gallery[${index}].description`}
                  id="description"
                  value={photo.description}
                  onChange={handleChange}
                  invalid={!!errors.description}
                />
                <FormFeedback>{errors && errors[index] && errors[index].description}</FormFeedback>
              </FormGroup>
            </div>
            <div className="photogallery-buttons">
              <ButtonGroup>
                <Button onClick={() => addNewPhoto(index)} outline>
                  <span className="bi bi-plus-lg"></span>
                </Button>
                <Button onClick={() => deletePhoto(index)} outline>
                  <span className="bi bi-trash3"></span>
                </Button>
                <Button onClick={() => movePhoto(index, 'up')} outline disabled={index === 0}>
                  <span className="bi bi-chevron-up"></span>
                </Button>
                <Button onClick={() => movePhoto(index, 'down')} outline  disabled={index === value.length - 1}>
                  <span className="bi bi-chevron-down"></span>
                </Button>
              </ButtonGroup>
            </div>
          </ListGroupItem>
        })}
      </ListGroup>
      <Input
        type="hidden"
        name="gallery"
        required
        value={value}
        invalid={!!errors.gallery}
      />
      <FormFeedback>{errors.gallery}</FormFeedback>
    </FormGroup>
  )
}
