import API from "../../../api";

export const fetchArticles = token =>
  API.get("/api/v1/articles/?limit=50", {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const createArticle = (token, data, user) => {
  return API.post(
    "/api/v1/articles/",
    { ...data, creator_id: user },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

export const editArticle = (token, data, user, slug) => {
  return API.patch(
    `/api/v2/articles/${slug}/`,
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

export const removeArticle = (token, slug) =>
  API.delete(`/api/v2/articles/${slug}/`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const getArticle = (token, article_id) => {
  return API.get(`/api/v2/articles/${article_id}/`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const getArticleTranslation = (token, groupID) => {
  return API.get(`/api/v2/articles/?grouping_id=${groupID}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const setArticleStatus = (token, slug, status) =>
  API.patch(
    `/api/v2/articles/${slug}/`,
    {
      publish_status: status
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
