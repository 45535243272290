import * as actions from './actionTypes';
import { SUCCESS } from '../../utils/actionSuffix';

const InitialState = {
  data: [],
  selected: -1,
  articleCategoriesNew: {
    data: []
  },
  promotedArticleCategoriesNew: {
    data: []
  }
};

export function articleCategories(state = InitialState, action) {
  switch (action.type) {
    case actions.SET_ARTICLE_CATEGORIES:
      return {
        ...state,
        data: action.data
      };

    case actions.FETCH_ARTICLE_CATEGORIES + SUCCESS:
      return {
        ...state,
        data: [...state.data, action.data]
      };

    case actions.SET_ARTICLE_CATEGORIES_NEW:
      return {
        ...state,
        articleCategoriesNew: {
          ...state.articleCategoriesNew,
          data: action.data
        }
      };
    case actions.SET_PROMOTED_ARTICLE_CATEGORIES_NEW:
      return {
        ...state,
        promotedArticleCategoriesNew: {
          ...state.promotedArticleCategoriesNew,
          data: action.data
        }
      };

    case actions.SET_SELECTED:
      return {
        ...state,
        selected: action.id
      };

    case actions.EDIT_CATEGORY + SUCCESS:
      // change to selector
      let index = state.data.findIndex((el) => el.id === state.selected);
      state.data[index] = action.data;
      return {
        ...state,
        selected: -1
      };

    default:
      return state;
  }
}
