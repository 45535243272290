import React from 'react';
import ReactDOM from 'react-dom';
import MyApp from './App';
import { Provider } from 'react-redux';
import configureStore from './services/redux/store';
import { addLocaleData, IntlProvider } from 'react-intl';
import plLocaleData from 'react-intl/locale-data/pl';

import { locale, getCurrentLang } from './utilities/locale';
import * as Sentry from '@sentry/browser';

import 'react-table/react-table.css';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/style.scss';
//   `'FR', 'DE', 'IT', 'RU', 'GB', 'UA', 'TR', 'PL',`;

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://611bbf2c988249018c09e0cbf9539355@sentry.io/1399578'
  });
}

const currentLang = getCurrentLang(window.location.pathname);

addLocaleData(plLocaleData);

const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;
renderMethod(
  <Provider store={configureStore()}>
    <IntlProvider locale={currentLang} messages={locale[currentLang]}>
      <MyApp />
    </IntlProvider>
  </Provider>,
  document.getElementById('root')
);
