import Home from '../views/Home';
import Login from '../views/Login';
import Articles from '../views/Articles';
import EditArticles from '../views/Articles/edit';
import NewArticle from '../views/Articles/new';
import Agroraports from '../views/Agroraports';
import EditAgroraports from '../views/Agroraports/edit';
import NewAgroraports from '../views/Agroraports/new';
import ArticleTypes from '../views/ArticleTypes';
import ArticleCategories from '../views/ArticleCategoriess';
import ArticleTags from '../views/ArticleTags';
import Events from '../views/Events';
import EditEvents from '../views/Events/edit';
import NewEvent from '../views/Events/new';
import EventsUsers from '../views/Events/users';
import Media from '../views/Media';
import Users from '../views/Users';
import NewUser from '../views/Users/new';
import EditUser from '../views/Users/edit';
import UserHistory from '../views/Users/history';
import Polls from '../views/Polls';
import NewPoll from '../views/Polls/new';
import EditPoll from '../views/Polls/edit';
import Conversations from '../views/Experts/Conversations';
import ShowOffSection from '../views/ShowOffSection/ShowOffSection';
import Components from '../views/Components/Components';
import SingleConversations from '../views/Experts/SingleConversations';
import RealEstateList from '../views/RealEstate/RealEstateList';
import NewRealEstate from '../views/RealEstate/NewRealEstate';
import RealEstate from '../views/RealEstate/RealEstate';
import VideosPage from '../views/Videos/Videos.page';
import QuizzesPage from '../views/Quizzes/Quizzes.page';
import QuizPage from '../views/Quizzes/Quiz.page';
import { Multimedia } from '../views/Multimedia';
import { MultimediaNew } from '../views/Multimedia/new';
import { MultimediaEdit } from '../views/Multimedia/edit';

// PRF
import ProductsPage from '../features/prf/views/Products.page';
import ProductPage from '../features/prf/views/Product.page';

const userPermission = (userType) => userType === 1;

export const routes = [
  {
    path: '/',
    component: Home,
    isPrivate: false
  },
  {
    path: '/login',
    component: Login,
    isPrivate: false
  },
  {
    path: '/articles',
    component: Articles,
    isPrivate: true,
    permission: 'can_create_articles'
  },
  {
    path: '/articles/new',
    component: NewArticle,
    isPrivate: true,
    permission: 'can_create_articles'
  },
  {
    path: '/articles/:id',
    component: EditArticles,
    isPrivate: true,
    permission: 'can_create_articles'
  },
  {
    path: '/articles-categories',
    component: ArticleCategories,
    isPrivate: true,
    permission: 'can_create_articles'
  },
  {
    path: '/articles-tags',
    component: ArticleTags,
    isPrivate: true,
    permission: 'can_create_articles'
  },
  {
    path: '/events',
    component: Events,
    isPrivate: true,
    permission: 'can_create_events'
  },
  {
    path: '/events/new',
    component: NewEvent,
    isPrivate: true,
    permission: 'can_create_events'
  },
  {
    path: '/events/:id',
    component: EditEvents,
    isPrivate: true,
    permission: 'can_create_events'
  },
  {
    path: '/events/:id/users',
    component: EventsUsers,
    isPrivate: true,
    permission: 'can_create_events'
  },
  {
    path: '/media',
    component: Media,
    isPrivate: true
  },
  {
    path: '/multimedia',
    component: Multimedia,
    isPrivate: true
  },
  {
    path: '/multimedia/new',
    component: MultimediaNew,
    isPrivate: true
  },
  {
    path: '/multimedia/:id',
    component: MultimediaEdit,
    isPrivate: true
  },
  {
    path: '/users',
    component: Users,
    isPrivate: true,
    permission: userPermission
  },
  {
    path: '/users/new',
    component: NewUser,
    isPrivate: true,
    permission: userPermission
  },
  {
    path: '/users/:id',
    component: EditUser,
    isPrivate: true,
    permission: userPermission
  },
  {
    path: '/users/history/:id',
    component: UserHistory,
    isPrivate: true,
    permission: userPermission
  },
  {
    path: '/experts/conversations',
    component: Conversations,
    isPrivate: true,
    permission: userPermission
  },
  {
    path: '/experts/conversations/:id',
    component: SingleConversations,
    isPrivate: true,
    permission: userPermission
  },
  {
    path: '/polls',
    component: Polls,
    isPrivate: true,
    permission: 'can_create_articles'
  },
  {
    path: '/polls/new',
    component: NewPoll,
    isPrivate: true,
    permission: 'can_create_articles'
  },
  {
    path: '/polls/:id',
    component: EditPoll,
    isPrivate: true,
    permission: 'can_create_articles'
  },
  {
    path: '/agroraport/',
    component: Agroraports,
    isPrivate: true,
    permission: 'can_create_articles',
    extraProps: { report: true }
  },
  {
    path: '/agroraport/new',
    component: NewAgroraports,
    isPrivate: true,
    permission: 'can_create_articles',
    extraProps: { report: true }
  },
  {
    path: '/agroraport/:id',
    component: EditAgroraports,
    isPrivate: true,
    permission: 'can_create_articles',
    extraProps: { report: true }
  },
  {
    path: '/agroraport-categories',
    component: ArticleTypes,
    isPrivate: true,
    permission: 'can_create_articles',
    extraProps: { report: true }
  },
  {
    path: '/show-off-section',
    component: ShowOffSection,
    isPrivate: true,
    permission: 'can_create_articles'
  },
  {
    path: '/components',
    component: Components,
    isPrivate: true,
    permission: 'can_create_articles'
  },
  {
    path: '/real-estate',
    component: RealEstateList,
    isPrivate: true,
    permission: 'can_create_articles'
  },
  {
    path: '/real-estate/new',
    component: NewRealEstate,
    isPrivate: true,
    permission: 'can_create_articles'
  },
  {
    path: '/real-estate/:slug',
    component: RealEstate,
    isPrivate: true,
    permission: 'can_create_articles'
  },
  {
    path: '/videos',
    component: VideosPage,
    isPrivate: true,
    permission: 'can_create_articles'
  },
  {
    path: '/quizzes',
    component: QuizzesPage,
    isPrivate: true,
    permission: 'can_create_articles'
  },
  {
    path: '/quizzes/:slug',
    component: QuizPage,
    isPrivate: true,
    permission: 'can_create_articles'
  },
  {
    path: '/products',
    component: ProductsPage,
    isPrivate: true
  },
  {
    path: '/products/:slug',
    component: ProductPage,
    isPrivate: true
  }
];
