import React from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useSelector } from 'react-redux';

import Layout from '../../components/Layout';
import { useRealEstate, useAllertMessage } from './useRealEstate';
import RealEstateForm from './RealEstateForm';

import { InitialProperty } from './InitialState';

function RealEstate() {
  const token = useSelector((state) => state.user.token.key);
  const { isReady, message, messageType, showMessage } = useAllertMessage();
  const history = useHistory();
  const { addProperty } = useRealEstate(token);
  return (
    <Layout>
      <h1 className="h2 pb-md-4 page-header">Nowa wierzytelność</h1>
      {isReady && <Alert color={messageType}>{message}</Alert>}
      <RealEstateForm
        initialValues={InitialProperty}
        media={[]}
        onSubmit={(values) =>
          addProperty(values)
            .then(({ data }) => {
              showMessage('Zapisano', 'success');
              setTimeout(() => {
                history.push(`/real-estate/${data.slug}`);
              }, 3000);
            })
            .catch(() => {
              showMessage('Nie udało sie zapisać', 'danger');
            })
        }
      />
      {isReady && <Alert color={messageType}>{message}</Alert>}
    </Layout>
  );
}

export default RealEstate;
