import React, { useState } from 'react';
import { insertLink } from '../../utils/link.js'

import Icon from '../../common/Icon'
import { isBlockActive } from '../../utils/SlateUtilityFunctions.js'
import Button from '../../common/Button.jsx';
import { Button as BButton, Dropdown, DropdownMenu, DropdownToggle, FormGroup, Input, Label } from 'reactstrap';
import { Editor } from 'slate';

const INITIAL_MODEL = {
  title: '',
  url: '',
  target: ''
}

const LinkButton = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [formData, setFormData] = useState({ ...INITIAL_MODEL })

  const { editor } = props

  const handleInsertLink = () => {
    insertLink(editor, { ...formData })
    toggle()
    setFormData({ ...INITIAL_MODEL })
  }

  const handleOpenLink = () => {
    if (!dropdownOpen) {
      const selectedText = editor.selection ? Editor.string(editor, editor.selection) : false;
      if (selectedText) {
        setFormData(prev => ({
          ...prev,
          title: selectedText
        }))
      }
    }
    toggle()
  }

  const setValue = e => {
    e.persist()
    const { target } = e
    setFormData(prev => ({ ...prev, [target.name]: target.value }))
  }

  const setCheckboxValue = e => {
    e.persist()
    const { target } = e
    setFormData(prev => ({ ...prev, [target.name]: target.checked ? target.value : '' }))
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={handleOpenLink}>
      <DropdownToggle
        data-toggle="dropdown"
        tag="div"
      >
        <Button
          active={isBlockActive(editor, 'link')}
          format={'link'}
        >
          <Icon icon="link" />
        </Button>
      </DropdownToggle>
      <DropdownMenu className="p-2 link-dropdown">
        <div onSubmit={handleInsertLink}>
          <Input
            className="mb-2"
            type="text"
            name="title"
            placeholder='Enter title'
            value={formData.title}
            size="sm"
            onChange={setValue}
          />
          <Input
            className="mb-2"
            type="text"
            placeholder='Enter target'
            value={formData.url}
            size="sm"
            name="url"
            onChange={setValue}
          />
          <FormGroup
            check
          >
            <Input
              id="link-target"
              name="target"
              type="checkbox"
              value="_blank"
              onChange={setCheckboxValue}
            />
            <Label check htmlFor="link-target" size="sm">
              Otwórz link w nowym oknie
            </Label>
          </FormGroup>
          <BButton
            type="button"
            onClick={handleInsertLink}
            size="sm"
          >Save</BButton>
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}


export default LinkButton;
