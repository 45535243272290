import * as api from './api';
import {
  takeLatest,
  call,
  put,
  select,
  takeEvery
} from '@redux-saga/core/effects';
import * as actionTypes from './actionTypes';
import * as actionCreators from './actionCreators';
import * as selectors from '../selectors';
import { SUCCESS, FAILURE } from '../../utils/actionSuffix';
import {
  mapFailure,
  alertFailure,
  alertSuccess
} from '../../utils/mapResponse';

export function* createPoll({ data }) {
  const token = yield select(selectors.selectToken);
  try {
    const {
      data: { id }
    } = yield call(api.createPoll, token, data);
    yield put(alertSuccess('Pomyślnie dodano sondę.'));
    yield put({ type: actionTypes.SET_POLL_ID, id });
  } catch (e) {
    yield put(mapFailure(e, actionTypes.CREATE_POLL + FAILURE));
    yield put(alertFailure('Wystąpił błąd podczas tworzenia sondy.'));
  }
}

export function* updatePoll({ data }) {
  const token = yield select(selectors.selectToken);
  try {
    const response = yield call(api.updatePoll, token, data.id, data);
    yield put(alertSuccess('Pomyślnie edytowano sondę.'));
    yield put({
      type: actionTypes.UPDATE_POLL + SUCCESS,
      data: response.data
    });
  } catch (e) {
    yield put(mapFailure(e, actionTypes.CREATE_POLL + FAILURE));
    yield put(alertFailure('Wystąpił błąd podczas edycji sondy.'));
  }
}

export function* fetchPoll({ id }) {
  const token = yield select(selectors.selectToken);
  try {
    const { data } = yield call(api.fetchPoll, token, id);
    yield put({ type: actionTypes.FETCH_POLL + SUCCESS, data });
  } catch (e) {
    yield put(mapFailure(e, actionTypes.CREATE_POLL + FAILURE));
    yield put(alertFailure('Wystąpił błąd podczas pobierania sondy.'));
  }
}

export function* fetchPollTranslations({ grouping_id }) {
  const token = yield select(selectors.selectToken);
  try {
    const { data } = yield call(api.fetchPolls, token, grouping_id);
    yield put({ type: actionTypes.FETCH_POLL_TRANSLATIONS + SUCCESS, data });
  } catch (e) {
    yield put(
      alertFailure('Wystąpił błąd podczas pobierania tłumaczeń sondy.')
    );
  }
}

export function* createPossibility({ index, data }) {
  const token = yield select(selectors.selectToken);
  try {
    const response = yield call(api.createPossibility, token, data);
    yield put({
      type: actionTypes.CREATE_POSSIBILITY + SUCCESS,
      data: response.data,
      index
    });
  } catch (e) {
    yield put(mapFailure(e, actionTypes.CREATE_POSSIBILITY + FAILURE));
    yield put(alertFailure('Wystąpił błąd podczas tworzenia odpowiedzi.'));
  }
}

export function* deletePoll({ id }) {
  const token = yield select(selectors.selectToken);
  try {
    const response = yield call(api.deletePoll, token, id);
    yield put(alertSuccess('Pomyślnie usunięto sondę.'));
  } catch (e) {
    yield put(alertFailure('Wystąpił błąd podczas usuwania sondy.'));
  }
}

export function* deletePossibility({ id }) {
  const token = yield select(selectors.selectToken);
  try {
    const response = yield call(api.deletePossibility, token, id);
    yield put(alertSuccess('Pomyślnie usunięto odpowiedź.'));
  } catch (e) {
    yield put(alertFailure('Wystąpił błąd podczas usuwania odpowiedzi.'));
  }
}

export function* updatePossibility({ id, data }) {
  const token = yield select(selectors.selectToken);
  try {
    const response = yield call(api.updatePossibility, token, id, data);
    yield put(alertSuccess('Pomyślnie edytowano odpowiedź.'));
  } catch (e) {
    yield put(alertFailure('Wystąpił błąd podczas edycji odpowiedzi.'));
  }
}

export default [
  takeLatest(actionTypes.SET_POLL_ID, fetchPoll),
  takeEvery(actionTypes.CREATE_POLL, createPoll),
  takeLatest(actionTypes.UPDATE_POLL, updatePoll),
  takeEvery(actionTypes.DELETE_POLL, deletePoll),
  takeLatest(actionTypes.FETCH_POLL_TRANSLATIONS, fetchPollTranslations),
  takeEvery(actionTypes.CREATE_POSSIBILITY, createPossibility),
  takeEvery(actionTypes.UPDATE_POSSIBILITY, updatePossibility),
  takeEvery(actionTypes.DELETE_POSSIBILITY, deletePossibility)
];
