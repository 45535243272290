import React from 'react';
import { sprintf } from 'sprintf-js';
import { useTimeoutFn } from 'react-use';

import API from '../../services/api';
import propertiesRoutes from '../../services/redux/reducers/properties/properties.api';
import { InitialProperty } from './InitialState';

export function getSearchLabel(addresses = []) {
  return addresses.filter((address) => !!address).join(', ');
}

export function useRealEstate(token, withInitialData = true) {
  const [realEstateList, setRealEstateList] = React.useState({
    count: -1,
    next: null,
    previous: null,
    results: []
  });
  const [isFetching, setIsFetching] = React.useState(true);

  const fetchData = async (params) => {
    setIsFetching(true);
    try {
      const { data } = await API.get(propertiesRoutes.PROPERTIES, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          limit: 10,
          ...params
        }
      });

      setRealEstateList(data);
      setIsFetching(false);
    } catch (e) {
      console.log('COULD NOT FETCH REAL ESTATE CONTENT');
    }
  };

  const importFile = async (data) =>
    API.post(propertiesRoutes.IMPORT_FILE, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(() => {
      fetchData();
    });

  const removeProperty = async (slug) =>
    API.delete(sprintf(propertiesRoutes.PROPERTY, slug), {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(() => {
      fetchData();
    });

  const addProperty = async (property) =>
    API.post(propertiesRoutes.PROPERTIES, property, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).finally(() => {
      fetchData();
    });

  const updateProperty = async (property) =>
    API.patch(sprintf(propertiesRoutes.PROPERTY, property.slug), property, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(({ data }) => {
      refreshRealEstateList(data);
    });

  const refreshRealEstateList = (updatedProperty) => {
    const refreshedProperties = realEstateList.results.map((property) => {
      if (property.slug === updatedProperty.slug) {
        return updatedProperty;
      }
      return property;
    });
    setRealEstateList({
      ...realEstateList,
      results: refreshedProperties
    });
  };

  React.useEffect(() => {
    if (withInitialData) {
      fetchData();
    }
  }, [token]);

  return {
    realEstateList,
    isFetching,
    fetchData,
    removeProperty,
    addProperty,
    updateProperty,
    importFile
  };
}

export function useProperty({ token, slug }) {
  const [property, setProperty] = React.useState(InitialProperty);
  const [media, setMedia] = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(true);

  const fetchProperty = async () => {
    setIsFetching(true);
    try {
      const { data } = await API.get(sprintf(propertiesRoutes.PROPERTY, slug), {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const media = await API.get(sprintf(propertiesRoutes.MEDIAS, slug), {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setMedia(media.data);
      setProperty(data);
      setIsFetching(false);
    } catch (e) {
      console.log('COULD NOT FETCH REAL ESTATE CONTENT');
    }
  };

  const removeMedia = async (id) =>
    API.delete(sprintf(propertiesRoutes.MEDIA, slug, id), {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(() => {
      fetchProperty();
    });

  const addMedia = async (medias) =>
    API.post(sprintf(propertiesRoutes.MEDIAS, slug), medias, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    }).then(() => {
      fetchProperty();
    });

  const updateProperty = async (property) =>
    API.patch(sprintf(propertiesRoutes.PROPERTY, slug), property, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(() => {
      fetchProperty();
    });

  React.useEffect(() => {
    fetchProperty();
  }, [token]);

  return {
    property,
    media,
    isFetching,
    fetchProperty,
    removeMedia,
    updateProperty,
    addMedia
  };
}

export function useAllertMessage() {
  const [isReady, setIsReady] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [messageType, setMessageType] = React.useState('success');

  const [, , reset] = useTimeoutFn(() => {
    setIsReady(false);
  }, 3000);

  const showMessage = (text, type) => {
    setMessage(text);
    setMessageType(type);
    setIsReady(true);
    reset();
  };

  return {
    isReady,
    message,
    messageType,
    showMessage
  };
}

export function usePropertyTypes(token) {
  const [propertyTypes, setPropertyTypes] = React.useState([]);

  const fetchData = async () => {
    try {
      const { data } = await API.get(propertiesRoutes.PROPERTY_TYPES, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          limit: 999
        }
      });

      setPropertyTypes(data.results);
    } catch (e) {
      console.log('COULD NOT FETCH PROPERTY TYPES');
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [token]);

  return {
    propertyTypes
  };
}

export function useProvinces(token) {
  const [provincesList, setProvincesList] = React.useState([]);

  const fetchData = async () => {
    try {
      const { data } = await API.get(propertiesRoutes.PROVINCES, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          limit: 999
        }
      });

      setProvincesList(data.results);
    } catch (e) {
      console.log('COULD NOT FETCH PROPERTY TYPES');
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [token]);

  return {
    provincesList
  };
}

export function useTransactionTypes(token) {
  const [transactionTypes, setTransactionTypes] = React.useState([]);

  const fetchData = async () => {
    try {
      const { data } = await API.get(propertiesRoutes.TRANSACTION_TYPES, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          limit: 999
        }
      });

      setTransactionTypes(data.results);
    } catch (e) {
      console.log('COULD NOT FETCH PROPERTY TYPES');
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [token]);

  return {
    transactionTypes
  };
}

export function useKeepers(token) {
  const [keepers, setKeepers] = React.useState([]);

  const fetchData = async () => {
    try {
      const { data } = await API.get(propertiesRoutes.KEEPER, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setKeepers(data.results);
    } catch (e) {
      console.log('COULD NOT FETCH PROPERTY TYPES');
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [token]);

  return {
    keepers
  };
}

export function usePropertyLocations(token) {
  const countiesOptions = async (inputValue) => {
    try {
      const { data } = await API.get(propertiesRoutes.COUNTIES, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          search: inputValue
        }
      });

      return data.results.map((countie) => ({
        label: getSearchLabel([countie.name, countie.province_name]),
        value: countie.id
      }));
    } catch (e) {
      console.log('COULD NOT FETCH PROPERTY TYPES');
      return [];
    }
  };

  const boroughsOptions = async (inputValue) => {
    try {
      const { data } = await API.get(propertiesRoutes.BOROUGHS, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          search: inputValue
        }
      });

      return data.results.map((borough) => ({
        label: borough.name,
        value: borough.id
      }));
    } catch (e) {
      console.log('COULD NOT FETCH PROPERTY TYPES');
      return [];
    }
  };

  const provincesOptions = async (inputValue) => {
    try {
      const { data } = await API.get(propertiesRoutes.PROVINCES, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          search: inputValue
        }
      });

      return data.results.map((province) => ({
        label: province.name,
        value: province.id
      }));
    } catch (e) {
      console.log('COULD NOT FETCH PROPERTY TYPES');
      return [];
    }
  };

  const townsOptions = async (inputValue) => {
    try {
      const { data } = await API.get(propertiesRoutes.TOWNS, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          search: inputValue
        }
      });

      return data.results.map((town) => ({
        label: getSearchLabel([
          town.name,
          town.county_name,
          town.province_name
        ]),
        value: town.id
      }));
    } catch (e) {
      console.log('COULD NOT FETCH PROPERTY TYPES');
      return [];
    }
  };

  const keeperOptions = async (inputValue) => {
    try {
      const { data } = await API.get(propertiesRoutes.KEEPER, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          search: inputValue
        }
      });

      return data.results.map((keeper) => ({
        label: keeper.name,
        value: keeper.id
      }));
    } catch (e) {
      console.log('COULD NOT FETCH PROPERTY TYPES');
      return [];
    }
  };

  return {
    countiesOptions,
    boroughsOptions,
    provincesOptions,
    townsOptions,
    keeperOptions
  };
}
