import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ExportUsers from './ExportUsers';
import { generateCSV, exportEventUsers } from '../../utilities/export';

class ExportButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal
    }));
  }

  handleClick = () => {
    const { type, eventid } = this.props;
    switch (type) {
      case 'exportEventUsers':
        exportEventUsers(eventid)
          .then((response) => {
            this.generateFile(response);
          })
          .catch((error) => {
            this.setState({
              error: true
            });
          });
        break;

      case 'exportUsers':
      default:
        this.toggle();
        break;
    }
  };

  generateFile(data) {
    generateCSV(data);
  }

  render() {
    const { type, ...rest } = this.props;
    return (
      <div>
        <Button
          type="button"
          outline
          color="secondary"
          size="sm"
          className="toolbar-thumb"
          onClick={this.handleClick}
        >
          Exportuj do csv
        </Button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Doprecyzuj wartości</ModalHeader>
          <ModalBody>
            {type === 'exportUsers' && (
              <ExportUsers generateFile={this.generateFile} {...rest} />
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default ExportButton;
