import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../services/redux/reducers/media/actionTypes';

import API from '../../services/api';

export const BANNERS_TYPES = [
  {
    slug: 'serwis-food-and-agro',
    name: 'Serwis food&agro'
  },
  {
    slug: 'agro-na-obcasach',
    name: 'Agro na obcasach'
  },
  {
    slug: 'po-godzinach',
    name: 'Po godzinach'
  }
];

export default function ({ mediaId }) {
  const banners = useSelector((state) => state.media.banners);
  const token = useSelector((state) => state.user.token.key);
  const banner = banners.find(({ banner }) =>
    !!banner ? banner.id === mediaId : false
  );
  const dispatch = useDispatch();

  const getBanners = () => dispatch(actions.FETCH_BANNERS);
  const createBanner = ({ slug }) =>
    API.post(
      '/api/v1/banners/',
      {
        page_slug: slug,
        banner_id: mediaId
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    ).then(() => getBanners());
  const updateBanner = ({ id }) =>
    API.patch(
      `/api/v1/banners/${id}/`,
      {
        banner_id: mediaId
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    ).then(() => getBanners());
  const deleteBanner = ({ id }) =>
    API.delete(`/api/v1/banners/${id}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(() => getBanners());
  const setBanner = ({ slug }) => {
    const existingBanner = banners.find(({ page_slug }) => page_slug === slug);

    if (existingBanner) {
      return updateBanner({ id: existingBanner.id });
    }

    return createBanner({ slug });
  };

  return {
    banner,
    banners,
    bannersTypes: BANNERS_TYPES,
    getBanners,
    createBanner,
    updateBanner,
    deleteBanner,
    setBanner
  };
}
