import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { getCurrentLang } from '../utilities/locale';
import { routes } from '../utilities/routes';
import PrivateRoute from '../common/NavItem/PrivateRoute';

import { selectUserEmail } from '../services/redux/reducers/selectors';

// IF user is not logged in redirect to login page
let currentLang = getCurrentLang(window.location.pathname);
currentLang = currentLang !== 'pl' ? `/${currentLang}` : '';

const Main = () => {
  const emails = ['dsn2@bnpparibas.pl', 'joka_32@interia.eu'];
  const userEmail = useSelector(selectUserEmail);
  return (
    <Switch>
      {routes
        .filter(({ path }) => {
          if (emails.indexOf(userEmail) !== -1) {
            if (path.includes('/real-estate') || path.includes('/media')) {
              return true;
            }
            return false;
          }
          return true;
        })
        .map(({ isPrivate, extraProps, ...props }, index) =>
          isPrivate ? (
            <PrivateRoute key={index} exact {...props} {...extraProps} />
          ) : (
            <Route key={index} exact {...props} />
          )
        )}
      <Route path="*">
        <Redirect to="/real-estate" />
      </Route>
    </Switch>
  );
};

export default Main;
