import React from 'react';
import { SelectItems } from '../../common/Inputs';
import PollsList from './PollsList';

export const SelectPoll = ({ selected, selectItem }) => {
  return (
    <SelectItems titleButton={'sondę'} count={selected.length} maxItems={1}>
      <PollsList
        select
        selected={selected}
        selectItem={selectItem}
        maxItems={1}
      />
    </SelectItems>
  );
};
