export const publishStatus = {
  1: 'Szkic',
  2: 'Oczekujący',
  3: 'Opublikowany'
};

export const COLOR = {
  brandOrange: '#ffa203',
  brandGreen: '#00965e',
  brandGreen20: '#cceadf',
  darkGreen: '#4d5765',
  black: '#000',
  white: '#fff',
  grey: '#2e3033',
  lightGrey: '#ececec',
  mediumGrey: '#979797'
};

export const USER_TYPE_PERMISSION = [1, 5];
