import API from '../../../api';
import { BANNERS_TYPES } from "../../../../components/Banners/useBanners";

export const fetchMedia = (token, page = 0, filter) => {
  let query = `?limit=10&offset=${10 * page}&`;
  if (filter) {
    query = query + `type=${filter}`;
  }
  return API.get(`/api/v1/media-files/${query}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const createMedia = (token, data) => {
  let fileData = new FormData();
  fileData.append('file', data.file);
  fileData.append('name', data.name);
  fileData.append('description', data.description);
  fileData.append('style', data.style)
  return API.post('/api/v1/media-files/', fileData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const editMedia = (token, id, data) => {
  return API.patch(`/api/v1/media-files/${id}/`, data, {
    headers: {
      Authorization: `Bearer ${token}`
      // 'Content-Type': 'multipart/form-data'
    }
  });
};

export const deleteMedia = (token, id) => {
  return API.delete(`/api/v1/media-files/${id}/`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const fetchBanners = (token) => {
  return API.get(`/api/v1/banners/`, {
    params: {
      limit: BANNERS_TYPES.length
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
