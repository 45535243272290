import { Transforms } from 'slate'
import { NODE_TYPES } from '../consts'
import { createParagraph } from './paragraph'

export const insertBlockquote = (editor, data) => {
  const quote = {
    type: NODE_TYPES.BLOCKQUOTE,
    data,
    children: [{ text: '' }]
  }

  Transforms.insertNodes(editor, quote, { select: true })
  Transforms.insertNodes(editor, createParagraph(""), { mode: 'highest' })
}
