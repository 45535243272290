import * as actionTypes from './actionTypes';

export const fetchEvents = () => ({
  type: actionTypes.FETCH_EVENTS
});

export const setEvents = data => ({
  type: actionTypes.SET_EVENTS,
  data
});

export const createEvent = (eventFormData, id) => ({
  type: actionTypes.CREATE_EVENT,
  eventFormData,
  id
});

export const fetchEvent = id => ({
  type: actionTypes.FETCH_EVENT,
  id
});

export const removeEventfile = id => ({
  type: actionTypes.EVENT_REMOVE_FILE,
  id
});

export const removeEvent = id => ({
  type: actionTypes.REMOVE_EVENT,
  id
});

export const setEventId = id => ({
  type: actionTypes.SET_EVENT_ID,
  id
});

export const resetReq = () => ({ type: actionTypes.RESET_REQ });

export const setEventStatus = (id, status) => ({
  type: actionTypes.SET_EVENT_STATUS,
  id,
  status
});

export const updateUserDesc = (eventid, id, data, add) => ({
  type: actionTypes.UPDATE_USER_DESC,
  eventid,
  id,
  data,
  add
});

export const removeEventUser = lectureid => ({
  type: actionTypes.REMOVE_EVENT_USER,
  lectureid
});

export const eventTranslation = groupID => ({
  type: actionTypes.FETCH_EVENT_TRANSLATION,
  groupID
});
