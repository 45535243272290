import React, { createRef, Component } from 'react';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import {
  Input,
  InputGroup,
  Label,
  Button,
  ButtonGroup,
  ListGroup,
  ListGroupItem,
  FormGroup,
  FormFeedback
} from 'reactstrap';
import axios from 'axios';

const regLong = val =>
  /^-?([1]?[1-7][1-9]|[1]?[1-8][0]|[1-9]?[0-9])\.{1}\d{1,6}/g.test(val);
const regLat = val => /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/g.test(val);

export const pointerIcon = new L.Icon({
  iconUrl: require('../../assets/mappin.svg'),
  iconRetinaUrl: require('../../assets/mappin.svg'),
  iconAnchor: [8.5, 8.5],
  popupAnchor: [0, 0],
  iconSize: [34, 34]
});

class MapPicker extends Component {
  constructor(props) {
    super(props);
    let { latlng } = this.props;
    let marker = {};
    let values = {
      lat: '',
      lng: ''
    };
    if (!latlng) {
      latlng = {
        lat: 51.919437,
        lng: 19.145136
      };
    } else {
      marker.latlng = {
        lat: latlng.lat,
        lng: latlng.lng
      };
      values.lat = latlng.lat;
      values.lng = latlng.lng;
    }
    this.state = {
      hasLocation: false,
      latlng,
      zoom: 6,
      marker: marker,
      search: '',
      searchResults: [],
      values
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeBound = this.handleChangeBound.bind(this);
    this.setNewMarker = this.setNewMarker.bind(this);
  }

  handleChange(event) {
    this.setState({ search: event.target.value });
  }

  handleChangeBound(val) {
    this.setState({
      values: {
        ...this.state.values,
        ...val
      }
    });
    this.props.setLangLong(val);
  }

  mapRef = createRef();

  handleClick = e => {
    const map = this.mapRef.current;
    if (map != null) {
      this.setState({
        hasLocation: true,
        marker: {
          latlng: e.latlng
        },
        zoom: e.zoom
      });
      // this.props.setLangLong(e.latlng);
      this.handleChangeBound(e.latlng);
    }
  };

  handleLocationFound = (e: Object) => {
    this.setState({
      latlng: e.latlng
    });
  };

  searchPlaces() {
    // https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&q=polska
    let { search } = this.state;
    search = encodeURI(search);
    axios
      .get(
        `https://nominatim.openstreetmap.org/search?format=json&accept-language=pl&addressdetails=1&q=${search}`
      )
      .then(response => {
        this.setState({
          searchResults: response.data
        });
      });
  }

  setPlace(place) {
    const { setPlaceDetails } = this.props;
    setPlaceDetails(place);
    this.setState({
      searchResults: [],
      marker: {
        latlng: {
          lat: place.lat,
          lng: place.lon
        }
      }
    });
  }

  setNewMarker() {
    let { values } = this.state;
    let checkLat = regLat(values.lat);
    let checkLng = regLong(values.lng);
    if (checkLat && checkLng) {
      this.setState({
        latlng: { ...values },
        marker: {
          latlng: { ...values }
        }
      });
    } else {
      // error
    }
  }

  render() {
    const markerPoint = this.state.marker.latlng ? (
      <Marker position={this.state.marker.latlng} icon={pointerIcon}>
        <Popup>You are here</Popup>
      </Marker>
    ) : null;
    const { searchResults, values } = this.state;
    const { disabled } = this.props;
    return (
      <div>
        <Map
          center={this.state.latlng}
          length={4}
          onClick={this.handleClick}
          onLocationfound={this.handleLocationFound}
          ref={this.mapRef}
          zoom={this.state.zoom}
          // className={disabled ? 'map--disabled' : ''}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {markerPoint}
        </Map>
        <FormGroup>
          <InputGroup>
            <Button>
              <i className="bi-search"></i>
            </Button>
            <Input
              type="search"
              placeholder="wyszukaj miejsce"
              onChange={this.handleChange}
              value={this.state.search}
            />
            <Button
              onClick={() => {
                this.searchPlaces();
              }}
              type="button"
            >
              Szukaj
            </Button>
          </InputGroup>
          {searchResults.length ? (
            <div className="">
              <p>Wyniki:</p>
              <ListGroup className="pb-md-4">
                {searchResults.map((place, index) => (
                  <ListGroupItem
                    key={place.place_id}
                    className="listItem--with-button"
                  >
                    {place.display_name}
                    <ButtonGroup size="sm">
                      <Button
                        size="sm"
                        // onClick={e => this.editCategory(e, index)}
                        onClick={() => this.setPlace(place)}
                      >
                        Wybierz
                      </Button>
                    </ButtonGroup>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          ) : null}
        </FormGroup>
        <FormGroup>
          <Label for="location_title">Szerokość geograficzna</Label>
          <Input
            type="text"
            name="latitude"
            id="latitude"
            required
            value={values.lat}
            // value={marker.latitude}
            onChange={e => this.handleChangeBound({ lat: e.target.value })}
            onBlur={() => this.setNewMarker()}
            invalid={values.lat && !regLat(values.lat)}
            placeholder="Przykładowa wartość: 23.00"
          />
          <FormFeedback>Wartość niepoprawna</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="location_title">Wysokość geograficzna</Label>
          <Input
            type="text"
            name="longitude"
            id="longitude"
            required
            value={values.lng}
            // value={marker.longitude}
            onChange={e => this.handleChangeBound({ lng: e.target.value })}
            onBlur={() => this.setNewMarker()}
            invalid={values.lng && !regLong(values.lng)}
            placeholder="Przykładowa wartość: 50.00"
          />
          <FormFeedback>Wartość niepoprawna</FormFeedback>
        </FormGroup>
      </div>
    );
  }
}

export default MapPicker;
