import React from 'react';
import { FormGroup, Label, ListGroup, ListGroupItem, Button } from 'reactstrap';
import { SelectPoll } from '../../components/Polls/SelectPoll';

export const SelectPolls = ({ value, setFieldValue }) => {
  return (
    <FormGroup>
      <Label>Sondy</Label>
      {value.length ? (
        <ListGroup>
          {value.map((item) => (
            <ListGroupItem
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              {item.title}{' '}
              <Button
                className="btn-with-icon"
                onClick={() => {
                  setFieldValue(
                    'polls_list',
                    value.filter((poll) => poll.id !== item.id)
                  );
                }}
              >
                <i className="bi-x-lg"></i>
              </Button>
            </ListGroupItem>
          ))}
        </ListGroup>
      ) : null}
      <SelectPoll
        selected={value}
        maxItems={1}
        selectItem={(item) => {
          const newValue = [...value.filter((poll) => poll.id !== item.id)];
          newValue.push(item);
          setFieldValue('polls_list', newValue);
        }}
      />
    </FormGroup>
  );
};
