import React, { Component, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MediaBody from './index';

export const SelectItems = ({
  children,
  small,
  titleButton,
  className,
  maxItems,
  count
}) => {
  const [show, setShow] = useState(false);
  const toggle = () => {
    setShow(!show);
  };
  // if (count === maxItems ) toggle();
  return (
    <div>
      {small ? (
        <Button
          type="button"
          outline
          color="secondary"
          size="sm"
          className="toolbar-thumb"
          onClick={toggle}
        >
          Wybierz {titleButton || 'media'}
        </Button>
      ) : (
        <Button color="primary" type="button" onClick={toggle}>
          Wybierz {titleButton || 'media'}
        </Button>
      )}
      <Modal isOpen={show} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Wybierz element</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          {/* <Button color="primary" onClick={toggle} />{' '} */}
          <Button color="secondary" onClick={toggle}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
