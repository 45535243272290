import { Field, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import Heading from '../../common/Typo/heading';
import API from '../../services/api';
import { setSelected } from '../../services/redux/reducers/articlesCategories/actionCreators';

const noArticleSelectedOption = {
  value: -1,
  label: 'Brak'
};

const FormikSelect = ({ field, form: { setFieldValue }, ...props }) => {
  return (
    <AsyncSelect
      {...field}
      {...props}
      cacheOptions
      label={field.value && field.value.label}
      value={field.value && field.value.value}
      onChange={(option) => setFieldValue(field.name, option.value)}
    />
  );
};

const ArticleTypesForm = ({
  fetchArticleCategories,
  fetchPromotedArticleCategories
}) => {
  const dispatch = useDispatch();
  const articleCategories = useSelector(
    (state) => state.articleCategories.articleCategoriesNew
  );
  const promotedArticleCategories = useSelector(
    (state) => state.articleCategories.promotedArticleCategoriesNew.data
  );
  const token = useSelector((state) => state.user.token.key);
  const selectedId = useSelector((state) => state.articleCategories.selected);
  const langCode = useSelector((state) => state.settings.lang);

  const selected = articleCategories.data.find((el) => el.id === selectedId);
  const promoted = promotedArticleCategories.find(
    (promoted) => promoted.lang_code === langCode
  );
  const initialValues = {
    article:
      selected && selected.promoted_article ? selected.promoted_article.id : -1,
    name: selected ? selected.type_name : ''
  };

  const promiseOptions = async (inputValue) => {
    const { data } = await API.get('/api/v2/articles/', {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        search: inputValue,
        lang_code: langCode,
        category: selectedId
      }
    });

    return data.results.map((article) => ({
      label: article.name,
      value: article.id
    }));
  };

  const handleSubmit = async (values) => {
    const { article } = values;
    try {
      if (article !== undefined) {
        await API.post(
          '/api/v2/promoted-articles/',
          {
            lang_code: langCode,
            category_id: selectedId,
            article_id: article
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      }
    } catch (e) {
      console.log('COULD NOT UPDATE PROMOTED ARTICLE');
    }

    fetchArticleCategories();
    dispatch(setSelected(-1));
  };

  const removePromoted = async (promoted) => {
    console.dir(promoted);
    try {
      if (promoted !== undefined) {
        await API.delete(`/api/v2/promoted-articles/${promoted.id}/`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      }
    } catch (e) {
      console.log('COULD NOT REMOVE PROMOTED ARTICLE');
    }

    fetchPromotedArticleCategories(selectedId);
    fetchArticleCategories();
    dispatch(setSelected(-1));
  };

  let defaultArticleValue;
  if (promoted) {
    defaultArticleValue = {
      label: promoted.article.name,
      value: promoted.article.name,
      id: promoted.id
    };
  }

  return (
    <>
      <Heading
        title={
          selectedId === -1
            ? 'Wybierz kategorię w celu edycji'
            : 'Edytuj kategorię'
        }
      />
      <Formik
        key={selectedId}
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={({ handleSubmit, values }) => (
          <Form onSubmit={handleSubmit}>
            <fieldset disabled={selectedId === -1}>
              <FormGroup>
                <Label for="name">Tytuł kategorii</Label>
                <Field
                  component={Input}
                  type="text"
                  value={values.name}
                  name="name"
                  id="name"
                  required
                  readOnly
                />
              </FormGroup>
              <FormGroup>
                <Label for="article">Tytuł eksponowanego artykułu</Label>
                {defaultArticleValue ? (
                  <ListGroup>
                    <ListGroupItem className="listItem--with-button">
                      {defaultArticleValue.label}
                      <Button
                        size="sm"
                        onClick={() => removePromoted(defaultArticleValue)}
                        color="danger"
                      >
                        Usuń
                      </Button>
                    </ListGroupItem>
                  </ListGroup>
                ) : (
                  <Field
                    id="article"
                    name="article"
                    defaultValue={noArticleSelectedOption}
                    defaultOptions={[noArticleSelectedOption]}
                    component={FormikSelect}
                    loadOptions={promiseOptions}
                  />
                )}
              </FormGroup>
              <Button type="submit" color="primary">
                Zapisz
              </Button>
            </fieldset>
          </Form>
        )}
      />
    </>
  );
};

export default ArticleTypesForm;
