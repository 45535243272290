import React, { Component } from "react";
import { connect } from "react-redux";
import Heading from "../../common/Typo/heading";
import { Formik } from "formik";
import { Form, Button, FormGroup, Input, Label, FormText } from "reactstrap";
// import {
//   createCategory,
//   editCategory
// } from '../../services/redux/reducers/articlesCategories/actionCreators';
import { updateArticleTypes } from "../../services/redux/reducers/articlesTypes/actionCreators";
// import MediaItem from '../../common/Media/item';
// import SelectMedia from '../Media/selectMedia';

class ArticleTypesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "new"
    };
  }

  newCat = React.createRef();

  render() {
    const { getCategory, selectedId, report } = this.props;
    let name = "";
    const dataCat = getCategory(selectedId);
    // console.log(dataCat);
    if (dataCat) {
      name = dataCat.type_name;
    }
    return (
      <>
        <Heading
          title={
            selectedId === -1
              ? "Wybierz kategorię w celu edycji"
              : "Edytuj kategorię"
          }
        />
        <Formik
          initialValues={{
            icon: "",
            colour: report && dataCat ? dataCat.colour : null
          }}
          enableReinitialize={true}
          ref={this.newCat}
          onSubmit={({ colour, ...values }, { setSubmitting }) => {
            // setSubmitting(false);
            if (report) {
              const categoryData = {
                colour
              };
              this.props.updateType(categoryData);
            } else {
              const categoryData = {
                icon: values.icon
              };
              this.props.updateType(categoryData);
            }
          }}
          render={({ values, handleSubmit, handleChange, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <fieldset disabled={selectedId === -1}>
                {dataCat && dataCat.icon ? (
                  <FormGroup>
                    <Label>Aktualna ikonka: </Label>
                    <div>
                      <img src={dataCat.icon} alt="" />
                    </div>
                  </FormGroup>
                ) : null}
                {!report && (
                  <FormGroup>
                    <Label>Edytuj ikonkę</Label>
                    <Input
                      type="file"
                      name="icon"
                      id="icon"
                      value={values.icon.filename}
                      onChange={event => {
                        setFieldValue("icon", event.currentTarget.files[0]);
                      }}
                      // required
                    />
                  </FormGroup>
                )}
                <FormGroup>
                  <Label for="category_title">Tytuł kategorii</Label>
                  <Input
                    type="text"
                    name="nameCategory"
                    id="category_title"
                    required
                    value={name}
                    onChange={handleChange}
                    readOnly
                  />
                </FormGroup>
                {report && (
                  <FormGroup>
                    <Label for="category_color">Kolor kategorii</Label>
                    <Input
                      type="color"
                      name="colour"
                      id="category_color"
                      required
                      value={values.colour}
                      onChange={handleChange}
                      // readOnly
                    />
                  </FormGroup>
                )}
                <Button type="submit" color="primary">
                  Zapisz
                </Button>
              </fieldset>
            </Form>
          )}
        />
      </>
    );
  }
}

function mapStateToProps(state, { report }) {
  const { selectedId, data, agroData } = state.articleTypes;
  const _data = report ? agroData : data;
  return {
    selectedId,
    getCategory: id => _data.find(el => el.id === id)
    // articleCategories
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateType: icon => dispatch(updateArticleTypes(icon))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleTypesForm);
