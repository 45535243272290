import { Field, Formik } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Card,
  CardText,
  CardBody,
  CardSubtitle
} from 'reactstrap';
import Layout from '../../components/Layout';
import API from '../../services/api';
import { useTimeoutFn } from 'react-use';

const AVAILABLE_LANGUAGES = [
  {
    value: '',
    label: 'Wybierz język...'
  },
  {
    value: 'PL',
    label: 'Polski'
  },
  {
    value: 'GB',
    label: 'English'
  },
  {
    value: 'FR',
    label: 'French'
  },
  {
    value: 'DE',
    label: 'Germany'
  },

  {
    value: 'IT',
    label: 'Italian'
  },
  {
    value: 'RU',
    label: 'Russian'
  },
  {
    value: 'TR',
    label: 'Turkish'
  },
  {
    value: 'UA',
    label: 'Ukrainian'
  }
];

const FormikCheckbox = ({ field, ...rest }) => (
  <Input type="checkbox" {...field} {...rest} checked={field.value} />
);

const FormikInput = ({ field, ...rest }) => <Input {...field} {...rest} />;

const FormikSelect = ({ field, options, ...rest }) => (
  <Input {...field} {...rest}>
    {options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </Input>
);

function useBarContent(token) {
  const [barContent, setBarContent] = React.useState({
    count: -1,
    next: null,
    previous: null,
    results: []
  });
  const [isFetching, setIsFetching] = React.useState(true);

  const fetchData = async () => {
    try {
      const { data } = await API.get(`/api/v1/bar-content/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setBarContent(data);
      setIsFetching(false);
    } catch (e) {
      console.log('COULD NOT FETCH BAT CONTENT');
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [token]);

  return {
    barContent,
    isFetching,
    fetchData
  };
}

function useAllertMessage() {
  const [isReady, setIsReady] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [messageType, setMessageType] = React.useState('success');

  const [, , reset] = useTimeoutFn(() => {
    setIsReady(false);
  }, 3000);

  const showMessage = (text, type) => {
    setMessage(text);
    setMessageType(type);
    setIsReady(true);
    reset();
  };

  return {
    isReady,
    message,
    messageType,
    showMessage
  };
}

const Components = () => {
  const token = useSelector((state) => state.user.token.key);
  const [refresh, setRefresh] = React.useState(false);
  const [editedBar, setEditedBar] = React.useState({
    active: null,
    content: null,
    lang_code: null,
    id: -1
  });
  const { barContent, isFetching, fetchData } = useBarContent(token);
  const { isReady, message, messageType, showMessage } = useAllertMessage();

  const shouldRenderForm = !isFetching && !refresh;
  const languagesUsed = barContent.results.reduce((acc, next) => {
    return [...acc, next.lang_code];
  }, []);

  let initialValues = {
    active: false,
    content: ''
  };
  if (editedBar.id !== -1) {
    initialValues = {
      active: editedBar.active,
      content: editedBar.content,
      lang_code: editedBar.lang_code,
      id: editedBar.id
    };
  }

  const handleSubmit = async (values) => {
    try {
      if (values.id) {
        await API.patch(
          `/api/v1/bar-content/${values.id}/`,
          { active: values.active, content: values.content },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else {
        await API.post(
          `/api/v1/bar-content/`,
          { active: values.active, content: values.content },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      }
      showMessage('Zapisano', 'success');
      setEditedBar({
        active: null,
        content: null,
        lang_code: null,
        id: -1
      });
      fetchData();
      setRefresh(true);
      setTimeout(() => {
        setRefresh(false);
      }, 200);
    } catch (e) {
      showMessage('Nie udało sie zapisać', 'danger');
    }
  };

  return (
    <Layout>
      {isReady && <Alert color={messageType}>{message}</Alert>}
      <h1 className="h2 pb-md-4 page-header">Komponenty</h1>
      <h4>Belka informacyjna</h4>
      {editedBar.id !== -1 && (
        <Button
          className="mb-4"
          onClick={() => {
            setEditedBar({
              active: null,
              content: null,
              lang_code: null,
              id: -1
            });
            setRefresh(true);
            setTimeout(() => {
              setRefresh(false);
            }, 200);
          }}
        >
          Nowa
        </Button>
      )}

      <br />
      <Row>
        <Col sm="4">
          <h5>Belki</h5>
          <div>
            {barContent.results.map((bar) => (
              <Card className="mb-4" key={bar.id}>
                <CardBody>
                  <CardSubtitle>
                    <b>Aktywna:</b> {String(bar.active)}
                  </CardSubtitle>
                  <CardSubtitle>
                    <b>Język:</b> {bar.lang_code}
                  </CardSubtitle>
                  <CardSubtitle>
                    <b>Zawartość:</b>
                  </CardSubtitle>
                  <CardText>{bar.content}</CardText>
                  <Button
                    className="mr-4"
                    onClick={() => {
                      setEditedBar(bar);
                      setRefresh(true);
                      setTimeout(() => {
                        setRefresh(false);
                      }, 200);
                    }}
                  >
                    Edytuj
                  </Button>
                </CardBody>
              </Card>
            ))}
          </div>
        </Col>
        <Col sm="4">
          <h5>Konfiguruj</h5>
          {shouldRenderForm ? (
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <FormGroup check>
                    <Label check>
                      <Field name="active" component={FormikCheckbox} />
                      Aktywna
                    </Label>
                  </FormGroup>
                  <FormGroup>
                    <Label for="content">Język</Label>
                    <Field
                      readOnly={editedBar.id !== -1}
                      type="select"
                      name="lang_code"
                      id="lang_code"
                      options={
                        editedBar.id !== -1
                          ? AVAILABLE_LANGUAGES.filter(
                              (lang) => lang.value === editedBar.lang_code
                            )
                          : AVAILABLE_LANGUAGES.filter(
                              (lang) => languagesUsed.indexOf(lang.value) === -1
                            )
                      }
                      component={FormikSelect}
                    />
                  </FormGroup>
                  <div style={{ height: 5 }} />
                  <FormGroup>
                    <Label for="content">Opis</Label>
                    <Field
                      type="textarea"
                      name="content"
                      id="content"
                      component={FormikInput}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button type="submit" color="primary" className="mt-3">
                      Zapisz
                    </Button>
                  </FormGroup>
                </form>
              )}
            ></Formik>
          ) : null}
        </Col>
      </Row>
    </Layout>
  );
};

export default Components;
