import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createEditor } from 'slate';
import { withHistory } from "slate-history";
import { Slate, Editable, withReact } from 'slate-react';
import Toolbar from './Toolbar/Toolbar'
import withLinks from './plugins/withLinks.js'
import withEmbeds from './plugins/withEmbeds.js'
import Link from './Elements/Link/Link'
import Image from './Elements/Image/Image'
import Video from './Elements/Video/Video'
import { serialize } from './utils/serialize';
import { deserialize } from './utils/deserialize';
import { resetOnEnter } from './utils/resetOnEnter';
import { NODE_TYPES } from './consts';
import GridImages from './Elements/Image/GridImages';
import Blockquote from './Elements/Blockquote/Blockquote';
import ArticleLink from './Elements/Additional/ArticleLink';
import { shortcutsEvents } from './utils/shortcuts';

const Element = (props) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case NODE_TYPES.H2:
      return <h2 {...attributes}>{children}</h2>
    case NODE_TYPES.H3:
      return <h3 {...attributes}>{children}</h3>
    case NODE_TYPES.BLOCKQUOTE:
      return <Blockquote {...props} />
    case NODE_TYPES.LIST_ITEM:
      return <li {...attributes}>{children}</li>
    case NODE_TYPES.OL_LIST:
      return <ol {...attributes}>{children}</ol>
    case NODE_TYPES.UL_LIST:
      return <ul {...attributes}>{children}</ul>
    case NODE_TYPES.LINK:
      return <Link {...props} />
    case NODE_TYPES.IMAGE:
      return <Image {...props} />
    case NODE_TYPES.GRID_IMAGES:
      return <GridImages {...props} />
    case NODE_TYPES.VIDEO:
      return <Video {...props} />
    case NODE_TYPES.DISCLAIMER:
      return <p {...attributes}><small className="disclaimer">{children}</small></p>
    case NODE_TYPES.SEPARATOR:
      return <div {...attributes}><div contentEditable={false}><hr /></div>{children}</div>
    case NODE_TYPES.ARTICLE_LINK:
      return <ArticleLink {...props} />
    default:
      return <p {...attributes}>{children}</p>
  }
}

const Leaf = ({ attributes, children, leaf }) => {

  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

const placeholder = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
]

const SlateEditor = ({ content, setEditorValue }) => {
  const editor = useMemo(() => withHistory(withEmbeds(withLinks(withReact(createEditor())))), []);

  const [initialized, setInitialized] = useState();
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(content ? deserialize(content) : placeholder)
    setInitialized(true)
  }, [])

  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => {
    return <Leaf {...props} />
  }, [])

  return initialized ? (
    <Slate
      editor={editor}
      value={value}
      onChange={newValue => {
        setValue(newValue)
        setEditorValue(serialize(editor))
      }}
    >
      <Toolbar />
      <div className="editor-wrapper">
        <Editable
          placeholder='Tutaj wpisz treść artykułu'
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={event => {
            resetOnEnter(event, editor)
            shortcutsEvents(event, editor)
          }}
        />
      </div>
    </Slate>
  ) : (<></>)
}

export default (props) => {
  return (
    <div className="editor">
      <SlateEditor {...props}></SlateEditor>
    </div>
  )
}
