import * as api from './api';
import {
  takeLatest,
  call,
  put,
  select,
  takeEvery,
  all
} from '@redux-saga/core/effects';
import * as actionTypes from './actionTypes';
import * as actionCreators from './actionCreators';
// import { SUCCESS } from '../../utils/actionSuffix';
import * as selectors from '../selectors';
import { SUCCESS, FAILURE } from '../../utils/actionSuffix';
import {
  mapFailure,
  alertFailure,
  alertSuccess
} from '../../utils/mapResponse';

export function* fetchEvents() {
  const token = yield select(selectors.selectToken);
  try {
    const response = yield call(api.fetchEvents, token);
    const { data } = response;
    const { results } = data;
    yield put(actionCreators.setEvents(results));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchEvent({ id }) {
  const token = yield select(selectors.selectToken);
  try {
    const response = yield call(api.getEvent, token, id);
    let { data } = response;
    data.medias_list = data.medias;
    data.thumb_id = data.thumb;
    yield put(actionCreators.eventTranslation(data.event));
    data.prelegents = data.lecturers;
    delete data.event;
    delete data.medias;
    delete data.thumb;
    delete data.lecturers;
    yield put({ type: actionTypes.SET_EVENT_ID, id });
    yield put(actionCreators.setEvents([data]));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchEventTranslation({ groupID }) {
  const token = yield select(selectors.selectToken);
  try {
    const {
      data: { results: translations }
    } = yield call(api.getEventTranslation, token, groupID);
    // map langs
    yield put({
      type: actionTypes.FETCH_EVENT_TRANSLATION + SUCCESS,
      translations
    });
  } catch (e) {
    yield put(alertFailure('Nie udało się pobrać tłumaczeń wydarzenia'));
  }
}

export function* createEvent({ eventFormData, id }) {
  const token = yield select(selectors.selectToken);
  const user = yield select(selectors.selectUser);
  try {
    let response = null;
    const { prelegents, invite } = eventFormData;
    delete eventFormData.prelegents;
    delete eventFormData.invite;
    if (!id) {
      response = yield call(api.createEvent, token, eventFormData, user);
      const {
        data: { id, event }
      } = response;
      if (prelegents.length) {
        yield all(
          prelegents.map(prelegent => {
            return call(api.setEventPrelegents, token, event, prelegent);
          })
        );
      }
      if (invite) {
        yield call(api.inviteUsers, token, id, invite);
      }
      yield put(actionCreators.setEventId(id));
    } else {
      // const { prelegents } = eventFormData;
      // delete eventFormData.prelegents;
      response = yield call(api.editEvent, token, eventFormData, user, id);
      if (prelegents.length) {
        yield all(
          prelegents.map(prelegent => {
            if (prelegent.id)
              return call(
                api.updatePrelengt,
                token,
                response.data.event,
                prelegent.id,
                prelegent
              );
            return call(
              api.setEventPrelegents,
              token,
              response.data.event,
              prelegent
            );
          })
        );
      }
      if (invite) {
        yield call(api.inviteUsers, token, id, invite);
      }
      yield put(actionCreators.fetchEvent(id));
    }
    yield put({ type: actionTypes.CREATE_EVENT + SUCCESS });
    yield put(alertSuccess('Wydarzenie dodane pomyślnie'));
    // console.log(id);
  } catch (e) {
    yield put(mapFailure(e, actionTypes.CREATE_EVENT));
    yield put(alertFailure('Wystąpił problem podczas dodania wydarzenia.'));
  }
}

export function* removeEvent({ id }) {
  const token = yield select(selectors.selectToken);
  try {
    yield call(api.removeEvent, token, id);
    yield put(actionCreators.fetchEvents());
  } catch (e) {
    yield put(mapFailure(e, actionTypes.REMOVE_EVENT + FAILURE));
    yield put(alertFailure('Wystąpił błąd podczas usuwania wydarzenia'));
  }
}

export function* changeEventStatus({ id, status }) {
  const token = yield select(selectors.selectToken);
  let response = null;
  let editId = id;
  try {
    let message = null;
    response = yield call(api.setEventStatus, token, editId, status);
    message = 'Edytowano pomyślnie wydarzenie.';
    const {
      data: { id }
    } = response;
    if (id) {
      yield put(actionCreators.setEventId(id));
      yield put({ type: actionTypes.SET_EVENT_STATUS + SUCCESS, status });
    }
    yield put(alertSuccess(message));
    yield put(actionCreators.fetchEvent(id));
  } catch (e) {
    // yield put(mapFailure(e, actionTypes.));
    yield put(
      alertFailure(
        'Wystąpił błąd podczas publikowania artykułu. Sprawdź, czy na pewno masz odpowiednie uprawnienia'
      )
    );
  }
}

// export function* updateUserDesc({ eventid, id, data, add }) {
//   const token = yield select(selectors.selectToken);
//   try {
//     if (add) {
//       yield call(api.setEventPrelegents, token, eventid, [
//         { user_id: id, ...data }
//       ]);
//     } else {
//       yield call(api.updatePrelengtDesc, token, eventid, id, data);
//       yield put(alertSuccess('Prelegent pomyślnie edytowany'));
//     }
//   } catch (e) {
//     console.log(e);
//   }
// }

export function* removeUser({ lectureid }) {
  const token = yield select(selectors.selectToken);
  try {
    yield call(api.removePrelengt, token, lectureid);
    yield put(alertSuccess('Pomyślnie usunięto prelegenta'));
  } catch (e) {
    console.log(e);
  }
}

export default [
  takeLatest(actionTypes.FETCH_EVENTS, fetchEvents),
  takeEvery(actionTypes.CREATE_EVENT, createEvent),
  takeEvery(actionTypes.FETCH_EVENT, fetchEvent),
  takeEvery(actionTypes.FETCH_EVENT_TRANSLATION, fetchEventTranslation),
  takeLatest(actionTypes.REMOVE_EVENT, removeEvent),
  takeEvery(actionTypes.SET_EVENT_STATUS, changeEventStatus),
  // takeEvery(actionTypes.UPDATE_USER_DESC, updateUserDesc),
  takeEvery(actionTypes.REMOVE_EVENT_USER, removeUser)
  //
];
