import React, { useRef, useState } from 'react'
import { Transforms } from 'slate';
import { useSelected, useFocused, ReactEditor, useSlateStatic } from "slate-react";
import { useAutosizeTextArea } from '../../utils/useAutosizeTextArea';

const GridImages = ({ attributes, element, children }) => {
  const editor = useSlateStatic()
  const { grid } = element;
  const selected = useSelected();
  const focused = useFocused();
  const [descList, setDesc] = useState(grid.map(({ desc }) => desc))

  const handleChange = (event, index) => {
    const _desc = [...descList]
    _desc[index] = event.target.value
    setDesc(_desc)
  }
  const textAreaRef = useRef(null)
  useAutosizeTextArea(textAreaRef, descList)

  const handleSave = () => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(
      editor,
      {
        ...element,
        grid: grid
          .map((g, index) => ({
            ...g,
            desc: descList[index]
          }))
      },
      { at: path }
    )
  }

  return (
    <div
      {...attributes}
      className='element-image'
      style={{ display: 'flex', justifyContent: 'center', boxShadow: selected && focused && '0 0 3px 3px lightgray' }}
    >
      <section contentEditable={false} className={`image-grid image-grid-${grid.length}`}>
        {grid.map(({ image, desc }, index) => {
          const { alt, url } = image
          return (
            <div className="image-grid__content" key={index}>
              <div className="image-grid__content-img">
                <img alt={alt} src={url} />
              </div>

              <textarea
                className="editor-textarea"
                rows="1"
                onChange={e => handleChange(e, index)}
                onBlur={handleSave}
                placeholder="Dodaj opis do zdjęcia"
                ref={textAreaRef}
                value={descList[index]}
              />
            </div>
          )
        })}
      </section>
      {children}
    </div>
  );
};
export default GridImages;
