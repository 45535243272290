import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import Header from './components/Header/header.jsx';
import Main from './views/index.jsx';
import { connect } from 'react-redux';
import cookies from './utilities/cookies';
import { ToastContainer } from 'react-toastify';
import {
  setUserApiData,
  fetchUserData
} from './services/redux/reducers/user/actionCreators';
import { clearActions } from './services/redux/reducers/alerts/actionCreators';
import { history } from './utilities/history';
import { injectIntl } from 'react-intl';

import * as Sentry from '@sentry/browser';

import 'react-toastify/dist/ReactToastify.css';

class MyApp extends Component {
  constructor(props) {
    super(props);
    const { token, id } = cookies.getUserData();
    this.state = {
      token,
      id,
      error: false
    };
    history.listen((location, action) => {
      this.props.clearAlert();
    });
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    if (process.env.NODE_ENV !== 'development') {
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }
  }

  componentDidMount() {
    const { token, id } = this.state;
    if (token && id) {
      this.props.loginUser(token, id);
    }
  }
  render() {
    return (
      <Router history={history}>
        <div>
          <ToastContainer />
          <Header />
          <Main />
        </div>
      </Router>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (token, id) => {
      return new Promise((resolve, reject) => {
        dispatch(setUserApiData(token, id));
        dispatch(fetchUserData());
        resolve();
      });
    },
    clearAlert: () => dispatch(clearActions())
  };
};

export default injectIntl(connect(null, mapDispatchToProps)(MyApp));
