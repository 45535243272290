import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { Input } from 'reactstrap';
import Layout from '../../components/Layout';
import API from '../../services/api';
import { deletePoll } from '../../services/redux/reducers/polls/actionCreators.js';
import { calculateMaxPages } from '../../utilities/calculates';
import { formatDate } from '../../utilities/dates';

const PublishStatusPL = {
  1: 'Nowe',
  2: 'Zaakceptowane',
  3: 'Odrzucone',
  4: 'Usunięte'
};

class ShowOffSection extends React.Component {
  state = {
    data: [],
    pages: null,
    loading: true,
    tableState: ''
  };

  tableRef = React.createRef();

  fetchData = (state, instance) => {
    const { token, lang } = this.props;
    const params = {
      limit: state.pageSize
    };
    this.setState({ loading: true, tableState: state });
    let offset = state.page * state.pageSize;
    if (offset) {
      params.offset = offset;
    }

    const [sortColumn] = state.sorted;
    if (sortColumn) {
      let ordering = sortColumn.desc ? '-' : '';
      ordering += sortColumn.id;
      params.ordering = ordering;
    }

    if (state.filtered.length) {
      state.filtered.forEach(element => {
        params[element.id] = element.value;
      });
    }

    API.get('/api/v1/photos/', {
      params,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(({ data }) => {
      this.setState({
        data: data.results,
        pages: calculateMaxPages(data.count, state.pageSize),
        loading: false
      });
    });
  };
  getFilterVal = type => {
    let filters = this.state.tableState.filtered;
    if (filters && filters.length) {
      let filter = filters.find(item => item.id === type);
      return filter ? filter.value : '';
    }
    return '';
  };

  changeStatus = (newStatus, photo) => {
    const { token } = this.props;
    const url = `/api/v1/photos/${photo.slug}/`;
    API.patch(
      url,
      {
        publish_status: newStatus
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    ).then(() => {
      this.tableRef.current.fireFetchData();
    });
  };

  render() {
    const { data, pages, loading } = this.state;

    return (
      <Layout>
        <h1 className="h2 pb-md-4 page-header">Twoje pole do popisu</h1>
        <div className="table-responsive">
          <ReactTable
            ref={this.tableRef}
            multiSort={false}
            columns={[
              {
                Header: 'Lp.',
                Cell: row => {
                  return <div>{row.index + 1 + row.page * row.pageSize}</div>;
                },
                maxWidth: 40,
                sortable: false
              },
              {
                Header: 'Tytuł',
                id: 'title',
                Cell: ({ original }) => {
                  return (
                    <a
                      href={original.image}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {original.title}
                    </a>
                  );
                }
              },
              {
                Header: 'Autor',
                id: 'owner__last_name',
                accessor: d => `${d.owner.first_name} ${d.owner.last_name}`
              },
              {
                Header: 'Data dodania',
                id: 'published_at',
                accessor: d =>
                  d.published_at ? formatDate(new Date(d.published_at)) : ' - '
              },
              {
                Header: 'Tagi',
                id: 'tags',
                accessor: d => d.tags.map(tag => tag.name).join(', '),
                sortable: false
              },
              {
                Header: 'Status',
                id: 'publish_status',
                accessor: d => (
                  <Input
                    type="select"
                    id={`select${d.id}`}
                    name={`select${d.id}`}
                    onChange={e => this.changeStatus(e.target.value, d)}
                    value={d.publish_status}
                    size="sm"
                  >
                    {Object.entries(PublishStatusPL).map(([id, label]) => (
                      <option value={id} key={id}>
                        {label}
                      </option>
                    ))}
                  </Input>
                ),
                filterable: true,
                Filter: ({ onChange, filter }) => (
                  <Input
                    type="select"
                    name="select_user_type"
                    id="select_user_type"
                    onChange={e => onChange(e.target.value)}
                    value={filter ? filter.value : undefined}
                    size="sm"
                  >
                    <option value="">Wybierz</option>
                    {Object.entries(PublishStatusPL).map(([id, name]) => (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    ))}
                  </Input>
                )
              },
              {
                Header: 'Liczba gwiazdek',
                id: 'votes_count',
                accessor: 'votes_count'
              }
            ]}
            manual
            data={data}
            pages={pages}
            loading={loading}
            onFetchData={this.fetchData}
            defaultPageSize={10}
            resizable={false}
            className="-striped -highlight"
          />
        </div>
      </Layout>
    );
  }
}

function mapStateToProps({ user, settings }) {
  return {
    token: user.token.key,
    lang: settings.lang
  };
}

export default connect(mapStateToProps, { deletePoll })(
  withRouter(ShowOffSection)
);
