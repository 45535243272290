import React, { Component } from 'react';

import Alerts from '../../common/Alerts';

class PlainLayout extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <div className="container-fluid">
        <main role="main" className="col-xs-12 pt-3 px-4">
          <Alerts />
          {this.props.children}
        </main>
      </div>
    );
  }
}

export default PlainLayout;
