import * as actionTypes from './actionTypes';
import { SUCCESS, FAILURE } from '../../utils/actionSuffix';
// add types
const InitialState = {
  uploading: false,
  uploaded: false,
  error: null
};

export function mediaRequest(state = InitialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_MEDIA + SUCCESS:
      return { ...state, uploading: false, uploaded: true, error: false };

    case actionTypes.CREATE_MEDIA:
      return { ...state, uploaded: false, uploading: true, error: false };

    case actionTypes.CREATE_MEDIA + FAILURE:
    case actionTypes.DELETE_MEDIA + FAILURE:
      return { ...state, uploading: false, error: true };

    default:
      return state;
  }
}
