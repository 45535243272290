import API from "../../../api";

export const fetchArticleTypes = token =>
  API.get("/api/v1/article-types/?limit=20", {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const updateArticleTypes = (token, id, data) => {
  // // console.log(icon);
  console.log(data);
  let fileData = data;
  const headers = {
    Authorization: `Bearer ${token}`
  };
  if (data.icon) {
    fileData = new FormData();
    fileData.append("icon", data.icon);
    headers["Content-Type"] = "multipart/form-data";
  }

  return API.patch(`/api/v1/article-types/${id}/`, fileData, {
    headers
  });
};
