import React from 'react';
import { useFormik } from 'formik';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import debounce from 'lodash.debounce';

import Inputs from '../../Inputs';

const HeroForm = ({
  onChange,
  py,
  title,
  description,
  imageUrl,
  isImageFullSize,
  isLight,
  button,
  anchor
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title,
      description,
      imageUrl,
      isImageFullSize,
      isLight,
      button,
      py: py,
      anchor
    },
    onSubmit: (values) => {
      onChange(values);
    }
  });

  const onBlur = () => {
    formik.submitForm();
  };

  const debounceSave = debounce(() => {
    onBlur();
  }, 800);

  return (
    <Form>
      <Row>
        <Col xs={6}>
          <FormGroup>
            <Label for="title">Tytuł</Label>
            <Input
              id="title"
              name="title"
              type="textarea"
              value={formik.values.title}
              invalid={!!formik.errors.title}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('title', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="description">Opis</Label>
            <Input
              id="description"
              name="description"
              type="textarea"
              value={formik.values.description}
              invalid={!!formik.errors.description}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('description', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <FormGroup>
            <Label for="imageUrl">Cover URL</Label>
            <Input
              id="imageUrl"
              name="imageUrl"
              type="textarea"
              value={formik.values.imageUrl}
              invalid={!!formik.errors.imageUrl}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('imageUrl', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
          <Inputs.MediaButton
            type="image"
            onChange={(url) => {
              formik.setFieldValue('imageUrl', url);
              debounceSave();
            }}
          />
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Input
              id="isImageFullSize"
              name="isImageFullSize"
              type="checkbox"
              checked={formik.values.isImageFullSize}
              invalid={!!formik.errors.isImageFullSize}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('isImageFullSize', target.checked);
              }}
            />
            <Label for="isImageFullSize">Cover na całą szerokość</Label>
          </FormGroup>
          <FormGroup>
            <Input
              id="isLight"
              name="isLight"
              type="checkbox"
              checked={formik.values.isLight}
              invalid={!!formik.errors.isLight}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('isLight', target.checked);
                debounceSave();
              }}
            />
            <Label for="isLight">Odwrotny wariant kolorystyczny</Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <FormGroup>
            <Label for="button.title">Przycisk</Label>
            <Input
              id="button.title"
              name="button.title"
              type="textarea"
              value={formik.values.button.title}
              invalid={!!formik.errors.button}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('button.title', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="button.link">Link</Label>
            <Input
              id="button.link"
              name="button.link"
              type="textarea"
              value={formik.values.button.link}
              invalid={!!formik.errors.button}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('button.link', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Inputs.SpacesInput
            id="py"
            name="py"
            checked={formik.values.pt}
            invalid={!!formik.errors.pt}
            onBlur={onBlur}
            onChange={({ target }) => {
              formik.setFieldValue('py', target.checked);
            }}
          />
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="anchor">Zakotwiczenie</Label>
            <Input
              id="anchor"
              name="anchor"
              type="text"
              value={formik.values.anchor}
              invalid={!!formik.errors.anchor}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('anchor', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default HeroForm;
