export const FETCH_POLL = 'FETCH_POLL';
export const CREATE_POLL = 'CREATE_POLL';
export const UPDATE_POLL = 'UPDATE_POLL';
export const DELETE_POLL = 'DELETE_POLL';

export const FETCH_POLL_TRANSLATIONS = 'FETCH_POLL_TRANSLATIONS';

export const CREATE_POSSIBILITY = 'CREATE_POSSIBILITY';
export const UPDATE_POSSIBILITY = 'UPDATE_POSSIBILITY';
export const DELETE_POSSIBILITY = 'DELETE_POSSIBILITY';

export const SET_POLL_ID = 'SET_POLL_ID';
export const RESET_POLL = 'RESET_POLL';
