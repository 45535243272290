import * as actionTypes from './actionTypes';

export const fetchArticles = () => ({
  type: actionTypes.FETCH_ARTICLES
});

export const setArticles = data => ({
  type: actionTypes.SET_ARTICLES,
  data
});

export const setArticleMediaFiles = data => ({
  type: actionTypes.SET_ARTICLE_MEDIA,
  data
});

export const setArticleMediaFilesState = data => ({
  type: actionTypes.SET_ARTICLE_MEDIA_SUCCESS,
  data
});

export const createArticle = (articleFormData, id) => ({
  type: actionTypes.CREATE_ARTICLE,
  articleFormData,
  id
});

export const fetchArticle = id => ({
  type: actionTypes.FETCH_ARTICLE,
  id
});

export const removeArticlefile = id => ({
  type: actionTypes.ARTICLE_REMOVE_FILE,
  id
});

export const removeArticle = id => ({
  type: actionTypes.REMOVE_ARTICLE,
  id
});

export const setArticleId = id => ({
  type: actionTypes.SET_ARTICLE_ID,
  id
});

export const resetReq = () => ({ type: actionTypes.RESET_REQ });

export const articleTranslation = groupID => ({
  type: actionTypes.FETCH_ARTICLE_TRANSLATION,
  groupID
});

export const setArticleStatus = (id, status) => ({
  type: actionTypes.SET_ARTICLE_STATUS,
  id,
  status
});

export const setArticleTranslation = data => {
  return {
    type: actionTypes.SET_ARTICLE_TRANSLATION,
    data
  }
}
