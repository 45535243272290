import { Node, Transforms } from 'slate';
import { NODE_TYPES } from '../consts';

const ELEMENTS_TO_RESET = [NODE_TYPES.BLOCKQUOTE, NODE_TYPES.H2, NODE_TYPES.H3, NODE_TYPES.DISCLAIMER, NODE_TYPES.SEPARATOR]

export const resetOnEnter = (event, editor) => {
  if (event.key === 'Enter') {
    const selectedElement = Node.descendant(editor, editor.selection.anchor.path.slice(0, -1));

    if (ELEMENTS_TO_RESET.includes(selectedElement.type)) {
      event.preventDefault();
      const selectedLeaf = Node.descendant(editor, editor.selection.anchor.path);

      if (selectedLeaf.text.length === editor.selection.anchor.offset) {
        Transforms.insertNodes(editor, {
          type: 'paragraph',
          children: [{ text: '', marks: [] }],
        });
      }
      else {
        Transforms.splitNodes(editor);
        Transforms.setNodes(editor, { type: 'paragraph' });
      }
    }
  }
}
