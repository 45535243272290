import React from 'react';
import Layout from '../../components/Layout/index.jsx';
import { MultimediaForm } from '../../components/Multimedia/MultimediaForm.jsx';

export const MultimediaNew = () => {
  return (
    <Layout>
      <h1 className="h2 pb-md-4 page-header">
        Nowe multimedium
      </h1>
      {/* Content */}
      <MultimediaForm />
    </Layout>
  );
}
