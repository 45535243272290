import React, { useState } from 'react';
import { useCategories } from '../../common/Articles/useCategories';
import ReactTable from 'react-table';
import { Button, Input } from 'reactstrap';
import API, { frontLink } from '../../services/api';
import { formatDate } from '../../utilities/dates';
import { displayStatus, statuses } from '../../utilities/status';
import { useSelector } from 'react-redux';
import { DeleteButton } from '../FunctionalButtons/DeleteButton';
import { calculateMaxPages } from '../../utilities/calculates';
import { useHistory } from 'react-router';
import { useArticleApi } from './Article.api';

const ArticlesList = ({ selectMode, selectArticle }) => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [pages, setPages] = useState(null)
  const [tableState, setTableState] = useState({})
  const slug = 'articles'
  const lang = useSelector(state => state.settings.lang)
  const token = useSelector((state) => state.user.token.key)
  const { fetchArticles, removeArticle } = useArticleApi()

  const { flattenCategories } = useCategories()

  const categories = flattenCategories() || []

  const fetchData = (state) => {
    setLoading(true)
    setTableState(state)

    const params = {
      limit: state.pageSize,
      offset: state.page * state.pageSize,
      lang_code: lang,
    }

    if (state.filtered.length) {
      state.filtered.forEach((element) => {
        if (element.id === 'name') {
          params.search = element.value
        } else if (element.id === 'article_type') {
          params[`${element.id}_id`] = element.value
        } else {
          params[`${element.id}`] = element.value
        }
      });
    }

    let sort = [];

    if (state.sorted.length) {
      sort.push(`${state.sorted[0].desc ? '-' : ''}${state.sorted[0].id}`);
    } else {
      sort.push(`-created_at`);
    }

    const sortingParams = sort.length ? sort.reduce((a, b) => {
      return a + ',' + b;
    }) : null

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        ...params,
        ordering: sortingParams
      }
    }

    return fetchArticles(config).then(({ data }) => {
      setData(data.results)
      setPages(calculateMaxPages(data.count, state.pageSize))
      setLoading(false)
    }).finally(() => {
      setLoading(false)
    });
  }

  const handleRemoveArticle = (slug) => {
    removeArticle(slug)
      .then(() => {
        fetchData(tableState)
      })
  }

  return (
    <div className="table-responsive">
      <ReactTable
        columns={[
          {
            Header: 'Lp.',
            Cell: (row) => {
              return <div>{row.index + 1 + row.page * row.pageSize}</div>;
            },
            filterable: false,
            maxWidth: 40
          },
          {
            Header: 'Tytuł',
            accessor: 'name'
          },
          {
            Header: 'Kategoria',
            id: 'category',
            accessor: (d) =>
              d.categories.map((cat) => cat.type_name).join(','),
            maxWidth: 210,
            filterMethod: (filter, row) => {
              if (filter.value === '') {
                return true;
              }
              return row._original.article_type === parseInt(filter.value);
            },
            Filter: ({ onChange, filter }) => (
              <Input
                type="select"
                name="select_user_type"
                id="select_user_type"
                onChange={(e) => onChange(e.target.value)}
                value={filter ? filter.value : undefined}
                size="sm"
              >
                <option value="">Wybierz</option>
                {categories.map(({ id, type_name }) => (
                  <option value={id} key={id}>
                    {type_name}
                  </option>
                ))}
              </Input>
            ),
            filterable: categories.length
          },
          {
            Header: 'Data modyfikacji',
            id: 'updated_at',
            accessor: (d) => formatDate(new Date(d.updated_at)),
            filterable: false,
            maxWidth: 210,
            sortable: true
          },
          {
            Header: 'Status',
            id: 'publish_status',
            accessor: (d) => displayStatus(d.publish_status),
            maxWidth: 180,
            Filter: ({ onChange, filter }) => (
              <Input
                type="select"
                id={`select_publish_status`}
                onChange={(e) => onChange(e.target.value)}
                size="sm"
                value={filter ? filter.value : undefined}
              >
                <option value="">Wybierz</option>
                {statuses.map((type) => (
                  <option value={type.id} key={type.id}>
                    {type.label}
                  </option>
                ))}
              </Input>
            )
          },
          {
            Header: 'Akcje',
            Cell: (d) => {
              return (
                <>
                  {selectMode ?
                    <>
                      <Button
                        color="primary"
                        onClick={() => selectArticle(d.original)}
                      >Wybierz</Button>
                    </> : <>
                      <Button
                        color="primary"
                        onClick={() =>
                          history.push(`/${slug}/${d.original.slug}`)
                        }
                        size="sm"
                      >
                        Edytuj
                      </Button>
                      <Button
                        color="secondary"
                        size="sm"
                        onClick={() => {
                          const slug = lang === 'PL' ? 'artykuly/' : 'articles/'
                          window.open(
                            `${frontLink()}${slug}${d.original.slug}`,
                            '_blank'
                          )
                        }
                        }
                      >
                        Zobacz
                      </Button>
                      <DeleteButton
                        callback={() => handleRemoveArticle(d.original.slug)}
                      />
                    </>
                  }
                </>
              );
            },
            filterable: false,
            maxWidth: 210
          }
        ]}
        manual
        data={data}
        pages={pages}
        filterable
        sortable={false}
        resizable={false}
        onFetchData={fetchData}
        defaultPageSize={10}
        className="-striped -highlight"
      />
    </div>
  );
}

export default ArticlesList
