import * as api from './api';
import {
  takeLatest,
  call,
  put,
  select,
  takeEvery
} from '@redux-saga/core/effects';
import * as actionTypes from './actionTypes';
import * as actionCreators from './actionCreators';
import * as selectors from '../selectors';
import { SUCCESS, FAILURE } from '../../utils/actionSuffix';
import {
  mapFailure,
  alertFailure,
  alertSuccess
} from '../../utils/mapResponse';

export function* fetchMedia({ page, filter }) {
  try {
    const token = yield select(selectors.selectToken);
    const response = yield call(api.fetchMedia, token, page, filter);
    const { data } = response;
    const { results, count } = data;
    yield put({
      type: actionTypes.FETCH_MEDIA + SUCCESS,
      data: results,
      count
    });
  } catch (e) {
    console.log(e);
  }
}

export function* fetchBanners() {
  try {
    const token = yield select(selectors.selectToken);
    const response = yield call(api.fetchBanners, token);
    const { data } = response;
    const { results } = data;
    yield put({
      type: actionTypes.FETCH_BANNERS + SUCCESS,
      data: results,
    });
  } catch (e) {
    console.log(e);
  }
}

export function* createMedia({ fileData }) {
  const token = yield select(selectors.selectToken);
  try {
    yield call(api.createMedia, token, fileData);
    yield put(actionCreators.fetchMedia());
    yield put(alertSuccess('Pomyślnie dodano nowy plik'));
    yield put({ type: actionTypes.CREATE_MEDIA + SUCCESS });
  } catch (e) {
    // console.log(e);
    yield put(mapFailure(e, actionTypes.CREATE_MEDIA + FAILURE));
    yield put(alertFailure('Wystąpił błąd podczas usuwania pliku.'));
  }
}

export function* editMedia({ fileData, id }) {
  const token = yield select(selectors.selectToken);
  try {
    yield call(api.editMedia, token, id, fileData);
    yield put(actionCreators.fetchMedia());
  } catch (e) {
    yield put(alertFailure('Wystąpił błąd podczas edycji pliku.'));
  }
}

export function* deleteMedia({ id }) {
  const token = yield select(selectors.selectToken);
  try {
    yield call(api.deleteMedia, token, id);
    yield put(alertSuccess('Pomyślnie usunięto plik'));
  } catch (e) {
    yield put(mapFailure(e, actionTypes.DELETE_MEDIA + FAILURE));
    yield put(
      alertFailure(
        'Wystąpił błąd podczas usuwania pliku. Jeżeli błąd sie powtarza skontaktuj się z administratorem.'
      )
    );
  }
}

export default [
  takeLatest(actionTypes.FETCH_MEDIA, fetchMedia),
  takeEvery(actionTypes.CREATE_MEDIA, createMedia),
  takeEvery(actionTypes.EDIT_MEDIA, editMedia),
  takeEvery(actionTypes.DELETE_MEDIA, deleteMedia),
  takeEvery(actionTypes.FETCH_BANNERS, fetchBanners)
];
