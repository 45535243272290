import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Alert } from 'reactstrap';

import Layout from '../../components/Layout';
import { useProperty, useAllertMessage } from './useRealEstate';
import RealEstateForm from './RealEstateForm';

function RealEstate() {
  const token = useSelector((state) => state.user.token.key);
  const { isReady, message, messageType, showMessage } = useAllertMessage();
  const { slug } = useParams();

  const { property, media, updateProperty } = useProperty({
    token,
    slug
  });

  return (
    <Layout>
      <h1 className="h2 pb-md-4 page-header">Nieruchomości</h1>
      {property.id !== -1 && (
        <RealEstateForm
          initialValues={property}
          onSubmit={(values) =>
            updateProperty(values)
              .then(() => {
                showMessage('Zapisano', 'success');
              })
              .catch(() => {
                showMessage('Nie udało sie zapisać', 'danger');
              })
          }
          media={media}
          mediaThumbnail={property.thumbnail}
        />
      )}
      {isReady && <Alert color={messageType}>{message}</Alert>}
    </Layout>
  );
}

export default RealEstate;
