import React from 'react';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import styled, { css } from 'styled-components';
import {
  Button,
  ListGroup,
  ListGroupItem,
  FormGroup,
  Input,
  Label
} from 'reactstrap';

class UploadFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alerts: [],
      descriptions: [],
      style: null
    };
  }

  checkFileAcceptance(type) {
    const acceptance = [
      'video/mp4',
      'image/jpeg',
      'image/png',
      'image/svg+xml',
      'application/pdf',
      'application/',
      'audio/mp3'
    ];
    return acceptance.includes(type);
  }

  handleFileDroping(file) {
    let alerts = [];
    if (file.type.includes('image') && file.size > 2000000) {
      alerts.push('Twój plik graficzny jest za duży');
    } else if (!this.checkFileAcceptance(file.type)) {
      alerts.push('Zły format pliku');
    } else {
      this.props.onDrop(file);
    }
    return alerts;
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    let alerts = [];
    if (rejectedFiles.length) {
      alerts.push('Twój plik jest za duży');
      rejectedFiles.forEach(file => {
        alerts = alerts.concat(this.handleFileDroping(file));
      });
    }
    acceptedFiles.forEach(file => {
      alerts = this.handleFileDroping(file);
    });
    this.setState({
      alerts: alerts
    });
  };

  addAlt = (e, index) => {
    let desc = [];
    desc[index] = e.target.value;
    this.setState({
      descriptions: desc
    });
    this.props.addDesc(index, e.target.value);
  };

  addName = (e, index) => {
    let name = [];
    name[index] = e.target.value;
    this.setState({
      name: name
    });
    this.props.addName(index, e.target.value);
  };

  addStyle = (e, index) => {
    let style = '';
    style = e;
    this.setState({
      style: style
    })
    this.props.addStyle(index, e);
  }

  checkStyle = () => {
    return !!this.state.style
}

  render() {
    const { alerts } = this.state;
    return (
      <div>
        <Dropzone onDrop={this.onDrop} maxSize={20000000} multiple={true}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div>
                <DropZoneContainer className="files-dropzone">
                  <div
                    {...getRootProps()}
                    className={classNames('dropzone', {
                      'dropzone--isActive': isDragActive
                    })}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Przeciągnij plik tutaj</p>
                    ) : (
                      <p>
                        Przeciągnij plik tutaj, lub dodaj plik{' '}
                        <Button type="button" outline color="primary" size="sm">
                          Dodaj plik
                        </Button>
                      </p>
                    )}
                  </div>
                </DropZoneContainer>
                {this.props.files ? (
                  <div className="pt-2">
                    Lista plików do dodania: <br />
                    <ListGroup>
                      {this.props.files.map(({ description, name }, index) => (
                        <ListGroupItem key={`file_${index}`}>
                          {/* {file.name} */}
                          <FormGroup>
                            <Label>Nazwa pliku (atrybut alt)</Label>
                            <Input
                              type="text"
                              name="name"
                              id="name"
                              value={name}
                              onChange={e => this.addName(e, index)}
                              // disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>Dodaj atrybut alt</Label>
                            <Input
                              type="text"
                              name="alt"
                              id="alt"
                              value={description}
                              onChange={e => this.addAlt(e, index)}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>Parametry stylów:</Label>
                            <p>Rozciągnij do szerokości kontenera</p>
                            <div>
                            <label className="switch">
                              <input type="checkbox"
                                     onChange={e => this.checkStyle() ? this.addStyle(null,index) :  this.addStyle('width: 100%;',index)}
                                     checked={this.checkStyle()}/>
                              <span className="slider round"></span>
                              <span style={{fontSize: '0.8rem'}}> </span>

                            </label>
                            </div>

                          </FormGroup>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </div>
                ) : null}
              </div>
            );
          }}
        </Dropzone>
        {alerts.length ? (
          <ListGroup className="list-group--errors">
            {alerts.map((item, index) => (
              <ListGroupItem color="danger" key={`file_alert${index}`}>
                {item}
              </ListGroupItem>
            ))}
          </ListGroup>
        ) : null}
      </div>
    );
  }
}

const DropZoneContainer = styled.div`
  ${props =>
    props.isDragActive &&
    css`
      border-color: green;
    `}
`;

export default UploadFile;
