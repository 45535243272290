import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from '../../components/Layout/index.jsx';
import ReactTable from 'react-table';
import { calculateMaxPages } from '../../utilities/calculates';
import API from '../../services/api';
import { formatDate } from '../../utilities/dates';
import {
  editUser,
  resetReq
} from '../../services/redux/reducers/user/actionCreators.js';

class UserHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      data: [],
      pages: null,
      loading: true,
      tableState: ''
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    const { loaded } = this.state;
    const { userType } = this.props;
    if (userType !== 1) {
      this.props.history.push('/');
    }
    if (!loaded) {
      this.props.editUser(this.props.match.params.id);
    }
  }

  fetchData(state, instance) {
    this.setState({ loading: true, tableState: state });
    const { token } = this.props;
    const id = this.props.match.params.id;

    let url = `/api/v1/users/${id}/changelog/?limit=${state.pageSize}`;
    let params = [];
    let offset = state.page * state.pageSize;
    if (offset) {
      params.push(`offset=${offset}`);
    }

    if (params.length) {
      let allParams = params.reduce((a, b) => {
        return a + '&' + b;
      });
      url = url + '&' + allParams;
    }

    API.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(({ data }) => {
      this.setState({
        data: data.results,
        pages: calculateMaxPages(data.count, state.pageSize),
        loading: false
      });
    });
  }

  componentWillUnmount() {
    const { resetReq } = this.props;
    resetReq();
  }

  render() {
    const { data, pages } = this.state;
    const { email } = this.props;
    return (
      <Layout>
        <h1 className="h2 pb-md-4 page-header">Historia użytkownika {email}</h1>
        {/* Content */}
        <ReactTable
          columns={[
            {
              Header: 'Lp.',
              Cell: row => {
                return <div>{row.index + 1 + row.page * row.pageSize}</div>;
              },
              filterable: false,
              maxWidth: 40
            },
            {
              Header: 'Użytkownik',
              accessor: 'performer'
            },
            {
              Header: 'Data wykonania',
              id: 'created_at',
              accessor: d => formatDate(new Date(d.created_at))
            },
            {
              Header: 'Stara wartość',
              accessor: 'old_value'
            },
            {
              Header: 'Nowa wartość',
              accessor: 'new_value'
            },
            {
              Header: 'Nazwa pola',
              accessor: 'field_name'
            }
          ]}
          manual
          data={data}
          pages={pages}
          filterable={false}
          resizable={false}
          onFetchData={this.fetchData}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      </Layout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { user } = state;
  return {
    token: user.token.key,
    email: user.edit.email,
    userType: user.user_type
  };
}

export default connect(
  mapStateToProps,
  { editUser, resetReq }
)(UserHistory);
