import * as actionTypes from './actionTypes';

export const createPoll = data => ({
  type: actionTypes.CREATE_POLL,
  data
});

export const createPossibility = (index, data) => ({
  type: actionTypes.CREATE_POSSIBILITY,
  index,
  data
});

export const fetchPoll = id => ({
  type: actionTypes.FETCH_POLL,
  id
});

export const setPollId = id => ({
  type: actionTypes.SET_POLL_ID,
  id
});

export const updatePoll = data => ({
  type: actionTypes.UPDATE_POLL,
  data
});

export const deletePoll = id => ({
  type: actionTypes.DELETE_POLL,
  id
});

export const resetPoll = () => ({
  type: actionTypes.RESET_POLL
});

export const deletePossibility = id => ({
  type: actionTypes.DELETE_POSSIBILITY,
  id
});

export const updatePossibility = (id, data) => ({
  type: actionTypes.UPDATE_POSSIBILITY,
  id,
  data
});

export const fetchPollTranslations = grouping_id => ({
  type: actionTypes.FETCH_POLL_TRANSLATIONS,
  grouping_id
});
