import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export const getQueryStringValue = (key, url) => {
  const params = url ? "?" + url.split("?")[1] : window.location.search;
  return decodeURIComponent(
    params.replace(
      new RegExp(
        "^(?:.*[&\\?]" +
        encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + // eslint-disable-line
          "(?:\\=([^&]*))?)?.*$",
        "i"
      ),
      "$1"
    )
  );
};
