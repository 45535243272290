import React from 'react';
import { Formik } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import {
  Button,
  Col,
  Form,
  Row,
  FormFeedback,
  FormGroup,
  Input,
  Label
} from 'reactstrap';
import { useSelector } from 'react-redux';

import { selectCMSLang } from '../../../services/redux/reducers/selectors';
import { selectUserType } from '../../../services/redux/reducers/user/selectors';
import { ReactComponent as NoVideoIcon } from '../../../assets/no_video.svg';
import { useVideos } from '../Videos.hooks';
import VideoEmbed from './VideoEmbed';

import './VideosPreview.scss';

const LANG_CODE_OPTIONS = [
  { value: 'FR', label: 'Francuski' },
  { value: 'DE', label: 'Niemiecki' },
  { value: 'IT', label: 'Włoski' },
  { value: 'PL', label: 'Polski' },
  { value: 'RU', label: 'Rosyjski' },
  { value: 'TR', label: 'Turecki' },
  { value: 'UA', label: 'Ukraiński' },
  { value: 'GB', label: 'Angielski' }
];

const STATUSES = [
  { value: 1, label: 'Szkic' },
  { value: 2, label: 'Oczekuje na publikację' },
  { value: 3, label: 'Opublikowany' }
];

export const getStatusValue = (status = 1) =>
  STATUSES.find((s) => Number(s.value) === Number(status)) || STATUSES[0];

export const getLangValue = (code = '') =>
  LANG_CODE_OPTIONS.find((lang) => lang.value === code) || LANG_CODE_OPTIONS[3];

function VideosPreview() {
  const { video, setVideo, addNewVideo, updateVideoDetails } = useVideos();
  const lang = useSelector(selectCMSLang);
  const userType = useSelector(selectUserType);

  const filteredStatuses = STATUSES.filter((status) => {
    if (userType === 3) {
      return status.value !== 3;
    }

    return true;
  });

  return (
    <div className="VideosPreview">
      <Formik
        validationSchema={Yup.object().shape({
          title: Yup.string()
            .required('Pole jest wymagane')
            .max(70, 'Maksymalna ilość znaków to 70 '),
          video_url: Yup.string().required('Pole jest wymagane'),
          description: Yup.string()
            .required('Pole jest wymagane')
            .max(1000, 'Maksymalna ilość znaków to 1000')
        })}
        enableReinitialize
        initialValues={{
          title: video.title,
          video_url: video.video_url,
          description: video.description,
          publish_status: video.publish_status
        }}
        onSubmit={({ status, ...values }, formik) => {
          if (!!video.slug) {
            return updateVideoDetails({
              ...values,
              lang_code: lang,
              slug: video.slug
            });
          }

          return addNewVideo({
            title: values.title,
            video_url: values.video_url,
            description: values.description,
            publish_status: values.publish_status,
            lang_code: lang
          }).finally(() => {
            formik.resetForm();
          });
        }}
        validateOnSubmit
        render={({
          values,
          handleSubmit,
          handleChange,
          setFieldValue,
          errors
        }) => (
          <Row>
            <Col xs={12}>
              <div className="VideosPreview__playback">
                {values.video_url ? (
                  <VideoEmbed mediaURL={values.video_url} />
                ) : (
                  <div className="VideosPreview__noVideo">
                    <NoVideoIcon />
                  </div>
                )}
              </div>
            </Col>
            <Col xs={12}>
              <Form onSubmit={handleSubmit} className="row" noValidate>
                <Col xs={12}>
                  <FormGroup>
                    <Label for="video_url">URL</Label>
                    <Input
                      type="text"
                      name="video_url"
                      required
                      value={values.video_url}
                      disabled={!!video.slug}
                      onChange={handleChange}
                      invalid={!!errors.video_url}
                      autoComplete="off"
                    />
                    <FormFeedback>{errors.video_url}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Label for="title">Tytuł filmu</Label>
                    <Input
                      type="text"
                      name="title"
                      required
                      value={values.title}
                      onChange={handleChange}
                      invalid={!!errors.title}
                      autoComplete="off"
                    />
                    <FormFeedback>{errors.title}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Label for="publish_status">Status</Label>
                    <Select
                      name="publish_status"
                      value={getStatusValue(values.publish_status)}
                      options={filteredStatuses}
                      onChange={({ value }) => {
                        setFieldValue('publish_status', value);
                      }}
                    />
                    <FormFeedback>{errors.lang_code}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <Label for="description">Opis</Label>
                    <Input
                      type="textarea"
                      name="description"
                      required
                      value={values.description}
                      onChange={handleChange}
                      invalid={!!errors.description}
                      autoComplete="off"
                      rows="6"
                    />
                    <FormFeedback>{errors.description}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs={12} className="VideosPreview__actions">
                  <Button color="primary">
                    {video.slug ? 'Zapisz zmiany' : 'Zapisz nowe'}
                  </Button>
                  {!!video.slug && (
                    <Button
                      color="info"
                      onClick={() =>
                        setVideo({
                          slug: '',
                          title: '',
                          video_url: '',
                          description: '',
                          lang_code: 'PL'
                        })
                      }
                    >
                      Dodaj nowe wideo
                    </Button>
                  )}
                </Col>
              </Form>
            </Col>
          </Row>
        )}
      />
    </div>
  );
}

export default VideosPreview;
