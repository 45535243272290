import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const ListElement = ({ onRemove, children, currentIndex }) => {
  return (
    <div>
      <div>
        {currentIndex !== 0 && (
          <FontAwesomeIcon icon={faTrashAlt} onClick={onRemove} />
        )}
      </div>
      {children}
    </div>
  );
};

export default ListElement;
