export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const SET_USER_API_DATA = 'SET_USER_API_DATA';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_TOKEN_COOKIE = 'SET_TOKEN_COOKIE';
export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const FETCH_USER_LOGS = 'FETCH_USER_LOGS';
export const FETCH_USERS = 'FETCH_USERS';
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const FETCH_USER_EDIT = 'FETCH_USER_EDIT';
export const SET_USER_DATA_EDIT = 'SET_USER_DATA_EDIT';
export const EDIT_USER_DATA = 'EDIT_USER_DATA';
export const RESET_REQ = 'RESET_REQ';
