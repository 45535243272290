import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  InputGroup,
  Input,
  FormGroup
} from "reactstrap";
import { avblLangs } from "../../utilities/locale";
import { switchLang } from "../../services/redux/reducers/settings/actionCreators";

class LangSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      value: this.props.lang
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value }, this.changeLang);
  }

  changeLang = () => {
    this.props.switchLang(this.state.value);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      // this.props.history.go();
      this.redirect();
    }
  }

  redirect = () => {
    const {
      articles: { data, selectedArticle },
      events: { selectedEvent },
      polls: { selected: selectedPoll },
      lang
    } = this.props;

    console.log(selectedArticle);
    // const selectedArticle = data.length ? data[0].id : -1;

    if (selectedArticle > -1) {
      const {
        articles: { translations }
      } = this.props;
      let article = translations.find(item => item.lang_code === lang);
      console.log("translate article");
      if (article) {
        window.location = `/articles/${article.id}`;
        return;
      } else {
        window.location = `${window.location.pathname}?lang=${lang}&group=${translations[0].grouping_id}`;
        console.log(window.location);
        return;
      }
    }

    if (selectedEvent > -1) {
      const {
        events: { translations }
      } = this.props;
      let event = translations.find(item => item.lang_code === lang);
      if (event) {
        window.location = `/events/${event.id}`;
        return;
      } else {
        window.location = `${window.location.pathname}?lang=${lang}&group=${translations[0].event}`;
        return;
      }
    }

    if (selectedPoll > -1) {
      const {
        polls: { translations }
      } = this.props;
      let poll = translations.find(item => item.lang_code === lang);
      if (poll) {
        window.location = `/polls/${poll.id}`;
        return;
      } else {
        window.location = `${window.location.pathname}?lang=${lang}&group=${translations[0].grouping_id}`;
        return;
      }
    }

    this.props.history.go();
  };

  render() {
    return (
      <div>
        <InputGroup size="sm">
          <Input
            type="select"
            name="select"
            value={this.state.value}
            onChange={this.handleChange}
          >
            {avblLangs
              ? avblLangs.map((item, index) => (
                  <option value={item.lang_code} key={index}>
                    {item.name}
                  </option>
                ))
              : null}
          </Input>
        </InputGroup>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    articles: state.articles,
    events: state.events,
    polls: state.polls,
    lang: state.settings.lang
  };
}

export default connect(
  mapStateToProps,
  { switchLang }
)(withRouter(LangSwitch));
