import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import API from '../../services/api';
import { calculateMaxPages } from '../../utilities/calculates';
import { userType } from '../../utilities/user';
import { DeleteButton } from '../FunctionalButtons/DeleteButton';

class EventUserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: null,
      loading: true,
      tableState: ''
    };
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData(state, instance) {
    this.setState({ loading: true, tableState: state });

    const { token, eventid } = this.props;

    let url = `/api/v1/events/${eventid}/participants/`;

    API.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(({ data }) => {
      this.setState({
        data: data.results,
        pages: calculateMaxPages(data.count, state.pageSize),
        loading: false
      });
    });
  }

  deleteUser(userid) {
    const { token, eventid } = this.props;
    let url = `/api/v1/events/${eventid}/participants/${userid}/?participant_type=3`;
    console.log(url);
    API.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(({ data }) => {
      console.log(data);
      this.fetchData(this.state.tableState);
    });
  }

  render() {
    const { data, pages } = this.state;
    return (
      <div className="table-responsive">
        <ReactTable
          columns={[
            {
              Header: 'Lp.',
              Cell: row => {
                return <div>{row.index + 1 + row.page * row.pageSize}</div>;
              },
              filterable: false,
              maxWidth: 40
            },
            {
              Header: 'Adres e-mail',
              accessor: 'user.email'
            },
            {
              Header: 'Typ użytkownika',
              accessor: 'user.user_type',
              Cell: d => {
                return userType(d.original.user.user_type);
              }
            },
            {
              Header: 'Akcje',
              Cell: d => {
                return (
                  <>
                    <DeleteButton
                      callback={() => this.deleteUser(d.original.user.id)}
                    />
                  </>
                );
              },
              maxWidth: 300
            }
          ]}
          manual
          data={data}
          pages={pages}
          filterable
          resizable={false}
          onFetchData={this.fetchData}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { user } = state;
  return {
    token: user.token.key
  };
}

export default connect(mapStateToProps)(EventUserList);
