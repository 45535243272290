export const NODE_TYPES = {
  P: 'paragraph',
  H2: 'headingTwo',
  H3: 'headingThree',
  BLOCKQUOTE: 'blockquote',
  LIST_ITEM: 'list-item',
  OL_LIST: 'orderedList',
  UL_LIST: 'unorderedList',
  LINK: 'link',
  ARTICLE_LINK: 'article-link',
  IMAGE: 'image',
  VIDEO: 'video',
  GRID_IMAGES: 'gridImages',
  DISCLAIMER: 'disclaimer',
  SEPARATOR: 'separator'
}

export const LIST_TYPES = [NODE_TYPES.OL_LIST, NODE_TYPES.UL_LIST]
export const TEXT_ALIGN_TYPES = ["alignLeft", "alignRight", "alignCenter"]
