import React, { useState } from 'react'
import { Button, FormGroup, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { InputField } from '../../../Inputs/InputField.jsx'
import IconButton from '../../common/Button.jsx'
import Icon from '../../common/Icon'
import { SelectPhotoForArticle } from '../../../Articles/select-photo.jsx'
import { useFormik } from 'formik'
import { insertBlockquote } from '../../utils/blockquote.js'
import { useSlateStatic } from 'slate-react'

const INTIAL_MODEL = {
  text: '',
  name: '',
  description: '',
  photo: ''
}

const BlockquoteEdit = ({ modalOpened, toggle }) => {
  const editor = useSlateStatic()
  const handleClose = () => {
    toggle()
  }
  const [errors, setErrors] = useState({})

  const { values, handleChange, setFieldValue, handleSubmit, resetForm } = useFormik({
    initialValues: {...INTIAL_MODEL},
    onSubmit: (values) => {
      setErrors({})

      if (!values.text) {
        setErrors({ text: 'Pole wymagane' })
        return
      }

      if (values.name || values.description || values.photo) {
        const allValid = [values.name, values.description].every(v => !!v)
        if (!allValid) {
          setErrors({ author: 'Uzupełnij wszystkie pola autora lub dodaj sam cytat' })
          return
        }
      }
      const { name, description, text, photo } = values
      insertBlockquote(editor, { name, description, text, photo: { id: photo.id, url: photo.file } })
      toggle()
      resetForm()
    }
  })

  return (
    <Modal
      isOpen={modalOpened}
      toggle={handleClose}
    >
      <ModalHeader
        toggle={handleClose}
      >
      </ModalHeader>
      <ModalBody>
        <div style={{ margin: '1rem 0' }}>
          <FormGroup>
            <InputField
              label="Treść cytatu"
              type="textarea"
              name="text"
              value={values.text}
              onChange={handleChange}
              invalid={!!errors.text}
              validation={errors.text}
            >
            </InputField>
          </FormGroup>
          <FormGroup>
            <InputField
              label="Autor cytatu"
              type="text"
              name="name"
              id="name"
              value={values.name}
              onChange={handleChange}
              invalid={!!errors.author}
              validation={errors.author}
            />
          </FormGroup>
          <FormGroup>
            <InputField
              label="Autor opis"
              type="text"
              name="description"
              id="description"
              value={values.description}
              onChange={handleChange}
              invalid={!!errors.author}
              validation={errors.author}
            />
          </FormGroup>
          <SelectPhotoForArticle
            label="Zdjęcie autora"
            value={values.photo}
            setFieldValue={(value) => setFieldValue('photo', value)}
          />
          <Button type="button" onClick={handleSubmit}>Dodaj cytat</Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

const BlockquoteButton = (props) => {
  const [modalOpened, setModalOpen] = useState(false)

  const handleClick = () => {
    setModalOpen(true)
  }

  const toggle = () => {
    setModalOpen(!modalOpened)
  }

  return (
    <>
      <IconButton
        format="blockquote"
        onClick={handleClick}
      >
        <Icon icon="blockquote" />
      </IconButton>
      <BlockquoteEdit
        modalOpened={modalOpened}
        toggle={toggle}
      />
    </>
  )
}

export default BlockquoteButton
