import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  createPoll,
  createPossibility,
  setPollId,
  updatePoll,
  resetPoll,
  deletePossibility,
  updatePossibility,
  fetchPollTranslations
} from "../../services/redux/reducers/polls/actionCreators";
import Loading from "../../common/Loading";
import { PollFormBody } from "./PollFormBody";
import { getQueryStringValue } from "../../utilities/history";
import { generateCSV, exportPollResults } from "../../utilities/export";

class PollForm extends Component {
  constructor(props) {
    super(props);
    const { pollId, lang } = this.props;

    const lang_code = getQueryStringValue("lang") || lang;
    const grouping_id = getQueryStringValue("group");
    const initialValues = {
      title: "",
      possibilities: [],
      published_at: new Date(),
      lang_code
    };
    if (grouping_id) initialValues.grouping_id = grouping_id;

    this.state = {
      loaded: !pollId,
      editMode: !!pollId,
      translationMode: !!grouping_id,
      initialValues,
      pollId
    };

    this.submitForm = this.submitForm.bind(this);
    this.convertValues = this.convertValues.bind(this);
    this.reviewPossibilities = this.reviewPossibilities.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
  }

  componentDidMount() {
    const { editMode, pollId, loaded } = this.state;
    const {
      poll: { selected }
    } = this.props;
    if (!loaded && pollId && selected < 0) {
      this.props.setPollId(pollId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      poll: { data, selected },
      fetchPollTranslations
    } = this.props;
    const { loaded, editMode, translationMode } = this.state;

    if (!loaded && !prevProps.poll.data && data) {
      this.setState({
        loaded: true,
        initialValues: this.convertValues(data)
      });
      fetchPollTranslations(data.grouping_id);
    }

    if (!editMode && prevProps.poll.selected < 0 && selected > 0) {
      this.props.history.push(`/polls/${selected}`);
    }
    if (
      translationMode &&
      prevProps.poll.selected > 0 &&
      prevProps.poll.selected !== selected
    ) {
      this.props.history.push(`/polls/${selected}`);
      window.location.reload();
    }
  }

  componentWillUnmount() {
    this.props.resetPoll();
  }

  convertValues(values) {
    const { initialValues, editMode } = this.state;
    if (initialValues.grouping_id) {
      delete values.id;
      delete values.lang_code;
    }
    delete values.voted;
    delete values.updated_at;
    return {
      ...initialValues,
      ...values,
      published_at: new Date(values.published_at)
    };
  }

  reviewPossibilities(possibilities) {
    const { updatePossibility, createPossibility } = this.props;
    const { initialValues, translationMode } = this.state;
    possibilities.forEach((item, index) => {
      const { id, answer, grouping_id } = item;
      if (!translationMode && id) {
        if (answer !== initialValues.possibilities[index].answer)
          updatePossibility(id, { answer });
      } else {
        createPossibility(index, {
          answer,
          grouping_id,
          lang_code: initialValues.lang_code
        });
      }
    });
  }

  submitForm(values) {
    const { createPoll, updatePoll } = this.props;
    const data = { ...values };
    if (data.id) {
      const { possibilities } = data;
      this.reviewPossibilities(possibilities);
      delete data.possibilities;
    }
    if (data.id) {
      updatePoll(data);
    } else {
      createPoll(data);
    }
  }

  downloadCSV() {
    const { pollId } = this.state;

    exportPollResults(pollId)
      .then(response => {
        generateCSV(response);
      })
      .catch(error => {
        this.setState({
          error: true
        });
      });
  }

  render() {
    const { initialValues, loaded } = this.state;
    const { deletePossibility } = this.props;
    return (
      <>
        {loaded ? (
          <PollFormBody
            onSubmit={this.submitForm}
            deletePossibility={deletePossibility}
            initialValues={initialValues}
            downloadCSV={this.downloadCSV}
          />
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ polls, settings }) => ({
  poll: polls,
  lang: settings.lang
});

export default connect(
  mapStateToProps,
  {
    createPoll,
    updatePoll,
    setPollId,
    createPossibility,
    resetPoll,
    deletePossibility,
    updatePossibility,
    fetchPollTranslations
  }
)(withRouter(PollForm));
