import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  createEvent,
  fetchEvent,
  setEventId,
  resetReq,
  setEventStatus,
  updateUserDesc,
  removeEventUser
} from '../../services/redux/reducers/events/actionCreators';
import {
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Col,
  Card,
  CardBody,
  Tooltip
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import { Formik } from 'formik';
import { MapPicker, Lectures } from '../../common/Inputs';
import SelectMedia from '../Media/selectMedia';
import Loading from '../../common/Loading';
import MediaItem from '../../common/Media/item';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { frontLink } from '../../services/api';
import { cloneDeep } from 'lodash';
import { getQueryStringValue } from '../../utilities/history';
import InvitedUsersList from '../EventUsers/InvitedUsersList';
import TextEditor from '../../common/Editor';
import * as Yup from "yup";

class EventForm extends Component {
  constructor(props) {
    super(props);
    const { eventid, settedlang } = this.props;
    let editMode = this.props.events.selectedEvent > -1;
    let loaded = false;

    let initialValues = {
      title: '',
      agenda: '',
      started_at: new Date(),
      ended_at: new Date(),
      description: '',
      location_title: '',
      location_street: '',
      location_street_details: '',
      latitude: '',
      longitude: '',
      thumb_id: '',
      medias_list: [],
      prelegents: [
        // { first_name: '', last_name: '', thumbnail: '', description: '' }
      ],
      lang_code: settedlang,
      telephone_required: false,
      registration_allowed: false,
      invitation_required: false,
      invite: '',
      email_intro: '',
      email_content: '',
      place_name: ''
    };
    let lang = getQueryStringValue('lang');
    let langGroup = getQueryStringValue('group');
    let blockedInputs = [];
    if (langGroup) {
      initialValues.event = langGroup;
      initialValues.lang_code = lang.toUpperCase();
      blockedInputs = [
        'started_at',
        'ended_at',
        'thumb_id',
        'medias_list',
        'prelegents',
        'latitude',
        'longitude',
        'mapPicker'
      ];
    }
    if (eventid && !langGroup) {
      editMode = true;
    }
    this.state = {
      editMode,
      loaded,
      initialValues,
      startDate: new Date(),
      endDate: new Date(),
      blockedInputs,
      langGroup
    };
    this.updatePrelegentDesc.bind(this);
  }

  componentDidMount() {
    if (
      (!this.state.loaded && this.state.editMode) ||
      (!this.state.loaded && this.state.langGroup)
    ) {
      this.props.fetchEvent(this.props.eventid);
    }
  }

  getGroupLangIDs() {
    const {
      events: { translations }
    } = this.props;
    if (translations) {
      this.setState({
        addedLangs: translations.map(item => ({
          id: item.id,
          lang: item.lang_code
          // name: avblLangs.find(lang => lang.lang_code === item.lang_code)
        }))
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { loaded, langGroup, editMode, initialValues } = this.state;
    if (!loaded && this.props.events.data.length && (editMode || langGroup)) {
      let data = cloneDeep(this.props.events.data[0]);
      if (!langGroup && data.lang_code !== this.props.settedlang) {
        window.location = '/events';
      }
      data.thumb_id = data.thumb_id ? data.thumb_id : '';
      if (langGroup) {
        delete data.lang_code;
        delete data.publish_status;
        delete data.prelegents;
      }
      // if (data.prelegents.length && editMode) {
      //   data.prelegents.forEach(
      //     (item, index) => (data.prelegents[index].edit = true)
      //   );
      // }
      if (data.email_content) {
        data.email_content = data.email_content.replace(/<br\s?\/?>/g,"\n")
      }
      if (data.email_intro) {
        data.email_intro = data.email_intro.replace(/<br\s?\/?>/g,"\n")
      }
      this.setState({
        loaded: true,
        initialValues: {
          ...initialValues,
          ...data
        },
        startDate: new Date(data.started_at),
        endDate: new Date(data.ended_at)
      });
    }
    if (
      !prevProps.events.translations.length &&
      this.props.events.translations.length
    ) {
      this.getGroupLangIDs();
    }
  }

  handleChange(date, propName) {
    this.setState({
      [propName]: date
    });
    if (propName === 'startDate') {
      this.setState({
        endDate: date
      });
    }
  }

  submitHandler(e) {
    e.preventDefault();
  }

  componentWillUnmount() {
    const { setEventId, resetReq } = this.props;
    setEventId(-1);
    resetReq();
  }

  updatePrelegentDesc({ description, user: { id }, add }) {
    const { eventid, updateUserDesc } = this.props;
    updateUserDesc(eventid, id, { description, participant_type: 2 }, add);
  }

  newEvent = React.createRef();

  render() {
    const { eventid, validation, userType, uploaded } = this.props;
    const {
      editMode,
      loaded,
      initialValues,
      endDate,
      blockedInputs,
      langGroup
    } = this.state;

    if (!editMode && uploaded) {
      return <Redirect to={`/events`} />;
    }

    const showContentInput = (editMode && loaded) || true

    return (
      <div>
        {(!editMode && !langGroup) ||
        (editMode && loaded) ||
        (langGroup && loaded) ? (
          <Formik
            validationSchema={Yup.object().shape({
              latitude: Yup.string().required("Pole jest wymagane"),
              location_street: Yup.string().required("Pole jest wymagane"),
              location_title: Yup.string().required("Pole jest wymagane"),
              longitude: Yup.string().required("Pole jest wymagane"),
              title: Yup.string().required("Pole jest wymagane"),
              email_intro: Yup.string().when('invitation_required', {
                is: true,
                then: Yup.string().required("Pole jest wymagane"),
                otherwise: Yup.string()
              }),
              // email_content: Yup.string().when('invitation_required', {
              //   is: true,
              //   then: Yup.string().required("Pole jest wymagane"),
              //   otherwise: Yup.string()
              // }),
              place_name: Yup.string().when('invitation_required', {
                is: true,
                then: Yup.string().required("Pole jest wymagane"),
                otherwise: Yup.string()
              }),
              invite: Yup.string().when('publish_status', {
                is: 3,
                then: Yup.string().matches(/^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/, 'Wpisz poprawne adresy'),
                otherwise: Yup.string()
              })
            })}
            initialValues={initialValues}
            ref={this.newEvent}
            enableReinitialize={true}
            onSubmit={values => {
              let data = cloneDeep(values);
              const { thumb_id, medias_list, prelegents, invite } = data;
              if (medias_list.length) {
                data.medias_list = medias_list.map(item => item.id);
              }
              if (thumb_id) {
                data.thumb_id = thumb_id.id;
              } else {
                data.thumb_id = null;
              }
              if (invite) {
                data.invite = data.invite
                  .replace(' ', '')
                  .split(';')
                  .filter(item => item);
              } else {
                delete data.invite;
              }
              if (!data.ended_at) {
                data.ended_at = endDate;
              }
              if (blockedInputs.length) {
                blockedInputs.forEach(element => {
                  if (data.hasOwnProperty(element)) {
                    delete data[element];
                  }
                });
              }
              if (data.email_content) {
                data.email_content = data.email_content.replace(/\r\n|\r|\n/g,"<br />")
              }
              if (data.email_intro) {
                data.email_intro = data.email_intro.replace(/\r\n|\r|\n/g,"<br />")
              }
              if (!editMode) {
                this.props.createEvent(data);
              } else {
                this.props.createEvent(data, eventid);
              }
            }}
            render={({ values, handleSubmit, handleChange, setFieldValue, touched, errors }) => (
              <Form className="row pb-4" onSubmit={handleSubmit} noValidate>
                <Col sm="8">
                  <FormGroup>
                    <Label for="title">Tytuł</Label>
                    <Input
                      type="text"
                      name="title"
                      id="title"
                      required
                      value={values.title}
                      onChange={handleChange}
                      invalid={touched.title && errors.title || !!validation.title}
                    />
                    <FormFeedback>{touched.title && errors.title ||validation.title}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="date_start">Data rozpoczęcia</Label> <br />
                    <DatePicker
                      showTimeSelect
                      selected={this.state.startDate}
                      onChange={date => {
                        setFieldValue('started_at', date);
                        setFieldValue('ended_at', date);
                        this.handleChange(date, 'startDate');
                      }}
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeCaption="time"
                      minDate={new Date()}
                      invalid={!!validation.started_at}
                      disabled={blockedInputs.includes('started_at')}
                    />
                    <FormFeedback>{validation.started_at}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="date_start">Data zakończenia</Label> <br />
                    <DatePicker
                      showTimeSelect
                      selected={this.state.endDate}
                      onChange={date => {
                        setFieldValue('ended_at', date);
                        this.handleChange(date, 'endDate');
                      }}
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeCaption="time"
                      minDate={this.state.startDate}
                      invalid={!!validation.ended_at}
                      disabled={blockedInputs.includes('ended_at')}
                    />
                    <FormFeedback>{validation.ended_at}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="content">Treść wydarzenia</Label>
                    {showContentInput && <TextEditor
                      content={values.description}
                      setEditorValue={content => {
                        setFieldValue('description', content);
                      }}
                    />}
                    <Input type="hidden" invalid={!!validation.description} />
                    <FormFeedback>{validation.description}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="content">Agenda wydarzenia</Label>
                    {showContentInput && <TextEditor
                      content={values.agenda}
                      setEditorValue={content => {
                        setFieldValue('agenda', content);
                      }}
                    />}
                    <Input type="hidden" invalid={!!validation.agenda} />
                    <FormFeedback>{validation.agenda}</FormFeedback>
                  </FormGroup>
                  <Lectures
                    setFieldValue={setFieldValue}
                    eventid={eventid}
                    editMode={editMode}
                    values={values.prelegents}
                    handleChange={handleChange}
                    removeLecture={this.props.removeEventUser}
                  />
                  <FormGroup>
                    <Label for="summary">
                      <FormattedMessage
                        id="article.excerpt"
                        defaultMessage="Zajawka"
                      />
                    </Label>
                    <Input
                      type="textarea"
                      name="summary"
                      id="summary"
                      value={values.summary}
                      onChange={handleChange}
                      invalid={!!validation.summary}
                    />
                    <Input type="hidden" invalid={!!validation.summary} />
                  </FormGroup>
                  <fieldset disabled={blockedInputs.includes('mapPicker')}>
                    <FormGroup>
                      <Label>Wybierz miejsce wydarzenia</Label>
                      <Input
                        type="hidden"
                        invalid={(touched.latitude && errors.latitude || validation.latitude) || (touched.longitude && errors.longitude || validation.longitude)}
                      />
                      <MapPicker
                        disabled={blockedInputs.includes('mapPicker')}
                        latlng={
                          editMode || langGroup
                            ? {
                                lat: values.latitude,
                                lng: values.longitude
                              }
                            : null
                        }
                        setLangLong={latlng => {
                          setFieldValue('latitude', latlng.lat);
                          setFieldValue('longitude', latlng.lng);
                        }}
                        setPlaceDetails={place => {
                          setFieldValue('latitude', place.lat);
                          setFieldValue('longitude', place.lon);
                          setFieldValue('location_street', place.display_name);
                          if (!values.place_name) {
                            setFieldValue('place_name', place.address.city || '')
                          }
                        }}
                      />
                      <FormFeedback>
                        Proszę wybrać miejsce wydarzenia
                      </FormFeedback>
                    </FormGroup>
                  </fieldset>
                  <FormGroup>
                    <Label for="location_title">Tytuł lokalizacji</Label>
                    <Input
                      type="text"
                      name="location_title"
                      id="location_title"
                      required
                      value={values.location_title}
                      onChange={handleChange}
                      invalid={touched.location_title && errors.location_title || !!validation.location_title}
                    />
                    <FormFeedback>{touched.location_title && errors.location_title || validation.location_title}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="location_street">Adres lokalizacji</Label>
                    <Input
                      type="text"
                      name="location_street"
                      id="location_street"
                      required
                      value={values.location_street}
                      onChange={handleChange}
                      invalid={touched.location_street && errors.location_street || !!validation.location_street}
                    />
                    <FormFeedback>{touched.location_street && errors.location_street || validation.location_street}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="location_street_details">
                      Szczegóły lokalizacji
                    </Label>
                    <Input
                      type="text"
                      name="location_street_details"
                      id="location_street_details"
                      required
                      value={values.location_street_details}
                      onChange={handleChange}
                      invalid={!!validation.location_street_details}
                    />
                    <FormFeedback>
                      {validation.location_street_details}
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="location_url">
                      <FormattedMessage
                        id="article.excerpt"
                        defaultMessage="Webinar"
                      />
                    </Label>
                    <Input
                      type="url"
                      name="location_url"
                      id="location_url"
                      value={values.location_url}
                      onChange={handleChange}
                      invalid={!!validation.location_url}
                      placeholder="http://link.pl"
                    />
                    <Input type="hidden" invalid={!!validation.location_url} />
                  </FormGroup>
                  {/* Thumb */}
                  <FormGroup>
                    <Label>Zdjęcie wyróżniające</Label>
                    <Input type="hidden" invalid={!!validation.thumb_id} />
                    {values.thumb_id ? (
                      // <MediaList list={[values.media.thumb]} />
                      <div className="d-flex align-items-start justify-content-between">
                        <MediaItem medium={values.thumb_id} />
                        <Button onClick={() => setFieldValue('thumb_id', '')}>
                          X
                        </Button>
                      </div>
                    ) : null}
                    <SelectMedia
                      selectType="thumb"
                      defaultFiter="image"
                      filesList={[values.thumb_id]}
                      selectedFile={file => {
                        setFieldValue('thumb_id', file);
                      }}
                    />
                    <FormFeedback>{validation.thumb_id}</FormFeedback>
                  </FormGroup>
                  {/* Media */}
                  <FormGroup>
                    <Label>Dodatkowe media</Label>
                    <Input type="hidden" invalid={!!validation.medias_list} />
                    {values.medias_list
                      ? values.medias_list.map((item, index) => (
                          <div
                            className="d-flex align-items-start justify-content-between"
                            key={`article_media_${item.id}`}
                          >
                            <MediaItem medium={item} />
                            <Button
                              onClick={() => {
                                let { medias_list } = values;
                                medias_list.splice(index, 1);
                                setFieldValue('medias_list', medias_list);
                                return false;
                              }}
                            >
                              X
                            </Button>
                          </div>
                        ))
                      : null}
                    <SelectMedia
                      selectType="files"
                      filesList={values.medias_list}
                      selectedFile={file => {
                        setFieldValue('medias_list', [
                          ...values.medias_list,
                          file
                        ]);
                      }}
                    />
                    <FormFeedback>{validation.medias_list}</FormFeedback>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="registration_allowed"
                        onChange={handleChange}
                        checked={values.registration_allowed}
                        value={values.registration_allowed}
                      />
                      Włączone zapisy na wydarzenia
                    </Label>
                    <FormFeedback>
                      {validation.registration_allowed}
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="telephone_required"
                        onChange={handleChange}
                        checked={values.telephone_required}
                        value={values.telephone_required}
                      />
                      Wymagany numer telefonu podczas zapisów
                    </Label>
                    <FormFeedback>{validation.telephone_required}</FormFeedback>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="invitation_required"
                        onChange={handleChange}
                        checked={values.invitation_required}
                        value={values.invitation_required}
                        />
                      Wydarzenie tylko dla zaproszonych
                    </Label>
                    <FormFeedback>
                      {validation.invitation_required}
                    </FormFeedback>
                  </FormGroup>
                  {values.invitation_required ? (
                    <>
                      {values.publish_status === 3 ? (
                        <>
                          <FormGroup className="mt-3">
                            <Label>Zaproś użytkowników</Label>
                            <Input
                              type="textarea"
                              name="invite"
                              onChange={handleChange}
                              placeholder="Wpisuj adresy email po średniku, np: jan.kowalski@gmail.com;jan.kowalski2@gmail.com;jan.kowalski3@gmail.com"
                              value={values.invite}
                              invalid={touched.invite && errors.invite}
                            />
                            <FormFeedback>{touched.invite && errors.invite}</FormFeedback>
                          </FormGroup>
                          <div className="mb-2">
                            <InvitedUsersList eventId={eventid} />
                          </div>
                        </>
                      ) : null}
                      <FormGroup>
                        <Label>Miejscowość w zaproszeniu</Label>
                        <Input
                          type="text"
                          name="place_name"
                          onChange={handleChange}
                          value={values.place_name}
                          invalid={touched.place_name && errors.place_name || !!validation.place_name}
                        />
                        <FormFeedback>{touched.place_name && errors.place_name || validation.place_name}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>Email zaproszenie intro</Label>
                        <Input
                          type="textarea"
                          name="email_intro"
                          rows="8"
                          onChange={handleChange}
                          value={values.email_intro}
                          invalid={touched.email_intro && errors.email_intro || !!validation.email_intro}
                        />
                        <FormFeedback>{touched.email_intro && errors.email_intro || validation.email_intro}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>Email zaproszenie treść</Label>
                        <Input
                          type="textarea"
                          name="email_content"
                          rows="8"
                          onChange={handleChange}
                          value={values.email_content}
                          invalid={touched.email_content && errors.email_content || !!validation.email_content}
                        />
                        <FormFeedback>{touched.email_content && errors.email_content || validation.email_content}</FormFeedback>
                      </FormGroup>
                    </>
                  ) : null}
                </Col>
                <Col sm="4">
                  <Card className="card--sticky">
                    <CardBody>
                      <FormGroup>
                        <Label for="publish_status">Status</Label>
                        <Input
                          type="select"
                          name="publish_status"
                          id="publish_status"
                          value={values.publish_status}
                          onChange={handleChange}
                        >
                          <option value="1">Szkic</option>
                          <option value="2">
                            Do publikacji, czekający na akcept
                          </option>
                          {userType === 1 || userType === 2 ? (
                            <option value="3">
                              Opublikowany i zaakceptowany
                            </option>
                          ) : null}
                        </Input>
                      </FormGroup>
                      <Button
                        disabled={!eventid}
                        type="button"
                        onClick={() =>
                          window.open(
                            `${frontLink()}events/${initialValues.slug}`,
                            '_blank' // <- This is what makes it open in a new window.
                          )
                        }
                        id="previewTooltip"
                      >
                        Podgląd
                      </Button>
                      {!eventid ? (
                        <Tooltip
                          placement="bottom"
                          isOpen={this.state.tooltipOpen}
                          target="previewTooltip"
                          toggle={this.toggle}
                        >
                          W celu zobaczenia podglądu zapisz szkic artykułu
                        </Tooltip>
                      ) : null}
                      {editMode ? (
                        <Button type="submit" color="primary">
                          Zapisz zmiany
                        </Button>
                      ) : (
                        <Button type="submit" color="primary">
                          Zapisz
                        </Button>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Form>
            )}
          />
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { events, eventsRequest, user, settings } = state;
  return {
    events,
    validation: eventsRequest.errorBody,
    uploaded: eventsRequest.uploaded,
    userType: user.user_type,
    settedlang: settings.lang
  };
}

export default connect(
  mapStateToProps,
  {
    createEvent,
    fetchEvent,
    setEventId,
    resetReq,
    setEventStatus,
    updateUserDesc,
    removeEventUser
  }
)(EventForm);
