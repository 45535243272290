import React from 'react';
import { getComponent } from 'agronomist-ui-components';

const PageComponent = ({ name, props }) => {
  const Element = getComponent(name);

  if (Element) {
    return <Element {...props} />;
  }

  return null;
};

const PagePreview = ({ page, language }) => {
  return (
    <>
      {page[language].map((component) => (
        <PageComponent
          key={`preview_${component.id}_${language}`}
          name={component.type}
          props={component.props}
        />
      ))}
    </>
  );
};

export default PagePreview;
