import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import API from '../../services/api';

const URLS = {
  CATEGORIES: '/api/v1/article-categories/',
}

const CategoryType = {
  AGRONOMIST: 1,
  AGRORAPORT: 2
};

export function useCategories() {
  const [categories, setCategories] = useState([])
  const token = useSelector((state) => state.user.token.key)

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await API.get(
          URLS.CATEGORIES,
          {
            params: {
              membership: CategoryType.AGRONOMIST,
              limit: 999
            },
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        setCategories(data.results)
      } catch (e) {
        console.error(e)
      }
    }

    fetchCategories()
  }, [])

  return {
    categories,
    flattenCategories: () => {
      return categories.reduce((res, cat) => {
        const { subcategories, ...rest } = cat
        res.push(rest)
        if (subcategories && subcategories.length) {
          res.push(...subcategories.map(s => ({
            ...s,
            isSubCategory: true
          })))
        }
        return res
      }, [])
    }
  }
}
