import React, { Component } from 'react';
import { FormGroup, FormFeedback, Label, Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import dayjs from 'dayjs';

function getRoundedCurrentTime() {
  const currentTime = dayjs();
  const roundedTime = currentTime.subtract(currentTime.minute() % 5, 'minute').startOf('minute');
  return roundedTime
}

export const DatepickerField = props => {
  const { label, validation } = props;

  const onChange = (_date) => {
    if (props.value === null) {
      const date = dayjs(_date)
      if (date.format('HHmm') === '0000') {
        const currentTime = getRoundedCurrentTime()
        const nDate = dayjs(date)
          .set('hour', currentTime.get('hour'))
          .set('minute', currentTime.get('minute'))
          .set('second', currentTime.get('second'))

        props.onChange(nDate.toDate())
        return
      }
    }

    props.onChange(_date)
  }

  return (
    <FormGroup>
      <Label for={props.name}>{label}</Label> <br />
      <DatePicker
        name={props.name}
        showTimeSelect
        selected={props.value}
        onChange={onChange}
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat="MMMM d, yyyy h:mm aa"
        timeCaption="time"
      />
      <Input
        type="hidden"
        required
        value={props.value}
        invalid={!!validation}
      />
      <FormFeedback>{validation}</FormFeedback>
    </FormGroup>
  );
};

// export default InputField;
