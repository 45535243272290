import {
  takeLatest,
  call,
  put,
  select,
  takeEvery
  // all
} from '@redux-saga/core/effects';

import {
  alertFailure,
  alertSuccess,
  clearAlert
} from '../../../services/redux/utils/mapResponse';
import api, { ResourceState } from '../api/prf.api';
import * as actions from './prf.actions';
import * as selectors from '../../../services/redux/reducers/selectors';

export function* fetchProducts() {
  const token = yield select(selectors.selectToken);
  yield put(actions.setProductsResourceState(ResourceState.FETCHING));
  try {
    const response = yield call(api.getProducts, { token });
    yield put(actions.setProducts(response.data));
    yield put(actions.setProductsResourceState(ResourceState.IDLE));
  } catch (e) {
    yield put(actions.setProductsResourceState(ResourceState.ERROR));
    yield put(alertFailure('Wystąpił błąd podczas pobierania listy produktów'));
  }
}

export function* addNewProduct({ names, titles, creator, slug }) {
  const token = yield select(selectors.selectToken);
  yield put(actions.setProductsActionsResourceState(ResourceState.FETCHING));
  try {
    const response = yield call(api.postProduct, {
      token,
      names,
      titles,
      creator,
      slug
    });
    yield put(actions.setProductsActionsResourceState(response.data));
    yield put(actions.setProductsActionsResourceState(ResourceState.IDLE));
    yield put(alertSuccess(`Dodano nowy produkt: ${names.pl}`));
    yield put(actions.fetchProducts());
  } catch (e) {
    yield put(actions.setProductsActionsResourceState(ResourceState.ERROR));
    yield put(alertFailure('Wystąpił błąd dodawania nowego produktu'));
  }
}

export function* removeProduct({ id }) {
  const token = yield select(selectors.selectToken);
  yield put(actions.setProductsActionsResourceState(ResourceState.FETCHING));
  try {
    const response = yield call(api.deleteProduct, { token, id });
    yield put(actions.setProductsActionsResourceState(response.data));
    yield put(actions.setProductsActionsResourceState(ResourceState.IDLE));
    yield put(actions.fetchProducts());
    yield put(alertSuccess('Produkt został pomyślnie usunięty'));
  } catch (e) {
    yield put(actions.setProductsActionsResourceState(ResourceState.ERROR));
    yield put(alertFailure('Wystąpił błąd podczas usuwania produktu'));
  }
}

export function* updateProduct({ product }) {
  const token = yield select(selectors.selectToken);
  yield put(actions.setProductsActionsResourceState(ResourceState.FETCHING));
  try {
    const response = yield call(api.patchProduct, { token, product });
    yield put(actions.setProductsActionsResourceState(response.data));
    yield put(actions.setProductsActionsResourceState(ResourceState.IDLE));
    yield put(actions.fetchProducts());
    yield put(alertSuccess('Produkt został zaktualizowany'));
  } catch (e) {
    yield put(actions.setProductsActionsResourceState(ResourceState.ERROR));
    yield put(alertFailure('Wystąpił błąd podczas aktualizacji produktu'));
  }
}

export default [
  takeLatest(actions.PRODUCTS_FETCH, fetchProducts),
  takeEvery(actions.PRODUCTS_ADD, addNewProduct),
  takeEvery(actions.PRODUCTS_REMOVE, removeProduct),
  takeEvery(actions.PRODUCTS_UPDATE, updateProduct)
];
