import { all } from 'redux-saga/effects';
import articleSaga from './articles/sagas';
import articleTypes from './articlesTypes/sagas';
import articleCategories from './articlesCategories/sagas';
import media from './media/sagas';
import userSagas from './user/sagas';
import eventsSaga from './events/sagas';
import polls from './polls/sagas';
import products from '../../../features/prf/state/prf.sagas';

export default function* rootSaga() {
  yield all([
    ...userSagas,
    ...articleSaga,
    ...articleTypes,
    ...articleCategories,
    ...media,
    ...eventsSaga,
    ...polls,
    ...products
  ]);
}
