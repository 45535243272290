import API from '../../../api';

export const fetchUserToken = (email, password) =>
  API.post('/api/v1/login/', {
    email,
    password
  });

export const fetchUserData = (token, id) =>
  API.get(`/api/v1/users/${id}/`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const fetchUserLogs = (token, id) =>
  API.get(`/api/v1/users/${id}/changelog/`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const fetchUsers = token =>
  API.get(`/api/v1/users/`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const createUser = (token, userData) =>
  API.post(`/api/v1/users/`, userData, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const removeUser = (token, id) =>
  API.delete(`/api/v1/users/${id}/`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const updateUser = (token, id, data) =>
  API.patch(`/api/v1/users/${id}/`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
