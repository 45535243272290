import * as actions from './actionTypes';
import { SUCCESS } from '../../utils/actionSuffix';

const InitialState = {
  data: [],
  selectedArticle: -1,
  translations: []
};

export function articles(state = InitialState, action) {
  switch (action.type) {
    case actions.SET_ARTICLES:
      return {
        ...state,
        data: action.data
      };

    case actions.SET_ARTICLE_ID:
    case actions.FETCH_ARTICLE + SUCCESS:
      return {
        ...state,
        selectedArticle: parseInt(action.id)
      };

    case actions.FETCH_ARTICLE_TRANSLATION + SUCCESS:
    case actions.SET_ARTICLE_TRANSLATION:
      return {
        ...state,
        translations: action.translations || action.data
      };

    case actions.RESET_REQ:
      return InitialState;

    default:
      return state;
  }
}
