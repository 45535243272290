import React, { Component } from 'react';
import Layout from '../../components/Layout/index.jsx';
import { AritcleForm } from "../../components/Articles/Form";

class NewAgroraports extends Component {
  render() {
    const report = this.props.match.path.includes('/agroraport');
    const { permission } = this.props;
    return (
      <Layout>
        <h1 className="h2 pb-md-4 page-header">
          Nowy artykuł {report && 'Agroraportu '} {this.props.match.params.id}
        </h1>
        {/* Content */}
        <AritcleForm report={report} />
      </Layout>
    );
  }
}

export default NewAgroraports;
