import { useSelector } from 'react-redux';
import { sprintf } from 'sprintf-js';
import API from '../../services/api';

const URLS = {
  ARTICLES: '/api/v1/articles/',
  ARTICLE_SLUG: '/api/v2/articles/%s/'
}

export function useArticleApi() {
  const token = useSelector((state) => state.user.token.key)

  const fetchArticles = (config = {}) =>
  API.get(URLS.ARTICLES, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    ...config
  });

  const createArticle = (data, user) => {
    return API.post(
      URLS.ARTICLES,
      { ...data, creator_id: user },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  };

  const editArticle = (data, slug) => {
    return API.patch(
      sprintf(URLS.ARTICLE_SLUG, slug),
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  };

  const removeArticle = (slug) =>
    API.delete(sprintf(URLS.ARTICLE_SLUG, slug), {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

  const getArticle = (slug) => {
    return API.get(
      sprintf(URLS.ARTICLE_SLUG, slug), {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

  const getArticleTranslation = (groupID) => {
    return API.get(`/api/v2/articles/?grouping_id=${groupID}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

  // TODO
  const setArticleStatus = (slug, status) =>
    API.patch(
      `/api/v2/articles/${slug}/`,
      {
        publish_status: status
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

  return {
    fetchArticles,
    createArticle,
    editArticle,
    removeArticle,
    getArticle,
    getArticleTranslation,
    setArticleStatus
  }
}
