export const FETCH_ARTICLES = 'FETCH_ARTICLES';
export const SET_ARTICLES = 'SET_ARTICLES';
export const SET_ARTICLE_MEDIA = 'SET_ARTICLE_MEDIA';
export const SET_ARTICLE_MEDIA_SUCCESS = 'SET_ARTICLE_MEDIA_SUCCESS';
export const CREATE_ARTICLE = 'CREATE_ARTICLE';
export const FETCH_ARTICLE = 'FETCH_ARTICLE';
export const ARTICLE_REMOVE_FILE = 'ARTICLE_REMOVE_FILE';
export const REMOVE_ARTICLE = 'REMOVE_ARTICLE';
export const SET_ARTICLE_ID = 'SET_ARTICLE_ID';
export const RESET_REQ = 'RESET_REQ';
export const FETCH_ARTICLE_TRANSLATION = 'FETCH_ARTICLE_TRANSLATION';
export const SET_ARTICLE_TRANSLATION = 'SET_ARTICLE_TRANSLATION';
export const SET_ARTICLE_STATUS = 'SET_ARTICLE_STATUS';
