import * as api from "./api";
import {
  takeLatest,
  call,
  put,
  select,
  takeEvery
} from "@redux-saga/core/effects";
import * as actionTypes from "./actionTypes";
import * as actionCreators from "./actionCreators";
import * as selectors from "./selectors";
import cookies from "../../../../utilities/cookies";
import { SUCCESS, FAILURE } from "../../utils/actionSuffix";
import {
  mapFailure,
  alertFailure,
  alertSuccess
} from "../../utils/mapResponse";

// eslint-disable-next-line require-yield
export function* setTokenCookie({ token, expireDate, id }) {
  cookies.setToken(token, expireDate, id);
}

// eslint-disable-next-line
export function* removeUserCookie() {
  cookies.removeToken();
}

export function* loginUser({ email, password }) {
  try {
    const response = yield call(api.fetchUserToken, email, password);
    const { token, expired_at, user } = response.data;
    if (user.user_type === 4) {
      yield put(alertFailure("Nie masz uprawnień"));
    } else {
      yield put(actionCreators.setUserToken(token));
      yield put(
        actionCreators.setTokenCookie(token, new Date(expired_at), user.id)
      );
      yield put(actionCreators.setUserData({ ...user }));
      yield put({ type: actionTypes.SET_USER_DATA + SUCCESS });
    }
  } catch (e) {
    yield put(alertFailure("Podałeś błędne dane lub użytkownik nie istnieje"));
  }
}

export function* fetchUserData() {
  try {
    const token = yield select(selectors.selectToken);
    const id = yield select(selectors.selectId);
    const response = yield call(api.fetchUserData, token, id);
    const { data } = response;
    yield put(actionCreators.setUserData({ ...data }));
    yield put({ type: actionTypes.FETCH_USER_DATA + SUCCESS });
  } catch (e) {
    yield put(mapFailure(e, actionTypes.FETCH_USER_DATA));
    yield put(alertFailure("Twoja sesja wygasła"));
  }
}

export function* fetchUserLogs({ id }) {
  try {
    const token = yield select(selectors.selectToken);
    const response = yield call(api.fetchUserLogs, token, id);
    yield put(
      actionCreators.setUserData({
        ...response.data
      })
    );
    yield put({ type: actionTypes.FETCH_USER_LOGS + SUCCESS });
  } catch (e) {
    yield put(mapFailure(e, actionTypes.FETCH_USER_LOGS));
    yield put(alertFailure("Wystąpił błąd"));
  }
}

export function* logoutUser() {
  try {
    yield put(
      actionCreators.setUserData({
        email: "",
        username: "",
        first_name: "",
        last_name: ""
      })
    );
  } catch (e) {
    console.log(e);
  }
}

export function* fetchUsers() {
  const token = yield select(selectors.selectToken);
  try {
    yield call(api.fetchUsers, token);
    // const { data } = response;
    // const { results } = data;
    // yield put(actionCreators.set);
  } catch (e) {
    const failure = mapFailure(e, actionTypes.FETCH_USER_DATA, true);
    console.log(failure);
    // yield* failure.forEach(function*(element) {
    //   yield put(element);
    // });
  }
}

export function* createUser({ userData }) {
  const token = yield select(selectors.selectToken);
  try {
    yield call(api.createUser, token, userData);
    yield put({ type: actionTypes.CREATE_USER + SUCCESS });
    yield put(alertSuccess("Użytkownik dodany pomyślnie"));
    // console.log(response);
  } catch (e) {
    // console.log(e);
    yield put(mapFailure(e, actionTypes.CREATE_USER));
    yield put(
      alertFailure("Wystąpił błąd podczas tworzenia nowego użytkownika")
    );
  }
}

export function* fetchUserDataEdit({ id }) {
  try {
    const token = yield select(selectors.selectToken);
    const response = yield call(api.fetchUserData, token, id);
    const { data } = response;
    yield put(
      actionCreators.setUserDataEdit({
        data
      })
    );
  } catch (e) {
    console.log(e);
  }
}

export function* editUserData({ id, data }) {
  try {
    const token = yield select(selectors.selectToken);
    const response = yield call(api.updateUser, token, id, data);
    const { data: newData } = response;
    yield put(
      actionCreators.setUserDataEdit({
        data: newData
      })
    );
    yield put({ type: actionTypes.SET_USER_DATA_EDIT + SUCCESS });
    yield put(alertSuccess("Użytkownik edytowany pomyślnie"));
  } catch (e) {
    console.log(e);
  }
}

export function* deleteUser({ id }) {
  const token = yield select(selectors.selectToken);
  try {
    yield call(api.removeUser, token, id);
    yield put(alertSuccess("Pomyślnie usunięto użytkownika"));
  } catch (e) {
    yield put(mapFailure(e, actionTypes.DELETE_USER + FAILURE));
    yield put(
      alertFailure(
        "Wystąpił błąd podczas usuwania użytkownika. Jeżeli błąd sie powtarza skontaktuj się z administratorem."
      )
    );
  }
}

export default [
  takeLatest(actionTypes.SET_TOKEN_COOKIE, setTokenCookie),
  takeLatest(actionTypes.LOGIN_USER, loginUser),
  takeLatest(actionTypes.FETCH_USER_DATA, fetchUserData),
  takeLatest(actionTypes.LOGOUT_USER, logoutUser),
  takeEvery(actionTypes.CREATE_USER, createUser),
  takeEvery(actionTypes.FETCH_USER_EDIT, fetchUserDataEdit),
  takeLatest(actionTypes.EDIT_USER_DATA, editUserData),
  takeEvery(actionTypes.DELETE_USER, deleteUser),
  takeLatest(actionTypes.FETCH_USER_LOGS, fetchUserLogs)
];
