import React from 'react';
import Layout from '../../components/Layout';
import ArticleTagsList from '../../common/Articles/tags';
import ArticleCategoriesForm from '../../components/Forms/ArticleCategories';
import { Row, Col, Button } from 'reactstrap';

class ArticleTags extends Layout {
  render() {
    return (
      <Layout>
        <Row>
          <Col sm="5">
            <h1 className="h2 pb-md-4 page-header">Tagi artykułów</h1>
            <ArticleTagsList isList={true} />
            <Button>Dodaj tag</Button>
          </Col>
          <Col sm="7">
            <ArticleCategoriesForm />
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default ArticleTags;
