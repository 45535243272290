import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { Button } from 'reactstrap';
import API from '../../services/api';
import { deletePoll } from '../../services/redux/reducers/polls/actionCreators.js';
import { calculateMaxPages } from '../../utilities/calculates';

class ConversationsList extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      pages: null,
      loading: true,
      tableState: ''
    };
    this.fetchData = this.fetchData.bind(this);
    this.getFilterVal = this.getFilterVal.bind(this);
  }
  fetchData(state, instance) {
    const { token, lang } = this.props;
    let url = `/api/v1/conversations/?limit=${state.pageSize}&`;
    let params = [];
    this.setState({ loading: true, tableState: state });
    let offset = state.page * state.pageSize;
    if (offset) {
      params.push(`offset=${offset}`);
    }
    if (state.filtered.length) {
      state.filtered.forEach(element => {
        if (element.id === 'title') {
          params.push(`&search=${element.value}`);
        }
      });
    }
    if (params.length) {
      let allParams = params.reduce((a, b) => a + '&' + b);
      url = url + allParams;
    }
    API.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(({ data }) => {
      this.setState({
        data: data.results,
        pages: calculateMaxPages(data.count, state.pageSize),
        loading: false
      });
    });
  }
  getFilterVal(type) {
    let filters = this.state.tableState.filtered;
    if (filters && filters.length) {
      let filter = filters.find(item => item.id === type);
      return filter ? filter.value : '';
    }
    return '';
  }

  render() {
    const { data, pages, loading } = this.state;
    const { select } = this.props;
    return (
      <div className="table-responsive">
        <ReactTable
          columns={[
            {
              Header: 'Lp.',
              Cell: row => {
                return <div>{row.index + 1 + row.page * row.pageSize}</div>;
              },
              maxWidth: 40
            },
            {
              Header: 'Korenspodenci',
              accessor: 'title',
              Cell: row => {
                const { client, expert } = row.original;
                return (
                  <div>
                    Klient: {client && client.email} <br /> Ekspert:{' '}
                    {expert.email}
                  </div>
                );
              }
              // searchable
              // filterable: true
            },
            {
              Header: 'Akcje',
              Cell: d => {
                return select ? (
                  <Button
                    color="primary"
                    onClick={() => this.props.selectItem(d.original)}
                    size="sm"
                    // disabled={this.checkIfMediumActive()}
                  >
                    Wybierz
                  </Button>
                ) : (
                  <>
                    <Button
                      color="primary"
                      onClick={() =>
                        this.props.history.push(
                          `/experts/conversations/${d.original.id}`
                        )
                      }
                      size="sm"
                    >
                      Przejdź
                    </Button>
                  </>
                );
              },
              maxWidth: 300
            }
          ]}
          manual
          data={data}
          pages={pages}
          loading={loading}
          onFetchData={this.fetchData}
          defaultPageSize={10}
          resizable={false}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { user, settings } = state;
  return {
    token: user.token.key,
    lang: settings.lang
  };
}

export default connect(mapStateToProps, { deletePoll })(
  withRouter(ConversationsList)
);
