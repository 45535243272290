import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { Formik, connect } from 'formik';
import {
  userTypes,
  consumerStatuses,
  consumerTypes
} from '../../utilities/user';
import API from '../../services/api';
import { cloneDeep } from 'lodash';
import cookies from '../../utilities/cookies';
import { format } from 'date-fns';

function objectToGetParams(object) {
  return (
    '?' +
    Object.keys(object)
      .filter(key => !!object[key])
      .map(key => `${key}=${encodeURIComponent(object[key])}`)
      .join('&')
  );
}

class ExportUsers extends Component {
  constructor(props) {
    super(props);
    const { userType, consumerType, consumerStatus } = this.props;
    this.state = {
      initialValues: {
        date_from: new Date(),
        date_to: '',
        user_type: userType || '',
        consumer_type: consumerType || '',
        consumer_status: consumerStatus || '',
        deleted_at: false
      },
      error: false
    };
  }

  render() {
    const { initialValues, error } = this.state;
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={values => {
          this.setState({
            error: false
          });
          const token = cookies.getToken();
          let query = cloneDeep(values);
          query.date_from = format(query.date_from, 'yyyy-MM-dd');
          if (query.date_to) {
            query.date_to = format(query.date_to, 'yyyy-MM-dd');
          } else {
            delete query.date_to;
          }
          API.get(`/api/v1/export-users/${objectToGetParams(query)}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
            .then(response => {
              this.props.generateFile(response);
            })
            .catch(error => {
              this.setState({
                error: true
              });
            });
        }}
        render={({ values, handleSubmit, handleChange, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            {error ? (
              <Alert color="danger">
                Brak userów o wybranych parametrach. Zmień dane i spróbuj
                ponownie.
              </Alert>
            ) : null}
            <FormGroup>
              <Label for="date_start">Eksport od</Label> <br />
              <DatePicker
                selected={values.date_from}
                onChange={date => {
                  setFieldValue('date_from', date);
                  // this.handleChange(date, 'startDate');
                }}
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="dd-MM-yyyy"
                timeCaption="time"
                maxDate={new Date()}
              />
            </FormGroup>
            <FormGroup>
              <Label for="date_start">Eksport do</Label> <br />
              <DatePicker
                selected={values.date_to}
                onChange={date => {
                  setFieldValue('date_to', date);
                  // this.handleChange(date, 'startDate');
                }}
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="dd-MM-yyyy"
                timeCaption="time"
                minDate={new Date(values.date_from)}
                maxDate={new Date()}
              />
            </FormGroup>
            <FormGroup>
              <Label>Typ użytkownika</Label>
              <Input
                type="select"
                name="user_type"
                id="user_type"
                onChange={handleChange}
                value={values.user_type}
                className="input--small"
              >
                <option value="">Wybierz</option>

                {userTypes.map((type, index) => (
                  <option value={index + 1} key={index}>
                    {type}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Typ klienta</Label>
              <Input
                type="select"
                name="consumer_type"
                id="consumer_type"
                onChange={handleChange}
                value={values.consumer_type}
                className="input--small"
              >
                <option value="">Wybierz</option>
                {consumerTypes.map((type, index) => (
                  <option value={type.val} key={index}>
                    {type.label}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Status użytkownika</Label>
              <Input
                type="select"
                name="consumer_status"
                id="consumer_status"
                onChange={handleChange}
                value={values.consumer_status}
                className="input--small"
              >
                <option value="">Wybierz</option>
                {consumerStatuses.map(
                  type =>
                    type.val !== 4 && (
                      <option value={type.val} key={type.val}>
                        {type.label}
                      </option>
                    )
                )}
              </Input>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="deleted_at"
                  id="deleted_at"
                  onChange={handleChange}
                  value={values.deleted_at}
                />{' '}
                Użytkownicy usunięci
              </Label>
            </FormGroup>
            <FormGroup>
              <Button type="submit" color="primary" className="mt-3">
                Eksportuj
              </Button>
            </FormGroup>
          </Form>
        )}
      />
    );
  }
}

export default ExportUsers;
