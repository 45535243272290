import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { FEATURED_TYPES } from '../../components/Articles/consts';

export const HighlightArticle = ({ value, setFieldValue }) => {
  return FEATURED_TYPES.map(option => {
    const id = `${option.value}_featured`
    return(
      <FormGroup check key={id}>
        <Input
          id={id}
          name="featured"
          type="radio"
          value={option.value}
          checked={value === option.value}
          onClick={() => {
            setFieldValue(Number(option.value))
          }}
        />
        <Label check for={id}>
          {option.label}
        </Label>
      </FormGroup>
    )
  })
}
