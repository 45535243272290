import React, { useState, createContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useQuizzesApi } from './Quizzes.api';

export const QuizContext = createContext();

export const QuizProvider = ({ children }) => {
  const {
    getQuiz,
    getQuestions,
    postQuestion,
    deleteQuestion,
    patchQuestion,
    postAnswer,
    patchAnswer,
    deleteAnswer,
    patchQuiz,
    deleteQuiz
  } = useQuizzesApi();
  const history = useHistory();
  const [quiz, setQuiz] = useState();
  const [questions, setQuestions] = useState([]);
  const { slug } = useParams();

  const updateQuizQuestions = () =>
    getQuestions({ slug: quiz.slug }).then(({ data }) => {
      setQuestions(data);
    });

  const getQuizData = (slug) =>
    Promise.all([getQuiz({ slug }), getQuestions({ slug })])
      .then((results) => {
        const [quiz, questions] = results;
        setQuiz(quiz.data);
        setQuestions(questions.data);
      })
      .catch(() => {
        history.push('/quizzes');
      });

  const createNewQuestion = () =>
    postQuestion({
      question_text: `Pytanie ${questions.length + 1}`,
      quiz: quiz.id
    }).then(() => {
      updateQuizQuestions();
    });

  const removeQuestion = (id) =>
    deleteQuestion({
      question_id: id
    }).then(() => {
      updateQuizQuestions();
    });

  const updateQuestion = ({ question_text, question_id }) =>
    patchQuestion({
      question_text,
      question_id
    }).then(() => {
      updateQuizQuestions();
    });

  const createNewAnswer = (question) =>
    postAnswer({
      question: question.id,
      answer_text: `Odpowiedź ${question.answers.length + 1}`,
      correct: false
    }).then(() => {
      updateQuizQuestions();
    });

  const updateAnswer = ({ answer_text, answer_image, id, correct, question }) =>
    patchAnswer({
      answer_text,
      answer_image,
      correct,
      answer_id: id,
      question
    }).then(() => {
      updateQuizQuestions();
    });

  const removeAnswer = (id) =>
    deleteAnswer({
      answer_id: id
    }).then(() => {
      updateQuizQuestions();
    });

  const updateQuiz = ({
    publish_status,
    highlight_image,
    title,
    description,
    slug
  }) =>
    patchQuiz({
      publish_status,
      highlight_image,
      title,
      description,
      slug
    }).then(() =>
      getQuiz({ slug }).then(({ data }) => {
        setQuiz(data);
      })
    );

  const removeQuiz = (quiz_slug) => deleteQuiz({ quiz_slug });

  useEffect(() => {
    if (slug && !quiz) {
      getQuizData(slug);
    }
  }, []);

  return (
    <QuizContext.Provider
      value={{
        quiz,
        questions: questions.sort((a, b) => a.id - b.id),
        getQuizData,
        createNewQuestion,
        removeQuestion,
        updateQuestion,
        createNewAnswer,
        updateAnswer,
        removeAnswer,
        updateQuiz,
        removeQuiz
      }}
    >
      {children}
    </QuizContext.Provider>
  );
};
