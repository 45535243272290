// import { jsx } from 'slate-hyperscript';
import { htmlToSlate } from 'slate-serializers'
import { getAttributeValue } from 'domutils'
import { NODE_TYPES } from '../consts'
import { path } from 'ramda'

const deserializeImage = (data) => ({
  url: data.src,
  alt: data.alt,
  class: data.class,
  id: data['data-id']
})

export const config = {
  elementTags: {
    a: (el) => ({
      type: 'link',
      target: el && getAttributeValue(el, 'target'),
      href: el && getAttributeValue(el, 'href'),
    }),
    h2: () => ({ type: NODE_TYPES.H2 }),
    h3: () => ({ type: NODE_TYPES.H3 }),
    li: () => ({ type: NODE_TYPES.LIST_ITEM }),
    ol: () => ({ type: NODE_TYPES.OL_LIST }),
    ul: () => ({ type: NODE_TYPES.UL_LIST }),
    hr: () => ({ type: NODE_TYPES.SEPARATOR }),
    p: (el) => {
      const child = el.children[0]
      const disclaimer = child && child.attribs && child.attribs.class === 'disclaimer'

      if (disclaimer) {
        return {
          type: NODE_TYPES.DISCLAIMER
        }
      }

      return { type: NODE_TYPES.P }
    },
    img: (el) => {
      if (el.parent.type === 'root') {
        return {
          type: NODE_TYPES.IMAGE,
          ...deserializeImage(el.attribs)
        }
      }
    },
    figure: (el) => {
      const oldStructureImage = path(['children', '1'], el)

      if ((oldStructureImage || {}).name === 'img') {
        return
      }

      const image = path(['children', '0'], el)
      const desc = path(['children', '1', 'children', '0'], el)

      if (!image || image.name !== 'img') return

      const xl = ((el.attribs && el.attribs.class) || '').includes('img--xl') ? 'xl' : undefined

      return {
        type: NODE_TYPES.IMAGE,
        ...deserializeImage(image.attribs),
        desc: desc ? desc.data : '',
        sizeType: xl
      }
    },
    div: (el) => {
      if (!el) return

      const isArticleLink = (getAttributeValue(el, 'class') || '').match(/\barticle-link-internal\b/g)

      if (isArticleLink) {
        const text = path(['children', '0', 'children', '0'], el)
        const link = path(['children', '1'], el)

        return {
          type: NODE_TYPES.ARTICLE_LINK,
          data: {
            text: text.data,
            href: link && getAttributeValue(link, 'href'),
            title: link && link.children[0].data
          }
        }
      }

      const isBlockqoute = (getAttributeValue(el, 'class') || '').match(/\barticle-quote\b/g)

      if (isBlockqoute) {
        const { children, name } = el
        if (children.length && name === 'div') {
          const quote = path(['children', '0', 'children', '0'], el)
          const author =  path(['children', '1'], el)
          const firstElementImg = (path(['children', '0'], author) || {}).name === 'img'

          const name = author
            ? (path(['children', firstElementImg ? '1' : '0', 'children', '0'], author) || {}).data
            : null
          const description = author
            ? (path(['children', firstElementImg ? '2' : '1', 'children', '0'], author) || {}).data
            : null
          const photo = firstElementImg && author
            ? deserializeImage(path(['children', '0'], author).attribs || {})
            : { id: null, url: null }

          return {
            type: NODE_TYPES.BLOCKQUOTE,
            data: {
              text: quote.data,
              name,
              description,
              photo
            }
          }
        }

        return
      }

      const isImageGrid = (getAttributeValue(el, 'class') || '').includes('image-grid image-grid-')

      if (!isImageGrid) return

      const grid = el.children.reduce((result, curr) => {
        if (getAttributeValue(curr, 'class').includes('image-grid__content')) {
          const image = path(['children', '0', 'children', '0'], curr)
          const imageAttrs = deserializeImage(image.attribs)

          const desc = path(['children', '1', 'children', '0'], curr)

          result.push({
            image: imageAttrs,
            desc: desc ? desc.data : ''
          })
        }

        return result
      }, [])

      if (grid.length < 2) return

      return {
        type: NODE_TYPES.GRID_IMAGES,
        grid,
        children: [{ text: '' }]
      }
    },
  },
  textTags: {
    em: () => ({ italic: true }),
    i: () => ({ italic: true }),
    strong: () => ({ bold: true }),
    u: () => ({ underline: true }),
  },
  filterWhitespaceNodes: true,
  convertBrToLineBreak: true,
}

export const deserialize = html => {
  const slateValue = htmlToSlate(html, config)
    .map(el => {
      if ([NODE_TYPES.GRID_IMAGES, NODE_TYPES.IMAGE, NODE_TYPES.BLOCKQUOTE, NODE_TYPES.ARTICLE_LINK].includes(el.type)) {
        return {
          ...el,
          children: [{ text: '' }]
        }
      }

      return el
    })

  return slateValue
}
