import React from 'react';
import { useFormik } from 'formik';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import debounce from 'lodash.debounce';

import Inputs from '../../Inputs';
import { COLOR } from '../../../consts/prf.const';

const mapBg = {
  grey: COLOR.lightGrey,
  white: COLOR.white,
  green: COLOR.brandGreen
};

const mapIconBg = {
  green: COLOR.brandGreen,
  white: COLOR.white,
  orange: COLOR.brandOrange
};

const InsuranceScopeForm = ({
  onChange,
  py,
  title,
  description,
  icon,
  bg,
  iconBg,
  items,
  isReversed,
  anchor
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title,
      description,
      items,
      icon,
      bg,
      iconBg,
      isReversed,
      py: py
    },
    onSubmit: (values) => {
      onChange(values);
    }
  });

  const onBlur = () => {
    formik.submitForm();
  };

  const debounceSave = debounce(() => {
    onBlur();
  }, 800);

  return (
    <Form>
      <Row>
        <Col xs={6}>
          <FormGroup>
            <Label for="title">Tytuł</Label>
            <Input
              id="title"
              name="title"
              type="textarea"
              value={formik.values.title}
              invalid={!!formik.errors.title}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('title', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="description">Podtytuł</Label>
            <Input
              id="description"
              name="description"
              type="textarea"
              value={formik.values.description}
              invalid={!!formik.errors.description}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('description', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <FormGroup>
            <Label for="items"></Label>
            <Input
              id="items"
              name="items"
              type="textarea"
              value={formik.values.items.join(';')}
              invalid={!!formik.errors.items}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('items', target.value.split(';'));
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup style={{ paddingLeft: '1rem' }}>
            <Input
              id="isReversed"
              name="isReversed"
              type="checkbox"
              checked={formik.values.isReversed}
              invalid={!!formik.errors.isReversed}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('isReversed', target.checked);
                debounceSave();
              }}
            />
            <Label for="isReversed">Wariant odwrotny</Label>
          </FormGroup>
          <FormGroup>
            <Label for="bg">Kolor tła</Label>
            <Input
              type="select"
              id="bg"
              name="bg"
              onChange={({ target }) => {
                formik.setFieldValue('bg', target.value);
                onBlur();
              }}
            >
              {Object.keys(mapBg).map((color) => (
                <option value={color} key={color}>
                  {color}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="iconBg">Kolor tła dla ikony</Label>
            <Input
              type="select"
              id="iconBg"
              name="iconBg"
              onChange={({ target }) => {
                formik.setFieldValue('iconBg', target.value);
                onBlur();
              }}
            >
              {Object.keys(mapIconBg).map((color) => (
                <option value={color} key={color}>
                  {color}
                </option>
              ))}
            </Input>
          </FormGroup>
          <Inputs.IconsInput
            labelProps={{ for: 'icon' }}
            inputProps={{
              name: 'icon',
              value: formik.values.icon,
              onChange: ({ target }) => {
                formik.setFieldValue('icon', target.value);
                onBlur();
              }
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Inputs.SpacesInput
            id="py"
            name="py"
            value={formik.values.py}
            invalid={!!formik.errors.py}
            onBlur={onBlur}
            onChange={({ target }) => {
              formik.setFieldValue('py', target.value);
            }}
          />
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="anchor">Zakotwiczenie</Label>
            <Input
              id="anchor"
              name="anchor"
              type="text"
              value={formik.values.anchor}
              invalid={!!formik.errors.anchor}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('anchor', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default InsuranceScopeForm;
