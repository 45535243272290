import React from 'react';
import { useFormik } from 'formik';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import debounce from 'lodash.debounce';

import Inputs from '../../Inputs';

const VideoForm = ({
  onChange,
  py,
  title,
  subtitle,
  youTubeVideoUrl,
  anchor
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title,
      subtitle,
      youTubeVideoUrl,
      py: py,
      anchor
    },
    onSubmit: (values) => {
      onChange(values);
    }
  });

  const onBlur = () => {
    formik.submitForm();
  };

  const debounceSave = debounce(() => {
    onBlur();
  }, 800);

  return (
    <Form>
      <Row>
        <Col xs={6}>
          <FormGroup>
            <Label for="title">Tytuł</Label>
            <Input
              id="title"
              name="title"
              type="textarea"
              value={formik.values.title}
              invalid={!!formik.errors.title}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('title', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="subtitle">Podtytuł</Label>
            <Input
              id="subtitle"
              name="subtitle"
              type="textarea"
              value={formik.values.subtitle}
              invalid={!!formik.errors.subtitle}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('subtitle', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <FormGroup>
            <Label for="youTubeVideoUrl">
              YouTube URL (np. https://www.youtube.com/embed/lgQOfO-EZD8)
            </Label>
            <Input
              id="youTubeVideoUrl"
              name="youTubeVideoUrl"
              type="textarea"
              value={formik.values.youTubeVideoUrl}
              invalid={!!formik.errors.youTubeVideoUrl}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('youTubeVideoUrl', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Inputs.SpacesInput
            id="py"
            name="py"
            value={formik.values.pt}
            invalid={!!formik.errors.pt}
            onBlur={onBlur}
            onChange={({ target }) => {
              formik.setFieldValue('py', target.value);
            }}
          />
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="anchor">Zakotwiczenie</Label>
            <Input
              id="anchor"
              name="anchor"
              type="text"
              value={formik.values.anchor}
              invalid={!!formik.errors.anchor}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('anchor', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default VideoForm;
