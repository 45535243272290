import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { MultimediaCategories } from './Categories';
import { Button, ButtonGroup, Col, FormFeedback, FormGroup, FormText, Input, Label, UncontrolledTooltip } from 'reactstrap';
import { InputField } from '../../common/Inputs';
import { Sidebar } from './Sidebar';
import { frontLink } from '../../services/api';
import { useMultimediaApi } from './Multimedia.api';
import { cloneDeep } from 'lodash';
import Loading from '../../common/Loading';
import { getQueryStringValue } from '../../utilities/history';
import { useDispatch } from 'react-redux';
import { alertFailure, alertSuccess } from '../../services/redux/utils/mapResponse';
import TextEditor from '../../common/Editor';
import { SelectPhotoForArticle } from '../../common/Articles/select-photo';
import { MULTIMEDIA_TYPES_VALUES, MUTLIMEDIA_TYPES } from './consts';
import { PhotoGallery } from './PhotoGallery';
import { useHistory } from 'react-router';
import * as Yup from "yup";

const MODEL = {
  title: '',
  short_title: '',
  summary: '',
  short_summary: '',
  description: '',
  categories_list: [],
  multimedia_type: '',
  cover_photo: '',
  medias_list: [],
  author_name: '',
  author_description: '',
  author_photo: '',
  published_at: null,
  published_all_at: null,
  access: 1,
  labels: [],
  publish_status: 1,
  gallery: null,
  url: ''
}

const URL_VALUES = [MULTIMEDIA_TYPES_VALUES.Video, MULTIMEDIA_TYPES_VALUES.Podcast]

export const MultimediaForm = ({ report, multimediaId }) => {
  const model = cloneDeep(MODEL)
  const dispatch = useDispatch()
  const history = useHistory()

  const lang = getQueryStringValue('lang');
  const [langGroup, setLangGroup] = useState(getQueryStringValue('group'))
  if (langGroup) {
    model.grouping_id = langGroup
    model.lang_code = lang.toUpperCase()
  }

  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [slug, setSlug] = useState(null)
  const [errors, setErrors] = useState({})
  const { createMultimedia, editMultimedia, getMultimediaEntity, createPhotoGallery, fetchPhotoGallery } = useMultimediaApi()

  const { values, handleChange, handleSubmit, setFieldValue, setValues, errors: formikErrors, validateField } = useFormik({
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Pole jest wymagane"),
      short_title: Yup.string().required("Pole jest wymagane"),
      multimedia_type: Yup.number().required("Pole jest wymagane"),
      published_at: Yup.string().required("Pole jest wymagane"),
      author_name: Yup.string().required("Pole jest wymagane"),
      url: Yup.string().when('multimedia_type', {
        is: val => URL_VALUES.includes(val),
        then: Yup.string().required("Pole jest wymagane"),
        otherwise: Yup.string().nullable()
      })
    }),
    validateOnBlur: true,
    validateOnChange: false,
    initialValues: model,
    onSubmit: async (values) => {
      try {
        setLoading(true)
        const data = cloneDeep(values)
        const { cover_photo, author_photo } = data;
        data.cover_photo_id = cover_photo
          ? cover_photo.id
          : ''

        delete data.cover_photo

        data.author_photo_id = author_photo
          ? author_photo.id
          : ''

        delete data.author_photo

        let gallery = null
        if (values.multimedia_type === MULTIMEDIA_TYPES_VALUES.PhotoGallery) {
          data.url = null
          gallery = data.gallery.map((item, index) => {
            return {
              photo_id: item.file.id,
              description: item.description,
              order: index
            }
          })
          delete data.gallery
          const valid = gallery.every(v => !!v.photo_id)
          if (!valid) {
            setErrors({
              gallery: 'Uzupełnij zdjęcia we wszystkich polach'
            })
            return
          }
        } else {
          delete data.gallery
        }

        if (editMode) {
          // delete data.published_all_at
          // delete data.short_name
          await editMultimedia(data, slug)
          if (gallery) {
            await createPhotoGallery(slug, gallery)
          }
          dispatch(alertSuccess('Edytowano pomyślnie artykuł.'))
        } else {
          const { data: resultData } = await createMultimedia(data)
          if (gallery) {
            await createPhotoGallery(resultData.slug, gallery)
          }
          dispatch(alertSuccess('Dodano pomyślnie artykuł.'))
        }
        history.push('/multimedia')
      } catch (error) {
        if (error && error.response) {
          setErrors(error.response.data)
        }
        dispatch(alertFailure('Wystąpił błąd podczas zapisywania artykułu'))
      } finally {
        setLoading(false)
      }
    }
  })

  useEffect(() => {
    setErrors(formikErrors)
  }, [formikErrors])

  useEffect(() => {
    setEditMode(multimediaId && !langGroup)

    const fetchData = async () => {
      setLoading(true)
      try {
        const { data } = await getMultimediaEntity(multimediaId)
        const _values = {
          title: data.title,
          short_title: data.short_title,
          summary: data.summary,
          short_summary: data.short_summary,
          description: data.description,
          // categories_list: data.categories_list,
          multimedia_type: data.multimedia_type,
          cover_photo: data.cover_photo || '',
          author_name: data.author_name,
          author_description: data.author_description,
          author_photo: data.author_photo || '',
          published_at: data.published_at
          ? new Date(data.published_at)
          : null,
          published_all_at: data.published_all_at
            ? new Date(data.published_all_at)
            : null,
          access: data.access,
          labels: data.labels,
          publish_status: data.publish_status,
          url: data.url
        };

        if (_values.multimedia_type === MULTIMEDIA_TYPES_VALUES.PhotoGallery) {
          const { data: galleryData } = await fetchPhotoGallery(multimediaId)
          _values.gallery = galleryData
            .sort((a, b) => a.order - b.order)
            .map(({ description, order, ...photo }) => ({
              description,
              file: {
                ...photo,
                id: photo.photo_id
            }
            }))
        }

        setValues({
          ...values,
          ..._values
        })
        setLangGroup(data.grouping_id)
        setSlug(data.slug)
        // dispatch(setArticleId(data.id))
        // getArticleTranslation(data.grouping_id)
        //   .then(({ data }) => {
        //     dispatch(setArticleTranslation(data.results))
        //   })
      } catch (error) {

      } finally {
        setLoading(false)
      }
    }
    if (multimediaId) {
      fetchData()
    }
  }, [])

  return (
    <form
      onSubmit={handleSubmit}
      className="row"
      noValidate
    >
      {loading && <Loading fixed/>}
      <Col sm="8">
        <InputField
          label="Tytuł"
          name="title"
          required
          value={values.title}
          onChange={handleChange}
          invalid={!!errors.title}
          validation={errors.title}
        />
        <FormGroup>
          <InputField
            label="Krótki tytuł"
            name="short_title"
            required
            value={values.short_title}
            onChange={handleChange}
            invalid={!!errors.short_title}
            validation={errors.short_title}
            maxLength="75"
          />
          <FormText className="field-length">{values.short_title.length}/75 znaki</FormText>
        </FormGroup>
        <FormGroup>
          <InputField
            label="Autor artykułu"
            type="text"
            name="author_name"
            id="author_name"
            value={values.author_name}
            onChange={handleChange}
            invalid={!!errors.author_name}
            validation={errors.author_name}
          />
          <FormFeedback>{errors.author_name}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <InputField
            label="Autor opis"
            type="text"
            name="author_description"
            id="author_description"
            value={values.author_description}
            onChange={handleChange}
            invalid={!!errors.author_description}
          />
          <FormFeedback>{errors.author_description}</FormFeedback>
        </FormGroup>
        <SelectPhotoForArticle
          label="Zdjęcie autora"
          value={values.author_photo}
          setFieldValue={(value) => setFieldValue('author_photo', value)}
          errors={errors}
        />
        <FormGroup>
          <Label for="multimedia_type">
            Kategoria multimedium
          </Label>
          <Input
            id="multimedia_type"
            name="multimedia_type"
            type="select"
            onChange={e => {
              e.persist()
              const value = Number(e.target.value)
              setFieldValue('multimedia_type', value)
              if (value === MULTIMEDIA_TYPES_VALUES.PhotoGallery) {
                setFieldValue('gallery', [{ file: '', description: '', order: 0 }])
                setFieldValue('url', '')
              } else {
                setFieldValue('gallery', null)
              }
              setTimeout(() => {
                validateField('multimedia_type')
              }, 1)
            }}
            value={values.multimedia_type}
            invalid={!!errors.multimedia_type}
          >
            <option value="">Wybierz kategorię</option>
            {MUTLIMEDIA_TYPES.map(type => {
              return (
                <option value={type.value}>{type.label}</option>
              )
            })}
          </Input>
          <FormFeedback>{errors.multimedia_type}</FormFeedback>
        </FormGroup>
        {URL_VALUES.includes(values.multimedia_type) && <FormGroup>
          <Label for="url">
            Url
          </Label>
          <Input
            name="url"
            id="url"
            value={values.url}
            onChange={handleChange}
            invalid={!!errors.url}
            validation={errors.url}
          />
          <Input type="hidden" invalid={!!errors.url} />
        </FormGroup>}
        {values.multimedia_type === MULTIMEDIA_TYPES_VALUES.PhotoGallery &&
          <PhotoGallery
            value={values.gallery}
            errors={errors}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
          />
        }
        <FormGroup>
          <InputField
            label="Krótki lead"
            type="textarea"
            name="short_summary"
            id="short_summary"
            value={values.short_summary}
            onChange={handleChange}
            invalid={!!errors.short_summary}
            maxLength="160"
          />
          <FormText className="field-length">{values.short_summary.length}/160 znaków</FormText>
          <Input type="hidden" invalid={!!errors.short_summary} />
        </FormGroup>
        <FormGroup>
          <Label for="summary">
            Lead
          </Label>
          <Input
            type="textarea"
            name="summary"
            id="summary"
            value={values.summary}
            onChange={handleChange}
            invalid={!!errors.summary}
          />
          <Input type="hidden" invalid={!!errors.summary} />
        </FormGroup>
        {!loading && <FormGroup>
            <Label for="content">
              Opis multimedium
            </Label>
            <TextEditor
              editMode={editMode}
              content={values.description}
              setEditorValue={(value) => {
                setFieldValue('description', value)
              }}
            />
            <Input type="hidden" invalid={!!errors.description} />
            <FormFeedback>{errors.description}</FormFeedback>
          </FormGroup>}
        {/* photo 1 */}
        <SelectPhotoForArticle
          label="Cover photo"
          value={values.cover_photo}
          setFieldValue={(value) => setFieldValue('cover_photo', value)}
          errors={errors}
        />
      </Col>
      <Col sm="4">
        <Sidebar
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          errors={errors}
        >
          <ButtonGroup className="form-sidebar-buttons">
            <Button
              id="previewButton"
              disabled={!values.publish_status}
              type="button"
              onClick={() =>
                window.open(
                  `${frontLink()}multimedia/${slug}`,
                  '_blank'
                )
              }
              color="secondary"
            >
              Podgląd
            </Button>
            {!multimediaId ? (
              <UncontrolledTooltip
                placement="bottom"
                target="previewButton"
              >
                W celu zobaczenia podglądu zapisz szkic artykułu
              </UncontrolledTooltip>
            ) : null}
            <Button type="submit" color="primary">
              Zapisz
            </Button>
          </ButtonGroup>
        </Sidebar>
      </Col>
    </form>
  )
}
