import React from 'react';
import { useFocused, useSelected, useSlateStatic } from 'slate-react'
import Button from '../../common/Button.jsx';
import { removeLink } from '../../utils/link.js'

const Link = ({ attributes, element, children }) => {
  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();
  return (
    <div className='link' >
      <a href={element.href} {...attributes}>{children}</a>
      {selected && focused && (
        <div className='link-popup' contentEditable='false'>
          <a href={element.href}>{element.href}</a>
          <Button onClick={() => removeLink(editor)}>
            <span className="bi bi-trash3"></span>
          </Button>
        </div>
      )}
    </div>
  )
}

export default Link
