export const FETCH_EVENTS = 'FETCH_EVENTS';
export const SET_EVENTS = 'SET_EVENTS';
export const SET_EVENT_MEDIA = 'SET_EVENT_MEDIA';
export const SET_EVENT_MEDIA_SUCCESS = 'SET_EVENT_MEDIA_SUCCESS';
export const CREATE_EVENT = 'CREATE_EVENT';
export const FETCH_EVENT = 'FETCH_EVENT';
export const FETCH_EVENT_TRANSLATION = 'FETCH_EVENT_TRANSLATION';
export const EVENT_REMOVE_FILE = 'EVENT_REMOVE_FILE';
export const REMOVE_EVENT = 'REMOVE_EVENT';
export const SET_EVENT_ID = 'SET_EVENT_ID';
export const RESET_REQ = 'RESET_REQ';
export const SET_EVENT_STATUS = 'SET_EVENT_STATUS';
export const UPDATE_USER_DESC = 'UPDATE_USER_DESC';
export const REMOVE_EVENT_USER = 'REMOVE_EVENT_USER';
