import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

const options = [
  {
    label: 'Wszyscy',
    value: 1
  },
  {
    label: 'Zalogowani',
    value: 2
  },
  {
    label: 'Klienci',
    value: 3
  }
];

export const UserAccessArticle = ({ value, setFieldValue }) => {
  return options.map(option => {
    const id = `${option.value}_access`
    return(
      <FormGroup check key={id}>
        <Input
          id={id}
          name="access"
          type="radio"
          value={option.value}
          checked={value === option.value}
          onClick={() => {
            setFieldValue('access', option.value)
          }}
        />
        <Label check for={id}>
          {option.label}
        </Label>
      </FormGroup>
    )
  })
}
