import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { Button, Input } from 'reactstrap';
import ArticleTypesList from '../../common/Articles/types';
import { DeleteButton } from '../../components/FunctionalButtons/DeleteButton';
import Layout from '../../components/Layout';
import API, { frontLink } from '../../services/api';
import { removeArticle } from '../../services/redux/reducers/articles/actionCreators';
import { fetchArticleTypes } from '../../services/redux/reducers/articlesTypes/actionCreators';
import { calculateMaxPages } from '../../utilities/calculates';
import { formatDate } from '../../utilities/dates';
import { displayStatus, statuses } from '../../utilities/status';

class Agroraports extends Component {
  constructor(props) {
    super(props);
    const report = this.props.match.path.includes('/agroraport');
    this.state = {
      data: [],
      pages: null,
      loading: true,
      tableState: '',
      report,
      slug: report ? 'agroraport' : 'articles'
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    const { articleTypes } = this.props;
    if (!articleTypes.data.length) {
      this.props.fetchArticleTypes();
    }
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    // console.log(match);
    if (prevProps.match.path !== match.path) {
      const report = match.path.includes('/agroraport');
      this.setState(
        {
          report,
          slug: report ? 'agroraport' : 'articles'
        },
        () => {
          this.fetchData(this.state.tableState);
        }
      );
    }
  }

  getNameOfSelect(id) {
    const { articleTypes } = this.props;
    const { report } = this.state;
    const data = report ? articleTypes.agroData : articleTypes.data;
    const el = data.find((element) => element.id === id) || {};
    return data.length ? el.type_name : id;
    // return id;
  }

  removeArticle(id) {
    this.props.removeArticle(id);
    setTimeout(() => {
      this.fetchData(this.state.tableState);
    }, 1000);
  }

  fetchData(state, instance) {
    this.setState({ loading: true, tableState: state });

    const { token, lang } = this.props;
    const { report } = this.state;
    const endpoint = report ? 'agroraport-articles' : 'articles';
    const membership = !report ? '&membership=1' : '';

    let url = `/api/v2/${endpoint}/?limit=${state.pageSize}&lang_code=${lang}${membership}`;
    let params = [];
    let offset = state.page * state.pageSize;
    if (offset) {
      params.push(`offset=${offset}`);
    }

    if (state.filtered.length) {
      state.filtered.forEach((element) => {
        if (element.id === 'name') {
          params.push(`search=${element.value}`);
        } else if (element.id === 'article_type') {
          params.push(`${element.id}_id=${element.value}`);
        } else {
          params.push(`${element.id}=${element.value}`);
        }
      });
    }

    let sort = [];

    if (state.sorted.length) {
      sort.push(`${state.sorted[0].desc ? '-' : ''}${state.sorted[0].id}`);
    } else {
      sort.push(`-created_at`);
    }

    if (params.length) {
      let allParams = params.reduce((a, b) => {
        return a + '&' + b;
      });
      url = url + '&' + allParams;
    }

    if (sort.length) {
      let allParams = sort.reduce((a, b) => {
        return a + ',' + b;
      });
      url = url + '&ordering=' + allParams;
    }

    API.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(({ data }) => {
      this.setState({
        data: data.results,
        pages: calculateMaxPages(data.count, state.pageSize),
        loading: false
      });
    });
  }

  render() {
    const { data, pages, report, slug } = this.state;
    return (
      <Layout>
        <h1 className="h2 pb-md-4 page-header">
          Artykuły {report && 'Agroraportu '}
          <Button
            onClick={(e) => {
              e.preventDefault();
              this.props.history.push(`/${slug}/new`);
            }}
            color="primary"
          >
            Dodaj nowy
          </Button>
        </h1>
        <h4>Lista artykułów</h4>
        <div className="table-responsive">
          <ReactTable
            columns={[
              {
                Header: 'Lp.',
                Cell: (row) => {
                  return <div>{row.index + 1 + row.page * row.pageSize}</div>;
                },
                filterable: false,
                maxWidth: 40
              },
              {
                Header: 'Tytuł',
                accessor: 'name'
              },
              {
                Header: 'Kategoria',
                id: 'article_type',
                accessor: (d) => this.getNameOfSelect(d.article_type),
                maxWidth: 210,
                filterMethod: (filter, row) => {
                  if (filter.value === '') {
                    return true;
                  }
                  return row._original.article_type === parseInt(filter.value);
                },
                Filter: ({ onChange }) => (
                  <ArticleTypesList
                    isList={false}
                    name="article-type"
                    selectType={(id) => onChange(id)}
                    sm
                    report={report}
                  />
                )
              },
              {
                Header: 'Data modyfikacji',
                id: 'updated_at',
                accessor: (d) => formatDate(new Date(d.updated_at)),
                filterable: false,
                maxWidth: 210
              },
              {
                Header: 'Status',
                id: 'publish_status',
                accessor: (d) => displayStatus(d.publish_status),
                maxWidth: 180,
                Filter: ({ onChange }) => (
                  <Input
                    type="select"
                    // name={this.props.name}
                    id={`select${this.props.name}`}
                    onChange={(e) => onChange(e.target.value)}
                    // ref={this.selectInput}
                    // value={this.state.value}
                    size="sm"
                  >
                    <option value="">Wybierz</option>
                    {statuses.map((type) => (
                      <option value={type.id} key={type.id}>
                        {type.label}
                      </option>
                    ))}
                  </Input>
                )
              },
              {
                Header: 'Akcje',
                Cell: (d) => {
                  return (
                    <>
                      <Button
                        color="primary"
                        onClick={() =>
                          this.props.history.push(`/${slug}/${d.original.slug}`)
                        }
                        size="sm"
                      >
                        Edytuj
                      </Button>
                      <Button
                        color="secondary"
                        size="sm"
                        onClick={() =>
                          window.open(
                            report
                              ? `${frontLink()}agroraport/article/${
                                  d.original.slug
                                }`
                              : `${frontLink()}articles/${d.original.slug}`,
                            '_blank' // <- This is what makes it open in a new window.
                          )
                        }
                      >
                        Zobacz
                      </Button>
                      <DeleteButton
                        callback={() => this.removeArticle(d.original.slug)}
                      />
                    </>
                  );
                },
                filterable: false,
                maxWidth: 210
              }
            ]}
            manual
            data={data}
            pages={pages}
            filterable
            resizable={false}
            onFetchData={this.fetchData}
            defaultPageSize={10}
            className="-striped -highlight"
          />
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { articleTypes, user, settings } = state;
  return {
    articleTypes,
    token: user.token.key,
    userType: user.user_type,
    lang: settings.lang
  };
}

export default connect(mapStateToProps, {
  fetchArticleTypes,
  removeArticle
})(Agroraports);
