import JSCookie from 'js-cookie';

class Cookies {
  TOKEN_NAME = 'bnp_token';
  TOKEN_ID = 'bnp_id';
  LANG = 'bnp_cms_lang';

  setToken(token, expire_at, id) {
    if (token) {
      JSCookie.set(this.TOKEN_NAME, token, {
        expires: expire_at
      });
      JSCookie.set(this.TOKEN_ID, id, {
        expires: expire_at
      });
    }
  }

  getToken() {
    return JSCookie.get(this.TOKEN_NAME);
  }

  getUserData() {
    return {
      token: JSCookie.get(this.TOKEN_NAME),
      id: JSCookie.get(this.TOKEN_ID)
    };
  }

  removeToken() {
    JSCookie.remove(this.TOKEN_NAME, {
      path: '/'
    });
    JSCookie.remove(this.TOKEN_ID, {
      path: '/'
    });
  }

  getTokenFromRequest(req) {
    return req.cookies[this.TOKEN_NAME];
  }

  setLang(lang) {
    return JSCookie.set(this.LANG, lang, {});
  }

  getLang() {
    return JSCookie.get(this.LANG);
  }
}

const cookies = new Cookies();

export default cookies;
