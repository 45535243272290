import React, { useState } from 'react';
import SelectMedia from '../../components/Media/selectMedia';
import {
  Button,
  ListGroup,
  ListGroupItem,
  ButtonGroup,
  FormGroup,
  Label,
  Form
} from 'reactstrap';
import MediaItem from '../../common/Media/item';

function RealEstateMedia({ medias, onRemove, onAdd }) {
  const [mediaList, setMediaList] = useState(medias || []);
  return (
    <>
      <h2 className="h2 pb-md-4 page-header">
        Media{' '}
        <SelectMedia
          selectType="files"
          selectedFile={(file) => {
            const newMedias = [file, ...mediaList];
            setMediaList(newMedias);
            onAdd(newMedias);
          }}
        />
      </h2>

      <ListGroup>
        {mediaList.length ? (
          mediaList.map((medium) => (
            <ListGroupItem
              key={medium.id}
              className="listItem--with-button listItem--with-button-top"
              action
            >
              <div className="files-item">
                <MediaItem medium={medium} />
                <ButtonGroup size="sm">
                  <Button
                    color="danger"
                    onClick={() => {
                      const newMedias = mediaList.filter(
                        (media) => medium.id !== media.id
                      );
                      setMediaList(newMedias);
                      onRemove(newMedias);
                    }}
                  >
                    Usuń
                  </Button>
                </ButtonGroup>
              </div>
            </ListGroupItem>
          ))
        ) : (
          <ListGroupItem>Brak plików</ListGroupItem>
        )}
      </ListGroup>
    </>
  );
}

export default RealEstateMedia;
