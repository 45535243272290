import React, { Component } from 'react';
import {
  Button,
  FormGroup,
  Label,
  Input,
  ListGroup,
  ListGroupItem
} from 'reactstrap';

export const Lectures = ({
  setFieldValue,
  values,
  editMode,
  eventid,
  handleChange,
  removeLecture
}) => {
  return (
    <FormGroup>
      <Label for="prelegents">Prelegenci</Label>
      {values ? (
        <ListGroup>
          {values.map((item, index) => (
            <ListGroupItem
              className="d-flex align-items-start justify-content-between"
              key={index}
            >
              <div className="col">
                <Label style={{ width: '100%' }}>
                  <div>Imię</div>
                  <Input
                    type="text"
                    name={`prelegents.${index}.first_name`}
                    onChange={handleChange}
                    value={values[index].first_name}
                    required
                  />
                </Label>
                <Label style={{ width: '100%' }}>
                  <div>Nazwisko</div>
                  <Input
                    type="text"
                    onChange={handleChange}
                    name={`prelegents.${index}.last_name`}
                    value={values[index].last_name}
                    required
                  />
                </Label>
                <FormGroup>
                  <Label>Zdjęcie</Label>
                  {/* <img src="" alt=""/> */}
                  {editMode && !values[index].thumbnail && (
                    <Input
                      type="text"
                      disabled
                      value={
                        values[index].thumbnail === null
                          ? null
                          : values[index].thumbnail.filename
                      }
                    />
                  )}
                  <Input
                    type="file"
                    name={`prelegents.${index}.thumbnail`}
                    onChange={event => {
                      setFieldValue(
                        `prelegents[${index}].thumbnail`,
                        event.currentTarget.files[0]
                      );
                    }}
                    value={
                      values[index].thumbnail === null
                        ? null
                        : values[index].thumbnail.filename
                    }
                    // required
                  />
                </FormGroup>
                <Label style={{ width: '100%' }}>
                  <div>Opis</div>
                  <Input
                    type="textarea"
                    onChange={e =>
                      setFieldValue(
                        `prelegents[${index}].description`,
                        e.target.value
                      )
                    }
                    value={values[index].description}
                  />
                </Label>
              </div>
              <Button
                type="button"
                onClick={() => {
                  if (item.id) {
                    removeLecture(item.id);
                  }
                  values.splice(index, 1);
                  setFieldValue('prelegents', values);
                  return false;
                }}
              >
                X
              </Button>
            </ListGroupItem>
          ))}
        </ListGroup>
      ) : null}
      <Button
        type="button"
        onClick={() => {
          setFieldValue('prelegents', [
            ...values,
            {
              first_name: '',
              last_name: '',
              thumbnail: '',
              description: ''
            }
          ]);
        }}
      >
        Dodaj prelegenta
      </Button>
    </FormGroup>
  );
};
