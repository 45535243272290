import React from 'react';
import PlainLayout from '../../components/Layout/plain.jsx';
import { Row, Col } from 'reactstrap';
import LoginForm from '../../components/Forms/LoginForm.jsx';

class LoginPage extends PlainLayout {

  render() {
    return (
      <PlainLayout>
        <Row className="justify-content-center">
          <Col sm={{ size: 5 }}>
            <LoginForm />
          </Col>
        </Row>
      </PlainLayout>
    );
  }
}

export default LoginPage;
