import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col
} from 'reactstrap';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

import API from '../../../../services/api';
import cookies from '../../../../utilities/cookies';

import { addNewProduct } from '../../state/prf.actions';

const validationSchema = Yup.object().shape({
  titlePL: Yup.string().required('Pole wymagane'),
  namePL: Yup.string().required('Pole wymagane'),
  titleGB: Yup.string().required('Pole wymagane'),
  nameGB: Yup.string().required('Pole wymagane')
});

const AddProductModal = ({ isOpen, toggle }) => {
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();

  const handleSubmit = ({
    titlePL,
    titleGB,
    namePL,
    nameGB,
    creator,
    slug
  }) => {
    dispatch(
      addNewProduct(
        {
          pl: namePL,
          gb: nameGB
        },
        {
          pl: titlePL,
          gb: titleGB
        },

        creator,
        slug
      )
    );
    toggle();
  };

  useEffect(() => {
    if (!users.length) {
      const token = cookies.getToken();
      API.get(`/api/v1/users/`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          user_type: 5,
          limit: 999
        }
      }).then(({ data }) => {
        setUsers(data.results);
      });
    }
  }, [users.length]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          namePL: '',
          titlePL: '',
          nameGB: '',
          titleGB: '',
          slug: '',
          creator: null
        }}
        validationSchema={validationSchema}
        render={({ handleSubmit, errors, values, handleChange, touched }) => (
          <Form onSubmit={handleSubmit}>
            <ModalHeader toggle={toggle}>Dodaj nowy produkt</ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label for="namePL">Nazwa produktu (PL)</Label>
                    <Input
                      type="text"
                      name="namePL"
                      id="namePL"
                      invalid={!!errors.namePL && touched.namePL}
                      value={values.namePL}
                      onChange={handleChange}
                    />
                    {!!errors.namePL && touched.namePL && (
                      <FormFeedback>{errors.namePL}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label for="nameGB">Nazwa produktu (GB)</Label>
                    <Input
                      type="text"
                      name="nameGB"
                      id="nameGB"
                      invalid={!!errors.nameGB && touched.nameGB}
                      value={values.nameGB}
                      onChange={handleChange}
                    />
                    {!!errors.nameGB && touched.nameGB && (
                      <FormFeedback>{errors.nameGB}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label for="titlePL">Tytuł strony (PL)</Label>
                    <Input
                      type="text"
                      name="titlePL"
                      id="titlePL"
                      invalid={!!errors.titlePL && touched.titlePL}
                      value={values.titlePL}
                      onChange={handleChange}
                    />
                    {!!errors.titlePL && touched.titlePL && (
                      <FormFeedback>{errors.titlePL}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label for="title">Tytuł strony (GB)</Label>
                    <Input
                      type="text"
                      name="titleGB"
                      id="titleGB"
                      invalid={!!errors.titleGB && touched.titleGB}
                      value={values.titleGB}
                      onChange={handleChange}
                    />
                    {!!errors.titleGB && touched.titleGB && (
                      <FormFeedback>{errors.titleGB}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label for="name">Osoba odpowiedzialna</Label>
                    <Input
                      name="creator"
                      id="creator"
                      invalid={!!errors.creator && touched.creator}
                      value={values.creator}
                      onChange={handleChange}
                      type="select"
                    >
                      <option value={null}> - </option>
                      {users.map(({ id, first_name, last_name }) => (
                        <option key={id} value={id}>
                          {first_name} {last_name}
                        </option>
                      ))}
                    </Input>
                    {!!errors.creator && touched.creator && (
                      <FormFeedback>{errors.creator}</FormFeedback>
                    )}
                  </FormGroup>
                  <Link to="/users/new">
                    <Button type="button">Dodaj nowego użytkownika</Button>
                  </Link>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label for="slug">Slug strony (opcjonalne)</Label>
                    <Input
                      type="text"
                      name="slug"
                      id="slug"
                      invalid={!!errors.slug && touched.slug}
                      value={values.slug}
                      onChange={handleChange}
                    />
                    {!!errors.namePL && touched.slug && (
                      <FormFeedback>{errors.slug}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" type="submit">
                Dodaj produkt
              </Button>
              <Button color="secondary" type="button" onClick={toggle}>
                Anuluj
              </Button>
            </ModalFooter>
          </Form>
        )}
      />
    </Modal>
  );
};

export default AddProductModal;
