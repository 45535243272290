import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import SelectMedia from '../../components/Media/selectMedia';
import { Button, ListGroup, ListGroupItem, ButtonGroup } from 'reactstrap';

import API from '../../services/api';
import MediaItem from '../../common/Media/item';

const RealEstateThumbnailWrapper = styled.div`
  .files-item__preview-row {
    flex-direction: column;
  }
  .files-item__preview--img {
    width: 100%;
    height: auto;
  }
`;

function RealEstateThumbnail({ medias, onRemove, onAdd }) {
  const [thumbnail, setThumbnail] = useState(null);
  const token = useSelector((state) => state.user.token.key);
  useEffect(() => {
    if (!!medias) {
      API.get(`/api/v1/media-files/${medias}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(({ data }) => {
        setThumbnail(data);
      });
    }
  }, []);
  return (
    <RealEstateThumbnailWrapper>
      <p className="h6 pb-md-4 page-header">
        Miniaturka{' '}
        <SelectMedia
          selectType="files"
          small
          selectedFile={(file) => {
            setThumbnail(file);
            onAdd(file.id);
          }}
        />
      </p>
      <ListGroup>
        {thumbnail ? (
          <ListGroupItem
            className="listItem--with-button listItem--with-button-top"
            action
          >
            <div className="files-item">
              <MediaItem medium={thumbnail} />
              <ButtonGroup size="sm">
                <Button
                  color="danger"
                  onClick={() => {
                    setThumbnail(null);
                    onRemove();
                  }}
                >
                  Usuń
                </Button>
              </ButtonGroup>
            </div>
          </ListGroupItem>
        ) : (
          <ListGroupItem>Brak miniaturki</ListGroupItem>
        )}
      </ListGroup>
    </RealEstateThumbnailWrapper>
  );
}

export default RealEstateThumbnail;
