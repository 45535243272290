import React from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import slugify from 'slugify';

import { publishStatus as publishStatusList } from '../../consts/prf.const';

import { componentsProps, componentsPropsUI } from './ProductEditActions.utils';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Pole wymagane'),
  name: Yup.string().required('Pole wymagane')
});

const ProductEditActions = ({
  onComponentClick,
  onSave,
  meta,
  name,
  title,
  slug,
  publishStatus,
  creator
}) => {
  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      meta: meta || '',
      name: name,
      title: title,
      publish_status: publishStatus,
      slug
    },
    onSubmit: (values) => {
      onSave({
        ...values,
        slug: slugify(values.slug)
      });
    }
  });

  return (
    <>
      <Card>
        <CardBody>
          <Form>
            <FormGroup>
              <Label for="slug">
                Slug strony <i>(/kredytomat/{formik.values.slug})</i>
              </Label>
              <Input
                id="name"
                name="name"
                type="text"
                value={formik.values.slug}
                invalid={!!formik.errors.slug}
                onChange={({ target }) => {
                  formik.setFieldValue('slug', target.value);
                }}
              />
              {!!formik.errors.slug && (
                <FormFeedback>{formik.errors.slug}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="name">Nazwa</Label>
              <Input
                id="name"
                name="name"
                type="text"
                value={formik.values.name}
                invalid={!!formik.errors.name}
                onChange={({ target }) => {
                  formik.setFieldValue('name', target.value);
                }}
              />
              {!!formik.errors.name && (
                <FormFeedback>{formik.errors.name}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="title">Tytuł strony</Label>
              <Input
                id="title"
                name="title"
                type="text"
                value={formik.values.title}
                invalid={!!formik.errors.title}
                onChange={({ target }) => {
                  formik.setFieldValue('title', target.value);
                }}
              />
              {!!formik.errors.title && (
                <FormFeedback>{formik.errors.title}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="meta">Meta</Label>
              <Input
                id="meta"
                name="meta"
                type="text"
                value={formik.values.meta}
                onChange={({ target }) => {
                  formik.setFieldValue('meta', target.value);
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="publish_status">Status</Label>
              <Input
                id="publish_status"
                name="publish_status"
                value={formik.values.publish_status}
                type="select"
                onChange={({ target }) => {
                  formik.setFieldValue('publish_status', target.value);
                }}
              >
                {Object.keys(publishStatusList).map((key) => (
                  <option key={key} value={key}>
                    {publishStatusList[key]}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="creator">Specjalista</Label>
              <Input
                id="creator"
                name="creator"
                type="creator"
                value={
                  !!creator ? `${creator.first_name} ${creator.last_name}` : '-'
                }
                disabled
              />
              {!!formik.errors.slug && (
                <FormFeedback>{formik.errors.slug}</FormFeedback>
              )}
            </FormGroup>
          </Form>
          <Button
            disabled={!formik.isValid}
            onClick={() => formik.submitForm()}
          >
            Zapis
          </Button>
        </CardBody>
      </Card>
      <Card style={{ marginTop: '1rem' }}>
        <CardBody>
          <CardTitle tag="h5">Komponenty</CardTitle>
          <div style={{ margin: '0 -4px' }}>
            {Object.keys(componentsProps).map((key) => (
              <Button
                style={{ margin: '4px' }}
                key={key}
                onClick={() => {
                  onComponentClick({
                    id: Date.now(),
                    type: key,
                    props: componentsProps[key]
                  });
                }}
              >
                {componentsPropsUI[key].name}
              </Button>
            ))}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ProductEditActions;
