import axios from 'axios';

let getApiUrlFromEnv = env => {
  switch (env) {
    case 'production':
      return 'https://api.agronomist.pl/';

    case 'stage':
      return 'https://meltida-api.agronomist.pl/';

    default:
    case 'develop':
      return 'https://api.devel.bnp.kerris.co/';
  }
};

export const frontLink = () => {
  switch (process.env.REACT_APP_API_ENV) {
    case 'production':
      return 'https://agronomist.pl/';

    case 'stage':
      return 'https://300codes.agronomist.pl/';

    default:
    case 'develop':
      return 'https://devel.bnp.kerris.co/';
  }
};

export default axios.create({
  baseURL:
    process.env.NODE_ENV !== 'development'
      ? getApiUrlFromEnv(process.env.REACT_APP_API_ENV)
      : ''
});
