import React from 'react';
import AsyncSelect from 'react-select/async';
import { Input } from 'reactstrap';

export const AsyncFormikSelect = ({
  field,
  form: { setFieldValue },
  ...props
}) => {
  return (
    <AsyncSelect
      {...field}
      {...props}
      cacheOptions
      label={field.value && field.value.label}
      value={field.value && field.value.value}
      onChange={(option) => setFieldValue(field.name, option.value)}
    />
  );
};

export const FormikCheckbox = ({ field, ...rest }) => (
  <Input type="checkbox" {...field} {...rest} checked={field.value} />
);

export const FormikSelect = ({ field, options, ...rest }) => (
  <Input {...field} {...rest}>
    {options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </Input>
);

export const FormikInput = ({ field, ...rest }) => (
  <Input {...field} {...rest} />
);

export const STATUS = [
  {
    label: 'Szkic',
    value: 1
  },
  {
    label: 'Opublikowana',
    value: 2
  },
  {
    label: 'Sprzedana',
    value: 3
  }
];
