import React from "react";
import Layout from "../../components/Layout/index.jsx";
import { Button, Input, Row, Col } from "reactstrap";
import ConversationsList from "../../components/Experts/ConversationsList.jsx";

class Polls extends React.Component {
  render() {
    return (
      <Layout>
        <h1 className="h2 pb-md-4 page-header">Konwersacje</h1>
        <Row className="space-between">
          <Col>
            <h4>Lista konwersacji</h4>
            <ConversationsList></ConversationsList>
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default Polls;
