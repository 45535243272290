import React, { useState, useEffect } from 'react';
import { useParams, Redirect, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import { useDispatch } from 'react-redux';

import Layout from '../../../components/Layout';
import { selectProductById } from '../state/prf.selectors';
import { updateProduct } from '../state/prf.actions';
import ProductEditActions from '../components/ProductEditActions';
import ElementsForms from '../components/ElementsForms';
import PagePreview from '../components/PagePreview';
import { clearActions } from '../../../services/redux/reducers/alerts/actionCreators';
import { selectUserType } from '../../../services/redux/reducers/user/selectors';
import { USER_TYPE_PERMISSION } from '../consts/prf.const';

const getInitPage = (product) => {
  if (!product)
    return {
      pl: [],
      gb: []
    };

  if (product.body.pl) {
    return product.body;
  }

  return {
    pl: [],
    gb: []
  };
};

const languages = ['pl', 'gb'];

const Product = () => {
  const [language, setLanguage] = useState('pl');
  const { slug } = useParams();
  const product = useSelector(selectProductById(slug));
  const [page, setPage] = useState(getInitPage(product));
  const [tab, setTab] = useState(1);
  const dispatch = useDispatch();
  const userType = useSelector(selectUserType);

  useEffect(() => {
    if (JSON.stringify(product.body) !== JSON.stringify(page)) {
      dispatch(
        updateProduct({
          ...product,
          body: page
        })
      );
    }

    // eslint-disable-next-line
  }, [page]);

  const onProductUpdate = (values) => {
    const updatedProduct = JSON.parse(JSON.stringify(product));
    updatedProduct.meta[language] = values.meta;
    updatedProduct.name[language] = values.name;
    updatedProduct.title[language] = values.title;
    updatedProduct.publish_status = Number(values.publish_status);
    updatedProduct.slug = values.slug;

    dispatch(
      updateProduct({
        ...updatedProduct,
        body: page
      })
    );

    setTimeout(() => {
      dispatch(clearActions());
    }, 2000);
  };

  const onRemoveElement = (id) => {
    setPage((state) => ({
      pl: state.pl.filter((element) => element.id !== id),
      gb: state.gb.filter((element) => element.id !== id)
    }));
  };

  const onMoveElementTop = (idx) => {
    const newIdx = idx - 1;
    const copySate = JSON.parse(JSON.stringify(page));

    languages.forEach((lng) => {
      const copyElement = page[lng][newIdx];
      copySate[lng][newIdx] = page[lng][idx];
      copySate[lng][idx] = copyElement;
    });

    setPage(copySate);
  };

  const onMoveElementButton = (idx) => {
    const newIdx = idx + 1;
    const copySate = JSON.parse(JSON.stringify(page));

    languages.forEach((lng) => {
      const copyElement = page[lng][newIdx];
      copySate[lng][newIdx] = page[lng][idx];
      copySate[lng][idx] = copyElement;
    });

    setPage(copySate);
  };

  const onUpdateElement = (element) => {
    const copySate = JSON.parse(JSON.stringify(page));
    copySate[language] = copySate[language].map((elm) => {
      if (elm.id === element.id) {
        return element;
      }

      return elm;
    });

    setPage(copySate);
  };

  if (!USER_TYPE_PERMISSION.includes(userType)) {
    return <Redirect to="/" />;
  }

  if (!product) {
    return <Redirect to="/products" />;
  }

  return (
    <Layout>
      <Row style={{ marginBottom: '1rem' }}>
        <Col xs={9}>
          <Link to="/products">
            <FontAwesomeIcon
              icon={faAngleLeft}
              style={{ marginRight: '0.5rem' }}
            />
            Wróć
          </Link>
        </Col>
        <Col xs={3}>
          <FormGroup>
            <Label for="language">Język</Label>
            <Input
              id="language"
              name="language"
              value={language}
              type="select"
              onChange={({ target }) => {
                setLanguage(target.value);
              }}
            >
              <option value="pl">Polski</option>
              <option value="gb">Angielski</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={9}>
          <Nav tabs>
            <NavItem style={{ cursor: 'pointer' }}>
              <NavLink
                className={tab === 1 && 'active'}
                onClick={() => setTab(1)}
              >
                Edycja
              </NavLink>
            </NavItem>
            <NavItem style={{ cursor: 'pointer' }}>
              <NavLink
                className={tab === 2 && 'active'}
                onClick={() => setTab(2)}
              >
                Podgląd
              </NavLink>
            </NavItem>
          </Nav>
          <Card>
            <CardBody>
              <TabContent activeTab={tab}>
                <TabPane tabId={1}>
                  <ElementsForms
                    page={page}
                    onRemove={onRemoveElement}
                    onMoveTop={onMoveElementTop}
                    onMoveButton={onMoveElementButton}
                    onUpdate={onUpdateElement}
                    language={language}
                  />
                </TabPane>
                <TabPane tabId={2}>
                  <PagePreview page={page} language={language} />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
        <Col xs={3}>
          <ProductEditActions
            onComponentClick={(element) => {
              setPage((state) => ({
                pl: [...state.pl, element],
                gb: [...state.gb, element]
              }));
            }}
            onSave={onProductUpdate}
            meta={product.meta[language]}
            name={product.name[language]}
            title={product.title[language]}
            publishStatus={product.publish_status}
            slug={product.slug}
            creator={product.creator}
          />
        </Col>
      </Row>
    </Layout>
  );
};

export default Product;
