import React from "react";
import { Button, Col, Card, CardBody, FormText } from "reactstrap";
import { InputField, DatepickerField, RepeatField } from "../../common/Inputs";
import { DeleteButton } from "../../components/FunctionalButtons/DeleteButton";
import { path } from 'ramda';
import * as Yup from "yup"
import { useFormik } from 'formik';

export const PollFormBody = ({
  deletePossibility,
  initialValues,
  downloadCSV,
  onSubmit
}) => {
  const { values, handleSubmit, handleChange, setFieldValue, touched, errors } = useFormik({
    validationSchema: Yup.object().shape({
        title: Yup.string()
          .required("Pole jest wymagane")
          .max(1000, "Pole nie powinno mieć więcej niż 1000 znaków"),
        possibilities: Yup.mixed()
          .when(['id'], {
            is: (val) => val,
            then: Yup
              .array().of(
                Yup.object().shape({
                  answer: Yup.string().trim().required('Pole jest wymagane')
                })
              )
              .min(2, "Muszą być minimum 2 odpowiedzi"),
            otherwise: Yup.array()
          })

      }),
    initialValues,
    onSubmit
  })

  const { grouping_id } = initialValues;

  const getValidationOfAnswer = (index) => {
    const _touched = path(['possibilities', index, 'answer'], touched)
    const _errors = path(['possibilities', index, 'answer'], errors)
    return _touched && _errors
  }

  return (
    <form onSubmit={handleSubmit} className="row">
      <Col sm="8">
        <InputField
          label="Tytuł"
          type="text"
          name="title"
          id="title"
          required
          value={values.title}
          onChange={handleChange}
          validation={errors.title}
        />
        <FormText className="mb-3">
          Upewnij się, że to pole ma nie więcej niż 1000 znaków.
        </FormText>
        <DatepickerField
          label="Data publikacji"
          name="published_at"
          onChange={val => {
            setFieldValue("published_at", val);
          }}
          value={values.published_at}
        />
        <fieldset disabled={!values.id}>
          <RepeatField
            label="Odpowiedzi"
            group={values.possibilities}
            name="possibilities"
            pushElements={e => {
              setFieldValue("possibilities", [
                ...values.possibilities,
                {
                  answer: "",
                  grouping_id
                }
              ]);
            }}
            onChange={handleChange}
            pushButton="Dodaj odpowiedź"
            validation={touched.possibilities && errors.possibilities}
            children={(item, index) => {
              return (
                <>
                  <Col>
                    <InputField
                      label="Odpowiedź"
                      name={`possibilities[${index}].answer`}
                      value={item.answer}
                      onChange={handleChange}
                      validation={getValidationOfAnswer(index)}
                    />
                  </Col>
                  <DeleteButton
                    buttonLabel="X"
                    callback={() => {
                      if (item.id) {
                        deletePossibility(item.id);
                      }
                      values.possibilities.splice(index, 1);
                      setFieldValue("possibilities", values.possibilities);
                    }}
                  />
                </>
              );
            }}
          />
        </fieldset>
        <FormText className="mt-3 mb-3">
          Długość odpowiedzi może wynosić maksymalnie 70 znaków.
        </FormText>
        {!values.id ? (
          <FormText>W celu dodania odpowiedzi zapisz najpierw ankietę</FormText>
        ) : null}
      </Col>
      <Col sm="4">
        <Card className="card--sticky">
          <CardBody className="d-flex justify-content-between flex-wrap mr-auto">
            <Button
              type="submit"
              color="primary"
              className="mb-2 mr-2"
            >
              Zapisz
            </Button>
            <Button
              type="button"
              color="secondary"
              className="mb-2"
              onClick={() => downloadCSV()}
              disabled={!grouping_id}
            >
              Eksportuj do CSV
            </Button>
          </CardBody>
        </Card>
      </Col>
    </form>
  );
};
