import API from '../../../api';

export const fetchArticleCategories = (token, lang, report) =>
  API.get(`/api/v1/article-tags/?membership=${report ? 2 : 1}&limit=20`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

const CategoryType = {
  AGRONOMIST: 1,
  AGRORAPORT: 2
};
export const fetchArticleCategoriesNew = (token, lang) =>
  API.get(
    `/api/v1/article-categories/?membership=${CategoryType.AGRONOMIST}&limit=999`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );

export const createCategory = (token, data) => {
  return API.post(
    '/api/v1/article-tags/',
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

export const editCategory = (token, data, id) => {
  return API.put(
    `/api/v1/article-tags/${id}/`,
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

export const deleteCategory = (token, id) => {
  return API.delete(`/api/v1/article-tags/${id}/`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const fetchPromotedArticleCategories = (token, category, lang_code) =>
  API.get('/api/v2/promoted-articles/', {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      category,
      lang_code
    }
  });
