import React from 'react';
import { useFormik } from 'formik';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import debounce from 'lodash.debounce';

import Inputs from '../../Inputs';

// "black" | "brandGreen" | "brandOrange" | "white"

const TextIconInput = ({ formik, onBlur, ids }) => {
  const [x, y] = ids;
  const isIcon = !!formik.values.features[x].list[y].name;

  if (isIcon) {
    return (
      <div style={{ marginBottom: '1rem' }}>
        <div style={{ padding: '4px', border: '1px solid black' }}>
          <Inputs.IconsInput
            labelProps={{ for: `features[${x}].list[${y}].name` }}
            inputProps={{
              name: `features[${x}].list[${y}].name`,
              value: formik.values.features[x].list[y].name,
              onChange: ({ target }) => {
                formik.setFieldValue(
                  `features[${x}].list[${y}].name`,
                  target.value
                );
                onBlur();
              }
            }}
          />
          <Input
            type="select"
            onChange={(e) => {
              formik.setFieldValue(
                `features[${x}].list[${y}].color`,
                e.target.value
              );
              onBlur();
            }}
            size="sm"
            defaultValue={formik.values.features[x].list[y].color}
          >
            <option value="brandOrange">Pomarańczowy</option>
            <option value="brandGreen">Zielony</option>
            <option value="black">Czarny</option>
            <option value="white">Biały</option>
          </Input>
          <Input
            type="select"
            onChange={(e) => {
              formik.setFieldValue(
                `features[${x}].list[${y}].size`,
                e.target.value
              );
              onBlur();
            }}
            size="sm"
            defaultValue={formik.values.features[x].list[y].size}
          >
            <option value="1rem">xs</option>
            <option value="2rem">sm</option>
            <option value="3rem">lg</option>
            <option value="6rem">xl</option>
          </Input>
        </div>
        <Button
          block
          size="sm"
          onClick={() => {
            formik.setFieldValue(`features[${x}].list[${y}]`, '');
            onBlur();
          }}
        >
          Zmień na tekst
        </Button>
      </div>
    );
  }

  return (
    <div style={{ marginBottom: '1rem' }}>
      <Input
        id={`features[${x}].list[${y}]`}
        name={`features[${x}].list[${y}]`}
        type="textarea"
        value={formik.values.features[x].list[y]}
        onBlur={onBlur}
        onChange={({ target }) => {
          formik.setFieldValue(`features[${x}].list[${y}]`, target.value);
        }}
      />
      <Button
        block
        size="sm"
        onClick={() => {
          formik.setFieldValue(`features[${x}].list[${y}]`, {
            name: 'TickSharp',
            color: 'brandOrange',
            size: '6rem'
          });
          onBlur();
        }}
      >
        Zmień na ikonę
      </Button>
    </div>
  );
};

const FeaturesInputs = ({ id, formik, onBlur, onRemove }) => {
  return (
    <Col xs={3}>
      <Button
        onClick={onRemove}
        type="button"
        style={{ marginBottom: '1rem' }}
        block
        color="danger"
      >
        Usuń benefit
      </Button>
      {!!formik.values.features[id].specialOffer ? (
        <div style={{ padding: '1rem', background: '#c2e7ff' }}>
          <FormGroup>
            <Label for={`features[${id}].specialOffer.text`}>Nazwa</Label>
            <Input
              maxLength={40}
              id={`features[${id}].specialOffer.text`}
              name={`features[${id}].specialOffer.text`}
              type="textarea"
              value={formik.values.features[id].specialOffer.text}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue(
                  `features[${id}].specialOffer.text`,
                  target.value
                );
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for={`features[${id}].specialOffer.textLink`}>
              Nazwa linku
            </Label>
            <Input
              id={`features[${id}].specialOffer.textLink`}
              name={`features[${id}].specialOffer.textLink`}
              type="textarea"
              value={formik.values.features[id].specialOffer.textLink}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue(
                  `features[${id}].specialOffer.textLink`,
                  target.value
                );
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for={`features[${id}].specialOffer.link`}>Link URL</Label>
            <Input
              id={`features[${id}].specialOffer.link`}
              name={`features[${id}].specialOffer.link`}
              type="textarea"
              value={formik.values.features[id].specialOffer.link}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue(
                  `features[${id}].specialOffer.link`,
                  target.value
                );
              }}
            />
          </FormGroup>
          <Button
            onClick={() => {
              formik.setFieldValue(`features[${id}].specialOffer`, undefined);
              onBlur();
            }}
            type="button"
            style={{ marginBottom: '1rem' }}
            block
            color="danger"
          >
            Usuń promocje
          </Button>
        </div>
      ) : (
        <Button
          onClick={() => {
            formik.setFieldValue(`features[${id}].specialOffer`, {
              text: 'Promocja\ndo x.xx',
              textLink: 'Sprawdź',
              link: '/'
            });
            onBlur();
          }}
          type="button"
          style={{ marginBottom: '1rem' }}
          block
          color="info"
        >
          Dodaj promocje
        </Button>
      )}
      <FormGroup>
        <Label for={`features[${id}].title`}>Nazwa</Label>
        <Input
          id={`features[${id}].title`}
          name={`features[${id}].title`}
          type="textarea"
          value={formik.values.features[id].title}
          onBlur={onBlur}
          onChange={({ target }) => {
            formik.setFieldValue(`features[${id}].title`, target.value);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label for={`features[${id}].subtitle`}>Podtytuł</Label>
        <Input
          id={`features[${id}].subtitle`}
          name={`features[${id}].subtitle`}
          type="textarea"
          value={formik.values.features[id].subtitle}
          onBlur={onBlur}
          onChange={({ target }) => {
            formik.setFieldValue(`features[${id}].subtitle`, target.value);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label
          for={`features[${id}].isRecommended`}
          style={{ marginRight: '1rem' }}
        >
          Jest rekomendowany
        </Label>
        <Input
          style={{ marginLeft: 'unset' }}
          id={`features[${id}].isRecommended`}
          name={`features[${id}].isRecommended`}
          type="checkbox"
          checked={formik.values.features[id].isRecommended}
          onBlur={onBlur}
          onChange={({ target }) => {
            formik.setFieldValue(
              `features[${id}].isRecommended`,
              target.checked
            );
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label for={`features[${id}].list`}>
          Nazwy benefitów (obsługa tagów)
        </Label>
        {formik.values.features[id].list.map((_, key) => (
          <TextIconInput
            key={`${id}_${key}`}
            formik={formik}
            onBlur={onBlur}
            ids={[id, key]}
          />
          // <Input
          //   id={`features[${id}].list[${key}]`}
          //   name={`features[${id}].list[${key}]`}
          //   type="textarea"
          //   value={formik.values.features[id].list[key]}
          //   onBlur={onBlur}
          //   onChange={({ target }) => {
          //     formik.setFieldValue(
          //       `features[${id}].list[${key}]`,
          //       target.value
          //     );
          //   }}
          // />
        ))}
      </FormGroup>
      {!formik.values.action && (
        <>
          <FormGroup>
            <Label for={`features[${id}].annotation`}>Adnotacja</Label>
            <Input
              id={`features[${id}].annotation`}
              name={`features[${id}].annotation`}
              type="textarea"
              value={formik.values.features[id].annotation}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue(
                  `features[${id}].annotation`,
                  target.value
                );
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for={`features[${id}].footer.text`}>Stopka</Label>
            <Input
              id={`features[${id}].footer.text`}
              name={`features[${id}].footer.text`}
              type="textarea"
              value={formik.values.features[id].footer.text}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue(
                  `features[${id}].footer.text`,
                  target.value
                );
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for={`features[${id}].footer.link`}>Link</Label>
            <Input
              id={`features[${id}].footer.link`}
              name={`features[${id}].footer.link`}
              type="textarea"
              value={formik.values.features[id].footer.link}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue(
                  `features[${id}].footer.link`,
                  target.value
                );
              }}
            />
          </FormGroup>
        </>
      )}
    </Col>
  );
};

const BenefitsTableForm = ({
  features,
  packages,
  footer,
  py,
  onChange,
  action,
  anchor
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      py,
      footer,
      packages: !!packages ? packages : [],
      features,
      action,
      anchor
    },
    onSubmit: (values) => {
      const isEmptyFooter = footer ? !footer.text : true;
      onChange({
        ...values,
        footer: isEmptyFooter ? null : values.footer
      });
    }
  });

  const onBlur = () => {
    formik.submitForm();
  };

  const debounceSave = debounce(() => {
    onBlur();
  }, 800);

  return (
    <Form>
      <Row>
        <Col xs={12}>
          <h6>Pakiety</h6>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="packages">Nazwy pakietów</Label>
            {formik.values.packages.map((_, key) => (
              <>
                <Input
                  key={`packages[${key}]`}
                  id={`packages[${key}]`}
                  name={`packages[${key}]`}
                  type="textarea"
                  value={formik.values.packages[key]}
                  onBlur={onBlur}
                  onChange={({ target }) => {
                    formik.setFieldValue(`packages[${key}]`, target.value);
                    debounceSave();
                  }}
                />
                <Button
                  onClick={() => {
                    formik.setFieldValue(
                      'packages',
                      [...formik.values.packages].filter((_, k) => k !== key)
                    );
                    formik.setFieldValue(
                      'features',
                      [...formik.values.features].map((feat) => ({
                        ...feat,
                        list: feat.list.filter((_, k) => k !== key)
                      }))
                    );
                    debounceSave();
                  }}
                  type="button"
                  style={{ marginBottom: '1rem' }}
                  block
                  color="danger"
                >
                  Usuń pakiet
                </Button>
              </>
            ))}
          </FormGroup>
        </Col>
        <Col xs={6}>
          {
            <Button
              onClick={() => {
                formik.setFieldValue('packages', [
                  ...formik.values.packages,
                  ''
                ]);
                formik.setFieldValue(
                  'features',
                  [...formik.values.features].map((feat) => ({
                    ...feat,
                    list: [...feat.list, '']
                  }))
                );
                debounceSave();
              }}
              type="button"
              style={{ marginBottom: '1rem' }}
              block
              color="success"
            >
              Dodaj pakiet
            </Button>
          }
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h6>Benefity</h6>
        </Col>
        {formik.values.features.map((feature, key) => (
          <FeaturesInputs
            formik={formik}
            onBlur={onBlur}
            key={key}
            id={key}
            onRemove={() => {
              formik.setFieldValue(
                'features',
                formik.values.features.filter((_, k) => k !== key)
              );
              debounceSave();
            }}
          />
        ))}
      </Row>
      <Row>
        {!!formik.values.action && (
          <Col xs={12}>
            <FormGroup>
              <Label for="action">Wspólna stopka</Label>
              <Input
                id="action"
                name="action.text"
                type="textarea"
                value={formik.values.action ? formik.values.action.text : ''}
                onBlur={onBlur}
                onChange={({ target }) => {
                  formik.setFieldValue('action.text', target.value);
                  debounceSave();
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="action">Wspólna stopka (link)</Label>
              <Input
                id="action"
                name="action.link"
                type="textarea"
                value={formik.values.action ? formik.values.action.link : ''}
                onBlur={onBlur}
                onChange={({ target }) => {
                  formik.setFieldValue('action.link', target.value);
                  debounceSave();
                }}
              />
            </FormGroup>
          </Col>
        )}
        <Col xs={12}>
          <Button
            onClick={() => {
              if (!!formik.values.action) {
                formik.setFieldValue(
                  'features',
                  formik.values.features.map((feature) => ({
                    ...feature,
                    footer: {
                      text: '',
                      link: ''
                    },
                    annotation: ''
                  }))
                );
                formik.setFieldValue('action', null);
              } else {
                formik.setFieldValue(
                  'features',
                  formik.values.features.map((feature) => ({
                    ...feature,
                    footer: null,
                    annotation: null
                  }))
                );
                formik.setFieldValue('action', {
                  text: 'ZAPYTAJ O OFERTĘ',
                  link: ''
                });
              }

              debounceSave();
            }}
            type="button"
            style={{ marginBottom: '1rem' }}
            block
            color={!!formik.values.action ? 'info' : 'success'}
          >
            {!!formik.values.action
              ? 'Podziel stopki benefitów'
              : 'Scal stopki benefitów'}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Button
            onClick={() => {
              formik.setFieldValue('features', [
                ...formik.values.features,
                {
                  isRecommended: false,
                  title: '',
                  subtitle: '',
                  list: formik.values.packages.map(() => 'Nazwa benefitu'),
                  footer: {
                    text: 'Aenean sed',
                    link: ''
                  },
                  annotation: ''
                }
              ]);
              debounceSave();
            }}
            type="button"
            style={{ marginBottom: '1rem' }}
            block
            color="success"
          >
            Dodaj benefit
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h6>Stopka</h6>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="footer">Tekst (Stopka)</Label>
            <Input
              id="footer"
              name="footer.text"
              type="textarea"
              value={formik.values.footer ? formik.values.footer.text : ''}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('footer.text', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="footer">Link (Stopka) (np /nazwa-strony)</Label>
            <Input
              id="footer"
              name="footer.link"
              type="textarea"
              value={formik.values.footer ? formik.values.footer.link : ''}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('footer.link', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="footer">Dodatkowy tekst (Stopka)</Label>
            <Input
              id="footer"
              name="footer.moreText"
              type="textarea"
              value={formik.values.footer ? formik.values.footer.moreText : ''}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('footer.moreText', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Inputs.SpacesInput
            id="py"
            name="py"
            value={formik.values.pt}
            invalid={!!formik.errors.pt}
            onBlur={onBlur}
            onChange={({ target }) => {
              formik.setFieldValue('py', target.value);
            }}
          />
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="anchor">Zakotwiczenie</Label>
            <Input
              id="anchor"
              name="anchor"
              type="text"
              value={formik.values.anchor}
              invalid={!!formik.errors.anchor}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('anchor', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default BenefitsTableForm;
