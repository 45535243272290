import React from 'react';
import { Spinner } from 'reactstrap';

export default function Loading({ fixed }) {
  return (
    <div className={['loading', fixed ? 'loading--fixed' : ''].join(' ')}>
      <Spinner style={{ width: '3rem', height: '3rem' }} color="primary" />
    </div>
  );
}
