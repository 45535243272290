import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import { alertActions } from '../../services/redux/actions';
import { loginUser } from '../../services/redux/reducers/user/actionCreators';

import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
// import { Formik } from 'formik';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    };
    this.loginForm = React.createRef();
  }

  checkValidation = () => {
    let form = document.querySelector('#loginForm');
    this.setState(
      {
        formValid: form.checkValidity()
      },
      this.redirectAfterLogin
    );
  };

  handleUserInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    let form = e.target;
    const { loginUser } = this.props;
    if (form.checkValidity()) {
      loginUser(this.state.email, this.state.password);
    }

    // store to update ?
  };

  redirectAfterLogin() {
    // redirect to home CMS
    this.props.history.push('/');
  }

  componentDidUpdate() {
    if (this.props.isLoggedIn) {
      this.redirectAfterLogin();
    }
  }

  render() {
    return (
      <Form
        action="/"
        id="loginForm"
        ref={this.loginForm}
        onSubmit={this.handleSubmit}
        noValidate
      >
        <div>
          <h1 className="text-center pt-md-4 pb-md-4">Zaloguj się</h1>
          <FormGroup>
            <Label for="exampleEmail">Email</Label>
            <Input
              type="email"
              name="email"
              placeholder="Wpisz adres e-mail"
              value={this.state.email}
              onChange={event => this.handleUserInput(event)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword">Password</Label>
            <Input
              type="password"
              name="password"
              placeholder="Wpisz hasło"
              value={this.state.password}
              onChange={event => this.handleUserInput(event)}
              required
            />
          </FormGroup>
          <Button type="submit" color="primary">
            Zaloguj się
          </Button>
        </div>
      </Form>
    );
  }
}

export default connect(
  state => ({
    token: state.user.token,
    isLoggedIn: !!state.user.email
  }),
  { loginUser }
)(withRouter(LoginForm));
