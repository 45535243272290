import React, { Component } from 'react';
import Layout from '../../components/Layout';
import MediaLayout from '../../components/Media';
import Heading from '../../common/Typo/heading';

class MediaView extends Component {
  render() {
    return (
      <Layout>
        <Heading title="Media" />
        <MediaLayout />
      </Layout>
    );
  }
}

export default MediaView;
