import * as actionTypes from './actionTypes';

export const fetchMedia = (page, filter) => ({
  type: actionTypes.FETCH_MEDIA,
  page,
  filter
});

export const setMedia = (data, count) => ({
  type: actionTypes.SET_MEDIA,
  data,
  count
});

export const createMedia = fileData => ({
  type: actionTypes.CREATE_MEDIA,
  fileData
});

export const editMedia = (id, fileData) => ({
  type: actionTypes.EDIT_MEDIA,
  id,
  fileData
});

export const deleteMedia = id => ({
  type: actionTypes.DELETE_MEDIA,
  id
});

export const fetchBanners = () => ({
  type: actionTypes.FETCH_BANNERS,
});
