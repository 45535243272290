import React from 'react';
import { useFormik } from 'formik';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import debounce from 'lodash.debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import Inputs from '../../Inputs';

const IncludedServicesListForm = ({
  onChange,
  py,
  title,
  services,
  background,
  justifyContent,
  anchor
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      anchor,
      py,
      title,
      services,
      background,
      justifyContent
    },
    onSubmit: (values) => {
      onChange(values);
    }
  });

  const onBlur = () => {
    formik.submitForm();
  };

  const debounceSave = debounce(() => {
    onBlur();
  }, 800);

  return (
    <Form>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="title">Tytuł</Label>
            <Input
              id="title"
              name="title"
              type="textarea"
              value={formik.values.title}
              invalid={!!formik.errors.title}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('title', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="justifyContent">Wyrównanie</Label>
            <Input
              type="select"
              value={formik.values.justifyContent}
              onChange={({ target }) => {
                formik.setFieldValue('justifyContent', target.value);
                onBlur();
              }}
            >
              <option value="flex-start">Początek</option>
              <option value="center">środek</option>
              <option value="flex-end">Koniec</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        {formik.values.services.map((service, key) => (
          <Col xs={6} key={service.icon}>
            <div
              style={{
                background: '#D3D3D3',
                padding: '1rem',
                marginBottom: '1rem'
              }}
            >
              <FormGroup>
                <Label for={`services[${key}].title`}>
                  <FontAwesomeIcon
                    style={{ margin: '0 4px 0 0' }}
                    icon={faTrashAlt}
                    onClick={() => {
                      formik.setFieldValue(
                        'services',
                        formik.values.services.filter((_, k) => key !== k)
                      );
                      onBlur();
                    }}
                  />
                  Tytuł
                </Label>
                <Input
                  id="title"
                  name="title"
                  type="textarea"
                  value={formik.values.services[key].title}
                  invalid={!!formik.errors.services}
                  onBlur={onBlur}
                  onChange={({ target }) => {
                    formik.setFieldValue(
                      `services[${key}].title`,
                      target.value
                    );
                    debounceSave();
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for={`services[${key}].description`}>Opis</Label>
                <Input
                  id="description"
                  name="description"
                  type="textarea"
                  value={formik.values.services[key].description}
                  invalid={!!formik.errors.services}
                  onBlur={onBlur}
                  onChange={({ target }) => {
                    formik.setFieldValue(
                      `services[${key}].description`,
                      target.value
                    );
                    debounceSave();
                  }}
                />
              </FormGroup>
              <Inputs.IconsInput
                labelProps={{ for: `services[${key}].icon` }}
                inputProps={{
                  name: `services[${key}].icon`,
                  value: formik.values.services[key].icon,
                  onChange: ({ target }) => {
                    formik.setFieldValue(`services[${key}].icon`, target.value);
                    onBlur();
                  }
                }}
              />
            </div>
          </Col>
        ))}
        <Col
          xs={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            justifyItems: 'center',
            alignItems: 'center'
          }}
        >
          <Button
            onClick={() => {
              formik.setFieldValue(`services`, [
                ...formik.values.services,
                {
                  title: '',
                  description: '',
                  icon: 'Money'
                }
              ]);
              onBlur();
            }}
          >
            Dodaj
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Inputs.SpacesInput
            id="py"
            name="py"
            value={formik.values.py}
            invalid={!!formik.errors.py}
            onBlur={onBlur}
            onChange={({ target }) => {
              formik.setFieldValue('py', target.value);
            }}
          />
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="anchor">Zakotwiczenie</Label>
            <Input
              id="anchor"
              name="anchor"
              type="text"
              value={formik.values.anchor}
              invalid={!!formik.errors.anchor}
              onBlur={onBlur}
              onChange={({ target }) => {
                formik.setFieldValue('anchor', target.value);
                debounceSave();
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default IncludedServicesListForm;
