import API from '../../../api';

export const fetchPolls = (token, grouping_id) =>
  API.get(`/api/v1/polls/?${grouping_id ? `grouping_id=${grouping_id}` : ''}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const fetchPoll = (token, id) =>
  API.get(`/api/v1/polls/${id}/`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const createPoll = (token, data) =>
  API.post(`/api/v1/polls/`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const updatePoll = (token, id, data) =>
  API.patch(`/api/v1/polls/${id}/`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const deletePoll = (token, id) =>
  API.delete(`/api/v1/polls/${id}/`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const createPossibility = (token, data) =>
  API.post(`/api/v1/poll-possibilities/`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const updatePossibility = (token, id, data) =>
  API.patch(`/api/v1/poll-possibilities/${id}/`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const deletePossibility = (token, id) =>
  API.delete(`/api/v1/poll-possibilities/${id}/`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
