import * as actionTypes from "./actionTypes";

export const fetchArticleTypes = () => ({
  type: actionTypes.FETCH_ARTICLE_TYPES
});

export const setArticleTypes = data => ({
  type: actionTypes.SET_ARTICLE_TYPES,
  data
});

export const setArticleTypesID = id => ({
  type: actionTypes.SET_ARTICLE_TYPES_ID,
  id
});

export const updateArticleTypes = data => ({
  type: actionTypes.UPDATE_ARTICLES_TYPES,
  data
});
