import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { ArticleCategories } from '../../common/Articles/categories';
import { Button, ButtonGroup, Col, FormFeedback, FormGroup, FormText, Input, Label, UncontrolledTooltip } from 'reactstrap';
import { InputField } from '../../common/Inputs';
import { SelectPhotoForArticle } from '../../common/Articles/select-photo';
import { SelectAttachments } from '../../common/Articles/select-attachments';
import { SelectPolls } from '../../common/Articles/select-polls';
import { Sidebar } from './Sidebar';
import { frontLink } from '../../services/api';
import { useArticleApi } from './Article.api';
import { cloneDeep } from 'lodash';
import Loading from '../../common/Loading';
import { getQueryStringValue } from '../../utilities/history';
import {
  setArticleTranslation,
  setArticleId,
  resetReq
} from '../../services/redux/reducers/articles/actionCreators';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { alertFailure, alertSuccess } from '../../services/redux/utils/mapResponse';
import TextEditor from '../../common/Editor';

const MODEL = {
  name: '',
  short_name: '',
  summary: '',
  short_summary: '',
  content: '',
  categories_list: [],
  article_type_id: '',
  thumb_id: '',
  carousel_photo_id: null,
  medias_list: [],
  author_name: '',
  author_summary: '',
  author_image: '',
  labels: [],
  published_at: null,
  published_all_at: null,
  video_url: '',
  polls_list: [],
  access: 1,
  featured: 1
}

export const AritcleForm = ({ report, articleid }) => {
  const model = cloneDeep(MODEL)
  const dispatch = useDispatch()
  const history = useHistory()
  const langFromStore = useSelector(state => state.settings.lang)

  const lang = getQueryStringValue('lang') || langFromStore;
  const [langGroup, setLangGroup] = useState(getQueryStringValue('group'))
  model.lang_code = lang.toUpperCase()
  if (langGroup) {
    model.grouping_id = langGroup
  }

  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [slug, setSlug] = useState(null)
  const [errors, setErrors] = useState({})
  const { createArticle, getArticle, editArticle, getArticleTranslation } = useArticleApi()
  const { values, handleChange, handleSubmit, setFieldValue, setValues } = useFormik({
    initialValues: model,
    onSubmit: async (values) => {
      try {
        setLoading(true)
        const data = cloneDeep(values)
        const { thumb_id, medias_list, carousel_photo_id, author_image, polls_list } = data;
        if (medias_list) {
          data.medias_list = medias_list.map(item => item.id);
        }

        data.thumb_id = thumb_id
          ? thumb_id.id
          : null

        data.carousel_photo_id = carousel_photo_id
          ? data.carousel_photo_id.id
          : null

        data.author_image_id = author_image
          ? author_image.id
          : null

        data.polls_list = polls_list.map(p => p.id)

        if (editMode) {
          delete data.published_all_at
          await editArticle(data, slug)
          dispatch(alertSuccess('Edytowano pomyślnie artykuł.'))
        } else {
          await createArticle(data).then(({ data: { slug } }) => {
            history.push(`/articles/${slug}`);
          })
          dispatch(alertSuccess('Dodano pomyślnie artykuł.'))
        }

      } catch (error) {
        if (error && error.response) {
          setErrors(error.response.data)
        }
        dispatch(alertFailure('Wystąpił błąd podczas zapisywania artykułu'))
      } finally {
        setLoading(false)
      }
    }
  })

  const handleOnChange = (e, name) => {
    handleChange(e);
    if (errors[name] !== null)
      setErrors({...errors, [name]: null})
  }

  useEffect(() => {
    setEditMode(articleid && !langGroup)

    const fetchData = async () => {
      setLoading(true)
      try {
        const { data } = await getArticle(articleid)
        const _values = {
          article_type_id: data.article_type,
          name: data.name,
          short_name: data.short_name,
          content: data.content,
          categories_list: data.categories.map(item => item.id),
          thumb_id: data.thumb ? data.thumb : '',
          medias_list: data.medias,
          author_name: data.author_name,
          author_image: data.author_image || '',
          author_summary: data.author_summary,
          grouping_id: data.grouping_id,
          summary: data.summary,
          short_summary: data.short_summary,
          labels: data.labels,
          featured: data.featured,
          access: data.access,
          publish_status: data.publish_status,
          published_at: data.published_at
          ? new Date(data.published_at)
          : null,
          published_all_at: data.published_all_at
            ? new Date(data.published_all_at)
            : null,
          carousel_photo_id: data.carousel_photo ? data.carousel_photo : '',
          video_url: data.video_url,
          polls_list: data.polls
        };

        setValues({
          ...values,
          ..._values
        })
        setLangGroup(data.grouping_id)
        setSlug(data.slug)
        dispatch(setArticleId(data.id))
        getArticleTranslation(data.grouping_id)
          .then(({ data }) => {
            dispatch(setArticleTranslation(data.results))
          })
      } catch (error) {

      } finally {
        setLoading(false)
      }
    }
    if (articleid) {
      fetchData()
    }

    return () => {
      dispatch(resetReq())
    }
  }, [])

  return (
    <form
      onSubmit={handleSubmit}
      className="row"
      noValidate
    >
      {loading && <Loading fixed/>}
      <Col sm="8">
        <InputField
          label="Tytuł"
          name="name"
          required
          value={values.name}
          onChange={(e)=> handleOnChange(e, "name")}
          invalid={!!errors.name}
          validation={errors.name}
        />
        <FormGroup>
          <InputField
            label="Krótki tytuł"
            name="short_name"
            required
            value={values.short_name}
            onChange={(e)=> handleOnChange(e, "short_name")}
            invalid={!!errors.short_name}
            validation={errors.short_name}
            maxLength="75"
          />
          <FormText className="field-length">{values.short_name.length}/75 znaki</FormText>
        </FormGroup>
        <FormGroup>
          <InputField
            label="Autor artykułu"
            type="text"
            name="author_name"
            id="author_name"
            value={values.author_name}
            onChange={(e)=> handleOnChange(e, "author_name")}
            invalid={!!errors.author_name}
            validation={errors.author_name}
          />
          <FormFeedback>{errors.author_name}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <InputField
            label="Autor opis"
            type="text"
            name="author_summary"
            id="author_summary"
            value={values.author_summary}
            onChange={(e)=> handleOnChange(e, "author_summary")}
            invalid={!!errors.author_summary}
          />
          <FormFeedback>{errors.author_summary}</FormFeedback>
        </FormGroup>
        <SelectPhotoForArticle
          label="Zdjęcie autora"
          value={values.author_image}
          setFieldValue={(value) => {
            setFieldValue('author_image', value);
            setErrors({...errors, thumb_id: null})
          }}
          errors={errors}
        />
        {/* categories */}
        {!report && (
          <FormGroup>
            <Label for="categories_list">
              Kategoria artykułu
            </Label>
            <Input
              type="hidden"
              required
              value={values.categories_list}
              onChange={(e)=> handleOnChange(e, "categories_list")}
              invalid={!!errors.categories_list}
            />
            <ArticleCategories
              value={values.categories_list}
              setFieldValue={cats => {
                setFieldValue('categories_list', cats);
                errors.categories_list && setErrors({...errors, categories_list: null})
              }}
            />
            <FormFeedback>{errors.categories_list}</FormFeedback>
          </FormGroup>
        )}
        {/* short lead */}
        <FormGroup>
          <InputField
            label="Krótki lead"
            type="textarea"
            name="short_summary"
            id="short_summary"
            value={values.short_summary}
            onChange={(e)=> handleOnChange(e, "short_summary")}
            invalid={!!errors.short_summary}
            maxLength="160"
            validation={errors.short_summary}
          />
          <FormText className="field-length">{values.short_summary.length}/160 znaków</FormText>
          <Input type="hidden" invalid={!!errors.short_summary} />
        </FormGroup>
        {/* lead */}
        <FormGroup>
          <Label for="summary">
            Lead
          </Label>
          <Input
            type="textarea"
            name="summary"
            id="summary"
            value={values.summary}
            onChange={(e)=> handleOnChange(e, "summary")}
            invalid={!!errors.summary}
          />
          <Input type="hidden" invalid={!!errors.summary} />
        </FormGroup>

        {/* content */}
        {!loading && <FormGroup>
            <Label for="content">
              Treść artykułu
            </Label>
            <TextEditor
              editMode={editMode}
              content={values.content}
              setEditorValue={(value) => {
                setFieldValue('content', value)
                errors.content && setErrors({...errors, content: null})
              }}
            />
            <Input type="hidden" invalid={!!errors.content} />
            <FormFeedback>{errors.content}</FormFeedback>
          </FormGroup>}
        {/* photo 1 */}
        <SelectPhotoForArticle
          value={values.thumb_id}
          setFieldValue={(value) => {
            setFieldValue('thumb_id', value);
            errors.thumb_id && setErrors({...errors, thumb_id: null})
          }}
          errors={errors}
        />
        {/* attachments */}
        <SelectAttachments
          value={values.medias_list}
          setFieldValue={(value) => {
            setFieldValue('medias_list', value);
            errors.medias_list && setErrors({...errors, medias_list: null})
          }}
          errors={errors}
        />
        {/* Video */}
        <InputField
          label="Wideo"
          type="text"
          name="video_url"
          id="video_url"
          required
          value={values.video_url}
          onChange={(e) => handleOnChange(e, "video_url")}
          invalid={!!errors.video_url}
          validation={errors.video_url}
        />
        {/* polls */}
        <SelectPolls
          value={values.polls_list}
          setFieldValue={setFieldValue}
        />
      </Col>
      <Col sm="4">
        <Sidebar
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          errors={errors}
          setErrors={setErrors}
        >
          <ButtonGroup className="form-sidebar-buttons">
            <Button
              id="previewButton"
              disabled={!values.publish_status}
              type="button"
              onClick={() =>
                window.open(
                  report
                    ? `${frontLink()}agroraport/article/${slug}`
                    : `${frontLink()}articles/${slug}`,
                  '_blank' // <- This is what makes it open in a new window.
                )
              }
              color="secondary"
            >
              Podgląd
            </Button>
            {!articleid ? (
              <UncontrolledTooltip
                placement="bottom"
                target="previewButton"
              >
                W celu zobaczenia podglądu zapisz szkic artykułu
              </UncontrolledTooltip>
            ) : null}
            <Button type="submit" color="primary">
              Zapisz
            </Button>
          </ButtonGroup>
        </Sidebar>
      </Col>
    </form>
  )
}
