import { useSelector } from 'react-redux';
import { sprintf } from 'sprintf-js';
import API from '../../services/api';

const URLS = {
  MULTIMEDIA: '/api/v1/multimedia/',
  MUTLIMEDIA_SLUG: '/api/v1/multimedia/%s/',
  PHOTO_GALLERY: '/api/v1/multimedia/%s/gallery-photos/'
}

export function useMultimediaApi() {
  const token = useSelector((state) => state.user.token.key)

  const fetchMultimedia = (params) => API.get(URLS.MULTIMEDIA, {
    params,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  const createMultimedia = (data, user) => {
    return API.post(
      URLS.MULTIMEDIA,
      { ...data, creator_id: user },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  };

  const editMultimedia = (data, slug) => {
    return API.patch(
      sprintf(URLS.MUTLIMEDIA_SLUG, slug),
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  };

  const removeMultimedia = (slug) =>
    API.delete(sprintf(URLS.MUTLIMEDIA_SLUG, slug), {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

  const getMultimediaEntity = (slug) => {
    return API.get(
      sprintf(URLS.MUTLIMEDIA_SLUG, slug), {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };


  const fetchPhotoGallery = (slug, data) => {
    return API.get(
      sprintf(URLS.PHOTO_GALLERY, slug),
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
  }

  const createPhotoGallery = (slug, data) => {
    return API.post(
      sprintf(URLS.PHOTO_GALLERY, slug),
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
  }

  // const getArticleTranslation = (groupID) => {
  //   return API.get(`/api/v2/articles/?grouping_id=${groupID}`, {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   });
  // };

  return {
    fetchMultimedia,
    createMultimedia,
    editMultimedia,
    removeMultimedia,
    getMultimediaEntity,
    fetchPhotoGallery,
    createPhotoGallery
    // getArticleTranslation,
    // setArticleStatus
  }
}
