import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cookies from '../../utilities/cookies';
import { logoutUser } from '../../services/redux/reducers/user/actionCreators';
import logo from '../../assets/logo.svg';
import LangSwitch from '../../common/Inputs/LangSwitch';

class Header extends Component {
  render() {
    const { email } = this.props.user;
    return (
      <header>
        <nav className="navbar navbar-expand static-top">
          <Link to="/" className="navbar-brand me-1">
            <img src={logo} alt="" />
          </Link>
          <ul className="navbar-nav ms-auto align-items-center">
            <li className="nav-item dropdown no-arrow mx-1">
              <LangSwitch />
            </li>
            {email ? (
              <li className="nav-item dropdown no-arrow mx-1">
                <div className="nav-link">{email}</div>
              </li>
            ) : null}
            <li className="nav-item dropdown no-arrow mx-1">
              {!email ? (
                <Link to="/login" className="nav-link">
                  Zaloguj się
                </Link>
              ) : null}
              {email ? (
                <Link
                  to="/login"
                  onClick={() => {
                    cookies.removeToken();
                    this.props.logoutUser();
                  }}
                  className="nav-link"
                >
                  Wyloguj się
                </Link>
              ) : null}
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    user
  };
}

export default connect(
  mapStateToProps,
  { logoutUser }
)(Header);
