import { Transforms } from 'slate';
import { NODE_TYPES } from '../consts';
import { createParagraph } from './paragraph'

export const createImageNode = (alt, { url, id, sizeType, style }) => ({
  type: NODE_TYPES.IMAGE,
  alt,
  url,
  id,
  class: style,
  sizeType,
  children: [{ text: "" }]
});

export const createVideoNode = ({ url, width, height }) => ({
  type: NODE_TYPES.VIDEO,
  url,
  width,
  height,
  children: [{ text: "" }]
})

export const createGridImagesNode = ({ grid }) => ({
  type: NODE_TYPES.GRID_IMAGES,
  grid,
  children: [{ text: "" }]
})

export const insertEmbed = (editor, embedData = {}, format) => {

  const isImage = format === NODE_TYPES.IMAGE
  const isGrid = format === NODE_TYPES.GRID_IMAGES

  const { url, width, height, grid } = embedData;

  const checkImage = isImage && !url
  const checkGrid = isGrid && !grid

  if (checkImage || checkGrid) return;

  embedData.width = width ? `${width}px` : '100%';
  embedData.height = height ? `${height}px` : 'auto'
  embedData.style = embedData.class ? embedData.class : ''

  const embed = isGrid
    ? createGridImagesNode(embedData)
    : isImage
      ? createImageNode("EditorImage", embedData)
      : createVideoNode(embedData);

  Transforms.insertNodes(editor, embed, { select: true });
  Transforms.insertNodes(editor, createParagraph(""), { mode: 'highest' })
}
