import React, { Component } from 'react';
import { connect } from "react-redux";
import {
  Label,
  Button,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Input
} from 'reactstrap';
import api from '../../services/api';
import { generateCSV } from '../../utilities/export';


class InvitedUserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      id: this.props.eventId,
      opened: false,
      none: false,
      filter: ''
    }
  }

  componentDidMount () {
    if (this.state.id) {
      this.fetchInvited()
    }
  }

  fetchInvited = () => {
    const { token } = this.props;
    const { id } = this.state;
    let url = `/api/v1/events/${id}/invited/`;
    api
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(({ data }) => {
        this.setState({
          list: data,
          none: data.length === 0
        });
      });
  };

  toggle = () => {
    this.setState((state) => ({ opened: !state.opened }))
  }

  handleChange = ({ target }) => {
    this.setState({
      filter: target.value
    })
  }

  handleExport = ({ }) => {
    const { token } = this.props;
    const { id } = this.state;
    let url = `/api/v1/events/${id}/invited-export/`;
    api
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        generateCSV(response);
      })
  }

  render() {
    const { list, opened, none, filter } = this.state;
    const _list = (list.length && filter) ? list.filter(({ email }) => email.includes(filter)) : list
    return (
      <div>
        <Label className="">Lista zaproszonych</Label>
        <Modal isOpen={opened} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Lista zaproszonych</ModalHeader>
          <ModalBody>
            <div className="text-right">
              <Button onClick={this.handleExport}>Eksport csv</Button>
            </div>
            <div>
              <FormGroup>
                <Label for="title">Filtruj</Label>
                <Input
                  type="text"
                  value={filter}
                  onChange={this.handleChange}
                />
              </FormGroup>
              {_list.length ? (
                <ListGroup>
                  {_list.map(({ email }, index) => (
                    <ListGroupItem>
                      <div>{email}</div>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              ) : null}
              {none && 'brak zaproszonych użytkowników'}
            </div>
          </ModalBody>
        </Modal>
        <div
          style={{ marginTop: '.5rem' }}>
          <Button
            color="primary"
            id="toggler"
            onClick={this.toggle}
          >
            {'Pokaż'}
          </Button>
          <Button onClick={this.handleExport}>Eksport csv</Button>
        </div>
        {/* </UncontrolledCollapse> */}
      </div>
    );
  }
};

function mapStateToProps(state, ownProps) {
  const { user, settings } = state;
  return {
    token: user.token.key,
    lang: settings.lang
  };
}

export default connect(mapStateToProps)(InvitedUserList);
