import React, { Component } from 'react';
import Sidebar from '../Sidebar/sidebar.jsx';
import Alerts from '../../common/Alerts';
import { Row, Col } from 'reactstrap';
import appLogo from '../../assets/bnp.png';

class Layout extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <div className="container-fluid container--strength">
        <div className="row">
          <Sidebar />
          <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-2">
            <Alerts />
            {this.props.children}
          </main>
        </div>
      </div>
    );
  }
}

export default Layout;
