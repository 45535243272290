import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from '../../components/Layout/index.jsx';
import ReactTable from 'react-table';
import { Button, Input } from 'reactstrap';
import {
  fetchEvents,
  removeEvent
} from '../../services/redux/reducers/events/actionCreators';
import { formatDate } from '../../utilities/dates';
import API from '../../services/api';
import { calculateMaxPages } from '../../utilities/calculates';
import { displayStatus, statuses } from '../../utilities/status';
import { DeleteButton } from '../../components/FunctionalButtons/DeleteButton';

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: null,
      loading: true,
      tableState: ''
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    // this.props.fetchEvents();
  }

  removeEvent(id) {
    this.props.removeEvent(id);
    setTimeout(() => {
      this.fetchData(this.state.tableState);
    }, 1000);
  }

  fetchData(state, instance) {
    this.setState({ loading: true, tableState: state });

    const { token, lang } = this.props;

    let url = `/api/v1/events/?lang_code=${lang}&limit=${state.pageSize}&`;
    let params = [];
    let offset = state.page * state.pageSize;
    if (offset) {
      params.push(`offset=${offset}`);
    }

    if (state.filtered.length) {
      state.filtered.forEach(element => {
        if (element.id === 'title') {
          params.push(`search=${element.value}`);
        } else {
          params.push(`${element.id}=${element.value}`);
        }
      });
    }

    let sort = [];

    if (state.sorted.length) {
      if (state.sorted[0].id === 'date') {
        sort.push(
          `${
            !state.sorted[0].desc ? 'event__started_at' : '-event__started_at'
          }`
        );
      } else {
        sort.push(`${state.sorted[0].desc ? '-' : ''}${state.sorted[0].id}`);
      }
    } else {
      sort.push(`-created_at`);
    }

    if (params.length) {
      let allParams = params.reduce((a, b) => {
        return a + '&' + b;
      });
      url = url + allParams;
    }

    if (sort.length) {
      let allParams = sort.reduce((a, b) => {
        return a + ',' + b;
      });
      url = url + '&ordering=' + allParams;
    }

    API.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(({ data }) => {
      // alert(calculateMaxPages(data.count, state.pageSize));
      this.setState({
        data: data.results,
        pages: calculateMaxPages(data.count, state.pageSize),
        loading: false
      });
    });
  }

  render() {
    const { data, pages } = this.state;
    return (
      <Layout>
        <h1 className="h2 pb-md-4 page-header">
          Wydarzenia{' '}
          <Button
            onClick={e => {
              e.preventDefault();
              this.props.history.push('/events/new');
            }}
            color="primary"
          >
            Dodaj nowy
          </Button>
        </h1>
        <h4>Lista wydarzeń</h4>
        <div className="table-responsive">
          <ReactTable
            columns={[
              {
                Header: 'Lp.',
                Cell: row => {
                  return <div>{row.index + 1 + row.page * row.pageSize}</div>;
                },
                filterable: false,
                maxWidth: 40
              },
              {
                Header: 'Tytuł',
                accessor: 'title'
              },
              {
                Header: 'Status',
                id: 'publish_status',
                accessor: d => displayStatus(d.publish_status),
                Filter: ({ onChange, filter }) => (
                  <Input
                    type="select"
                    // name={this.props.name}
                    id={`select${this.props.name}`}
                    onChange={e => onChange(e.target.value)}
                    // ref={this.selectInput}
                    // value={this.state.value}
                    size="sm"
                    value={filter ? filter.value : undefined}
                  >
                    <option value="">Wybierz</option>
                    {statuses.map(type => (
                      <option value={type.id} key={type.id}>
                        {type.label}
                      </option>
                    ))}
                  </Input>
                ),
                maxWidth: 210
              },
              {
                Header: 'Data wydarzenia',
                id: 'date',
                maxWidth: 210,
                filterable: false,
                Cell: d => {
                  return (
                    <div>
                      Początek: {formatDate(new Date(d.original.started_at))}{' '}
                      <br />
                      Koniec: {formatDate(new Date(d.original.ended_at))}
                    </div>
                  );
                }
              },
              {
                Header: 'Akcje',
                filterable: false,
                Cell: d => {
                  return (
                    <>
                      <Button
                        color="primary"
                        onClick={() =>
                          this.props.history.push(`/events/${d.original.id}`)
                        }
                        size="sm"
                      >
                        Edytuj
                      </Button>
                      <Button
                        color="secondary"
                        size="sm"
                        onClick={() =>
                          this.props.history.push(`/events/${d.original.id}`)
                        }
                      >
                        Zobacz
                      </Button>
                      <Button
                        color="secondary"
                        size="sm"
                        onClick={() =>
                          this.props.history.push(
                            `/events/${d.original.id}/users`
                          )
                        }
                      >
                        Lista
                      </Button>
                      <DeleteButton
                        callback={() => this.removeEvent(d.original.id)}
                      />
                    </>
                  );
                },
                maxWidth: 300
              }
            ]}
            manual
            data={data}
            pages={pages}
            filterable
            resizable={false}
            onFetchData={this.fetchData}
            defaultPageSize={10}
            className="-striped -highlight"
          />
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { events, user, settings } = state;
  return {
    events,
    token: user.token.key,
    lang: settings.lang
  };
}

export default connect(
  mapStateToProps,
  {
    fetchEvents,
    removeEvent
  }
)(Events);
