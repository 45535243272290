import React, { Component } from 'react';
import Layout from '../../components/Layout/index.jsx';
import EventForm from '../../components/Forms/EventForm';
import { getQueryStringValue } from '../../utilities/history';

class EditArticles extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { permission } = this.props;

    return (
      <Layout>
        <h1 className="h2 pb-md-4 page-header">
          {getQueryStringValue('group') ? (
            <>
              <strong>Dodajesz tłumaczenie</strong> wydarzenia
            </>
          ) : (
            'Edycja wydarzenia'
          )}
        </h1>
        {/* Content */}
        <EventForm eventid={this.props.match.params.id} />
      </Layout>
    );
  }
}

export default EditArticles;
