import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from 'reactstrap';

import useBanners, { BANNERS_TYPES } from './useBanners';

const BannerComponent = styled.div`
  display: block;
  p {
    margin: 0;
  }

  button {
    margin-top: 1rem;
  }
`;

const StyldeModal = styled(Modal)`
  .modal-content {
    max-width: 320px;
    margin: auto;
  }
`;

function Banner({ mediaId }) {
  const [slug, setSlug] = useState(BANNERS_TYPES[0].slug);
  const [modal, setModal] = useState(false);
  const { banner, setBanner, deleteBanner } = useBanners({ mediaId });

  const toggle = () => setModal(!modal);
  return (
    <>
      <BannerComponent>
        {!!banner && (
          <p>
            Ustawione jako banner dla:{' '}
            <b>
              {BANNERS_TYPES.find(({ slug }) => slug === banner.page_slug).name}
            </b>
          </p>
        )}
        <div>
          {banner ? (
            <Button
              size="sm"
              outline
              color="danger"
              onClick={() => deleteBanner({ id: banner.id })}
            >
              Usuń banner
            </Button>
          ) : (
            <Button size="sm" outline color="success" onClick={toggle}>
              Ustaw jako banner
            </Button>
          )}
        </div>
      </BannerComponent>
      {!banner && (
        <StyldeModal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Ustaw medium jako Banner</ModalHeader>
          <ModalBody>
            <p>Wybierz dla jakie kategorii ma zostać ustawiony Banner:</p>
            <Input
              type="select"
              name="slug"
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
            >
              {BANNERS_TYPES.map(({ name, slug }) => (
                <option key={slug} value={slug}>
                  {name}
                </option>
              ))}
            </Input>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => setBanner({ slug }).finally(() => toggle())}
            >
              Ustaw
            </Button>{' '}
            <Button color="secondary" onClick={toggle}>
              Anuluj
            </Button>
          </ModalFooter>
        </StyldeModal>
      )}
    </>
  );
}

export default Banner;
