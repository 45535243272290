import React, { useContext } from 'react';
import {
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { statuses } from '../../../../utilities/status';
import { QuizContext } from '../../Quiz.context';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Pole wymagane'),
  description: Yup.string().required('Pole wymagane')
});

function QuizDetails() {
  const { quiz, updateQuiz, questions, removeQuiz } = useContext(QuizContext);
  const history = useHistory();
  if (!quiz) {
    return null;
  }

  const QUIZ_IS_PUBLISHED = Number(quiz.publish_status) === 3;

  const handleSubmit = (
    { title, description, highlight_image, publish_status },
    formik
  ) => {
    if (questions.length === 0 && Number(publish_status) === 3) {
      formik.setSubmitting(false);
      return toast.error('Nie można opublikować Quizu bez pytań.');
    }

    const hasMissingAnswer = questions.reduce((acc, { answers }) => {
      if (answers.length === 0 && !acc) {
        return true;
      }
      return acc;
    }, false);

    if (hasMissingAnswer && Number(publish_status) === 3) {
      formik.setSubmitting(false);
      return toast.error('Jedno z pytań nie zawiera żadnej odpowiedzi.');
    }

    const hasMissingCorrectAnswer = questions.reduce((acc, { answers }) => {
      if (answers.filter(({ correct }) => correct).length === 0 && !acc) {
        return true;
      }
      return acc;
    }, false);

    if (hasMissingCorrectAnswer && Number(publish_status) === 3) {
      formik.setSubmitting(false);
      return toast.error(
        'Jedno z pytań nie zawiera żadnej poprawnej odpowiedzi.'
      );
    }

    formik.setSubmitting(true);
    updateQuiz({
      title,
      description,
      highlight_image,
      publish_status,
      slug: quiz.slug
    })
      .then(() => {
        toast.success('Quiz został zaktualizowany');
      })
      .catch((err) => {
        if (err.response) {
          Object.keys(err.response.data).map((key) =>
            toast.error(err.response.data[key].join(' '))
          );
        }
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  return (
    <Card className="Quiz__info">
      <CardBody>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          initialValues={{
            publish_status: quiz.publish_status,
            description: quiz.description,
            highlight_image: null,
            title: quiz.title
          }}
          render={({
            values,
            handleSubmit,
            handleChange,
            errors,
            touched,
            setFieldValue,
            isSubmitting,
            setSubmitting
          }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="title">Tytuł</Label>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  invalid={!!errors.title && touched.title}
                  value={values.title}
                  onChange={handleChange}
                  disabled={QUIZ_IS_PUBLISHED}
                />
                {!!errors.title && touched.title && (
                  <FormFeedback>{errors.title}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="description">Opis</Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  value={values.description}
                  onChange={handleChange}
                  invalid={!!errors.description && touched.description}
                  disabled={QUIZ_IS_PUBLISHED}
                />
                {!!errors.description && touched.description && (
                  <FormFeedback>{errors.description}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="status">Status</Label>
                <Input
                  type="select"
                  name="publish_status"
                  id="publish_status"
                  value={values.publish_status}
                  onChange={handleChange}
                  disabled={QUIZ_IS_PUBLISHED}
                >
                  {statuses.map((status) => (
                    <option value={status.id}>{status.label}</option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="status">Okładka</Label>
                <img
                  className="Quiz__highlight_image"
                  src={
                    values.highlight_image
                      ? window.URL.createObjectURL(values.highlight_image)
                      : quiz.highlight_image
                  }
                  alt="Okładka"
                />
                {!QUIZ_IS_PUBLISHED && (
                  <Input
                    type="file"
                    name="highlight_image"
                    id="highlight_image"
                    disabled={QUIZ_IS_PUBLISHED}
                    onChange={(event) => {
                      setFieldValue('highlight_image', event.target.files[0]);
                    }}
                    invalid={!!errors.highlight_image}
                  />
                )}
              </FormGroup>
              <div className="Quiz__info__actions">
                {!isSubmitting && (
                  <>
                    <Button
                      onClick={() => {
                        removeQuiz(quiz.slug).finally(() => {
                          history.push('/quizzes');
                        });
                      }}
                      type="button"
                      color="danger"
                    >
                      Usuń
                    </Button>
                    {QUIZ_IS_PUBLISHED ? (
                      <Button
                        type="button"
                        color="warning"
                        onClick={() => {
                          setSubmitting(true);
                          updateQuiz({
                            publish_status: 1,
                            slug: quiz.slug
                          })
                            .then(() => {
                              toast.success('Quiz został zaktualizowany');
                            })
                            .catch((err) => {
                              if (err.response) {
                                Object.keys(err.response.data).map((key) =>
                                  toast.error(err.response.data[key].join(' '))
                                );
                              }
                            })
                            .finally(() => {
                              setSubmitting(false);
                            });
                        }}
                      >
                        Edytuj
                      </Button>
                    ) : (
                      <Button type="submit" color="success">
                        Zapisz
                      </Button>
                    )}
                  </>
                )}
              </div>
            </Form>
          )}
        />
      </CardBody>
    </Card>
  );
}

export default QuizDetails;
