import { slateToHtml } from 'slate-serializers'
import { NODE_TYPES } from '../consts'
import { Element, Text } from 'domhandler'

const ELEMENT_NAME_TAG_MAP = {
  [NODE_TYPES.P]: 'p',
  [NODE_TYPES.H2]: 'h2',
  [NODE_TYPES.H3]: 'h3',
  [NODE_TYPES.UL_LIST]: 'ul',
  [NODE_TYPES.OL_LIST]: 'ol',
  [NODE_TYPES.LIST_ITEM]: 'li',
  [NODE_TYPES.SEPARATOR]: 'hr'
}

const MARK_ELEMENT_TAG_MAP = {
  bold: ['strong'],
  underline: ['u'],
  italic: ['em']
}

const serializeImage = (image) => {
  const { url, alt, id, class: className } = image
  return new Element('img', { src: url, alt, 'data-id': id, class: className})
}

const serializeImageDesc = (desc) => {
  return new Element('p', null, [new Text(desc)])
}

const serializeImageWithDesc = (image, desc) => {
  const children = [serializeImage(image)]
  if (desc) {
    children.push(serializeImageDesc(desc))
  }
  return children
}

const config = {
  markMap: MARK_ELEMENT_TAG_MAP,
  elementMap: ELEMENT_NAME_TAG_MAP,
  elementTransforms: {
    [NODE_TYPES.LINK]: ({ node, children = [] }) => {
      const attrs = {}
      return new Element(
        'a',
        {
          href: node.href,
          target: node.target,
          ...attrs,
        },
        children,
      )
    },
    [NODE_TYPES.ARTICLE_LINK]: ({ node }) => {
      const { data } = node
      return new Element(
        'div',
        {
          class: 'article-link-internal'
        },
        [
          new Element(
            'span',
            {},
            [new Text(data.text)]
          ),
          new Element(
            'a',
            {
              href: data.href,
            },
            [new Text(data.title)]
          )
        ]
      )
    },
    [NODE_TYPES.DISCLAIMER]: ({ node, children }) => {
      return new Element(
        'p',
        {},
        [new Element(
          'small',
          { class: 'disclaimer'},
          children
        )]
      )
    },
    [NODE_TYPES.IMAGE]: ({ node }) => {
      const { desc, sizeType, ...img } = node

      const children = serializeImageWithDesc(img, desc)

      return new Element(
          'figure',
          {
            class: sizeType === 'xl' ? 'img--xl' : ''
          },
          children
        )
    },
    [NODE_TYPES.GRID_IMAGES]: ({ node }) => {
      const { grid } = node
      return new Element(
        'div',
        {
          class: `image-grid image-grid-${grid.length}`
        },
        grid.map(({ image, desc }) => {
          const children = [
            new Element(
              'div',
              { class: 'image-grid__content-img' },
              [serializeImage(image)]
            )
          ]

          if (desc) {
            children.push(serializeImageDesc(desc))
          }

          return new Element(
            'div',
            {
              class: 'image-grid__content'
            },
            children
          )
        })
      )
    },
    [NODE_TYPES.BLOCKQUOTE]: ({ node }) => {
      const { data } = node
      const children = [
        new Element(
          'blockquote',
          {},
          [new Text(data.text)]
        )
      ]

      if (data.name && data.description) {
        const author = []

        if (data.photo &&  data.photo.url) {
          author.push(serializeImage(data.photo))
        }
        author.push(
          new Element(
            'p',
            {},
            [new Text(data.name)]
          ),
          new Element(
            'p',
            {},
            [new Text(data.description)]
          )
        )

        children.push(new Element(
          'div',
          { class: 'article-quote__author' },
          author
        ))
      }

      return new Element(
        'div',
        {
          class: 'article-quote'
        },
        children
      )
    }
  },
  encodeEntities: true,
  alwaysEncodeCodeEntities: false,
  convertLineBreakToBr: true,
}

export const serialize = (node) => {
  return slateToHtml(node.children, config)
}
