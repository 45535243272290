import React from 'react'
import { useFocused, useSelected } from 'slate-react';

const Blockquote = ({ attributes, element, children }) => {
  const { data } = element
  const selected = useSelected();
  const focused = useFocused();
  const isFocused = selected && focused

  return (
    <div
      {...attributes}
      className="element-blockquote"
      style={{ boxShadow: isFocused && '0 0 3px 3px lightgray' }}
    >
      <div contentEditable={false}>
        <blockquote>
          {data.text}
        </blockquote>
        {data.photo && data.name && data.description && <div className="element-blockquote-author">
          <img src={data.photo.url} alt="" />
          <p>{data.name}</p>
          <p>{data.description}</p>
        </div>}
      </div>
      {children}
    </div>
  )
}

export default Blockquote
