import * as actions from './prf.actions';
import { ResourceState } from '../api/prf.api';

const InitialState = {
  list: [],
  resourceState: ResourceState.IDLE,
  actionsResourceState: ResourceState.IDLE
};

export function products(state = InitialState, action) {
  switch (action.type) {
    case actions.PRODUCTS_SET_RESOURCE_STATE:
      return {
        ...state,
        resourceState: action.data
      };

    case actions.PRODUCTS_SET:
      return {
        ...state,
        list: action.data
      };

    case actions.PRODUCTS_SET_ACTIONS_RESOURCE_STATE:
      return {
        ...state,
        actionsResourceState: action.data
      };

    default:
      return state;
  }
}
