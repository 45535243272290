import React, { useState } from 'react';
import { useHistory } from 'react-router';
import ReactTable from 'react-table';
import { Button, Input } from 'reactstrap';
import { DeleteButton } from '../../components/FunctionalButtons/DeleteButton';
import Layout from '../../components/Layout';
import { MULTIMEDIA_TYPES_VALUES, MUTLIMEDIA_TYPES } from '../../components/Multimedia/consts';
import { useMultimediaApi } from '../../components/Multimedia/Multimedia.api';
import { formatDate } from '../../utilities/dates';
import { displayStatus, statuses } from '../../utilities/status';
import { frontLink } from '../../services/api';

export const Multimedia = () => {
  const slug = 'multimedia'
  const [data, setData] = useState([])
  const [pages, setPages] = useState(null)
  const [loading, setLoading] = useState(false)
  const { fetchMultimedia, removeMultimedia } = useMultimediaApi()
  const history = useHistory()

  const fetchData = (state) => {
    setLoading(true)

    let sort = [];

    if (state.sorted.length) {
      sort.push(`${state.sorted[0].desc ? '-' : ''}${state.sorted[0].id}`);
    } else {
      sort.push(`-created_at`);
    }

    const sortingParams = sort.length ? sort.reduce((a, b) => {
      return a + ',' + b;
    }) : null

    fetchMultimedia({
      limit: state.pageSize,
      offset: state.pageSize * state.page,
      ordering: sortingParams,
      ...state.filtered.reduce((result, filter) => {
        if (filter.id === 'title') {
          result.search = filter.value
        } else {
          result[filter.id] = filter.value
        }
        return result
      }, {})
    })
      .then(({ data }) => {
        setData(data.results)
        setPages(Math.ceil(data.count / 10))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Layout>
      <h1 className="h2 pb-md-4 page-header">
        Multimedia
        <Button
          className="ms-3"
          onClick={(e) => {
            e.preventDefault();
            history.push(`/${slug}/new`);
          }}
          color="primary"
        >
          Dodaj nowe
        </Button>
      </h1>
      <h4>Lista multimediów</h4>

      <div className="table-responsive">
          <ReactTable
            columns={[
              {
                Header: 'Lp.',
                Cell: (row) => {
                  return <div>{row.index + 1 + row.page * row.pageSize}</div>;
                },
                filterable: false,
                maxWidth: 40
              },
              {
                Header: 'Tytuł',
                accessor: 'title'
              },
              {
                Header: 'Typ multimedium',
                id: 'multimedia_type',
                accessor: (d) => {
                  return (MUTLIMEDIA_TYPES.find(m => m.value === d.multimedia_type) || {}).label
                },
                maxWidth: 210,
                filterable: true,
                filterMethod: (filter, row) => {
                  if (filter.value === '') {
                    return true;
                  }
                  return row._original.multimedia_type === parseInt(filter.value);
                },
                Filter: ({ onChange, filter }) => (
                  <Input
                    type="select"
                    name="select_multimedia_type"
                    id="select_multimedia_type"
                    onChange={(e) => onChange(e.target.value)}
                    value={filter ? filter.value : undefined}
                    size="sm"
                  >
                    <option value="">Wybierz</option>
                    {MUTLIMEDIA_TYPES.map(type => {
                      return (
                        <option value={type.value}>{type.label}</option>
                      )
                    })}
                  </Input>
                )
              },
              {
                Header: 'Data utworzenia',
                id: 'created_at',
                accessor: (d) => formatDate(new Date(d.created_at)),
                filterable: false,
                maxWidth: 210,
                sortable: true
              },
              {
                Header: 'Status',
                id: 'publish_status',
                accessor: (d) => displayStatus(d.publish_status),
                maxWidth: 180,
                filterable: true,
                Filter: ({ onChange, filter }) => (
                  <Input
                    type="select"
                    id={`select-multimedia`}
                    onChange={(e) => onChange(e.target.value)}
                    size="sm"
                    value={filter ? filter.value : undefined}
                  >
                    <option value="">Wybierz</option>
                    {statuses.map((type) => (
                      <option value={type.id} key={type.id}>
                        {type.label}
                      </option>
                    ))}
                  </Input>
                )
              },
              {
                Header: 'Akcje',
                Cell: (d) => {
                  return (
                    <>
                      <Button
                        color="primary"
                        onClick={() =>
                          history.push(`/${slug}/${d.original.slug}`)
                        }
                        size="sm"
                      >
                        Edytuj
                      </Button>
                      <Button
                        color="secondary"
                        size="sm"
                        onClick={() =>
                          window.open(
                            `${frontLink()}multimedia/${d.original.slug}`,
                            '_blank' // <- This is what makes it open in a new window.
                          )
                        }
                      >
                        Zobacz
                      </Button>
                      <DeleteButton
                        callback={() => {
                          removeMultimedia(d.original.slug)
                            .then(() => {
                              fetchData()
                            })
                        }}
                      />
                    </>
                  );
                },
                filterable: false,
                maxWidth: 210
              }
            ]}
            manual
            data={data}
            pages={pages}
            filterable
            sortable={false}
            resizable={false}
            onFetchData={fetchData}
            defaultPageSize={10}
            className="-striped -highlight"
          />
        </div>
    </Layout>
  )
}
