import React, { Component } from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  ListGroup,
  ListGroupItem,
  FormFeedback
} from "reactstrap";

export const RepeatField = props => {
  const { label, validation, group, children, onChange } = props;

  const generalError = typeof validation === 'string'
    ? validation
    : null

  return (
    <div>
      <Label>{label}</Label>
      <ListGroup>
        {group.length
          ? group.map((item, index) => (
              <ListGroupItem
                className="d-flex align-items-start justify-content-between"
                key={index}
              >
                {children(item, index)}
              </ListGroupItem>
            ))
          : null}
      </ListGroup>
      <Input type="hidden" invalid={!!generalError} />
      <Button type="button" onClick={props.pushElements}>
        {props.pushButton}
      </Button>
      <FormFeedback>{generalError}</FormFeedback>
    </div>
  );
};

// export default InputField;
