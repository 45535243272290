export const InitialProperty = {
  id: -1,
  name: '',
  slug: '',
  province: null,
  county: null,
  borough: null,
  town: null,
  property_type: null,
  transaction_type: null,
  keeper: null,
  promoted: false,
  revision_required: false,
  published_at: '',
  publish_status: 1,
  local_area: null,
  land_area: null,
  price: null,
  offer_number: '',
  nr_kw: '',
  description: '',
  details: '',
  province_name: '',
  county_name: '',
  borough_name: '',
  town_name: '',
  thumbnail: '',
  thumbnail_link: '',
  keeper_data: {
    id: null,
    first_name: '',
    last_name: '',
    email: '',
    telephone: ''
  }
};
