import React from 'react';
import ReactTable from 'react-table';
import classNames from 'classnames';
import debounce from 'lodash.debounce';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';

import { selectCMSLang } from '../../../services/redux/reducers/selectors';
import { useVideos } from '../Videos.hooks';
import { getStatusValue } from './VideosPreview';

import './VideosList.scss';

function VideosList() {
  const {
    list,
    searchParams,
    isProcessing,
    getVideos,
    setVideo,
    video,
    removeVideo
  } = useVideos();

  const lang = useSelector(selectCMSLang);

  const onPageChange = (nextPage) => {
    getVideos({
      limit: searchParams.pageSize,
      offset: searchParams.pageSize * nextPage
    });
  };

  const onPageSizeChange = (nextPageSize) => {
    getVideos({
      limit: nextPageSize,
      offset: 0
    });
  };

  const onFilteredChange = (filters) => {
    const params = filters.reduce((prev, current) => {
      if (current.id === 'title') {
        return {
          ...prev,
          search: current.value
        };
      }
      return {
        ...prev,
        [current.id]: current.value
      };
    }, {});
    searchParams.setFilters(params);
  };

  const onFilteredChangedeDounce = debounce((filters) => {
    onFilteredChange(filters);
  }, 500);

  const onRemove = (slug) => {
    // eslint-disable-next-line
    const remove = confirm(`Czy napewno chcesz usunąć wideo ${slug}?`);
    if (remove) {
      setVideo({
        slug: '',
        title: '',
        video_url: '',
        description: '',
        lang_code: lang
      });
      removeVideo(slug);
    }
  };

  return (
    <div>
      <ReactTable
        columns={[
          {
            Header: 'Nazwa',
            accessor: 'title',
            sortable: false
          },
          {
            Header: 'Status',
            accessor: 'publish_status',
            sortable: false,
            maxWidth: 150,
            Filter: ({ filter, onChange, ...props }) => (
              <select
                onChange={(event) => onChange(event.target.value)}
                value={filter ? filter.value : ''}
              >
                <option value=""></option>
                <option value="1">Szkic</option>
                <option value="2">Oczekuje</option>
                <option value="3">Opublikowany</option>
              </select>
            ),
            Cell: ({ value }) => <>{getStatusValue(value).label}</>
          },
          {
            Header: '',
            accessor: 'slug',
            sortable: false,
            filterable: false,
            maxWidth: 72,
            Cell: ({ value }) => (
              <Button
                size="sm"
                color="danger"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  onRemove(value);
                }}
              >
                Usuń
              </Button>
            )
          }
        ]}
        manual
        filterable
        minRows={0}
        resolveData={(data) => data.map((row) => row)}
        data={list.results}
        pages={searchParams.pages}
        page={searchParams.page}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onFilteredChange={onFilteredChangedeDounce}
        defaultPageSize={searchParams.defaultPageSize}
        loading={isProcessing}
        className="-striped -highlight"
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              onClick: (e) => {
                setVideo(rowInfo.original);
              },
              className: classNames({
                active: rowInfo.original.slug === video.slug
              })
            };
          } else {
            return {};
          }
        }}
      />
    </div>
  );
}

export default VideosList;
