import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col } from 'reactstrap';

import Layout from '../../../components/Layout';

import { fetchProducts } from '../state/prf.actions';
import AddProductModal from '../components/AddProductModal';
import ProductsList from '../components/ProductsList';
import { selectUserType } from '../../../services/redux/reducers/user/selectors';
import { USER_TYPE_PERMISSION } from '../consts/prf.const';

const Products = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const userType = useSelector(selectUserType);

  useEffect(() => {
    if (USER_TYPE_PERMISSION.includes(userType)) {
      dispatch(fetchProducts());
    }
  }, [dispatch, userType]);

  if (!USER_TYPE_PERMISSION.includes(userType)) {
    return (
      <Layout>
        <Row>
          <Col>
            <strong>Brak uprawnień</strong>
          </Col>
        </Row>
      </Layout>
    );
  }

  return (
    <Layout>
      <Row>
        <Col>
          <h1 className="h2">Produkty</h1>
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <Button
            onClick={() => setModalOpen(true)}
            color="primary"
            type="button"
          >
            Dodaj produkt
          </Button>
          <AddProductModal
            isOpen={isModalOpen}
            toggle={() => setModalOpen((state) => !state)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <ProductsList />
        </Col>
      </Row>
    </Layout>
  );
};

export default Products;
