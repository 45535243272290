import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Form, Button, FormGroup, Input, Label } from 'reactstrap';
import { clone } from 'lodash';
import { editMedia } from '../../services/redux/reducers/media/actionCreators';

class MediaForm extends Component {
  // constructor(props) {
  //   super(props)
  // }

  editMediaForm = React.createRef();

  render() {
    let className = 'form-hide form-hide--media pt-sm-2';
    const { show, file, editMedia } = this.props;
    // console.log(file)
    if (show) {
      className += ' on';
    }
    return (
      <div className={className}>
        {/* <p className="h4">Edytuj dane pliku</p> */}
        <Formik
          initialValues={{
            name: clone(file.name),
            description: file.description ? clone(file.description) : '',
            style: file.style ? clone(file.style) : null,
          }}
          enableReinitialize={true}
          ref={this.editMediaForm}
          onSubmit={values => {
            editMedia(file.id, values);
            setTimeout(() => {
              this.props.hide();
            }, 500);
          }}
          render={({ values, handleSubmit, handleChange, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label>Nazwa pliku (atrybut alt)</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={values.name}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label>Atrybut alt</Label>
                <Input
                  type="text"
                  name="description"
                  id="description"
                  value={values.description}
                  onChange={()=>handleChange()}
                />
              </FormGroup>
              <FormGroup>
                <Label>Parametry stylów: {values.style}</Label>
                <p>Rozciągnij do szerokości kontenera:</p>
                <div>
                  <span style={{fontSize: '0.8rem'}}> </span>
                  <label className="switch">
                    <input type="checkbox"
                           onChange={() => {
                             !!values.style ? setFieldValue('style', null ) :  (setFieldValue('style','width: 100%;'))
                           }}
                           checked={!!values.style}/>
                    <span className="slider round"></span>
                  </label>
                </div>
              </FormGroup>
              <Button type="submit">Zapisz</Button>{' '}
              <Button onClick={() => this.props.hide()} color="info">
                Zamknij
              </Button>
            </Form>
          )}
        />
      </div>
    );
  }
}

export default connect(
  null,
  { editMedia }
)(MediaForm);
