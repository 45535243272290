import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MediaBody from './index';

class SelectMedia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    if (this.props.toggle) {
      this.props.toggle()
      return
    }

    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    const { small } = this.props;
    const isOpen = () => {
      if (this.props.modalOpened !== undefined) {
        return this.props.modalOpened
      }

      return this.state.modal
    }
    return (
      <div>
        {this.props.children
          ? this.props.children
          : small
            ? (
              <Button
                type="button"
                outline
                color="secondary"
                size="sm"
                className="toolbar-thumb"
                onClick={this.toggle}
              >
                Dodaj zdjęcie
              </Button>
            ) : (
              <Button color="primary" type="button" onClick={this.toggle}>
                Wybierz {this.props.title || 'media'}
              </Button>
            )}
        <Modal
          isOpen={isOpen()}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Wybierz pliki</ModalHeader>
          <ModalBody>
            <MediaBody
              maxFiles={1}
              selectMedia={true}
              selectType={this.props.selectType}
              close={this.toggle}
              defaultFiter={this.props.defaultFiter}
              filesList={this.props.filesList}
              selectedFile={file => {
                this.props.selectedFile(file);
              }}
            />
          </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" onClick={this.toggle} />{' '} */}
            <Button color="secondary" onClick={this.toggle}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default SelectMedia;
