import { clone } from 'ramda';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader
} from 'reactstrap';
import SelectMedia from '../../../../components/Media/selectMedia';
import EditorButton from '../../common/Button';
import Icon from '../../common/Icon';
import { NODE_TYPES } from '../../consts';
import { insertEmbed } from '../../utils/embed';

const serializeImage = (file, sizeType) => ({
  url: file.file,
  id: file.id,
  alt: file.description,
  class: file.style,
  sizeType
});

const AddGridImages = ({ editor, amount, setBlockToggle, closeDropdown }) => {
  const [initialModel, setInitialModel] = useState([]);
  const [modalOpened, setModalOpen] = useState(false);
  const [grid, setGrid] = useState([]);

  const isValidGrid =
    grid.filter(({ image }) => image !== null).length === initialModel.length;

  const insertGrid = (e) => {
    insertEmbed(editor, { grid }, NODE_TYPES.GRID_IMAGES);
    close();
  };

  const handleClick = (e) => {
    e.preventDefault();
    const model = new Array(amount)
      .fill()
      .map((f) => ({ image: null, desc: '' }));
    setInitialModel(clone(model));
    setGrid(model);
    setModalOpen(true);
  };

  const setValue = (e, key) => {
    e.persist();
    const { target } = e;
    const _grid = clone(grid);
    _grid[key].desc = target.value;
    setGrid(_grid);
  };

  const close = () => {
    closeDropdown();
    setModalOpen(false);
  };

  useEffect(() => {
    setBlockToggle(modalOpened);
  }, [modalOpened]);

  return (
    <>
      <DropdownItem
        onClick={handleClick}
        size="sm"
        toggle={false}
        className="dropdown-item"
      >
        {amount} zdjęcia
      </DropdownItem>
      <Modal
        isOpen={modalOpened}
        toggle={() => {
          close();
        }}
      >
        <ModalHeader
          toggle={() => {
            close();
          }}
        >
          Moduł {amount} zdjęcia
        </ModalHeader>
        <ModalBody>
          <div
            className={`add-grid-images add-grid-images-${amount}`}
            style={{ margin: '1rem 0' }}
          >
            <div style={{ display: 'flex', margin: '-1rem' }}>
              {grid.map((item, key) => (
                <div key={key} style={{ margin: '1rem' }}>
                  {item.image !== null ? (
                    <>
                      <div
                        style={{
                          height: 'auto',
                          width: '210px',
                          paddingBottom: '75%',
                          backgroundImage: `url("${item.image.url}")`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'contain',
                          marginBottom: '1rem'
                        }}
                      />
                      <input
                        type="text"
                        name={`${key}_desc`}
                        className="form-control"
                        placeholder="Opis zdjęcia"
                        autoComplete="off"
                        value={item.desc}
                        onChange={(e) => setValue(e, key)}
                      />
                    </>
                  ) : (
                    <SelectMedia
                      small={true}
                      selectType="files"
                      selectedFile={(file) => {
                        const newGrid = clone(grid);
                        newGrid[key].image = serializeImage(file);
                        setGrid(newGrid);
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
            <Button
              style={{ marginTop: '1rem' }}
              className="btn btn-primary btn-sm"
              type="button"
              disabled={!isValidGrid}
              onClick={insertGrid}
            >
              Dodaj moduł
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const SelectMediaComponent = ({
  label,
  editor,
  closeDropdown,
  setBlockToggle,
  sizeType
}) => {
  const [modalOpened, setModalOpen] = useState(false);
  const handleClick = (e) => {
    e.preventDefault();
    setModalOpen(true);
  };

  const toggle = () => {
    setModalOpen(!modalOpened);
  };

  const addImage = (file) => {
    insertEmbed(editor, serializeImage(file, sizeType), NODE_TYPES.IMAGE);
    toggle();
    closeDropdown();
  };

  useEffect(() => {
    setBlockToggle(modalOpened);
  }, [modalOpened]);

  return (
    <SelectMedia
      small
      selectType="files"
      selectedFile={(file) => {
        addImage(file);
      }}
      modalOpened={modalOpened}
      toggle={toggle}
    >
      <DropdownItem
        onClick={handleClick}
        size="sm"
        toggle={false}
        className="dropdown-item"
      >
        {label}
      </DropdownItem>
    </SelectMedia>
  );
};

const ImageButton = ({ editor }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [blockToggle, setBlockToggle] = useState(false);
  const toggle = () => {
    if (blockToggle) return;
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => setDropdownOpen(false);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} size="sm">
      <DropdownToggle data-toggle="dropdown" tag="div">
        <EditorButton onClick={() => setDropdownOpen(true)}>
          <Icon icon="image"></Icon>
        </EditorButton>
      </DropdownToggle>
      <DropdownMenu>
        <SelectMediaComponent
          label="Pojedyncze zdjęcie"
          editor={editor}
          setBlockToggle={setBlockToggle}
          closeDropdown={closeDropdown}
        />

        <SelectMediaComponent
          label="Zdjęcie XL"
          editor={editor}
          setBlockToggle={setBlockToggle}
          closeDropdown={closeDropdown}
          sizeType="xl"
        />

        <AddGridImages
          editor={editor}
          setBlockToggle={setBlockToggle}
          closeDropdown={closeDropdown}
          amount={2}
        />

        <AddGridImages
          editor={editor}
          setBlockToggle={setBlockToggle}
          closeDropdown={closeDropdown}
          amount={3}
        />
      </DropdownMenu>
    </Dropdown>
  );
};
export default ImageButton;
