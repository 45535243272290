import React from 'react';

function getMediaUrlMeta(mediaURL) {
  if (mediaURL.includes('youtube')) {
    return {
      provider: 'youtube',
      url: mediaURL.replace('/watch?v=', '/embed/')
    };
  }

  if (mediaURL.includes('vimeo')) {
    return {
      provider: 'vimeo',
      url: mediaURL.replace(
        'https://vimeo.com/',
        'https://player.vimeo.com/video/'
      )
    };
  }

  return {
    provider: 'unknown',
    url: null
  };
}

function VideoEmbed({ mediaURL }) {
  const media = getMediaUrlMeta(mediaURL);
  switch (media.provider) {
    case 'youtube':
      return (
        <iframe
          title="video"
          src={media.url}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      );
    case 'vimeo':
      return (
        <iframe title="video" src={media.url} frameBorder="1" allowFullScreen />
      );
    default:
      return (
        <div className="VideosPreview__noVideo">
          <h3>Nieznane źródło wideo</h3>
        </div>
      );
  }
}

export default VideoEmbed;
