import * as api from "./api";
import {
  takeLatest,
  call,
  put,
  select,
  takeEvery
} from "@redux-saga/core/effects";
import * as actionTypes from "./actionTypes";
import * as actionCreators from "./actionCreators";
import * as selectors from "../selectors";

// import { SUCCESS } from '../../utils/actionSuffix';

export function* fetchArticleTypes() {
  const token = yield select(selectors.selectToken);
  try {
    const response = yield call(api.fetchArticleTypes, token);
    const { data } = response;
    const { results } = data;
    yield put(actionCreators.setArticleTypes(results));
  } catch (e) {
    console.log(e);
  }
}

export function* updateArticleType({ data }) {
  const token = yield select(selectors.selectToken);
  const id = yield select(selectors.selectArticleTypeId);
  try {
    const response = yield call(api.updateArticleTypes, token, id, data);
    // console.log(icon);
    console.log(response);
  } catch (e) {
    console.log(e);
  }
}

export default [
  takeLatest(actionTypes.FETCH_ARTICLE_TYPES, fetchArticleTypes),
  takeEvery(actionTypes.UPDATE_ARTICLES_TYPES, updateArticleType)
  //
];
