import React from 'react';
// import { render } from 'react-dom';
import { connect } from 'react-redux';

import Layout from '../../components/Layout/index.jsx';
import { Button, Input, Row, Col } from 'reactstrap';
import API from '../../services/api';
import {
  consumerStatus,
  consumerType,
  userType,
  consumerStatuses,
  consumerTypes,
  userTypes
} from '../../utilities/user';
import { calculateMaxPages } from '../../utilities/calculates';
import { DeleteButton } from '../../components/FunctionalButtons/DeleteButton';
import { deleteUser } from '../../services/redux/reducers/user/actionCreators';
import ReactTable from 'react-table';
import ExportButton from '../../common/Export/ExportButton';

class Users extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      pages: null,
      loading: true,
      tableState: ''
    };
    this.fetchData = this.fetchData.bind(this);
    this.getFilterVal = this.getFilterVal.bind(this);
  }
  fetchData(state, instance) {
    let url = `/api/v1/users/?limit=${state.pageSize}&`;
    let params = [];
    const { token } = this.props;
    this.setState({ loading: true, tableState: state });
    let offset = state.page * state.pageSize;
    if (offset) {
      params.push(`offset=${offset}`);
    }
    if (state.filtered.length) {
      state.filtered.forEach(element => {
        switch (element.id) {
          case 'email':
            params.push(`search=${element.value}`);
            break;

          default:
            params.push(`${element.id}=${element.value}`);
            break;
        }
      });
    }
    if (state.sorted.length) {
      params.push(
        `ordering=${state.sorted[0].desc ? '-' : ''}${state.sorted[0].id}`
      );
    }
    if (params.length) {
      let allParams = params.reduce((a, b) => a + '&' + b);
      url = url + allParams;
    }
    API.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(({ data }) => {
      this.setState({
        data: data.results,
        pages: calculateMaxPages(data.count, state.pageSize),
        loading: false
      });
    });
  }
  removeUser(id) {
    this.props.deleteUser(id);
    setTimeout(() => {
      this.fetchData(this.state.tableState);
    }, 1000);
  }
  getFilterVal(type) {
    let filters = this.state.tableState.filtered;
    if (filters && filters.length) {
      let filter = filters.find(item => item.id === type);
      return filter ? filter.value : '';
    }
    return '';
  }
  render() {
    const { data, pages, loading } = this.state;
    const { userType: currUserType } = this.props;
    return (
      <Layout>
        <h1 className="h2 pb-md-4 page-header">
          Użytkownicy{' '}
          <Button
            onClick={e => {
              e.preventDefault();
              this.props.history.push('/users/new');
            }}
            color="primary"
          >
            Dodaj nowego
          </Button>
        </h1>
        <Row className="space-between">
          <Col>
            <h4>Lista użytkowników</h4>
          </Col>
          <Col sm="3" className="text-right">
            <ExportButton
              type="exportUsers"
              userType={this.getFilterVal('user_type')}
              consumerType={this.getFilterVal('consumer_type')}
              consumerStatus={this.getFilterVal('consumer_status')}
            />
          </Col>
        </Row>
        <div className="table-responsive">
          <ReactTable
            columns={[
              {
                Header: 'Lp.',
                Cell: row => {
                  return <div>{row.index + 1 + row.page * row.pageSize}</div>;
                },
                maxWidth: 40
              },
              {
                Header: 'Email',
                accessor: 'email',
                // searchable
                filterable: true
              },
              {
                Header: 'Imię i nazwisko',
                id: 'user_data',
                accessor: d => `${d.first_name} ${d.last_name}`
              },
              {
                Header: 'Typ użytkownika',
                id: 'user_type',
                accessor: d => (d.user_type ? userType(d.user_type) : ''),
                filterable: true,
                Filter: ({ onChange }) => (
                  <Input
                    type="select"
                    name="select_user_type"
                    id="select_user_type"
                    onChange={e => onChange(e.target.value)}
                    // ref={this.selectInput}
                    value={this.getFilterVal('user_type')}
                    size="sm"
                  >
                    <option value="">Wybierz</option>
                    {userTypes.map((type, index) => (
                      <option value={index + 1} key={index}>
                        {type}
                      </option>
                    ))}
                  </Input>
                )
              },
              {
                Header: 'Typ klienta',
                id: 'consumer_type',
                accessor: d =>
                  parseInt(d.consumer_type) !== 1
                    ? consumerType(d.consumer_type).label
                    : 'nie dotyczy',
                filterable: true,
                Filter: ({ onChange }) => (
                  <Input
                    type="select"
                    name="select_consumer_type"
                    id="select_consumer_type"
                    onChange={e => onChange(e.target.value)}
                    // ref={this.selectInput}
                    // value={this.state.value}
                    value={this.getFilterVal('consumer_type')}
                    size="sm"
                  >
                    <option value="">Wybierz</option>
                    <option value={1}>nie dotyczy</option>
                    {consumerTypes.map((type, index) => (
                      <option value={type.val} key={index}>
                        {type.label}
                      </option>
                    ))}
                  </Input>
                )
              },
              {
                Header: 'Status klienta',
                id: 'consumer_status',
                accessor: d =>
                  d.consumer_status && consumerStatus(d.consumer_status)
                    ? consumerStatus(d.consumer_status).label
                    : 'nie dotyczy',
                filterable: true,
                Filter: ({ onChange }) => (
                  <Input
                    type="select"
                    name="select_consumer_status"
                    id="select_consumer_status"
                    onChange={e => onChange(e.target.value)}
                    // ref={this.selectInput}
                    value={this.getFilterVal('consumer_status')}
                    size="sm"
                  >
                    <option value="">Wybierz</option>
                    {consumerStatuses.map(type => (
                      <option value={type.val} key={type.val}>
                        {type.label}
                      </option>
                    ))}
                  </Input>
                )
              },
              {
                Header: 'Akcje',
                Cell: d => {
                  return (
                    <>
                      <Button
                        color="primary"
                        onClick={() =>
                          this.props.history.push(`users/${d.original.id}`)
                        }
                        size="sm"
                      >
                        Edytuj
                      </Button>
                      {currUserType === 1 ? (
                        <Button
                          color="info"
                          onClick={() =>
                            this.props.history.push(
                              `users/history/${d.original.id}`
                            )
                          }
                          size="sm"
                        >
                          Historia
                        </Button>
                      ) : null}
                      <DeleteButton
                        callback={() => this.removeUser(d.original.id)}
                      />
                    </>
                  );
                },
                maxWidth: 300
              }
            ]}
            manual
            data={data}
            pages={pages}
            loading={loading}
            onFetchData={this.fetchData}
            defaultPageSize={10}
            resizable={false}
            className="-striped -highlight"
          />
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { user } = state;
  return {
    token: user.token.key,
    userType: user.user_type
  };
}

export default connect(
  mapStateToProps,
  { deleteUser }
)(Users);
