import React, { Component } from 'react';
import Layout from '../../components/Layout/index.jsx';
import { AritcleForm } from '../../components/Articles/Form';

class NewArticle extends Component {
  render() {
    return (
      <Layout>
        <h1 className="h2 pb-md-4 page-header">
          Nowy artykuł {this.props.match.params.id}
        </h1>
        {/* Content */}
        <AritcleForm report={false} />
      </Layout>
    );
  }
}

export default NewArticle;
