import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

export const DeleteButton = ({ buttonLabel, callback }) => {
  const [show, setShow] = useState(false);
  const toggle = () => {
    setShow(!show);
  };
  return (
    <>
      <Button color="danger" size="sm" onClick={toggle}>
        {buttonLabel || 'Usuń'}
      </Button>
      <Modal isOpen={show} toggle={toggle} size="sm">
        <ModalBody>Czy jesteś pewny, że chcesz usunąć element?</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              toggle();
              if (callback) {
                callback();
              }
            }}
          >
            Tak
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Nie
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
