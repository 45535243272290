import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Alert,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import './QuizAnswers.scss';

function handleUpdateAnswer(answer, fieldName, newValue) {
  return {
    ...answer,
    [fieldName]: newValue
  };
}

function AnswerTextInput({ defaultValue, answer, onUpdateAnswer, disabled }) {
  const [value, setValue] = useState('');

  const updateField = (field, answer) => {
    if (field.value !== '' && field.value !== answer[field.name]) {
      const updatedAnswer = handleUpdateAnswer(answer, field.name, field.value);
      return onUpdateAnswer(updatedAnswer);
    }

    return (field.value = answer[field.name]);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <input
      className="QuizAnswers__answer_text"
      value={value}
      disabled={disabled}
      name="answer_text"
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onBlur={(e) => {
        updateField(e.target, answer);
      }}
    />
  );
}

function QuizAnswers({
  answers,
  onAddAnswer,
  onUpdateAnswer,
  onRemoveAnswer,
  readOnly
}) {
  return (
    <div className="QuizAnswers">
      <Row>
        {answers
          .sort((a, b) => a.id - b.id)
          .map((answer) => (
            <Col xs={6} className="QuizAnswers__answer">
              <Card>
                <CardBody>
                  <div className="QuizAnswers__answer__title">
                    <AnswerTextInput
                      defaultValue={answer.answer_text}
                      answer={answer}
                      onUpdateAnswer={onUpdateAnswer}
                      disabled={readOnly}
                    />
                    {!readOnly && (
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        onClick={() => onRemoveAnswer(answer)}
                      />
                    )}
                  </div>
                </CardBody>
                {answer.answer_image && (
                  <img
                    bottom
                    width="100%"
                    src={answer.answer_image}
                    alt={answer.answer_text}
                  />
                )}
                {!!answer.answer_image && !readOnly && (
                  <Button
                    color="secondary"
                    size="sm"
                    block
                    onClick={() => {
                      onUpdateAnswer(
                        handleUpdateAnswer(answer, 'answer_image', null)
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
                )}
                <CardBody>
                  <FormGroup>
                    {!answer.answer_image && !readOnly && (
                      <Input
                        type="file"
                        name="answer_image"
                        id="answer_image"
                        disabled={readOnly}
                        onChange={(event) => {
                          onUpdateAnswer(
                            handleUpdateAnswer(
                              answer,
                              'answer_image',
                              event.currentTarget.files[0]
                            )
                          );
                        }}
                      />
                    )}
                  </FormGroup>
                  <FormGroup check className="QuizAnswers__correct">
                    <Label check>
                      <Input
                        name="correct"
                        type="checkbox"
                        checked={answer.correct}
                        disabled={readOnly}
                        onChange={(e) =>
                          onUpdateAnswer(
                            handleUpdateAnswer(
                              answer,
                              'correct',
                              e.target.checked
                            )
                          )
                        }
                      />
                      Poprawna odpowiedź
                    </Label>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          ))}
      </Row>
      {!answers.length && (
        <Alert color="info">
          Twoje pytanie nie zawiera żadnych odpowiedzi. Utwórz pierwszą
          odpowiedź.
        </Alert>
      )}

      {!readOnly && (
        <div className="QuizAnswers__actions">
          <Button color="info" block onClick={onAddAnswer}>
            Dodaj nową odpowiedź
          </Button>
        </div>
      )}
    </div>
  );
}

QuizAnswers.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      question: PropTypes.string.isRequired,
      answer_text: PropTypes.string.isRequired,
      answer_image: PropTypes.string,
      correct: PropTypes.bool.isRequired
    })
  ).isRequired,
  onAddAnswer: PropTypes.func,
  onUpdateAnswer: PropTypes.func,
  onRemoveAnswer: PropTypes.func
};
QuizAnswers.defaultProps = {};

export default QuizAnswers;
