import API from "../services/api";
import cookies from "./cookies";

export function generateCSV(csvContent) {
  let encodedUri = encodeURI(csvContent.data);
  let link = document.createElement("a");
  link.setAttribute("href", "data:text/csv;charset=utf-8," + encodedUri);
  link.setAttribute("download", "export.csv");
  document.body.appendChild(link); // Required for FF

  link.click();
}

export const exportEventUsers = eventid => {
  const token = cookies.getToken();
  return API.get(`/api/v1/events/${eventid}/participants-export/`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const exportPollResults = pollId => {
  const token = cookies.getToken();
  return API.get(`/api/v1/polls/${pollId}/results-export/`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
