import React, { Component } from 'react';
import Layout from '../../components/Layout/index.jsx';
import PollForm from '../../components/Polls/PollForm';

class NewPoll extends Component {
  render() {
    return (
      <Layout>
        <h1 className="h2 pb-md-4 page-header">Nowa sonda</h1>
        {/* Content */}
        <PollForm />
      </Layout>
    );
  }
}

export default NewPoll;
