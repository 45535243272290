import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';

class Alerts extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { alert } = this.props;
    return (
      <>
        {alert.message && (
          <Alert color={`${alert.type}`}>{alert.message}</Alert>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert
  };
}

export default connect(mapStateToProps)(Alerts);
