import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UploadFile } from '../../common/Inputs';
import { withFormik } from 'formik';
import { Form, Button, FormGroup, Label } from 'reactstrap';
import { createMedia } from '../../services/redux/reducers/media/actionCreators';

class UploadForm extends Component {
  addFile = (acceptedFiles, setFieldValue, values) => {
    if (values.files && Array.isArray(acceptedFiles)) {
      let newFiles = [];
      acceptedFiles.forEach(element => {
        newFiles.push({ file: element, description: '' });
      });
      setFieldValue('files', [...values.files, ...acceptedFiles]);
    } else {
      setFieldValue('files', [
        ...values.files,
        { file: acceptedFiles, description: '', name: acceptedFiles.name }
      ]);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.mediaRequest.uploading && this.props.mediaRequest.uploaded) {
      this.props.setFieldValue('files', []);
    }
  }

  render() {
    const { handleSubmit, setFieldValue, values } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Dodaj nowe media</Label>
          <UploadFile
            onDrop={acceptedFiles => {
              this.addFile(acceptedFiles, setFieldValue, values);
            }}
            addDesc={(index, val) =>
              setFieldValue(`files[${index}].description`, val)
            }
            addName={(index, val) => setFieldValue(`files[${index}].name`, val)}
            addStyle={(index, val) => setFieldValue(`files[${index}].style`, val)}
            files={values.files}
          />
        </FormGroup>
        <FormGroup>
          <Button onClick={e => handleSubmit(e)}>Prześlij pliki</Button>
        </FormGroup>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  const { mediaRequest } = state;
  return {
    mediaRequest
  };
}

const FormikProp = {
  mapPropsToValues: () => ({ files: [] }),
  handleSubmit: (values, { props }) => {
    const { files } = values;
    files.forEach(item => props.createMedia(item));
  }
};

export default connect(
  mapStateToProps,
  { createMedia }
)(withFormik(FormikProp)(UploadForm));
