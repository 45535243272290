import React from 'react'
import { useFocused, useSelected } from 'slate-react';

const ArticleLink = ({ attributes, element, children }) => {
  const { data } = element
  const selected = useSelected();
  const focused = useFocused();
  const isFocused = selected && focused

  return (
    <div
      {...attributes}
      className="element-article-link-wrapper"
      style={{ boxShadow: isFocused && '0 0 3px 3px lightgray' }}
    >
      <div contentEditable={false}>
        {data.title && data.href && (
          <div className="element-article-link">
            {data.text}
            <div>{data.title}</div>
          </div>
        )}
      </div>
      {children}
    </div>
  )
}

export default ArticleLink
