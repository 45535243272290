import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { frontLink } from '../../services/api';
import ReactTable from 'react-table';
import { Button, Input, Col, Row, Alert } from 'reactstrap';
import styled from 'styled-components';
import queryString from 'query-string';
import debounce from 'lodash.debounce';

import { DeleteButton } from '../../components/FunctionalButtons/DeleteButton';
import { calculateMaxPages } from '../../utilities/calculates';
import Layout from '../../components/Layout';
import { formatDate } from '../../utilities/dates';
import {
  useRealEstate,
  usePropertyTypes,
  useProvinces,
  useAllertMessage
} from './useRealEstate';
import {
  AsyncFormikSelect,
  FormikCheckbox,
  FormikInput,
  FormikSelect,
  STATUS
} from './RealEstateInputs';

const RowCenter = styled.span`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  input {
    margin: 0;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

function RealEstateList() {
  const [isUploading, setUploading] = useState(false);
  const token = useSelector((state) => state.user.token.key);
  const { isReady, message, messageType, showMessage } = useAllertMessage();
  const {
    realEstateList,
    removeProperty,
    updateProperty,
    fetchData,
    importFile
  } = useRealEstate(token, false);
  const { provincesList } = useProvinces(token);
  const { propertyTypes } = usePropertyTypes(token);
  const pages = calculateMaxPages(realEstateList.count, 10);
  const location = useLocation();
  const history = useHistory();
  const search = queryString.parse(location.search);

  const onSearch = (params, clear = false) => {
    return history.push(
      `${location.pathname}?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
        arrayFormat: 'comma'
      })}`
    );
  };

  const debounceSearch = debounce(({ filtered, page, pageSize, ...props }) => {
    document.querySelector('main').scrollTo(0, 0);
    const filters = filtered
      .map(({ id, value }) => ({ [id]: value }))
      .reduce(function (result, item) {
        var key = Object.keys(item)[0];
        result[key] = item[key];
        return result;
      }, {});
    onSearch({
      promoted: filters.promoted,
      provinces_list: filters.province_name,
      property_type: filters.property_type,
      publish_status: filters.publish_status,
      offer_number: filters.offer_number,
      name: filters.name,
      offset: page * pageSize,
      limit: pageSize
    });
  }, 500);

  const uploadFile = (e) => {
    e.preventDefault();
    if (e.target.elements['file'].files.length === 0) return;
    const form = e.target;
    var formData = new FormData(form);
    setUploading(true);
    importFile(formData)
      .then(() => {
        showMessage('Plik został zaimportowany', 'success');
      })
      .catch(() => {
        showMessage('Nie udało sie zaimportować pliku', 'danger');
      })
      .finally(() => {
        form.reset();
        setUploading(false);
      });
  };

  useEffect(() => {
    fetchData(search);
  }, [location]);

  return (
    <Layout>
      <Row>
        <Col xs={12} md={6}>
          <h1 className="h2 pb-md-4 page-header">
            Nieruchomości
            <Button
              onClick={(e) => {
                e.preventDefault();
                history.push('/real-estate/new');
              }}
              color="primary"
            >
              Dodaj nowy
            </Button>
          </h1>
        </Col>
        <Col xs={12} md={6}>
          <form onSubmit={uploadFile}>
            <input type="file" name="file" />
            {isUploading ? (
              'Wysyłanie...'
            ) : (
              <Button type="submit" color="primary">
                Importuj z pliku .xls
              </Button>
            )}
          </form>
          {isReady && <Alert color={messageType}>{message}</Alert>}
        </Col>
      </Row>
      <h4>Lista nieruchomości</h4>
      <div className="table-responsive">
        <ReactTable
          columns={[
            {
              Header: 'Numer oferty',
              accessor: 'offer_number',
              Filter: ({ onChange }) => (
                <Input
                  type="text"
                  onChange={(e) => onChange(e.target.value)}
                  size="sm"
                  defaultValue={search.offer_number}
                />
              )
            },
            {
              Header: 'Tytuł',
              accessor: 'name',
              Filter: ({ onChange }) => (
                <Input
                  type="text"
                  onChange={(e) => onChange(e.target.value)}
                  size="sm"
                  defaultValue={search.name}
                />
              )
            },
            {
              Header: 'Rodzaj nieruchomości',
              id: 'property_type',
              accessor: (d) => {
                const type = propertyTypes.find(
                  (type) => type.id === d.property_type
                );
                return type ? type.name : '';
              },
              Filter: ({ onChange }) => (
                <Input
                  type="select"
                  onChange={(e) => onChange(e.target.value)}
                  size="sm"
                  defaultValue={search.property_type}
                >
                  <option value="">Wszystkie</option>
                  {propertyTypes.map(({ id, name }) => (
                    <option value={id} key={id}>
                      {name}
                    </option>
                  ))}
                </Input>
              )
            },
            {
              Header: 'Województwo',
              accessor: 'province_name',
              Filter: ({ onChange }) => (
                <Input
                  type="select"
                  onChange={(e) => onChange(e.target.value)}
                  size="sm"
                  defaultValue={search.provinces_list}
                >
                  <option value="">CAŁA POLSKA</option>
                  {provincesList.map(({ id, name }) => (
                    <option value={id} key={id}>
                      {name}
                    </option>
                  ))}
                </Input>
              )
            },
            {
              Header: 'Promowane',
              accessor: 'promoted',
              maxWidth: 150,
              Filter: ({ onChange }) => (
                <Input
                  type="select"
                  onChange={(e) => onChange(e.target.value)}
                  size="sm"
                  defaultValue={search.promoted}
                >
                  <option value="">Wszystkie</option>
                  <option value="true">Promowane</option>
                </Input>
              ),
              Cell: (row) => (
                <RowCenter>
                  <Input
                    type="checkbox"
                    name="promoted"
                    defaultChecked={row.original.promoted}
                    onChange={({ target }) => {
                      updateProperty({
                        slug: row.original.slug,
                        promoted: target.checked
                      });
                    }}
                  />
                </RowCenter>
              )
            },
            {
              Header: 'Status',
              accessor: 'publish_status',
              Filter: ({ onChange }) => (
                <Input
                  type="select"
                  onChange={(e) => onChange(e.target.value)}
                  size="sm"
                  defaultValue={search.publish_status}
                >
                  <option value="">Wybierz</option>
                  {STATUS.map((type) => (
                    <option value={type.value} key={type.value}>
                      {type.label}
                    </option>
                  ))}
                </Input>
              ),
              Cell: (row) => (
                <FormikSelect
                  type="select"
                  name="publish_status"
                  options={STATUS}
                  defaultValue={row.original.publish_status}
                  onChange={({ target }) => {
                    updateProperty({
                      slug: row.original.slug,
                      publish_status: target.value,
                      promoted:
                        target.value === '3' ? false : row.original.promoted
                    });
                  }}
                />
              )
            },
            {
              Header: 'Akcje',
              Cell: (d) => {
                return (
                  <Actions>
                    <Button
                      color="primary"
                      onClick={() =>
                        history.push(`/real-estate/${d.original.slug}`)
                      }
                      size="sm"
                    >
                      Edytuj
                    </Button>
                    <Button
                      color="secondary"
                      size="sm"
                      onClick={() =>
                        window.open(
                          `${frontLink()}nieruchomosci/${d.original.slug}`,
                          '_blank'
                        )
                      }
                    >
                      Zobacz
                    </Button>
                    <DeleteButton
                      callback={() => removeProperty(d.original.slug)}
                    />
                  </Actions>
                );
              },
              filterable: false,
              maxWidth: 210
            }
          ]}
          manual
          data={realEstateList.results}
          pages={pages}
          filterable
          sortable={false}
          resizable={false}
          onFetchData={debounceSearch}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      </div>
    </Layout>
  );
}

export default RealEstateList;
