import React, { useContext } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import ReactTable from 'react-table';
import classNames from 'classnames';
import debounce from 'lodash.debounce';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';

import { getStatusValue } from '../../../../views/Videos/components/VideosPreview';
import { selectCMSLang } from '../../../../services/redux/reducers/selectors';
import { QuizzesContext } from '../../Quizzes.context';

import './QuizzesList.scss';

function QuizzesList() {
  const {
    quizzes,
    removeQuiz,
    updatePageSize,
    pages,
    page,
    updatePage,
    updateFilters
  } = useContext(QuizzesContext);
  const lang = useSelector(selectCMSLang);
  const history = useHistory();

  const onFilteredChangedeDounce = debounce((filters) => {
    const params = filters.reduce((prev, current) => {
      if (current.id === 'title') {
        return {
          ...prev,
          search: current.value
        };
      }
      return {
        ...prev,
        [current.id]: current.value
      };
    }, {});

    updateFilters(params);
  }, 500);

  return (
    <div>
      <ReactTable
        columns={[
          {
            Header: 'Nazwa',
            accessor: 'title',
            sortable: false
          },
          {
            Header: 'Status',
            accessor: 'publish_status',
            sortable: false,
            maxWidth: 150,
            Filter: ({ filter, onChange, ...props }) => (
              <select
                onChange={(event) => onChange(event.target.value)}
                value={filter ? filter.value : ''}
              >
                <option value=""></option>
                <option value="1">Szkic</option>
                <option value="2">Oczekuje</option>
                <option value="3">Opublikowany</option>
              </select>
            ),
            Cell: ({ value }) => <>{getStatusValue(value).label}</>
          },
          {
            Header: '',
            accessor: 'slug',
            sortable: false,
            filterable: false,
            maxWidth: 72,
            Cell: ({ value }) => (
              <Button
                size="sm"
                color="danger"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  removeQuiz(value);
                }}
              >
                Usuń
              </Button>
            )
          }
        ]}
        manual
        filterable
        minRows={0}
        resolveData={(data) => data.map((row) => row)}
        data={quizzes.results}
        pages={pages}
        page={page}
        onPageChange={(val) => {
          updatePage(val);
        }}
        onPageSizeChange={(val) => {
          updatePageSize(val);
        }}
        onFilteredChange={onFilteredChangedeDounce}
        defaultPageSize={5}
        loading={false}
        className="-striped -highlight"
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              onClick: (e) => {
                history.push(
                  generatePath('/quizzes/:slug', {
                    slug: rowInfo.original.slug
                  })
                );
              }
            };
          } else {
            return {};
          }
        }}
      />
    </div>
  );
}

export default QuizzesList;
