import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchArticleTypes,
  setArticleTypesID
} from "../../services/redux/reducers/articlesTypes/actionCreators";
import { Input } from "reactstrap";
import { ListGroup, ListGroupItem, ButtonGroup, Button } from "reactstrap";

class ArticleTypesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.initialValue || ""
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState(
      { value: event.target.value },
      this.props.selectType(event.target.value)
    );
  }

  componentDidMount() {
    if (!this.props.articleTypes.length) {
      this.props.fetchTypes();
    }
  }

  editCategory(e, id) {
    this.props.editCategory(id);
  }

  selectInput = React.createRef();

  render() {
    const { isList, articleTypes } = this.props;
    return (
      <>
        {isList ? (
          <ListGroup>
            {articleTypes.length ? (
              articleTypes.map(type => (
                <ListGroupItem key={type.id} className="listItem--with-button">
                  <div>
                    Ikona:{" "}
                    <img
                      src={type.icon}
                      alt=""
                      className="mb-sm-2 types--icon"
                    />{" "}
                    <br />
                    Nazwa: {type.type_name}
                  </div>
                  <ButtonGroup size="sm">
                    <Button
                      size="sm"
                      onClick={e => this.editCategory(e, type.id)}
                    >
                      Edytuj
                    </Button>
                  </ButtonGroup>
                </ListGroupItem>
              ))
            ) : (
              <ListGroupItem>Brak typów</ListGroupItem>
            )}
          </ListGroup>
        ) : (
          <Input
            type="select"
            name={this.props.name}
            id={`select${this.props.name}`}
            onChange={this.handleChange}
            ref={this.selectInput}
            value={this.state.value}
            size={this.props.sm ? "sm" : ""}
          >
            <option value="">Wybierz</option>
            {articleTypes.map(type => (
              <option
                value={type.id}
                key={type.id}
                // selected={initialValue && initialValue === type.id}
              >
                {type.type_name}
              </option>
            ))}
          </Input>
        )}
      </>
    );
  }
}

function mapStateToProps(state, { report }) {
  const { articleTypes } = state;
  return {
    articleTypes: report ? articleTypes.agroData : articleTypes.data
  };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchTypes: () => dispatch(fetchArticleTypes()),
    editCategory: id => dispatch(setArticleTypesID(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleTypesList);
