import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import cookies from '../../utilities/cookies';
import Loading from '../Loading';
import { logoutUser } from '../../services/redux/reducers/user/actionCreators';
import Layout from '../../components/Layout/index.jsx';

// import { userIsAuthenticated, userIsNotAuthenticated } from "./services/auth";

const RedirectLogin = props => (
  <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
);

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
    let token = cookies.getUserData().token;
    this.state = {
      token
    };
  }

  componentDidUpdate() {
    if (this.props.failure) {
      cookies.removeToken();
      this.props.logoutUser();
    }
  }

  checkPermission() {
    const { userData, permission } = this.props;
    if (typeof permission === 'function') {
      return permission(userData.user_type);
    }
    return permission ? userData[permission] : true;
  }

  render() {
    const {
      component: Component,
      isLogginIn,
      isLoggedIn,
      failure,
      userData,
      ...rest
    } = this.props;
    const { token } = this.state;
    return (
      <div>
        <Route
          {...rest}
          render={props => (
            <div>
              {isLoggedIn && this.checkPermission() && <Component {...props} />}
              {token && isLogginIn && <Loading />}
              {!token && RedirectLogin(props)}
              {failure ? RedirectLogin(props) : null}
              {!this.checkPermission() && (
                <Layout>
                  <strong>Brak uprawnień</strong>
                </Layout>
              )}
            </div>
          )}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { userRequest, user } = state;
  return {
    token: user.token.key,
    isLoggedIn: !!user.email,
    isLogginIn: !!userRequest.isLoginUser,
    failure: !!userRequest.fetchUserDataError,
    userData: user
  };
}

export default connect(
  mapStateToProps,
  { logoutUser }
)(PrivateRoute);
