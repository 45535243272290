// export const avblLangs = ['fr', 'de', 'ru', 'gb', 'ua', 'tr', 'pl'];

export const langs = [
  { lang_code: 'fr', name: '', active: false },
  { lang_code: 'de', name: '', active: false },
  { lang_code: 'ru', name: '', active: false },
  { lang_code: 'GB', name: 'Angielski', active: true },
  { lang_code: 'ua', name: '', active: false },
  { lang_code: 'tr', name: '', active: false },
  { lang_code: 'PL', name: 'Polski', active: true }
];

export const avblLangs = langs.filter(item => item.active);

export const getCurrentLang = pathname => {
  let currentLang = 'pl';
  avblLangs.forEach(lang => {
    if (pathname.indexOf(`/${lang}/`) > -1) {
      currentLang = lang;
    }
  });
  return currentLang;
};

export const getLangByCode = code => {
  let lang = avblLangs.find(item => item.lang_code === code);
  return lang ? lang.name : null;
};

export const locale = {
  // pl: {
  //   hello: 'Witaj',
  //   world: 'Swiecie'
  // },
  en: {
    hello: 'Hello',
    world: 'World',
    article: {
      pageTitle: 'Articles'
    }
  }
};
