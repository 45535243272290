import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchArticleCategoriesNew,
  setSelected,
  deleteCategory
} from "../../services/redux/reducers/articlesCategories/actionCreators";
import { Input } from "reactstrap";
import { ListGroup, ListGroupItem, Button, ButtonGroup } from "reactstrap";

class ArticleCategoriesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.initialValue,
      list: []
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { multiple } = this.props;
    const { value } = this.state;
    let newVal = parseInt(event.target.value);
    if (multiple) {
      let existig = value.findIndex(item => parseInt(item) === newVal);
      if (existig >= 0) {
        value.splice(existig, 1);
        this.setState({ value }, () => {
          this.props.selectType(this.state.value);
        });
      } else {
        this.setState({ value: [...value, newVal] }, () => {
          this.props.selectType(this.state.value);
        });
      }
    } else {
      this.setState({ value: newVal }, this.props.selectType(newVal));
    }
  }

  componentDidMount() {
    // if (!this.props.articleCategories.data.length) {
    this.props.fetchCategories();
    // }
  }

  editCategory(e, catId) {
    e.preventDefault();
    this.props.setCatToEdit(catId);
  }

  deleteCategory(e, catId) {
    // alert('brak akcji');
    this.props.deleteCategory(catId);
  }

  selectInput = React.createRef();

  render() {
    const { isList, articleCategories, multiple, translatedCats } = this.props;
    return (
      <>
        {isList ? (
          <ListGroup className="pb-md-4">
            {articleCategories.articleCategoriesNew.data.length ? (
              articleCategories.articleCategoriesNew.data.map(type => (
                <ListGroupItem key={type.id} className="listItem--with-button">
                  {type.name}
                  <ButtonGroup size="sm">
                    <Button
                      size="sm"
                      onClick={e => this.editCategory(e, type.id)}
                    >
                      Edytuj
                    </Button>
                    <Button
                      size="sm"
                      onClick={e => this.deleteCategory(e, type.id)}
                      color="danger"
                    >
                      Usuń
                    </Button>
                  </ButtonGroup>
                </ListGroupItem>
              ))
            ) : (
              <ListGroupItem>Brak kategorii</ListGroupItem>
            )}
          </ListGroup>
        ) : (
          <Input
            type="select"
            name={this.props.name}
            id={`select${this.props.name}`}
            // onChange={this.handleChange}
            ref={this.selectInput}
            value={this.state.value}
            multiple={multiple}
            onClick={this.handleChange}
          >
            {!multiple ? <option value="">Wybierz</option> : null}
            {articleCategories.articleCategoriesNew.data.map(type =>
              <option value={type.id} key={type.id}>
                {type.type_name}
              </option>
            )}
          </Input>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
    articleCategories,
    settings: { lang }
  } = state;
  let translatedCats = {};
  // articleCategories.articleCategoriesNew.data.forEach(item => {
    // let newTranslate = item.translations.find(t => t.lang_code === lang);
    // if (newTranslate) translatedCats[item.id] = newTranslate.name;
  // });
  return {
    articleCategories,
    translatedCats
  };
}

const mapDispatchToProps = (dispatch, { report }) => {
  return {
    fetchCategories: () => dispatch(fetchArticleCategoriesNew(report)),
    setCatToEdit: id => dispatch(setSelected(id)),
    deleteCategory: id => dispatch(deleteCategory(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleCategoriesList);
