export const FEATURED_VALUES = {
  NO_HIGH: 1,
  HIGH_SMALL: 2,
  HIGH_BIG: 3
}

export const FEATURED_TYPES = [
  { label: 'duży kafel w głównej sekcji', value: FEATURED_VALUES.HIGH_BIG },
  { label: 'mały kafel w głównej sekcji', value: FEATURED_VALUES.HIGH_SMALL },
  { label: 'nie wyróżniaj', value: FEATURED_VALUES.NO_HIGH }
]
