import React from 'react'

const Button = (props) => {
  const { children, format, active, ...rest } = props
  return (
    <span
      className={`editor-toolbar-el ${active ? 'editor-toolbar-el--active' : ''}`}
      title={format}
      {...rest}
    >
      {children}
    </span>
  )
}

export default Button;
