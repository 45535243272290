import { update } from 'immutable';
import React, { useState, createContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectCMSLang } from '../../services/redux/reducers/selectors';
import { useQuizzesApi } from './Quizzes.api';

const defaultContext = {
  quizzes: {
    count: -1,
    next: null,
    previous: null,
    results: []
  }
};

export const QuizzesContext = createContext(defaultContext);

export const QuizzesProvider = ({ children }) => {
  const [quizzes, setQuizzes] = useState(defaultContext.quizzes);
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(1);
  const [filters, setFilters] = useState({});

  const lang = useSelector(selectCMSLang);
  const { getQuizzes, deleteQuiz } = useQuizzesApi();

  const updateQuizzes = () =>
    getQuizzes({
      lang_code: lang,
      limit: pageSize,
      offset: page * pageSize,
      ordering: '-created_at',
      ...filters
    }).then(({ data }) => {
      setPages(Math.ceil(data.count / pageSize));
      setQuizzes(data);
    });

  const removeQuiz = (quiz_slug) =>
    deleteQuiz({ quiz_slug }).then(() => {
      updateQuizzes();
    });

  useEffect(() => {
    updateQuizzes();
  }, [pageSize, page, filters]);

  return (
    <QuizzesContext.Provider
      value={{
        quizzes,
        pages,
        page,
        filters,
        removeQuiz,
        updatePage: (number = 0) => setPage(Number(number)),
        updatePageSize: (size = 5) => setPageSize(Number(size)),
        updateFilters: setFilters
      }}
    >
      {children}
    </QuizzesContext.Provider>
  );
};
