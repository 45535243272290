import * as actions from './actionTypes';
import { SUCCESS } from '../../utils/actionSuffix';

const InitialState = {
  data: null,
  selected: -1,
  translations: null
};

export function polls(state = InitialState, action) {
  switch (action.type) {
    case actions.SET_POLL_ID:
      return {
        ...state,
        selected: action.id
      };

    case actions.FETCH_POLL + SUCCESS:
    case actions.UPDATE_POLL + SUCCESS:
      return {
        ...state,
        data: action.data
      };

    case actions.CREATE_POSSIBILITY + SUCCESS:
      const possibilities = [...state.data.possibilities];
      possibilities[action.index] = action.data;
      return {
        ...state,
        data: {
          ...state.data,
          possibilities
        }
      };

    case actions.FETCH_POLL_TRANSLATIONS + SUCCESS:
      return {
        ...state,
        translations: action.data.results
      };

    case actions.RESET_POLL:
      return InitialState;

    default:
      return state;
  }
}
