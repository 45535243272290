import React from 'react';

const iconList={
    bold: 'type-bold',
    italic: 'type-italic',
    underline: 'type-underline',
    headingTwo: 'type-h2',
    headingThree: 'type-h3',
    blockquote: 'quote',
    orderedList: 'list-ol',
    unorderedList: 'list-ul',
    link: 'link',
    image: 'image',
    video: 'code-square'
}

const Icon = (props)=>{
    const {icon} = props

    return(
      <i
        className={`bi bi-${iconList[icon]}`}
      />
    )
}

export default Icon;
