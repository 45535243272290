import React, { Component } from 'react';
import Layout from '../../components/Layout/index.jsx';
import { AritcleForm } from "../../components/Articles/Form";
import { getQueryStringValue } from '../../utilities/history';

class EditAgroraports extends Component {
  render() {
    const id = this.props.match.params.id;
    const { permission } = this.props;
    const report = this.props.match.path.includes('/agroraport');

    return (
      <Layout>
        <h1 className="h2 pb-md-4 page-header">
          {getQueryStringValue('group') ? (
            <>
              <strong>Dodajesz tłumaczenie</strong> artykułu
            </>
          ) : (
            'Edycja artykułu'
          )}
        </h1>{' '}
        <AritcleForm articleid={id} report={report} />
      </Layout>
    );
  }
}

export default EditAgroraports;
