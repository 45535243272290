import React from 'react';
import Layout from '../../components/Layout/index.jsx';
import { Button, Input, Row, Col } from 'reactstrap';
import PollsList from '../../components/Polls/PollsList';

class Polls extends React.Component {
  render() {
    return (
      <Layout>
        <h1 className="h2 pb-md-4 page-header">
          Sondy{' '}
          <Button
            onClick={e => {
              e.preventDefault();
              this.props.history.push('/polls/new');
            }}
            color="primary"
          >
            Dodaj nową
          </Button>
        </h1>
        <Row className="space-between">
          <Col>
            <h4>Lista sond</h4>
            <PollsList />
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default Polls;
